<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-title style="justify-content: start !important">
			<span v-text="$t('virtualWalletListingCard.title')"></span>
		</v-card-title>
		<v-card-text>
			<p v-if="!dense" v-text="$t('virtualWalletListingCard.desc')"></p>

			<v-alert v-if="value.results.length === 0" text prominent type="info">
				<span v-text="$t('virtualWalletListingCard.empty')"></span>
			</v-alert>

			<v-list>
				<template v-for="(item, itemIdx) in value.results">
					<v-divider v-if="!dense || itemIdx > 0" :key="itemIdx + '_sep'" />
					<VirtualWalletCard :key="itemIdx" :value="item" :user-name="$options.filters.completeUserName(user)" :route="route" flat hide-link />
				</template>
				<template v-if="!dense && $root.websiteConfig.data.fields.wristband_assignment === '1'" >
					<v-divider />
					<v-list-item class="flex-column flex-md-row">
						<v-list-item-icon class="d-flex justify-center w-100-sm-only">
							<v-avatar size="128" color="secondaryLight">
								<v-icon size="96">mdi-window-close</v-icon>
							</v-avatar>
						</v-list-item-icon>
						<v-list-item-content>
							<template v-if="!assigning">
								<h3 class="title text-center text-md-left" v-text="$t('virtualWalletListingCard.assign.title', {
									media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale)
								})"></h3>
								<v-btn class="mt-1" color="button" :block="$vuetify.breakpoint.smAndDown" @click="handleAssignClick">
									<v-icon left>mdi-account-plus</v-icon>
									<span v-text="$t('virtualWalletListingCard.assign.btn')"></span>
								</v-btn>
							</template>
							<template v-else>
								<v-form :disabled="adding" ref="form" data-test-selector="virtual_wallet_assign_form" v-model="formIsValid" @submit.prevent="handleSubmit" lazy-validation>
									<v-text-field
										v-model="data.uid"
										:rules="[rules.required]"
										:label="$t('input.uid')"
										outlined
										autofocus
										:error-messages="formErrors.uid"
										background-color="white"
										@input="formErrors = {}"
									></v-text-field>

									<v-btn type="submit" color="button" block :loading="adding" :disabled="adding">
										<v-icon left>mdi-account-plus</v-icon>
										<span v-text="$t('virtualWalletListingCard.assign.btn')"></span>
									</v-btn>
								</v-form>
							</template>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-card-text>

		<v-list-item v-if="value.results.length > 0" class="justify-end">
			<v-list-item-action-text>
				<router-link class="text-decoration-none d-flex align-center" :to="routeAll">
					<span v-text="$t('virtualWalletListingCard.sellAll')"></span>
					<v-icon right x-small>mdi-arrow-right</v-icon>
				</router-link>
			</v-list-item-action-text>
		</v-list-item>
	</v-card>
</template>

<script>
import VirtualWalletCard from '@/components/VirtualWalletCard';
import { AccountModel, PaginationModel, Rules, EComService } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletListingCard',

	components: { VirtualWalletCard },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		route: {
			type: Function,
			default: item => ({ name: 'account_section_sub', params: { section: 'wallet', sub: item.data.id } }),
		},
		routeAll: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'wallet' } }),
		},
		user: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
	},

	data: () => ({
		assigning: false,
		adding: false,
		formIsValid: false,
		formErrors: {},
		rules: {},
		data: {
			uid: '',
		},
	}),

	computed: {
		size() {
			return this.dense ? 64 : 128;
		},
	},

	methods: {
		handleAssignClick() {
			this.assigning = true;
		},
		handleSubmit() {
			this.formErrors = {};
			if (this.$refs.form.validate()) {
				this.adding = true;
				new EComService().createVirtualWallet(this.user.data.id, this.data.uid)
					.then(wallet => {
						this.assigning = false;
						this.$emit('created', wallet);
					})
					.catch(error => {
						if (error.response.status === 422) {
							this.formErrors.uid = error.messages.error;
						} else {
							this.formErrors.uid = this.$i18n.t('error.invalidUid');
						}
					})
					.finally(() => this.adding = false);
			}
		},
	},

	created() {
		this.rules.required = value => Rules.required(value) || this.$t('rules.required');
	},
}
</script>
