<template>
  <div>
    <v-btn
        icon
        fab
        x-small
        @click="minusClicked"
        :loading="loading"
        class="pa-0 ma-0 title font-weight-bold"
        :disabled="!minusEnabled"
        style="border: 1px solid #CCCDD1;"
    >
      <v-icon> mdi-minus </v-icon>
    </v-btn>
    <span class="mx-3 font-weight-bold" style="color: #686464">
      {{quantity}}
    </span>
    <v-btn
        icon
        fab
        x-small
        @click="plusClicked"
        :loading="loading"
        class="pa-0 ma-0 title font-weight-bold"
        :disabled="!plusEnabled"
        style="border: 1px solid #CCCDD1"
    >
      <v-icon> mdi-plus </v-icon>
    </v-btn>
  </div>
</template>

<script>
import ProductMixin, { WindowWidthMixin } from '@/mixins/ProductMixin'

export default  {
  name : 'CartProductQuantityPicker',
  mixins : [WindowWidthMixin, ProductMixin],
  props : {
    processing : {
      type : Boolean,
      default : false
    },
    reloadWalletId : {
      type : Number | null,
      default : null
    }
  },
  data() {
    return {
      increment : this.product.data.increment || 1
    }
  },
  computed : {
    minusEnabled() {
      return !this.loading && this.quantity > 1 && (!this._min || (this.quantity > this._min && this.quantity - this.increment >= this._min));
    },
    plusEnabled() {
      return !this.loading  && (!this._max || (this.quantity < this._max && this.quantity + this.increment <= this._max ));
    }
  },
  methods : {
    plusClicked() {
      if (!this.plusEnabled) {
        return;
      }
      this.quantity = this.quantity + this.increment;
      this.handleApplyChanges();
    },
    minusClicked() {
      if (!this.minusEnabled) {
        return;
      }
      this.quantity = this.quantity - this.increment;
      this.handleApplyChanges(this.reloadWalletId)
    }
  }

}
</script>


<style scoped lang="scss">

</style>