<template>
	<v-skeleton-loader v-if="skeleton" v-bind="$attrs" v-on="$listeners" type="heading"></v-skeleton-loader>
	<v-sheet v-else color="transparent">

		<v-input
			v-model="_value"
			v-on="$listeners"
			:rules="rules"
			hide-details
		/>

		<v-select
			v-model="selection"
			v-bind="$attrs"
			:items="_items"
			:label="$t('reloadAssigner.label')"
			:placeholder="$t('reloadAssigner.placeholder')"
			:item-disabled="disableItem"
			:loading="loading"
			item-value="id"
			return-object
			@change="handleChange"
		>
			<template #selection="{ item }">
				<div class="d-flex align-center">
					<v-icon style="flex: 0" class="mr-3" v-text="item.icon"></v-icon>
					<div v-if="!isNaN(item.id)" class="py-3">
						<strong v-text="item.text"></strong>
						<br><span class="text-capitalize" v-text="$t('reloadAssigner.accountItem', {
							media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
							number: item.uid,
						})"></span>
					</div>
					<div v-else v-text="item.text"></div>
				</div>
			</template>
			<template #item="{ item }">
				<div class="d-flex">
					<v-icon style="flex: 0" class="mr-3" v-text="item.icon"></v-icon>
					<div v-if="!isNaN(item.id)" class="py-3">
						<strong v-text="item.text"></strong>
						<br><span class="text-capitalize" v-text="$t('reloadAssigner.accountItem', {
							media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
							number: item.uid,
						})"></span>
					</div>
					<div v-else v-text="item.text"></div>
				</div>
			</template>
		</v-select>

		<template v-if="selection">

			<!-- MEMBER: WARNINGS -->
			<template >
				<v-alert v-if="state === 'success'" type="success" dense>
					<span v-text="$t('alert.reloadSuccessful')"></span>
				</v-alert>
				<v-alert v-else-if="state === 'error'" type="error" dense>
					<span v-if="formErrors.member" v-text="formErrors.member.error"></span>
					<span v-if="formErrors.mediaNotFound" v-text="$t('alert.mediaNotFound', {
						media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
					})"></span>
				</v-alert>
			</template>

			<!-- NEW MEDIA -->
			<v-form v-if="selection.key === 'new_media'" :ref="selection.key + '_form'" :disabled="waiting" :data-test-selector="'reload_assigner_' + selection.key + '_form'" v-model="formValid[selection.key]" @submit.prevent="handleSubmitNewMedia">
				<v-text-field
					name="number"
					v-model="data.newMedia.uid"
					:rules="[_rules.required]"
					:label="$t('reloadAssigner.mediaName.label', {
						media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale).substring(0, 1).toUpperCase() + $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale).substring(1),
					})"
					:error-messages="formErrors[selection.key].member"
					@input="formErrors[selection.key] = {}"
					:disabled="waiting"
					autofocus
					outlined
				>
					<template #append>
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">
									mdi-help-circle-outline
								</v-icon>
							</template>
							<span v-text="$t('reloadAssigner.mediaName.help')"></span>
						</v-tooltip>
					</template>
				</v-text-field>

				<v-select
					v-model="data.newMedia.account"
					:items="_subAccounts"
					v-bind="$attrs"
					:label="$t('reloadAssigner.pickMember', {
						media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
					})"
					:placeholder="$t('reloadAssigner.pickMember', {
						media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
					})"
					return-object
				>
					<template #selection="{ item }">
						<div class="d-flex align-center">
							<v-icon style="flex: 0" class="mr-3" v-text="item.icon"></v-icon>
							<div v-if="!isNaN(item.id)" class="py-3">
								<strong v-text="item.text"></strong>
								<br><span class="text-capitalize" v-text="$t('reloadAssigner.accountItem', {
							media: 'account',
							number: item.id,
						})"></span>
							</div>
							<div v-else v-text="item.text"></div>
						</div>
					</template>
					<template #item="{ item }">
						<div class="d-flex">
							<v-icon style="flex: 0" class="mr-3" v-text="item.icon"></v-icon>
							<div v-if="!isNaN(item.id)" class="py-3">
								<strong v-text="item.text"></strong>
								<br><span class="text-capitalize" v-text="$t('reloadAssigner.accountItem', {
							media: 'account',
							number: item.id,
						})"></span>
							</div>
							<div v-else v-text="item.text"></div>
						</div>
					</template>
				</v-select>

				<v-row class="mt-4">
					<v-col cols="12" md="6">
						<v-btn type="submit" color="button" block :disabled="!canConfirm" :loading="waiting">
							<span v-text="$t('btn.confirm')"></span>
						</v-btn>
					</v-col>
					<v-col cols="12" md="6">
						<v-btn color="button" outlined block :disabled="!canCancel" @click="handleCancelClick">
							<span v-text="$t('btn.cancel')"></span>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</template>
	</v-sheet>
</template>

<script>
import { PaginationModel, Rules, AccountModel, ReloadProductModel, EComService } from '@connectngo/sdk';

let dataSearchQueryTimeout;
const emptyData = {
	newMedia: {
		number: '',
		found: false,
		first_name: '',
		last_name: '',
		custom_fields: [],
	},
	search: {
		account: null,
		query: '',
	},
};

export default {
	name: 'ReloadAssigner',

	props: {
		value: {
			type: Object,
			default: () => null,
		},
		wallets: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		subAccounts: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		reloadItem: {
			type: Object,
			default: () => new ReloadProductModel(),
		},
		rules: {
			type: Array,
			default: () => ([]),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		noWristband: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		selection: null,
		waiting: false,
		canConfirm: false,
		items: [],
		searchedWallets: new PaginationModel(),
		data: {},
		state: null,
		formValid: {},
		formErrors: {},
		_rules: {},
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		_wallets: {
			get() {
				return this.wallets;
			},
			set(value) {
				this.$emit('update:wallets', value);
			},
		},
		_items() {
			return this.wallets.results.map(wallet => ({
				id: wallet.data.id,
				uid: wallet.data.uid && wallet.data.uid.data.uid,
				icon: 'mdi-pound',
				key: 'member',
				text: this.$options.filters.translatable(wallet.data.wallet_type.data.name, wallet.data.wallet_type.data.name_i18n, this.$i18n.locale),
			})).concat(this.items).map(item => ({
				data: {},
				...item,
			}));
		},
		_subAccounts() {
			return this.subAccounts.results.map(account => ({
				id: account.data.id,
				icon: 'mdi-pound',
				key: 'member',
				text: account.data.firstname,
			}));
		},
		canCancel() {
			return this.canConfirm;
		},
	},

	methods: {
		handleChange() {
			this.searchCompleted = false;
			this.missingFields = null;
			this.state = null;
			this.handleSubmit();
		},

		handleSubmit() {
			this.formErrors[this.selection.key] = {};
			switch (this.selection.key) {
				case 'member':
					this.attachWallet(this.selection.id);
					break;
			}
		},

		handleSubmitNewMedia() {
			this.formErrors[this.selection.key] = {};
			if (this.$refs[this.selection.key + '_form'].validate()) {
				this.waiting = true;
				new EComService().createVirtualWallet(this.data.newMedia.account.id, this.data.newMedia.uid)
					.then((wallet) => {
						this.wallets.results.push(wallet);
						this.selection = {
							id: wallet.data.id,
							uid: wallet.data.uid.data.uid,
							icon: 'mdi-pound',
							key: 'member',
							text: this.$options.filters.translatable(wallet.data.wallet_type.data.name, wallet.data.wallet_type.data.name_i18n, this.$i18n.locale)
						};
						this.attachWallet(wallet.data.id);
					})
					.catch(error => {
						switch (error.response.status) {
							case 404:
								this.state = 'error';
								this.formErrors.mediaNotFound = true;
								break;
							default:
								this.$handleError(this, error);
								break;
						}
					})
					.finally(() => this.waiting = false);
			}
		},

		attachWallet(walletId) {
			this.waiting = true;
			return new EComService().attachWalletToCartDetail(
					this.reloadItem.data.id,
					walletId,
					null,
					this.reloadItem.data.transaction_detail_index
				)
				.then(() =>	this.state = 'success')
				.catch(error => {
					this.state = 'error';
					this.$handleError(this, { errors: error.messages }, this.formErrors.member, false);
				})
				.finally(() => this.waiting = false);
		},

		handleCancelClick() {
			this.cleanSelection();
		},

		cleanSelection() {
			this.data = this.$deepClone(emptyData);
			this.formErrors = null;
			this.selection = null;
			this.searchCompleted = false;
		},

		disableItem() {
			return !(this.selection === null || this.selection.key === 'retrieve' || this.selection.key === 'new_media');
		},
	},

	created() {

		this.data = this.$deepClone(emptyData);

		this.items = [];

		if (!this.noWristband) {
			this.items.push({ key: 'new_media', icon: 'mdi-plus-box-outline', text: this.$i18n.t('reloadAssigner.newMedia', {
				media: this.$options.filters.translatable(this.$root.websiteConfig.data.fields.media_name, this.$root.websiteConfig.data.fields.media_name_i18n, this.$i18n.locale),
			}) });
		}

		this.items.push({ key: 'retrieve', icon: 'mdi-arrow-right', text: this.$i18n.t('reloadAssigner.retrieve') });

		this.items.forEach(item => {
			this.formErrors[item.key] = {};
			this.formValid[item.key] = false;
		});

		if (this.reloadItem.data.wallet) {
			this.selection = {
				id: this.reloadItem.data.wallet.data.id,
				icon: 'mdi-pound',
				key: 'member',
				text: this.$options.filters.translatable(this.reloadItem.data.wallet.data.wallet_type.data.name, this.reloadItem.data.wallet.data.wallet_type.data.name_i18n, this.$i18n.locale)
			}
		}

		this._rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			email: value => Rules.email(value) || this.$t('rules.email'),
		};
	},

	destroyed() {
		clearTimeout(dataSearchQueryTimeout);
	},

	watch: {
		data: {
			deep: true,
			handler() {
				setTimeout(() => {
					this.canConfirm = !this.waiting && this.selection && this.formValid[this.selection.key];
				});
			},
		},

		selection(value) {
			if (value && ['member', 'retrieve'].indexOf(value.key) !== -1) {
				this._value = value;
			} else {
				this._value = null;
			}
		},

		'data.search.account'(value) {
			clearTimeout(dataSearchQueryTimeout);
		},

		'data.search.query'(value) {
			if (value) {
				clearTimeout(dataSearchQueryTimeout);
				dataSearchQueryTimeout = setTimeout(() => {
					this.searchByEmail(value);
				}, 1000);
			}
		},
	},
}
</script>
