import Form from "./Form";
import ServerError from "./ServerError";
import * as Sentry from "@sentry/vue";

const $error: any = {};

$error.install = function (Vue: any, options?: any) {
	Vue.prototype.$handleError = function(vm: any, error: any, formErrors?: any, showSnack = true) {
		const i18nError = (error.errorI18n) ? this.$i18n.t(error.errorI18n) : null;
		if (i18nError) {
			error.messages.error = i18nError;
		}
		const errors = error.errors || (error.messages instanceof Object && error.messages);
		if (errors) {
			Form.applyErrors(vm, errors, formErrors);
			const errorObj = error.messages instanceof Object ? error.messages.error : null;

			// Extract the first error message
			let firstErrorMessage = '';
			for (const messages of Object.values(errors)) {
				firstErrorMessage = (messages as string[])[0]; // Take the first error message
				break; // Exit after finding the first error
			}

			if (errorObj) {
				Sentry.captureMessage(error.messages.error);
			}

			if (showSnack) {
				const checkForm = this.$i18n.t('error.checkForm');
				vm.$root.$snack(`${errorObj || checkForm}\n${firstErrorMessage}`, 'error', 'mdi-alert-rhombus');
			}
		} else {
			vm.$root.error = new ServerError(vm, error);
			Sentry.captureException(vm.$root.error);
		}
	};

	Vue.prototype.$isAlreadyPaid = function (error: any): boolean {
		return error.response.status === 409 && error.hasOwnProperty('no_balance_left') && error.no_balance_left === true;
	};

	Vue.prototype.$isAlreadyLocked = function (error: any): boolean {
		return error.response.status === 423 && error.hasOwnProperty('timeout');
	};
}

export default $error;
