<template>
	<v-skeleton-loader v-if="skeleton" v-bind="$attrs" v-on="$listeners" type="heading"></v-skeleton-loader>
	<v-sheet v-else color="transparent">

		<v-input
			v-model="_value"
			v-on="$listeners"
			:rules="rules"
			hide-details
		 />

		<!-- MAIN SELECTOR -->
		<v-select
			v-model="selection"
			v-bind="$attrs"
			:items="_items"
			item-title="text"
			item-value="id"
			:label="$t('reloadAssigner.label')"
			:placeholder="$t('reloadAssigner.placeholder')"
			:rules="[_rules.required]"
			:loading="loading"
			clearable
			return-object
			@change="handleChange"
		>
			<template #selection="{ item }">
				<div class="d-flex align-center">
					<div v-if="!isNaN(item.id)" class="py-3">
						<strong v-text="item.text"></strong>
					</div>
					<div v-else v-text="item.text"></div>
				</div>
			</template>
			<template #item="{ item }">
				<div class="d-flex">
					<div v-if="!isNaN(item.id)" class="py-3">
						<strong v-text="item.text"></strong>
					</div>
					<div v-else v-text="item.text"></div>
				</div>
			</template>
		</v-select>

		<template v-if="selection">

			<!-- MEMBER: WARNINGS -->
			<template v-if="selection.key === 'member'">
				<v-alert v-if="state === 'success'" type="success" dense>
					<span v-text="$t('alert.accountAssigned')"></span>
				</v-alert>
				<v-alert v-else-if="state === 'update'" type="error" dense>
					<span v-text="$t('alert.pleaseUpdateAccountInfo')"></span>
				</v-alert>
				<v-alert v-else-if="state === 'requirements'" type="error" dense>
					<span v-text="$t('alert.externalAccountDoesntMeetRequirements')"></span>
				</v-alert>
			</template>

			<!-- SEARCH -->
			<v-form v-if="selection.key === 'search'" :ref="selection.key + '_form'" :data-test-selector="'reload_assigner_' + selection.key + '_form'" v-model="formValid[selection.key]" @submit.prevent="handleSubmit">
				<v-autocomplete
					v-model="data.search.account"
					:search-input.sync="data.search.query"
					:items="searchedAccounts.results"
					:rules="[_rules.required]"
					:label="$t('reloadAssigner.email')"
					:error-messages="formErrors[selection.key].email"
					:loading="loadingSearch"
					item-value="data.id"
					autofocus
					no-filter
					hide-no-data
					hide-selected
					outlined
					clearable
					return-object
					@change="handleSearchedChange"
					@click:clear="detachAccount"
					@input="formErrors[selection.key] = {}"
				>
					<template #selection="{ item }">
						<div class="d-flex align-center">
							<div class="py-3">
								<strong v-text="getAccountName(item)"></strong>
							</div>
						</div>
					</template>
					<template #item="{ item }">
						<div class="d-flex">
							<div class="py-3">
								<strong v-text="getAccountName(item)"></strong>
							</div>
						</div>
					</template>
				</v-autocomplete>


				<v-alert v-if="state === 'success'" type="success" dense>
					<span v-text="$t('alert.accountAssigned')"></span>
				</v-alert>
				<v-alert v-else-if="searchCompleted && searchedAccounts.results.length === 0" type="warning" dense>
					<span v-text="$t('productAssigner.noAccountFoundByEmail')"></span>
				</v-alert>
				<v-alert v-else-if="state === 'update'" type="error" dense>
					<span v-text="$t('alert.pleaseUpdateAccountInfo')"></span>
				</v-alert>
				<v-alert v-else-if="state === 'requirements'" type="error" dense>
					<span v-text="$t('alert.externalAccountDoesntMeetRequirements')"></span>
				</v-alert>

				<v-btn color="button" outlined block :disabled="!canCancel" @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-form>

			<!-- NEW MEMBER -->
			<v-form v-if="selection.key === 'new_member'" :ref="selection.key + '_form'" :data-test-selector="'reload_assigner_' + selection.key + '_form'" v-model="formValid[selection.key]" @submit.prevent="handleSubmit">

				<v-text-field
					v-model="data.new_member.firstName"
					:label="$t('input.first_name')"
					:error-messages="formErrors.firstName"
					:rules="[_rules.required]"
					outlined
					background-color="white"
					@input="formErrors = {}"
				></v-text-field>

				<v-text-field
					v-model="data.new_member.lastName"
					:label="$t('input.last_name')"
					:error-messages="formErrors.lastName"
					:rules="[_rules.required]"
					outlined
					background-color="white"
					@input="formErrors = {}"
				></v-text-field>

				<v-text-field
					v-model="data.new_member.email"
					:label="$t('input.email')"
					:error-messages="formErrors.email"
					:rules="[_rules.required, _rules.email]"
					outlined
					background-color="white"
					@input="formErrors = {}"
				></v-text-field>

				<CustomField
					v-for="field in customFields.results"
					:value="data.new_member.fields[field.key]"
					v-model="data.new_member.fields[field.data.name]"
					:key="field.data.id"
					:field="field"
					:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
					:error-messages="formErrors[field.data.name]"
					:required="field.data.required"
					:rules="_rules"
					class="pt-0 mt-0"
					outlined
					inset
					@input="formErrors = {}"
				/>

				<v-btn color="button" block :disabled="!canAddSubAccount" :loading="addingSubAccount" @click="handleAddNewSubAccountClick">
					<span v-text="$t('btn.addNewSubAccount')"></span>
				</v-btn>

				<v-btn class="mt-2" color="button" outlined block :disabled="!canCancel" @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-form>

			<!-- CUSTOM FIELDS -->
			<v-form v-if="missingFields" :ref="'update_required_account_fields_form'" :data-test-selector="'update_required_field_' + selection.key + '_form'" v-model="formValid[selection.key]" @submit.prevent="handleAccountSubmit">
				<v-card>
					<v-card-text>
						<CustomField
							v-for="field in missingFields.results"
							v-model="requiredUser.data.fields[field.data.name]"
							:key="field.id"
							:field="field"
							:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
							:error-messages="formErrors.fields?.[field.data.name] || []"
							:required="field.data.required !== undefined ? field.data.required : false"
							class="pt-0 mt-0"
							outlined
							inset
						/>
						<v-btn color="button" :loading="loading" :disabled="loading" block @click="handleAccountSubmit">
							<span v-text="$t('btn.save')"></span>
						</v-btn>
					</v-card-text>
				</v-card>
			</v-form>
		</template>
	</v-sheet>
</template>

<script>
import CustomField from './CustomField';
import { PaginationModel, BaseModel, Rules, AccountModel, EComService, CartItemModel } from '@connectngo/sdk';
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

let dataSearchQueryTimeout;
const emptyData = {
	search: {
		account: null,
		query: '',
	},
	new_member: {
		fields: {},
	},
};

export default {
	name: 'ProductAssigner',

	components: { CustomField },

	mixins : [ReloadV2Mixin],

	props: {
		value: {
			type: Object,
			default: () => null,
		},
		users: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		cartDetail: {
			type: Object,
			default: () => new CartItemModel(),
		},
		rules: {
			type: Array,
			default: () => ([]),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		noWristband: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
			default: 0,
		}
	},

	data: () => ({
		selection: null,
		loading: false,
		loadingSearch: false,
		addingSubAccount: false,
		canConfirm: false,
		items: [],
		searchedAccounts: new PaginationModel(),
		searchedAccountSelection: null,
		selectionMissingRequirements: false,
		requiredUser: null,
		missingFields: null,
		searchCompleted: false,
		data: {},
		state: null,
		formValid: {},
		formErrors: {
			member: {},
			new_member: {},
			search: {},
			fields: {},
			retrieve: {},
		},
		_rules: {},
		customFields: new PaginationModel(),
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		_users: {
			get() {
				return this.users;
			},
			set(value) {
				this.$emit('update:users', value);
			},
		},
		_items() {
			return this.users.results.map(user => ({
				id: user.data.id,
				icon: 'mdi-pound',
				key: 'member',
				text: this.getAccountName(user),
			})).concat(this.items).map(item => ({
				data: {},
				...item,
			}));
		},
		canAddSubAccount() {
			return this.canConfirm && !this.addingSubAccount;
		},
		canCancel() {
			return this.canConfirm && !this.addingSubAccount;
		},
	},

	methods: {
		handleChange(value) {
			if(value) {
				this.searchCompleted = false;
				this.missingFields = null;
				this.handleSubmit();
			} else {
				this.cleanSelection();
				this.detachAccount();
			}
		},

		handleSearchedChange(value) {
			if (value) {
				value.data.is_searched_account = true;
				this.users.results.push(new AccountModel(value.data));
				this.searchedAccountSelection = {
					id: value.data.id,
					icon: 'mdi-pound',
					key: 'member',
					text: value.data.firstname,
				}
				this._value = this.searchedAccountSelection;
				this.attachAccount(value.data.id)
			} else {
				this.cleanSelection();
				this.detachAccount();
			}
		},

		handleSubmit() {
			if (this.selection) {
				this.formErrors[this.selection.key] = {};
				switch (this.selection.key) {
					case 'search':
						if(this.searchedAccountSelection) {
							this.selection = this.searchedAccountSelection;
							this.attachAccount(this.searchedAccountSelection.id);
						}
						break;
					case 'member':
						this.attachAccount(this.selection.id);
						break;
					case 'retrieve':
						this.detachAccount();
						break;
				}
			} else {
				this.$snack(this.$i18n.t('alert.missingSelection'), 'error', 'mdi-window-close');
			}
		},

		handleAccountSubmit() {
			if (this.$refs.update_required_account_fields_form.validate()) {
				this.loading = true;
				return Promise.all([
					new EComService().patchAccount(this.requiredUser.data.id, {
						fields: this.requiredUser.data.fields
					}),
				])
					.then(([account]) => {
						return this.attachAccount(account.data.id)
							.then(() => {
								this.missingFields = null;
							})
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			}
		},

		handleAddNewSubAccountClick() {
			if (this.$refs.new_member_form.validate()) {
				this.addingSubAccount = true;
				return Promise.all([
					new EComService().createSubAccount(
						this.data.new_member.email,
						null,
						this.$i18n.locale,
						this.data.new_member.firstName,
						this.data.new_member.lastName,
						this.$root.user.data.id,
						this.data.new_member.fields,
					)
				])
					.then(subAccount => {
						this.$snack(this.$i18n.t('productAssigner.subAccountCreated'));
						this.users.results.push(subAccount[0]);
						this.selection = {
							id: subAccount[0].data.id,
							icon: 'mdi-pound',
							key: 'member',
							text: this.getAccountName(subAccount[0]),
						}
						this.attachAccount(subAccount[0].data.id);
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.addingSubAccount = false));
			}
		},

		detachAccount() {
			this.loading = true;
			return new EComService().unassignCartDetailAccount(this.cartDetail.data.id)
				.catch(error => this.$handleError(this, error))
				.finally(() => this.loading = false);
		},

		attachAccount(accountId) {
			this.loading = true;
			return new EComService().assignCartDetailAccount(this.cartDetail.data.id, accountId, this.index)
				.then(() => this.state = 'success')
				.catch(error => {
					switch (error.response.status) {
						case 422:
							if (this.users.getById(accountId).data.is_searched_account) {
								this.state = 'requirements';
							} else {
								const fields = {};
								error.fields.forEach(field => {
									fields[field.name] = error.messages.error;
								});
								this.$handleError(this, { errors: fields }, this.formErrors.member, false);
								this.state = 'update';

								this.requiredUser = this.users.getById(accountId);
								this.missingFields = new PaginationModel(error.fields, BaseModel);
								this.missingFields.data = [];
							}
							break;
						default:
							this.$handleError(this, error);
							break;
					}

					// Re-throw the error to propagate it to the calling function
					// and avoid to clean the form
					throw error;
				})
				.finally(() => this.loading = false);
		},

		cleanSelection() {
			this.data = this.$deepClone(emptyData);
			this.requiredUser = null;
			this.missingFields = null;
			this.selection = null;
			this.searchCompleted = false;
		},

		handleCancelClick() {
			this.cleanSelection();
			this.detachAccount();
		},

		searchByEmail(email) {
			this.searchCompleted = false;
			this.loadingSearch = true;
			return new EComService().getAccountsByEmail(email)
				.then(response => {
					this.searchCompleted = true;
					this.searchedAccounts.setResults(response.results);
				})
				.catch(error => {
					this.$handleError(this, error, this.formErrors.search, false)
				})
				.finally(() => this.loadingSearch = false);
		},

		getAccountName(account) {
			return this.walletAccountName(account.data);
		},

		loadCustomFields() {
			this.loading = true;
			new EComService().getCustomFields()
				.then(customFields => {
					Object.assign(this, { customFields });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.data = this.$deepClone(emptyData);
		this.items = [];

		if (!this.noWristband) {
			this.items.push({ key: 'new_media', icon: 'mdi-plus-box-outline', text: this.$i18n.t('reloadAssigner.newMedia', {
				media: this.$options.filters.translatable(this.$root.websiteConfig.data.fields.media_name, this.$root.websiteConfig.data.fields.media_name_i18n, this.$i18n.locale),
			}) });
		}

		this.items.push({ key: 'new_member', icon: 'mdi-account-plus-outline mdi-flip', text: this.$i18n.t('reloadAssigner.newMember') });
		this.items.push({ key: 'search', icon: 'mdi-account-search-outline', text: this.$i18n.t('reloadAssigner.search') });

		this.items.forEach(item => {
			this.formErrors[item.key] = {};
			this.formValid[item.key] = false;
		});

		if (this.cartDetail.data.account) {
			if(this.users.getById(this.cartDetail.data.account.id) === undefined){
				this.cartDetail.data.account.is_searched_account = true;
				this.users.results.push(new AccountModel(this.cartDetail.data.account));
			}
			this.selection = {
				id: this.cartDetail.data.account.id,
				icon: 'mdi-pound',
				key: 'member',
				text: this.cartDetail.data.account.firstname,
			}

			// Check for existing account required field for assignment made from reload
			if(this.cartDetail.data.missing_account_fields.length === 0) {
				this.state = 'success'
			} else {
				const fields = {};
				this.cartDetail.data.missing_account_fields.forEach(field => {
					fields[field.name] = field;
				});
				this.$handleError(this, { errors: fields }, this.formErrors.member, false);
				this.state = 'update';

				this.requiredUser = this.users.getById(this.cartDetail.data.account.id);
				this.missingFields = new PaginationModel(this.cartDetail.data.missing_account_fields, BaseModel);
				this.missingFields.data = [];
			}
		}

		this._rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			email: value => Rules.email(value) || this.$t('rules.email'),
		};
	},

	destroyed() {
		clearTimeout(dataSearchQueryTimeout);
	},

	watch: {
		data: {
			deep: true,
			handler() {
				setTimeout(() => {
					this.canConfirm = !this.loadingSearch && this.selection && this.formValid[this.selection.key];
				});
			},
		},

		selection(value) {
			if (value && value.key === 'new_member') {
				this.loadCustomFields();
			}

			if (value && ['member', 'retrieve'].indexOf(value.key) !== -1) {
				this._value = value;
			} else {
				this._value = null;
			}
		},

		'data.search.account'(value) {
			clearTimeout(dataSearchQueryTimeout);
		},

		'data.search.query'(value) {
			this.formErrors.search = {};
			if (value) {
				clearTimeout(dataSearchQueryTimeout);
				dataSearchQueryTimeout = setTimeout(() => {
					this.searchByEmail(value);
				}, 1000);
			}
		},
	},
}
</script>
