<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions"></v-skeleton-loader>
	<v-form v-else id="editContactInfoCard" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation @submit="handleSubmit">
		<v-card v-bind="$attrs" v-on="$listeners">

			<v-card-title class="text-left">
				<span v-text="$t('account.edit.info.title')"></span>
			</v-card-title>
			<v-card-text>

				<v-expand-transition>
					<v-alert v-if="saved" type="success" prominent dismissible>
						<span v-text="$t('alert.infoUpdated')"></span>
					</v-alert>
				</v-expand-transition>

				<v-text-field v-if="$route.params.is_b2b"
					v-model=" _value.data.company_name"
					:label="$t('input.company_name')"
					:error-messages="formErrors.company_name"
					:rules="[rules.required]"
					:loading="skeleton"
					disabled
					outlined
					@input="handleInput">
				</v-text-field >

				<v-text-field
					v-if="!$route.params.is_b2b"
					v-model=" _value.data.firstname"
					:label="$t('input.first_name')"
					:error-messages="formErrors.first_name"
					:rules="[rules.required]"
					:loading="skeleton"
					outlined
					@input="handleInput"
				></v-text-field>

				<v-text-field
					v-if="!$route.params.is_b2b"
					v-model="_value.data.lastname"
					:label="$t('input.last_name')"
					:error-messages="formErrors.last_name"
					:rules="[rules.required]"
					:loading="skeleton"
					outlined
					@input="handleInput"
				></v-text-field>

				<p v-if="!$route.params.is_b2b" v-text="$t('account.edit.info.explication')"></p>
				<p class="font-weight-bold" v-text="$t('account.edit.info.warning')"></p>

				<v-text-field
					v-model="_value.data.email"
					:label="$t('input.email')"
					:error-messages="formErrors.email"
					:rules="[rules.required, rules.email]"
					:loading="skeleton"
					:disabled="$route.params.is_b2b ? true : false"
					outlined
					@input="handleInput"
				></v-text-field>

				<CustomField
					v-for="field in customFields.results"
					v-model="_value.data.fields[field.data.name]"
					:key="field.data.id"
					:field="field"
					:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
					:error-messages="formErrors[field.data.name]"
					:required="field.data.required"
					:rules="rules"
					class="pt-0 mt-0"
					outlined
					inset
					v-if="!$route.params.is_b2b"
					@input="handleInput"
				/>
			</v-card-text>
			<v-card-actions v-if="!$route.params.is_b2b">
				<v-btn color="button" type="submit" :loading="loading" :disabled="!canSave" block @click.prevent="handleSubmit">
					<v-icon left>mdi-content-save-outline</v-icon>
					<span v-text="$t('btn.save')"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import CustomField from '@/components/CustomField';
import { Rules, AccountModel, EComService, PaginationModel} from '@connectngo/sdk';

export default {
	name: 'EditContactInfoCard',

	components: { CustomField },

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		saved: false,
		formIsValid: true,
		formErrors: {},
		customFields: new PaginationModel(),
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		canSave() {
			return !this.loading && this.formIsValid && !this.skeleton;
		},
		canSubmit() {
			return !this.loading && !this.skeleton;
		},
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
			};
		},
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},

		handleSubmit() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				return Promise.all([
					new EComService().patchAccount(this.value.data.id, {
						email: this.value.data.email,
						firstname: this.value.data.firstname,
						lastname: this.value.data.lastname,
						fields: this.value.data.fields,
					}),
				])
					.then(([account]) => {
						Object.assign(this._value.data, account.data);
						Object.assign(this.$emit('input', account));

						this.$route.meta.sub = this.$options.filters.shortUserName(account);
						this.$breadcrumbs.refresh();

						this.$snack(this.$i18n.t('alert.infoUpdated'));
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			}
		},

		load() {
			this.loading = true;
			new EComService().getCustomFields()
				.then(customFields => {
					this.$route.meta.sub = this.$options.filters.shortUserName(this._value);
					Object.assign(this, { customFields });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
