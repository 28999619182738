var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',_vm._g(_vm._b({},'v-container',_vm.$attrs,false),_vm.$listeners),[(_vm.loadingProductPdf)?_c('ProcessOverlay'):_vm._e(),_c('Modal',{attrs:{"title":_vm.$t('account.transactions.products.pdfModal.title'),"max-width":"800","scrollable":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"text-center",domProps:{"textContent":_vm._s(_vm.$t('pdfGeneration.processing'))}})]},proxy:true},{key:"buttons",fn:function(){return [_c('v-btn',{on:{"click":function($event){_vm.showProductsPdfProcessing = false}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.close'))}})])]},proxy:true}]),model:{value:(_vm.showProductsPdfProcessing),callback:function ($$v) {_vm.showProductsPdfProcessing=$$v},expression:"showProductsPdfProcessing"}}),_c('div',{staticClass:"text-center d-none d-print-block mb-4"},[_c('img',{attrs:{"src":_vm.$root.websiteConfig.data.images['logo-header'],"height":"75","alt":"Logo"}})]),_c('v-container',{staticClass:"mb-8 px-0",staticStyle:{"max-width":"55rem"}},[(_vm.invoice.data.reloadIncomplete)?_c('v-alert',{staticClass:"text-left mb-6",attrs:{"type":"error","outlined":"","text":"","prominent":"","icon":"mdi-exclamation"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('reloadConfirmation.error.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('reloadConfirmation.error.text', {media : _vm.mediaName})))])]):_vm._e(),_c('v-expansion-panels',{staticClass:"d-print-none",attrs:{"multiple":""},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.groups),function(group,groupIdx){return _c('v-expansion-panel',{key:groupIdx,class:{
						'mt-8': groupIdx > 0,
					}},[_c('v-expansion-panel-header',{staticClass:"d-flex justify-center align-center text-sm-h6"},[_c('div',{staticClass:"text-center",staticStyle:{"flex":"1"},domProps:{"textContent":_vm._s(group.text)}})]),_c('v-expansion-panel-content',[_c('v-row',[_vm._l((group.children),function(product,productIdx){return [(!product.data.return)?_c('v-col',{key:productIdx,attrs:{"cols":"12"}},[(!product.data.return)?_c('ProductConfirmationItem',{attrs:{"product":product,"invoice":_vm.invoice}}):_vm._e()],1):_vm._e()]})],2)],1)],1)}),1),_vm._l((_vm.groups),function(group,groupIdx){return _c('v-card',{key:groupIdx,class:{
					'd-none d-print-block': true,
					'mt-8': groupIdx > 0,
				}},[_c('v-card-title',{domProps:{"textContent":_vm._s(group.text)}}),_c('v-card-text',[_c('v-row',[_vm._l((group.children),function(product,productIdx){return [(!product.data.return)?_c('v-col',{key:productIdx,attrs:{"cols":"12","md":"6"}},[(!product.data.return)?_c('ProductConfirmationItem',{attrs:{"product":product}}):_vm._e()],1):_vm._e()]})],2)],1)],1)})],2),_c('v-container',{staticClass:"mb-8 px-0",staticStyle:{"max-width":"55rem"}},[_c('v-card',{staticClass:"py-4 py-md-12",attrs:{"data-testid":"orderConfirmationCard"}},[_c('v-card-title',{staticClass:"text-h4 mb-8",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.pageTitle'))}}),_c('v-card-text',[_c('h2',{domProps:{"textContent":_vm._s(
							_vm.$t('cart.confirmation.thankYouHeader', {
								name: _vm.$root.user.data.firstname,
							})
						)}}),_c('p',{staticClass:"mt-4",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.orderCompleted'))}}),_c('p',{domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.shouldReceiveDetails'))}}),_c('TransactionCard',{staticClass:"mt-8",attrs:{"value":_vm.invoice,"skeleton":_vm.skeleton,"to":{
							name: 'account_section_sub',
							params: {
								section: 'transactions',
								sub: _vm.invoice.data.uuid,
							},
						},"hide-details":"","flat":"","tile":""}}),_c('div',{staticClass:"mt-8 d-print-none"},[_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"data-testid":"printTransaction","color":"button","large":"","block":""},on:{"click":_vm.handlePrintClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer")]),_c('span',{domProps:{"textContent":_vm._s(
									_vm.$t('cart.confirmation.printTransaction')
								)}})],1),(_vm.invoice.data.account && !_vm.invoice.data.account.is_guest)?_c('div',{staticClass:"mt-12"},[_c('h3',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(
									_vm.$t('cart.confirmation.onlineAccount.title')
								)}}),_c('p',{domProps:{"textContent":_vm._s(
									_vm.$t('cart.confirmation.onlineAccount.desc')
								)}}),_c('v-btn',{staticClass:"mt-4",attrs:{"data-testid":"myAccountBtn","color":"button","large":"","block":"","to":{ name: 'account' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-right")]),_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t(
											'cart.confirmation.onlineAccount.goToAccount'
										)
									)}})],1)],1):_vm._e(),(_vm.invoice.data.has_product_pdf)?_c('div',{staticClass:"mt-12"},[_c('h3',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(
									_vm.$t('cart.confirmation.productPdf.title')
								)}}),_c('p',{domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.productPdf.desc'))}}),_c('v-btn',{staticClass:"mt-4",attrs:{"data-testid":"productPDFBtn","color":"button","large":"","block":""},on:{"click":_vm.handlePdfClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-right")]),_c('span',{domProps:{"textContent":_vm._s(
										_vm.$t(
											'cart.confirmation.productPdf.access'
										)
									)}})],1)],1):_vm._e()],1)],1)],1)],1),(_vm.productDetails.hasWaiver || _vm.productDetails.isMifare)?_c('v-container',{staticClass:"pa-print-0 mb-8 px-0",staticStyle:{"max-width":"55rem"}},[(_vm.productDetails.isMifare)?_c('v-card',{staticClass:"py-4 py-md-12"},[_c('v-card-title',{staticClass:"text-md-h4",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.firstTime.title'))}}),_c('v-card-text',[_c('p',{staticClass:"text-md-h6",domProps:{"innerHTML":_vm._s(
							_vm.$t('cart.confirmation.firstTime.desc', {
								media: _vm.$options.filters.translatable(
									_vm.$root.websiteConfig.data.fields.media_name,
									_vm.$root.websiteConfig.data.fields
										.media_name_i18n,
									_vm.$i18n.locale
								),
							})
						)}}),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"12","md":"6","order-md":"first"}},[_c('img',{staticClass:"d-print-none",staticStyle:{"max-width":"20rem","width":"100%"},attrs:{"src":_vm.$root.websiteConfig.data.images.ticketOffice,"alt":"First time"}})]),_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"12","md":"6","order-md":"last"}},[_c('p',{staticClass:"text-md-h5 text-center text-md-left d-md-block mb-0 px-md-12",domProps:{"textContent":_vm._s(
									_vm.$t(
										'cart.confirmation.firstTime.explanation',
										{
											media: _vm.$options.filters.translatable(
												_vm.$root.websiteConfig.data.fields
													.media_name,
												_vm.$root.websiteConfig.data.fields
													.media_name_i18n,
												_vm.$i18n.locale
											),
										}
									)
								)}})])],1)],1),_c('v-card-text',[_c('v-divider',{staticClass:"my-md-6"})],1),_c('v-card-title',{staticClass:"text-md-h4",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.notFirstTime.title'))}}),_c('v-card-text',[_c('p',{staticClass:"text-md-h6",domProps:{"innerHTML":_vm._s(
							_vm.$t('cart.confirmation.notFirstTime.desc', {
								media: _vm.$options.filters.translatable(
									_vm.$root.websiteConfig.data.fields.media_name,
									_vm.$root.websiteConfig.data.fields
										.media_name_i18n,
									_vm.$i18n.locale
								),
							})
						)}}),_c('v-row',{staticClass:"mt-md-12"},[_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"12","md":"6","order-md":"last"}},[_c('p',{staticClass:"text-md-h5 text-center text-md-left d-md-block mb-0 px-md-12",domProps:{"textContent":_vm._s(
									_vm.$t(
										'cart.confirmation.notFirstTime.explanation',
										{
											media: _vm.$options.filters.translatable(
												_vm.$root.websiteConfig.data.fields
													.media_name,
												_vm.$root.websiteConfig.data.fields
													.media_name_i18n,
												_vm.$i18n.locale
											),
										}
									)
								)}})]),_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"12","md":"6","order-md":"last"}},[_c('img',{staticClass:"d-print-none",staticStyle:{"max-width":"20rem","width":"100%"},attrs:{"src":_vm.$root.websiteConfig.data.images.firstTime,"alt":"Not first time"}})])],1)],1)],1):_vm._e(),(_vm.productDetails.hasWaiver)?_c('v-card',{staticClass:"py-4 py-md-12"},[_c('v-card-text',[_c('v-alert',{staticClass:"pa-8 mt-8 rounded-lg my-0",attrs:{"type":"warning","prominent":""}},[_c('h4',{staticClass:"text-uppercase mb-4",domProps:{"innerHTML":_vm._s(
								_vm.$t(
									'cart.confirmation.notFirstTime.avoidDelays.title'
								)
							)}}),_c('p',{domProps:{"innerHTML":_vm._s(
								_vm.$t(
									'cart.confirmation.notFirstTime.avoidDelays.line1'
								)
							)}}),_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
								_vm.$t(
									'cart.confirmation.notFirstTime.avoidDelays.line2'
								)
							)}})])],1)],1):_vm._e()],1):_vm._e(),(_vm.productDetails.hasBarcode && !_vm.hideDirectAccess)?_c('v-container',{staticClass:"mb-8 px-0",staticStyle:{"max-width":"55rem"}},[_c('v-card',{staticClass:"py-4 py-md-12 pa-print-0"},[_c('v-card-title',{staticClass:"text-md-h4",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.directAccess.title'))}}),_c('v-card-text',{staticClass:"text-md-h6"},[_c('div',{staticClass:"text-center mx-auto",staticStyle:{"max-width":"40rem"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('cart.confirmation.directAccess.desc'))}})]),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"6","md":"6","sm":"12","order-md":"last"}},[_c('p',{staticClass:"text-md-h5 text-center text-md-left d-none d-md-block mb-0",domProps:{"innerHTML":_vm._s(
									_vm.$t(
										'cart.confirmation.directAccess.explanation'
									)
								)}})]),_c('v-col',{staticClass:"d-print-none d-flex flex-column justify-center align-center",attrs:{"cols":"6","md":"6","sm":"12","order-md":"first"}},[_c('img',{staticClass:"d-print-none",staticStyle:{"max-width":"20rem","width":"100%"},attrs:{"src":_vm.$root.websiteConfig.data.images.directAccess,"alt":"Direct Access"}})])],1)],1)],1)],1):_vm._e(),_c('v-container',{staticClass:"px-0",staticStyle:{"max-width":"55rem"}},[_c('SummaryOrderCard',{staticClass:"pa-0 pa-sm-12 pa-print-0 mb-8",attrs:{"cart":_vm.invoice,"payments":_vm.payments,"skeleton":_vm.skeleton},on:{"update:cart":function($event){_vm.invoice=$event},"update:payments":function($event){_vm.payments=$event}}}),_c('h4',{domProps:{"textContent":_vm._s(
					_vm.$t('cart.confirmation.thankYouFooter', {
						name: this.$root.user.data.firstname,
					})
				)}}),_c('h4',{staticClass:"font-weight-light mb-8",domProps:{"textContent":_vm._s(_vm.$t('cart.confirmation.termsConditions'))}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }