<template>
	<v-container v-bind="$attrs" v-on="$listeners">

		<PaymentProcessOverlay
			:loading="savingInvoice"
		/>

		<v-row>
			<v-col cols="12" md="6">
				<PaymentForm :cart.sync="cart"
							 :payments.sync="payments"
							 :skeleton="skeleton || loading"
							 @completed="handlePaymentCompleted"
							 @partialPaymentCompleted="handlePartialPaymentCompleted"/>
			</v-col>
			<v-col cols="12" md="6">
				<RebateCard :cart.sync="cart" :skeleton="skeleton" class="mb-8" />
				<SummaryOrderCard :cart.sync="cart"
								  :payments.sync="payments"
								  :skeleton="skeleton || loading"
								  :load="load"
								  :showContinueButton="checkFullDiscount"
								  :show-cart-payments="true"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import PaymentForm from "@/components/PaymentForm";
	import RebateCard from "@/components/RebateCard";
	import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";
	import SummaryOrderCard from "@/components/SummaryOrderCard";
	import {
		CartModel,
		EComService,
		PaymentModel,
		EventBus,
	} from "@connectngo/sdk";
  import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'
  import globalVariables from '@/global'

	export default {
		name: "Payment",

		components: {
			PaymentForm,
			RebateCard,
			SummaryOrderCard,
			PaymentProcessOverlay,
		},

    mixins : [ReloadV2Mixin],

		props: {
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			payments: {
				type: PaymentModel,
				default: () => new PaymentModel(),
			},
			loading: {
				type: Boolean,
				default: false,
			},
		},

		data: () => ({
			savingInvoice: false,
			skeleton: true,
		}),

		computed: {
			_cart: {
				get() {
					return this.cart;
				},
				set(value) {
					this.$emit("update:cart", value);
				},
			},
			checkFullDiscount: function () {
				return (
					Object.keys(this.cart?.getAppliedDiscounts())?.length > 0 &&
					this.cart.data?.total <= 0
				);
			},
		},

		methods: {
			/* Google Tag Manager */
			triggerGAEvent(invoice) {
				const items = [];
				this.cart.data.items.forEach((item, index) => {
					items.push({
						item_id: item.data.product.data.id,
						item_name: this.$options.filters.translatable(
							item.data.product.data.name,
							item.data.product.data.name_i18n,
							this.$i18n.locale
						),
						coupon: item.data.hasDiscount
							? this.$options.filters.translatable(
									item.data.discount.name,
									item.data.discount.name_i18n,
									this.$i18n.locale
							  )
							: null,
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						discount: item.data.discountTotal,
						index: index++,
						item_category: item.data.mainTag
							? this.$options.filters.translatable(
									item.data.mainTag.name,
									item.data.mainTag.name_i18n,
									this.$i18n.locale
							  )
							: null,
						price: item.data.total,
						quantity: item.data.quantity,
					});
				});
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "purchase",
						ecommerce: {
							transaction_id: invoice.data.invoice.transaction_id,
							value: invoice.data.invoice.amount,
							currency:
								this.$root.websiteConfig.data.tenant.currency.code,
							items: items,
						},
					});
				}
			},

			handlePaymentCompleted() {
				this.saveInvoice();
			},

			handlePartialPaymentCompleted() {
				this.$emit('partialPaymentCompleted')
			},
			saveInvoice() {
				this.savingInvoice = true;
				return new EComService()
					.saveInvoice(this.cart.data.id)
					.then((invoice) => {
						this.triggerGAEvent(invoice);
						const createCartCb = () => {
							return new EComService().createCart().then((cart) => {
								this.$root.cart = cart;
                globalVariables.cart = cart;
								EventBus.publish("CART_UPDATED", cart);
								const nextStep =
									!this.reloadV2FfEnabled && this.$root.websiteConfig.data.fields
										.active_reload === "1"
										? "reload"
										: "confirmation";
								this.$router.push({
									name: "invoice_step",
									params: {
										uuid: invoice.data.invoice.uuid,
										step: nextStep,
									},
								});
							});
						};
						return invoice.data.need_fulfillment
							? new EComService()
									.autoFulfillInvoice(invoice.data.invoice.uuid)
									.then(createCartCb)
							: createCartCb();
					})
					.catch((error) => this.$handleError(this, error))
					.finally(() => (this.savingInvoice = false));
			},
			load() {
				if (!this.$root.user.data.id) {
					this.$router.push({ name: "cart_step", params: { step: 'identification' }});
				}

				this.patchCartAccountId();
				this.validateCart();

				if (
					(this.payments.data.balance <= 0 ||
					this.checkFullDiscount) &&
					0 < this.cart.data.items.length
				) {
					this.saveInvoice().then(() => (this.skeleton = false));
				} else {
					this.skeleton = false;
				}
			},
			patchCartAccountId() {
				new EComService()
					.patchCart({ account_id: this.$root.user.data.id })
					.then((data) => {
						this.$root.cart = data;
					})
					.catch((e) => {
						this.$handleError(this, e);
					});
			},
			validateCart() {
				new EComService()
					.validateCart()
					.catch((e) => {
						if (new EComService().isMissingRequiredFields(e)) {
							EventBus.publish('MISSING_ACCOUNT_FIELDS', e.fields);
						} else {
							this.$handleError(this, e);
						}
					});
			}
		},

		created() {
      if (!this.allReloadableAssigned) {
        // If reload items without wallet attached redirect to cart summary
        this.$router.push({ name: 'cart' })
        return;
      }
			this.load();
		},
	};
</script>
