<template>
	<div style="position: relative" :class="classname">
		<v-btn data-testid="minus" class="abs" style="left: 0.1rem" :color="canGoDown ? 'secondaryDark' : null" :disabled="!canGoDown" :x-large="large" icon @mousedown.stop="handleDownClick">
			<v-icon>mdi-minus</v-icon>
		</v-btn>
		<v-text-field
			data-testid="numericInput"
			ref="input"
			v-model="_value"
			:disabled="readonly"
			type="number"
			class="white"
			hide-spin-buttons
			hide-details
			outlined
			:class="{ large }"
			:dense="!large"
			@blur="handleBlur"
		/>
		<v-btn data-testid="plus" class="abs" style="right: 0.1rem" :color="canGoUp ? 'secondaryDark' : null" :disabled="!canGoUp" :x-large="large" icon @mousedown.stop="handleUpClick">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'InputNumeric',

	props: {
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: null,
		},
		increment: {
			type: Number,
			default: 1,
		},
		value: {
			type: Number,
			default: 0,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		classname: {
			type: String,
			default: '',
		}
	},

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				value = parseInt(value);
				const newValue = this.getValidValue(value);
				this.$emit('input', newValue);

				if (newValue !== value) {
					setTimeout(() => { // Hack to fix HTML5 input number not matching value prop value
						this.$refs.input.$el.querySelector('input').value = newValue;
					});
				}
			},
		},
		canGoDown() {
			return !this.readonly && (this.min === null || this.value > this.min);
		},
		canGoUp() {
			return !this.readonly && (this.max === null || this.value < this.max);
		},
	},

	methods: {
		handleBlur() {
			this._value = this.getValidValue(this.value, true);
		},
		handleDownClick() {
			if (this._value - this.increment >= this.min) {
				this._value -= this.increment
			}
		},
		handleUpClick() {
			if (!this.max || this._value + this.increment <= this.max) {
				this._value += this.increment;
			}
		},
		getValidValue(value, parseNull = false) {
			if (!parseNull && !value) {
				// Leave empty..
			} else if (value > this.max && this.max !== null) {
				value = this.value;
			} else if (value < this.min && this.min !== null) {
				value = this.value;
			}

			if (isNaN(value)) {
				value = !parseNull ? null : 0;
			} else if (parseNull && value === null) {
				value = 0;
			}

			return value;
		}
	},
}
</script>

<style lang="scss" scoped>
::v-deep input {
	text-align: center;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		display: none;
	}
}
.abs {
	position: absolute;
	z-index: 2;
	top: 0.1rem;
}
.large ::v-deep input {
	font-size: 1.25rem;
	font-weight: bold;
}
</style>
