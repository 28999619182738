<template>
	<v-container
		v-bind="$attrs"
		v-on="$listeners"
	 >
		<ProcessOverlay v-if="loadingProductPdf" />

		<Modal
			v-model="showProductsPdfProcessing"
			:title="$t('account.transactions.products.pdfModal.title')"
			max-width="800"
			scrollable
		 >
			<template #body>
				<p
					class="text-center"
					v-text="$t('pdfGeneration.processing')"
				></p>
			</template>
			<template #buttons>
				<v-btn @click="showProductsPdfProcessing = false">
					<span v-text="$t('btn.close')"></span>
				</v-btn>
			</template>
		</Modal>

		<!-- LOGO -->
		<div class="text-center d-none d-print-block mb-4">
			<img
				:src="$root.websiteConfig.data.images['logo-header']"
				height="75"
				alt="Logo"
			/>
		</div>

		<!-- PRODUCTS -->
		<v-container class="mb-8 px-0" style="max-width: 55rem">

      <v-alert v-if="invoice.data.reloadIncomplete" type="error" outlined text prominent class="text-left mb-6" icon="mdi-exclamation">
        <h4 class="mb-3">{{$t('reloadConfirmation.error.title')}}</h4>
        <p>{{$t('reloadConfirmation.error.text', {media : mediaName})}}</p>
      </v-alert>

			<!-- DESKTOP -->
			<v-expansion-panels v-model="panels" class="d-print-none" multiple>
				<v-expansion-panel
					:class="{
						'mt-8': groupIdx > 0,
					}"
					:key="groupIdx"
					v-for="(group, groupIdx) in groups"
				>
					<v-expansion-panel-header
						class="d-flex justify-center align-center text-sm-h6"
					>
						<div
							class="text-center"
							style="flex: 1"
							v-text="group.text"
						></div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-row>
							<template
								v-for="(product, productIdx) in group.children"
							>
								<v-col
									cols="12"
									v-if="!product.data.return"
									:key="productIdx"
								>
									<ProductConfirmationItem
										v-if="!product.data.return"
										:product="product"
										:invoice="invoice"
									/>
								</v-col>
							</template>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<!-- PRINTED -->
			<v-card
				:class="{
					'd-none d-print-block': true,
					'mt-8': groupIdx > 0,
				}"
				:key="groupIdx"
				v-for="(group, groupIdx) in groups"
			>
				<v-card-title v-text="group.text"></v-card-title>
				<v-card-text>
					<v-row>
						<template
							v-for="(product, productIdx) in group.children"
						>
							<v-col
								cols="12"
								md="6"
								v-if="!product.data.return"
								:key="productIdx"
							>
								<ProductConfirmationItem
									v-if="!product.data.return"
									:product="product"
								/>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- CONFIRMATION -->
		<v-container class="mb-8 px-0" style="max-width: 55rem">
			<v-card data-testid="orderConfirmationCard" class="py-4 py-md-12">
				<v-card-title
					class="text-h4 mb-8"
					v-text="$t('cart.confirmation.pageTitle')"
				></v-card-title>
				<v-card-text>
					<h2
						v-text="
							$t('cart.confirmation.thankYouHeader', {
								name: $root.user.data.firstname,
							})
						"
					></h2>
					<p
						class="mt-4"
						v-text="$t('cart.confirmation.orderCompleted')"
					></p>
					<p
						v-text="$t('cart.confirmation.shouldReceiveDetails')"
					></p>

					<TransactionCard
						:value="invoice"
						:skeleton="skeleton"
						:to="{
							name: 'account_section_sub',
							params: {
								section: 'transactions',
								sub: invoice.data.uuid,
							},
						}"
						class="mt-8"
						hide-details
						flat
						tile
					/>

					<div class="mt-8 d-print-none">
						<v-btn
							data-testid="printTransaction"
							class="d-none d-md-block"
							color="button"
							large
							block
							@click="handlePrintClick"
						>
							<v-icon left>mdi-printer</v-icon>
							<span
								v-text="
									$t('cart.confirmation.printTransaction')
								"
							></span>
						</v-btn>

						<div v-if="invoice.data.account && !invoice.data.account.is_guest" class="mt-12">
							<h3
								class="text-h5 font-weight-light"
								v-text="
									$t('cart.confirmation.onlineAccount.title')
								"
							></h3>
							<p
								v-text="
									$t('cart.confirmation.onlineAccount.desc')
								"
							></p>
							<v-btn
								data-testid="myAccountBtn"
								class="mt-4"
								color="button"
								large
								block
								:to="{ name: 'account' }"
							>
								<v-icon left>mdi-arrow-right</v-icon>
								<span
									v-text="
										$t(
											'cart.confirmation.onlineAccount.goToAccount'
										)
									"
								></span>
							</v-btn>
						</div>

						<div v-if="invoice.data.has_product_pdf" class="mt-12">
							<h3
								class="text-h5 font-weight-light"
								v-text="
									$t('cart.confirmation.productPdf.title')
								"
							></h3>
							<p
								v-text="$t('cart.confirmation.productPdf.desc')"
							></p>
							<v-btn
								data-testid="productPDFBtn"
								class="mt-4"
								color="button"
								large
								block
								@click="handlePdfClick"
							>
								<v-icon left>mdi-arrow-right</v-icon>
								<span
									v-text="
										$t(
											'cart.confirmation.productPdf.access'
										)
									"
								></span>
							</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-container>

		<v-container
			v-if="productDetails.hasWaiver || productDetails.isMifare"
			class="pa-print-0 mb-8 px-0"
			style="max-width: 55rem"
		>
			<v-card v-if="productDetails.isMifare" class="py-4 py-md-12">
				<!-- IT'S MY FIRST TIME -->
				<v-card-title
					class="text-md-h4"
					v-text="$t('cart.confirmation.firstTime.title')"
				></v-card-title>
				<v-card-text>
					<p
						class="text-md-h6"
						v-html="
							$t('cart.confirmation.firstTime.desc', {
								media: $options.filters.translatable(
									$root.websiteConfig.data.fields.media_name,
									$root.websiteConfig.data.fields
										.media_name_i18n,
									$i18n.locale
								),
							})
						"
					></p>

					<v-row class="mt-12">
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="12"
							md="6"
							order-md="first"
						>
							<img
								class="d-print-none"
								style="max-width: 20rem; width: 100%"
								:src="$root.websiteConfig.data.images.ticketOffice"
								alt="First time"
							/>
						</v-col>
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="12"
							md="6"
							order-md="last"
						>
							<p
								class="text-md-h5 text-center text-md-left d-md-block mb-0 px-md-12"
								v-text="
									$t(
										'cart.confirmation.firstTime.explanation',
										{
											media: $options.filters.translatable(
												$root.websiteConfig.data.fields
													.media_name,
												$root.websiteConfig.data.fields
													.media_name_i18n,
												$i18n.locale
											),
										}
									)
								"
							></p>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-text>
					<v-divider class="my-md-6"></v-divider>
				</v-card-text>

				<!-- NOT FIRST TIME -->
				<v-card-title
					class="text-md-h4"
					v-text="$t('cart.confirmation.notFirstTime.title')"
				></v-card-title>
				<v-card-text>
					<p
						class="text-md-h6"
						v-html="
							$t('cart.confirmation.notFirstTime.desc', {
								media: $options.filters.translatable(
									$root.websiteConfig.data.fields.media_name,
									$root.websiteConfig.data.fields
										.media_name_i18n,
									$i18n.locale
								),
							})
						"
					></p>

					<v-row class="mt-md-12">
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="12"
							md="6"
							order-md="last"
						>
							<p
								class="text-md-h5 text-center text-md-left d-md-block mb-0 px-md-12"
								v-text="
									$t(
										'cart.confirmation.notFirstTime.explanation',
										{
											media: $options.filters.translatable(
												$root.websiteConfig.data.fields
													.media_name,
												$root.websiteConfig.data.fields
													.media_name_i18n,
												$i18n.locale
											),
										}
									)
								"
							></p>
						</v-col>
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="12"
							md="6"
							order-md="last"
						>
							<img
								class="d-print-none"
								style="max-width: 20rem; width: 100%"
								:src="$root.websiteConfig.data.images.firstTime"
								alt="Not first time"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<!-- WAIVERS ALERT -->
			<v-card v-if="productDetails.hasWaiver" class="py-4 py-md-12">
				<v-card-text>
					<v-alert
						type="warning"
						prominent
						class="pa-8 mt-8 rounded-lg my-0"
					>
						<h4
							class="text-uppercase mb-4"
							v-html="
								$t(
									'cart.confirmation.notFirstTime.avoidDelays.title'
								)
							"
						></h4>
						<p
							v-html="
								$t(
									'cart.confirmation.notFirstTime.avoidDelays.line1'
								)
							"
						></p>
						<p
							class="mb-0"
							v-html="
								$t(
									'cart.confirmation.notFirstTime.avoidDelays.line2'
								)
							"
						></p>
					</v-alert>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- DIRECT ACCESS -->
		<v-container
			v-if="productDetails.hasBarcode && !hideDirectAccess"
			class="mb-8 px-0"
			style="max-width: 55rem"
		>
			<v-card class="py-4 py-md-12 pa-print-0">
				<v-card-title
					class="text-md-h4"
					v-text="$t('cart.confirmation.directAccess.title')"
				></v-card-title>
				<v-card-text class="text-md-h6">
					<div class="text-center mx-auto" style="max-width: 40rem">
						<p
							v-html="$t('cart.confirmation.directAccess.desc')"
						></p>
					</div>
					<v-row class="mt-12">
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="6"
							md="6"
							sm="12"
							order-md="last"
						>
							<p
								class="text-md-h5 text-center text-md-left d-none d-md-block mb-0"
								v-html="
									$t(
										'cart.confirmation.directAccess.explanation'
									)
								"
							></p>
						</v-col>
						<v-col
							class="d-print-none d-flex flex-column justify-center align-center"
							cols="6"
							md="6"
							sm="12"
							order-md="first"
						>
							<img
								class="d-print-none"
								style="max-width: 20rem; width: 100%"
								:src="$root.websiteConfig.data.images.directAccess"
								alt="Direct Access"
							/>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- SUMMARY -->
		<v-container class="px-0" style="max-width: 55rem">
			<SummaryOrderCard
				:cart.sync="invoice"
				:payments.sync="payments"
				:skeleton="skeleton"
				class="pa-0 pa-sm-12 pa-print-0 mb-8"
			/>

			<h4
				v-text="
					$t('cart.confirmation.thankYouFooter', {
						name: this.$root.user.data.firstname,
					})
				"
			></h4>

			<h4
				class="font-weight-light mb-8"
				v-text="$t('cart.confirmation.termsConditions')"
			></h4>
		</v-container>
	</v-container>
</template>

<script>
import SummaryOrderCard from "@/components/SummaryOrderCard";
import TransactionCard from "@/components/TransactionCard";
import QrCode from "@/components/QrCode";
import Modal from "@/components/Modal";
import VBtn from "vuetify/lib/components/VBtn/VBtn";
import VIcon from "vuetify/lib/components/VIcon/VIcon";
import {
	EComService,
	PaginationModel,
	TransactionModel,
	BaseModel,
	PaymentModel,
} from "@connectngo/sdk";
import ProcessOverlay from "@/components/ProcessOverlay";
import DigitalWallet from '@/components/DigitalWallet/DigitalWallet'

const ProductConfirmationItem = {
	components: { QrCode, VBtn, VIcon, Modal, DigitalWallet },

	props: {
		product: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		invoice: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
	},

	data: () => ({
		loadingWaiver: false,
		showPdf: false,
		waiverPdf: new BaseModel(),
	}),

	methods: {
		handleShowWaiverClick(waiver) {
			this.loadingWaiver = waiver.hash;
			return Promise.all([new EComService().getWaiverPdf(waiver.hash)])
				.then(([waiverPdf]) => {
					Object.assign(this, { waiverPdf, showPdf: true });
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loadingWaiver = false));
		},
	},

	computed: {
		transactionDetailId() {
			if (this.product.data.type === 'wallet') {
				return this.product.data.transaction_detail_id
			}

			return this.product.data.id
		},
	},

	template: `
		<div v-if="!product.data.return">
		<Modal v-model="showPdf" :title="$t('productWaiverCard.pdfModal.title')" max-width="800">
			<template #body>
				<iframe :src="waiverPdf.data.base_64" class="waiver_pdf"></iframe>
			</template>
		</Modal>

		<div class="d-flex">
			<div style="flex: 0" class="d-flex flex-column justify-center">
				<QrCode v-model="product.data.barcode"/>
			</div>
			<div style="flex: 1; line-height: 1rem" class="d-flex flex-column justify-center text-left">
				<p class="text-uppercase overline mb-0" v-text="product.data.category"></p>
				<p class="mb-3"
				   v-text="$options.filters.translatable(product.data.product.name, product.data.product.name_i18n, $i18n.locale)"></p>
				<p class="mb-0">
					<small>
						<span v-text="$t('cart.confirmation.productNumber')"></span>
						<br><span v-text="product.data.barcode"></span>
					</small>
				</p>
				<p class="mb-0 mt-1" v-if="product.data.event">
					<small>
							<span
								v-if="$options.filters.tsToFormat(product.data.event.start_datetime * 1000, 'LL', true) !== $options.filters.tsToFormat(product.data.event.end_datetime * 1000, 'LL', true)">
								{{
									$t('productWaiverCard.visitingDateComplete', {
										start_date: $options.filters.tsToFormat(product.data.event.start_datetime * 1000, 'LL', true),
										start_time: $options.filters.tsToFormat(product.data.event.start_datetime * 1000, 'LT', true),
										end_date: $options.filters.tsToFormat(product.data.event.end_datetime * 1000, 'LL', true),
										end_time: $options.filters.tsToFormat(product.data.event.end_datetime * 1000, 'LT', true)
									})
								}}
							</span>
						<span v-else>
								{{
								$t('productWaiverCard.visitingDateCompleteSameDay', {
									start_date: $options.filters.tsToFormat(product.data.event.start_datetime * 1000, 'LL', true),
									start_time: $options.filters.tsToFormat(product.data.event.start_datetime * 1000, 'LT', true),
									end_time: $options.filters.tsToFormat(product.data.event.end_datetime * 1000, 'LT', true)
								})
							}}
						</span>
					</small>
				</p>
			</div>
		</div>

		<div v-if="product.data.event_group" class="d-flex align-center mt-2 mb-4">
			<v-icon>mdi-calendar</v-icon>
			<div class="ml-3 text-left">
				<div class="overline font-weight-light" style="font-size: 0.55rem !important; line-height: 0.55rem"
					 v-text="$t('productWaiverCard.visitingDate')"></div>
				<div v-text="product.data.admission_number"></div>
			</div>
		</div>

		<div key="waiver.hash" v-for="waiver in product.data.waivers" class="mt-2 d-print-none">
			<v-btn color="button" block :href="waiver.signed ? null : waiver.url" target="_blank"
				   :loading="loadingWaiver === waiver.hash" :disabled="loadingWaiver === waiver.hash"
				   @click="waiver.signed ? handleShowWaiverClick(waiver) : null">
				<span v-text="$t('waiver.' + (waiver.signed ? 'see' : 'sign'))"></span>
			</v-btn>
		</div>

		<DigitalWallet
			v-if="invoice.data?.uuid && product.data.type === 'wallet'"
			:source="'transaction-detail'"
			:itemId="transactionDetailId"
			:invoiceUuid="invoice.data.uuid"
			:itemIdx="product.data.transaction_detail_index"
			class="mt-4 text-center"
		/>
		</div>`,
};
export default {
	name: "Confirmation",

	components: {
		ProcessOverlay,
		SummaryOrderCard,
		TransactionCard,
		ProductConfirmationItem,
		Modal,
	},

	props: {
		invoice: {
			type: TransactionModel,
			default: () => new TransactionModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		skeleton: true,
		products: new PaginationModel(),
		productsPdf: new BaseModel(),
		loadingProductPdf: false,
		productPdfUrl: null,
		showProductsPdfProcessing: false,
		modal: {
			visible: false,
		},
		panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    mediaName : null,
	}),

	computed: {
		groups() {
			const groups = [];
			this.products.results.forEach((product) => {
				const group = groups.find(
					(group) => group.text === product.data.product.name
				);

				if (!group) {
					groups.push({
						text: this.$options.filters.translatable(
							product.data.product.name,
							product.data.product.name_i18n
						),
						children: [product],
					});
				} else {
					group.children.push(product);
				}
			});
			return groups;
		},
		productDetails() {
			const productDetails = {
				hasWaiver: false,
				isMifare: false,
				hasBarcode: false,
			};
			this.products.results.forEach((product) => {
				if (product.data.waivers) {
					productDetails.hasWaiver = true;
				}

				if (product.data.wallets) {
					product.data.wallets.forEach((wallet) => {
						if (wallet.wallet_type.issuing === "MIFARE") {
							productDetails.isMifare = true;
						} else if (
							wallet.wallet_type.issuing === "INTERNAL_BARCODE"
						) {
							productDetails.hasBarcode = true;
						}
					});
				}
			});

			return productDetails;
		},
		hideDirectAccess() {
			return this.$root.websiteConfig.data.fields.hide_direct_access === "1";
		},
	},

	methods: {

		handlePrintClick() {
			window.print();
		},

		handlePdfClick(redirect = false) {

			if (this.productPdfUrl) {
				this.showProductPdf(redirect, this.productPdfUrl);
				return;
			}

			this.loadingProductPdf = true;
			new EComService()
				.getProductsPdfUrl(this.invoice.data.uuid)
				.then((productsPdf) => {
					if (productsPdf == null) {
						this.showProductsPdfProcessing = false;
						return;
					}

					if (
						productsPdf.response &&
						productsPdf.response.status === 423
					) {
						this.showProductsPdfProcessing = true;
					} else {
						this.showProductPdf(redirect, productsPdf.data.url);
					}
				})
				.catch((reason) => {
					this.$handleError(this, reason);
				})
				.finally(() => (this.loadingProductPdf = false));
		},

		showProductPdf(redirect, url) {
			if (redirect) {
				window.location.href = url;
			} else {
				window.open(url, "_blank");
			}
		},
		load() {
			this.loading = true;

			if (this.$root.user.data.is_guest) {
				// Clear current user after guest checkout
				new EComService().logout().finally(() => {
					this.$root.user = new AccountModel();
				});
			}

			return Promise.all([
				new EComService().getTransactionProducts(
					this.invoice.data.uuid
				),
				new EComService().getProductsPdfUrl(this.invoice.data.uuid),
			])
				.then(([products, pdfStatus]) => {
					if (pdfStatus !== null && pdfStatus.data && pdfStatus.data.url) {
						this.productPdfUrl = pdfStatus.data.url;
					}
					this.products = products;
					this.skeleton = false;

					if (this.$route.name === "invoice_step_pdf") {
						this.handlePdfClick(true);
					}
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();

    this.mediaName = this.$options.filters.translatable(
        this.$root.websiteConfig.data.fields.media_name,
        this.$root.websiteConfig.data.fields.media_name_i18n,
        this.$i18n.locale
    ) || 'UID'
	},
};
</script>
