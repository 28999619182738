<template>
  <v-combobox
      v-model="walletId"
      dense
      :outlined="required"
      :flat="!required"
      :error="required"
      :hide-details="!errorUid"
      :error-messages="errorUid"
      :items="walletsSelectData"
      :prepend-inner-icon="required ? 'mdi-alert-circle-outline' : 'mdi-magnify'"
      :placeholder="placeholder"
      :readonly="loading"
      :disabled="loading"
      :loading="loading"
      ref="comboBox"
      @change="$nextTick(() => { $refs.comboBox.isMenuActive = false})"
	  @focusout="focusOut"
	  @keyup.enter="inputChanged"
      :class="{'required' : required, 'optional' : !required}"
  />

</template>

<script>
import { EComService, EventBus} from '@connectngo/sdk'
import globalVariables from '@/global'
import { WindowWidthMixin } from '@/mixins/ProductMixin'
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'
export default {
  name: "ReloadUidPicker",
	mixins : [WindowWidthMixin, ReloadV2Mixin],
  props : {

    transactionDetailId : {
      type : Number,
      required : true,
    },
    productName : {
      type : String,
      required : true,
    },
    mediaName : {
      type : String,
      default : 'UID'
    },
    required : {
      type : Boolean,
      default:  false,
    },
    reloadWallets: {
      type : Array,
      default: () => []
    }
  },
  data() {
    return {
		  loading : false,
      walletId : null,
      errorUid : this.required ? this.$t('reloadTag.assignment_required') : null,
      loginEvent : null,
      logoutEvent : null,
      walletsSelectData : []
    }
  },
  beforeMount () {
    this.walletsSelectData = this.formatWalletsSelectData(this.reloadWallets, this.mediaName);
  },
  computed : {
    isLoggedIn() {
      return this.$root.user.data.id !== null
    },
    placeholder() {
      return `${this.$t('reloadTag.add_uid', {media : this.mediaName})} (${this.$t( this.required ? 'reloadTag.required' : 'reloadTag.optional')})`
    },
},
  methods : {
    attachUid(newUid = null) {
      if(!this.walletId && !newUid) {
        return
      }
      this.loading = true;
      new EComService().assignReloadWalletToTd(this.$root.cart.data.uuid, this.transactionDetailId, newUid || this.walletId)
          .then(cart => {
            globalVariables.cart = cart
            EventBus.publish('CART_UPDATED', cart)
            this.$emit('invalidUid', false);
            this.$snack(
                this.$i18n.t("reloadTag.success_reload_snack", { media: this.mediaName, productName : this.productName })
            );
          })
          .catch(err => {
            this.$emit('invalidUid', true);
            if(err.response?.status === 422 && err.walletId) {
              this.errorUid = err.walletId[0]
            } else if(err.response?.status === 422 && err.error) {
              this.errorUid = err.error
            } else {
              this.$handleError(this, err)
            }
            this.walletId = null;
          })
          .finally(() => {
            this.loading = false
          })
    },
    async inputChanged() {
      this.loading = true;
      if(!this.walletId) {
	  	this.loading = false;
        return;
      }
      if(this.walletId?.value) {
        return this.attachUid(this.walletId?.value);
      } else {
        try {
          const wallet = await new EComService().getWalletFromUid(this.walletId);
          return this.attachUid( wallet.data.wallet_id)
        } catch (err) {
          this.$emit('invalidUid', true);
          if (err.response?.status === 404) {
            this.errorUid = this.$t('reloadTag.invalid_uid', { media: this.mediaName })
          } else {
            this.errorUid = err.message
            this.$handleError(this, err)
          }
          this.loading = false;
        }
      }
    },
	  focusOut() {
		// On mobile submit on focus out
		if (this.isMobile && !this.loading) {
			this.inputChanged();
		}
	  }
  },
  watch : {
    reloadWallets(newVal) {
      this.walletsSelectData = this.formatWalletsSelectData(newVal, this.mediaName);
    }
  }
}
</script>

<style scoped>
  ::v-deep .v-subheader {
    font-size: 1rem;
    font-weight: bold;
  }

  .v-input.required ::v-deep .v-input__slot fieldset {
    border: 2px solid #C43C30 !important;
  }
</style>
