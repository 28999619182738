// @ts-nocheck
import globalVariables from "@/global";

const InteractiveMapMixin = {

	data: () => ({
		interactiveMapProductIds : []
	}),
	computed: {
		hasInteractiveMap() {
			return this.$root.websiteConfig.data.interactive_map;
		},
		cartHasMapItemProduct() {
			const cartProductIds = this.$root.cart.data.items
				.map(item => item.data.product.data.id);
			return cartProductIds.some(i => globalVariables.interactiveMapProductIds.includes(i));
		},
		mustDisplayInteractiveMapCrossSellModal() {
			return this.hasInteractiveMap && !globalVariables.interactiveMapModalShown && !this.cartHasMapItemProduct;
		},
	},
	methods : {
		getMapItemProductIds(items) {
			return items.forEach(mapItem => {
				if(mapItem.mappable_type === "EverestShared\\Models\\Product") {
					this.interactiveMapProductIds.push(mapItem.mappable_id)
				} else {
					return this.getMapItemProductIds(mapItem.children);
				}
			})
		},
	},
};

export default InteractiveMapMixin;
