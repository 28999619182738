import Vue from 'vue';

Vue.directive('windowFocus', {
	bind: (el, binding, vnode) => {
		window.addEventListener('focus', binding.value);
	},
	unbind: (el, binding, vnode) => {
		window.removeEventListener('focus', binding.value);
	},
})
