<template>
	<v-container class="py-md-0 py-12 text-center">
		<!-- NAVIGATION -->
		<template v-if="$root.accountLinks !== null">
			<h2 class="display-1 font-weight-light mt-12" v-text="">Navigation</h2>
			<v-row class="mt-8 mb-12">
				<v-col cols="12" md="6">
					<ProductTag v-bind:to="{ name: 'home' }" v-bind:name="$t('route.home')" style="min-width: 20rem" :skeleton="skeleton" dark />
				</v-col>
				<v-col cols="12" md="6">
					<ProductTag v-bind:to="{ name: 'cart' }" v-bind:name="$t('cart.welcome.title')" style="min-width: 20rem" :skeleton="skeleton" dark />
				</v-col>
				<v-col cols="12" md="6" :key="index" v-for="(link, index) in $root.accountLinks">
					<ProductTag v-if="link.key === 'account'" v-bind:to="{ name: link.key }" v-bind:name="$t('route.' + link.key)" style="min-width: 20rem" :skeleton="skeleton" dark />
					<ProductTag v-else-if="link.key !== 'logout'" v-bind:to="{ name: link.key }" v-bind:name="$t('route.account_' + link.key)" style="min-width: 20rem" :skeleton="skeleton" dark />
				</v-col>
			</v-row>
		</template>

		<!-- PRODUCTS -->
		<template v-if="$root.tags !== null">
			<h2 class="display-1 font-weight-light mt-12" v-text="$t('route.products')"></h2>
			<v-row class="mt-8 mb-12">
				<v-col cols="12" md="6" :key="index" v-for="(tag, index) in $root.tags.results">
					<ProductTag v-bind="tag.data" :to="{ name: 'tag', params: { key: tag.data.slug } }" style="min-width: 20rem" :skeleton="skeleton" dark />
				</v-col>
			</v-row>
		</template>
	</v-container>
</template>

<script>
import ProductTag from '@/components/ProductTag'

export default {
	name: "Sitemap",

	components: { ProductTag },
}
</script>

