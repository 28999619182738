<template>
	<div class="w-100 container px-0" :class="className">
		<template v-if="isDesktopBreakpoint && showTitle">
			<div v-if="showDetails">
				<h1>Your title here</h1>
				<h2 class="font-weight-bold">Choose your time of visit</h2>
			</div>
			<div
				v-else-if="!hideHeader"
				class="mb-2 select__title"
			>
				<ul>
					<li	class="ml-2 font-weight-bold">{{ $options.filters.translatable(product.data.name, product.data.name_i18n, $i18n.locale) }}</li>
				</ul>
			</div>
			<!-- Add the row your first selection is.  -->
			<p class="text-previous-selections primary--text mb-0 pl-6" v-for="(text) in otherSelectedTimeslotsText" :key="text">
				{{ text }}
			</p>
			<v-expansion-panels v-model="panel" flat v-if="!showDetails">
				<v-expansion-panel class="rounded-lg">
					<v-expansion-panel-header class="text-black dropdown-header">
						{{
							selectedTime
								? selectedTime?.period
								: $t('route.tags.combos.select.choose_your_time_of_visit')
						}}
					</v-expansion-panel-header>
					<v-expansion-panel-content class="px-0">
						<v-list class="d-flex flex-column pt-0">
							<v-list-item
								v-for="(timeSlot, index) in timeSlots"
								:key="index"
								@click="onSelectTimeslotAndCollapsePanel(timeSlot)"
								class="px-6"
							>
								<v-list-item-content>
									<div
										class="
											d-flex
											justify-space-between
											w-100
										"
										:class="{ 'primary--text': isHighlighted(timeSlot) }"
									>
										<div
											class="text-left flex-grow-1"
											v-text="timeSlot.period"
										></div>
										<div
											class="text-left flex-grow-1"
											v-text="
												timeSlot.amountLeft + ' ' + $t('product.left')
											"
										></div>
									</div>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-list v-else class="d-flex flex-column">
				<v-list-item
					v-for="(timeSlot, index) in timeSlots"
					:key="index"
					@click="selectTime(timeSlot)"
					class="px-6"
				>
					<v-list-item-content>
						<div class="d-flex justify-space-between w-100">
							<div
								class="text-left flex-grow-1"
								v-text="timeSlot.period"
							></div>
							<div
								class="text-left flex-grow-1"
								v-text="timeSlot.amountLeft + ' ' + $t('product.left')"
							></div>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<v-expansion-panels v-else v-model="panel" flat>
			<v-expansion-panel class="rounded-xl box-shadow">
				<v-expansion-panel-header color="secondaryDark--text">
					<div>
						<v-icon
							v-if="selectedTime?.period"
							class="mr-1 secondaryDark--text"
							color="primary"
						>
							mdi-check
						</v-icon>
						{{ product.data.name }} {{ progress }}
					</div>
					<template v-slot:actions>
						<v-icon
							v-if="panel === 0"
							:class="{
								'mdi-chevron-down': panel === 0,
								'mdi-chevron-right': panel !== 0,
							}"
						>
							mdi-chevron-up
						</v-icon>
						<v-icon v-else>mdi-chevron-right</v-icon>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="pb-3">
					<v-divider v-if="panel !== null" class="mx-4 mt-n3 mb-2" />
					<p :key="text" v-for="(text, index) in otherSelectedTimeslotsText" class="text-previous-selections checkbox-expansion-panel primary--text mb-2 pl-6">*{{ text }}</p>
					<div
						class="
							checkbox-container
							pl-6
							py-1
							pr-4
							d-flex
							justify-space-between
							align-center
						"
						v-for="(timeSlot) in timeSlots"
						:key="timeSlot.period"
						:class="{
							'primary--text': isHighlighted(timeSlot),
							'lightOrange': checked[timeSlot.id] && isDesktopBreakpoint,
						}"
					>
						<v-checkbox
							type="checkbox"
							:label="`${timeSlot.period}`"
							v-model="checked[timeSlot.id]"
							hide-details
							@change="onCheckboxSelectTime(timeSlot)"
							class="mediumGrey--text"
						/>
						<span v-text="$t('product.places_remaining', { amount: timeSlot.amountLeft })" />
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import { isEqual } from "lodash";
export default {
	name: "ProductTimeslotPicker",
	props: {
		otherSelectedTimeslots: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			required: false,
			default: "Select Time",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		timeSlots: {
			type: Array,
			default: () => [],
		},
		selectedIndex: {
			type: Number,
			default: 0,
		},
		product: {
			type: Object,
			default: () => {},
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		showDetails: {
			type: Boolean,
			default: false,
		},
		className: {
			type: String,
			default: "",
		},
		progress: {
			type: String,
			default: "0/0",
		},
	},
	data: () => ({
		selectedTime: false,
		panel: null,
		checked: {},
	}),
	watch: {
		timeSlots: {
			immediate: true,
			handler: function (newVal, oldVal) {
				if (!isEqual(newVal, oldVal) && newVal.length > 0) {
					this.resetCheckboxValues();
					this.selectedTime = null;
					this.panel = 0;
				} else if (!isEqual(newVal, oldVal) && newVal.length === 0) {
					this.resetCheckboxValues();
					this.selectedTime = null;
					this.panel = null;
				}
			},
			deep: true,
		},
	},
	computed: {
		otherSelectedTimeslotsText() {
			return this.otherSelectedTimeslots.map((timeslot) => {
				const ordinal = this.ordinalSuffixOf(timeslot.index + 1);
				return `${ordinal} ${timeslot.period}`;
			});
		},
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		},
	},
	methods: {
		selectTime(timeSlot) {
			this.selectedTime = timeSlot;
			this.$emit("option-selected", timeSlot);
		},
		onCheckboxSelectTime(timeSlot) {
			if (!Object.keys(this.checked).length) {
				this.resetCheckboxValues();
			}
			for (let id in this.checked) {
    			if (Number(id) !== Number(timeSlot.id)) {
      				this.checked[id] = false;
    			}
  			}
			if (this.checked?.[timeSlot.id]) {
				this.selectTime(timeSlot);
			} else {
				this.selectTime(null);
			}
		},
		resetCheckboxValues() {
			this.timeSlots.forEach(timeSlot => {
    			this.$set(this.checked, timeSlot.id, false);
  			});
		},
		isValidDate(newVal) {
			const regex = /^\d{4}-\d{2}-\d{2}$/;
    		return regex.test(newVal);
		},
		isHighlighted(timeslot) {
			return this.otherSelectedTimeslots.some((ts) => ts.period === timeslot.period);
		},
		onSelectTimeslotAndCollapsePanel(timeSlot) {
			this.selectTime(timeSlot);
			this.panel = null;
		},
		ordinalSuffixOf(i) {
  			const j = i % 10,
    			k = i % 100;
  			if (i === 1) {
    			return this.$i18n.t('combo.first');
  			}
  			if (i === 2) {
    			return this.$i18n.t('combo.second');
  			}
  			if (i === 3) {
    			return this.$i18n.t('combo.third');
  			}
  			if (j == 1 && k != 11) {
    			return  this.$i18n.t('combo.st', { index: i });
  			}
  			if (j == 2 && k != 12) {
    			return this.$i18n.t('combo.nd', { index: i });
  			}
  			if (j == 3 && k != 13) {
    			return this.$i18n.t('combo.rd', { index: i });
  			}
  			return this.$i18n.t('combo.th', { index: i });
		},
	},
	created() {
		this.resetCheckboxValues();
	},
};
</script>
<style scoped lang="scss">
.container {
	padding: 0px !important;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.checkbox-container {
	.v-input {
		margin-top: 0px;
	}
	i.v-icon {
		font-size: 20px;
	}
	::v-deep label, span {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}
}

.v-expansion-panel-header {
	min-height: 75px;
	padding: 16px 24px;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	text-align: left;
	.v-expansion-panel-header__icon > .v-icon {
		font-size: 30px;
	}
	.v-icon.mdi-check {
		font-size: 20px;
		padding-bottom: 4px;
	}
	.v-icon {
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}
	&.text-black {
		color: #000000;
	}
	&.dropdown-header {
		min-height: 48px;
	}
}

.text-previous-selections {
	font-size: 16px;
	font-weight: 500;
	line-height: 19.15px;
	&.checkbox-expansion-panel {
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
	}
}

// The checkbox.
::v-deep i.v-icon {
	font-size: 20px;
}

::v-deep .v-input--selection-controls__ripple {
	height: 25px;
	width: 25px;
	left: -7px;
	top: calc(50% - 20px);
}

.v-expansion-panel-content {
	label {
		height: 24px;
		padding: 4px 0px 4px 0px;
		gap: 16px;
	}
}

.select__title {
	font-size: 17.5px;
	font-weight: 600;
	li {
		display: list-item;
	}
}

.v-list-item:hover {
	background-color: #e0f5f7 !important;
}

.v-expansion-panel {
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	&.box-shadow {
		box-shadow: 0px 4px 8px 0px #6864641A;
	}
}

::v-deep .v-expansion-panel-content__wrap {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.v-list-item {
	height: 25px;
}

@media screen and (max-width: 600px) {
	.checkbox-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start !important;
		gap: 8px;
		label {
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
		}
		span {
			font-size: 11px;
			font-weight: 400;
			line-height: 17px;
			text-align: left;
		}
	}

}
</style>
