<template>
	<Modal v-model="_visible" :title="$t('reservation.switch_account')" max-width="400" no-close persistent>
		<template #body>
			<v-card-text class="text-left caption open-sans-font"
				v-html="$t('reservation.sign_in_as', { userEmail: $root.user.data.email, resEmail: resEmail })">
			</v-card-text>
		</template>
		<template #buttons>
			<v-btn color="button" @click="logout">
				<span v-text="$t('btn.login')"></span>
			</v-btn>
			<v-btn @click="cancelClick">
				<span v-text="$t('btn.cancel')"></span>
			</v-btn>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal';
export default {
	name: 'SwitchAccountModal',
	components: { Modal },

	props: {
		showLoginModal: {
			type: Boolean,
			default: false,
		},
		resEmail: {
			type: String,
			default: null,
		},
	},

	methods: {
		logout() {
			this.$emit('logOutEvent');
		},

		cancelClick() {
			this.$emit('cancelClick');
		}
	},

	computed: {
		_visible: {
			get() {
				return this.showLoginModal;
			}
		},
	},

}
</script>

<style lang="sass" scoped>

</style>
