// @ts-nocheck
import Vue from "vue";
import { EComService } from "@connectngo/sdk";

const ReloadV2Mixin =  Vue.extend({
    computed : {
        reloadableItems(): Array<any> {
            return this.$root.cart.data.items.filter(item => item.data.product.data.reloadable)
        },
        reloadItemTotal(): number {
            if(this.reloadableItems.length === 0) {
                return 0;
            }
            return this.reloadableItems.reduce((acc, item) => acc + item.data.quantity, 0);
        },
        reloadItemTotalAssigned(): number {
            if(this.reloadableItems.length === 0) {
                return 0;
            }
            return this.reloadableItems.reduce(
                (acc, item) => acc + item.data.reload_wallets.reduce(
                    (acc2, wallet) => acc2 + wallet.quantity, 0 )
                , 0);
        },
        allReloadableAssigned(): boolean {
            if(this.reloadableItems.length === 0) {
                return true;
            }
            return this.reloadItemTotal === this.reloadItemTotalAssigned
        },
        reloadV2FfEnabled() {
            const { feature_flags } = this.$root.websiteConfig.data || {};
            return feature_flags?.includes('co-7601-reload-flow-v2');
        },
        reloadV2Enabled() {
            return this.reloadV2FfEnabled && this.$root.websiteConfig.data.fields.active_reload === "1";
        }
    },
	methods: {
        fetchUserReloadWallets() {
            if (this.$root.user.data.id !== null) {
                return new EComService().getUserReloadableWallets(this.$root.cart.data.uuid);
            } else {
                return new EComService().getCartReloadableWallets(this.$root.cart.data.uuid);
            }
        },
        formatWalletsSelectData(wallets, mediaName, walletIdValue = true) {
            this.loading = true;
            let data = [];
            wallets.forEach(wallet => {
                const uidSelectData = {text : `${mediaName} : ${wallet.uid.uid}`, value : walletIdValue ? wallet.id : wallet.uid.uid};
                let index = data.findIndex(item => item.accountId === (wallet.account?.id || null))
                if (index < 0) {
                    data.push({
                        header : wallet.account?.id ?  this.walletAccountName(wallet.account) : this.$t('reloadTag.without_account', {media : mediaName}),
                        accountId : wallet.account?.id || null
                    })
                    data.push(uidSelectData)
                } else {
                    // Insert in proper group
                    data.splice(index + 1, 0, uidSelectData);
                }
            })
            this.loading = false;
            return data;
        },
        walletAccountName(account) {
            if(this.$root.user.data.id && account.parent_account_id === this.$root.user.data.id || account.id === this.$root.user.data.id ) {
                // If logged in account or sub account display full name
                return account.firstname + " " + account.lastname;
            } else {
                return account.firstname[0] + '*'.repeat(account.firstname.length - 2) + account.firstname.slice(-1) + ' ' +
                    account.lastname[0] + '*'.repeat(account.lastname.length - 2) + account.lastname.slice(-1);
            }
        }
    },
});

export default ReloadV2Mixin;
