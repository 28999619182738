var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"display-1 font-weight-light",domProps:{"textContent":_vm._s(_vm.$t('account.memories.title', {
				name: _vm.$root.user.data.firstname,
			}))}})])],1),(_vm.items.results.length === 0)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"info"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('account.memories.empty'))}})]):_vm._e(),_c('v-row',_vm._l((_vm.items.results),function(item){return _c('v-col',{key:item.guid,attrs:{"cols":"12","lg":"6"}},[_c('VirtualWalletCard',{attrs:{"value":item,"user-name":_vm.$root.user.data.firstname,"skeleton":_vm.skeleton,"route":item => ({
					name: 'account_section_sub_action',
					params: {
						section: 'wallet',
						sub: item.data.id,
						action: 'memories',
					}
				})}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }