<template>
	<v-overlay :value="paying || success || error || loading" opacity="0.85">
		<v-card width="17.5rem" light>
			<template v-if="paying && !success && !error">
				<v-card-title v-text="$t('paymentProcessOverlay.pleaseWait')"></v-card-title>
				<v-card-text class="text-center">
					<v-progress-circular indeterminate width="12" color="secondaryDark" size="128" />
					<p class="mb-0 mt-8" v-text="$t('paymentProcessOverlay.waitProcessing')"></p>
				</v-card-text>
			</template>
			<template v-else-if="success">
				<v-card-title v-text="$t('paymentProcessOverlay.paymentAccepted')"></v-card-title>
				<v-card-text class="text-center">
					<v-icon color="success" size="128" class="mb-8">mdi-check-circle-outline</v-icon>
				</v-card-text>
			</template>
			<template v-else-if="loading">
				<v-card-title v-text="$t('paymentProcessOverlay.processing')"></v-card-title>
				<v-card-text class="text-center">
					<v-progress-circular indeterminate width="12" color="secondaryDark" size="128" />
					<p class="mb-0 mt-8" v-text="$t('paymentProcessOverlay.waitProcessing')"></p>
				</v-card-text>
			</template>
			<template v-else-if="error">
				<v-card-title v-text="$t('paymentProcessOverlay.paymentError')"></v-card-title>
				<v-card-text class="text-center">
					<v-icon color="error" size="128">mdi-close-circle-outline</v-icon>
					<p class="mt-8" v-text="$t('paymentProcessOverlay.tryAgain')"></p>
					<v-btn block outlined @click="_paying = false; _error = false; $emit('closed');">
						<span v-text="$t('btn.close')"></span>
					</v-btn>
				</v-card-text>
			</template>
		</v-card>
	</v-overlay>
</template>

<script>
export default {
	name: 'PaymentProcessOverlay',

	props: {
		paying: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},

	destroyed() {
		window.removeEventListener("beforeunload", this.onBeforeUnload, {capture: true})
	},

	computed: {
		_paying: {
			get() {
				return this.paying;
			},
			set(value) {
				this.$emit('update:paying', value);
			},
		},
		_success: {
			get() {
				return this.success;
			},
			set(value) {
				this.$emit('update:success', value);
			},
		},
		_error: {
			get() {
				return this.error;
			},
			set(value) {
				this.$emit('update:error', value);
			},
		},
	},

	methods: {
		onBeforeUnload (event) {
			event.preventDefault();
			return event.returnValue =  this.$t('paymentProcessOverlay.pleaseWait');
		}
	},

	watch: {
		paying(value) {
			if (value) {
				window.addEventListener("beforeunload", this.onBeforeUnload, {capture: true});
			}
			else {
				window.removeEventListener("beforeunload", this.onBeforeUnload, {capture: true})
			}
		}
	}
}
</script>
