<template>
	<v-container v-if="skeleton">
		<v-row :key="rowIdx" v-for="rowIdx in 3">
			<v-col cols="4" :key="colIdx" v-for="colIdx in 3">
				<v-skeleton-loader type="image" height="100"></v-skeleton-loader>
			</v-col>
		</v-row>
	</v-container>
	<div v-else>

		<!-- SHARE DIALOG -->
		<ShareDialog
			:visible.sync="shareDialog.visible"
			:title="$t('shareDialog.title')"
			:text="shareDialog.share.text"
			:url="shareDialog.share.url"
			max-width="450"
			scrollable
			@copied="handleLinkCopied"
		/>

		<!-- GALLERY DIALOG -->
		<Modal v-model="gallery.visible" width="90vw" hide-header hide-footer @close="handleModalClose">
			<template #body>
				<div style="position: absolute; top: 1rem; right: 1rem; z-index: 1;">
					<v-btn data-test-selector="modal_title_close" icon x-large dark @click="handleModalClose">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div style="position: absolute; bottom: 1rem; right: 1rem; z-index: 1;">
					<v-tooltip bottom>
						<template #activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" :disabled="gallery.items[gallery.mediaIdx].data.status !== 'PURCHASED'" dark icon large @click="handleDownloadClick(gallery.items[gallery.mediaIdx])">
								<v-icon>mdi-cloud-download-outline</v-icon>
							</v-btn>
						</template>
						<span v-text="$t('btn.download')"></span>
					</v-tooltip>

					<v-tooltip bottom>
						<template #activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" :disabled="gallery.items[gallery.mediaIdx].data.status !== 'PURCHASED'" dark class="ml-2" icon large @click="handleShareClick(gallery.items[gallery.mediaIdx])">
								<v-icon>mdi-share-outline</v-icon>
							</v-btn>
						</template>
						<span v-text="$t('btn.share')"></span>
					</v-tooltip>
				</div>

				<v-carousel v-model="gallery.mediaIdx" height="80vh" hide-delimiters @change="handleCarouselChange">
					<v-carousel-item v-for="(item, itemIdx) in gallery.items" :key="itemIdx">
						<Media :ref="'media_' + itemIdx" :value="item" height="80vh" playable contain />
					</v-carousel-item>
				</v-carousel>
			</template>
		</Modal>

		<v-card-text v-if="value.results.length === 0">
			<v-alert type="info" class="mb-0" text prominent>
				<span v-text="$t('mediaGallery.empty')"></span>
			</v-alert>
		</v-card-text>
		<template v-else v-for="(group, groupIdx) in _groupedByDates">
			<v-card-title v-if="!noGrouping" :key="group.date + '_title'" class="text-left pb-4 font-weight-bold" v-text="group.date" />
			<v-card-text v-if="!dense" :key="group.date + '_text'">
				<vue-masonry-wall v-if="!reload" :items="group.results" :options="options" @append="handleAppend">
					<template v-slot:default="{ item, index }">
						<Media
							:value="item"
							@click="handleMediaClick(item, groupIdx, index)"
							thumbnail
						/>
					</template>
				</vue-masonry-wall>
			</v-card-text>
			<v-card-text v-else :key="group.date + '_text'">
				<v-row dense>
					<v-col cols="4" :key="item.data.src" v-for="(item, index) in group.results">
						<Media
							:value="item"
							:height="100"
							thumbnail
							@click="handleMediaClick(item, groupIdx, index)"
						/>
					</v-col>
				</v-row>
			</v-card-text>
		</template>
	</div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";
import Media from '@/components/Media';
import Modal from '@/components/Modal';
import ShareDialog from '@/components/ShareDialog';
import moment from 'moment';
import { PaginationModel, Text } from '@connectngo/sdk';

export default {
	name: 'MediaGallery',

	components: { VueMasonryWall, Media, Modal, ShareDialog },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel()
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		noGrouping: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		reload: false,
		gallery: {
			visible: false,
			index: 0,
		},
		shareDialog: {
			visible: false,
			share: {
				text: null,
				url: null,
			},
		},
	}),

	computed: {
		_groupedByDates() {
			if (this.noGrouping) {
				return [{
					date: null,
					unix: null,
					results: this.value.results
				}];
			}

			let groups = [];
			this.value.results.forEach(item => {
				const date = moment(item.data.created_at).format('YYYY-MM-DD');
				let group = groups.find(_group => _group.date === date);
				if (!group) {
					group = {
						date,
						unix: moment(item.data.created_at).unix(),
						results: [],
					};
					groups.push(group);
				}
				group.results.push(item);
			});

			// Order by date DESC
			groups = groups.sort((a, b) => a.unix < b.unix ? 1 : -1);

			return groups;
		},
		options() {
			return {
				width: this.dense ? 100 : 300,
				padding: 8,
			}
		},
	},

	methods: {
		handleModalClose() {
			this.gallery.visible = false;
			const ref = this.$refs['media_' + this.gallery.mediaIdx];
			if (ref && ref.length > 0) {
				ref[0].stop();
			}
		},
		handleLinkCopied(response) {
			this.$snack(this.$i18n.t('shareDialog.copied'), 'success');
		},
		handleAppend() {
			this.$emit('load');
		},
		handleDownloadClick(item) {
			const link = document.createElement('a');
			link.href = item.data.url;
			link.target = '_blank';
			link.download = Text.slugify(item.data.media_source_name)
				+ (item.data.media_type.indexOf('video')
					? '.mp4'
					: item.data.media_type === 'image/png'
						? '.png'
						: '.jpg'
				);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		handleShareClick(item) {
			Object.assign(this.shareDialog, {
				visible: true,
				share: {
					text: this.$options.filters.timestampToHumanDate(item.data.created_at),
					url: window.location.origin + this.$router.resolve({
						name: 'sharing',
						params: {
							hash: item.data.hash
						}
					}).href,
				},
			});
		},
		handleMediaClick(media, groupIdx, mediaIdx) {
			Object.assign(this.gallery, {
				visible: true,
				items: this._groupedByDates[groupIdx].results,
				mediaIdx,
			});
		},
		handleCarouselChange() {
			this.gallery.items.forEach((item, itemIdx) => {
				const ref = this.$refs['media_' + itemIdx];
				if (ref && ref[0].playing) {
					setTimeout(() => ref[0].stop(), 300);
				}
			});
		},
	},

	watch: {
		value: {
			deep: true,
			handler() {
				this.reload = true;
				this.$nextTick(() => this.reload = false);
			},
		},
	},
}
</script>
