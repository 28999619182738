import Error404 from "./Error/Error404.vue";
import Home from "@/routes/App/Home.vue";
import Register from "@/routes/App/Register.vue";
import KYCPage from "@/routes/App/KYC.vue";
import Cart from "@/routes/App/Cart.vue";
import Invoice from "@/routes/App/Invoice.vue";
import InvoiceV1 from "@/routes/App/InvoiceV1.vue";
import Reservation from "@/routes/App/Reservation.vue";
import Account from "@/routes/App/Account.vue";
import Mode from "@/routes/Mode.vue";
import SocialSharing from "@/routes/App/SocialSharing.vue";
import Tag from "@/routes/App/Tag.vue";
import Product from "@/routes/App/Product.vue";
import Sitemap from "@/routes/App/Sitemap.vue";
import InteractiveMap from "@/routes/App/InteractiveMap.vue";
import globalVariables from "@/global";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";
import ReceivableStatement from "@/routes/App/ReceivableStatement.vue";
import ComboDetail from "@/routes/App/Combo/ComboDetail.vue";
import ReloadV2 from "@/routes/App/ReloadV2.vue";

const routes = [
	{
		path: "/",
		redirect: () => {
			const lang = globalVariables.websiteConfig.data.default_locale.toLowerCase();
			return { name: "home", params: { lang } };
		},
	},
	{ path: "/:lang/", component: Home, name: "home" },
	{
		path: "/:lang/complete-guest/:guest_id/:email/:hash",
		meta: { middlewares: ["anonymous"] },
		component: Register,
		name: "complete-guest",
	},
	{
		path: "/:lang/register",
		meta: { middlewares: ["anonymous"] },
		component: Register,
		name: "register",
	},
	{
		path: "/:lang/register/reservation/:resId",
		meta: { middlewares: ["anonymous"] },
		component: Register,
		name: "res-register",
	},
	{
		path: "/:lang/login",
		meta: { middlewares: ["anonymous"] },
		component: KYCPage,
		name: "login",
	},
	{
		path: "/:lang/login/reservation/:resId",
		meta: { middlewares: ["anonymous"] },
		component: KYCPage,
		name: "res-login",
	},
	{
		path: "/:lang/password/forgot",
		meta: { middlewares: ["anonymous"] },
		component: KYCPage,
		name: "password.forgot",
	},
	{
		path: "/:lang/password/reset",
		meta: { middlewares: ["anonymous"] },
		component: ResetPasswordForm,
		name: "password.reset",
	},
	{ path: "/:lang/combo/:slug", component: ComboDetail, name: "combo" },
	{ path: "/:lang/tag/:key", component: Tag, name: "tag" },
	{ path: "/:lang/product/:slug", component: Product, name: "product" },
	{
		path: "/:lang/account",
		meta: { middlewares: ["restricted"] },
		component: Account,
		name: "account",
		children: [
			{
				path: "/:lang/account/:section",
				meta: { middlewares: ["restricted"] },
				component: Account,
				name: "account_section",
				children: [
					{
						path: "/:lang/account/:section/:sub",
						meta: { middlewares: ["restricted"] },
						component: Account,
						name: "account_section_sub",
						children: [
							{
								path: "/:lang/account/:section/:sub/:action",
								meta: { middlewares: ["restricted"] },
								component: Account,
								name: "account_section_sub_action",
								children: [
									{
										path:
											"/:lang/account/:section/:sub/:action/:param1",
										meta: { middlewares: ["restricted"] },
										component: Account,
										name:
											"account_section_sub_action_param1",
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{ path: "/:lang/share/:hash", component: SocialSharing, name: "sharing" },
	{ path: "/:lang/mode/:mode", component: Mode, name: "mode" },
	{
		path: "/:lang/cart",
		component: Cart,
		name: "cart",
		children: [
			{ path: "/:lang/cart/:step", component: Cart, name: "cart_step" },
			{
				path: "/:lang/cart/:step/:engine",
				component: Cart,
				name: "cart_payment_3ds_prepare",
			},
			{
				path: "/:lang/cart/:step/:engine/:id",
				component: Cart,
				name: "cart_payment_3ds",
			},
		],
	},
	{
		path: "/:lang/invoice/:uuid",
		component: Invoice,
		name: "invoice",
		children: [
			{
				path: "/:lang/invoice/:uuid/:step",
				component: Invoice,
				name: "invoice_step",
			},
			{
				path: "/:lang/invoice/:uuid/:step/pdf",
				component: Invoice,
				name: "invoice_step_pdf",
			},
		],
	},
	{
		path: "/:lang/transaction/product/:encryptedId",
		component: InvoiceV1,
		name: "invoiceV1",
	},
	{
		path: "/:lang/reservation/:resId",
		component: Reservation,
		name: "reservation",
		children: [
			{
				path: "/:lang/reservation/:resId/3ds-payment/:id",
				component: Reservation,
				name: "reservation_3ds",
			},
			{
				path: "/:lang/reservation/:resId/3ds-payment",
				component: Reservation,
				name: "reservation_3ds_prepare",
			},
		],
	},
	{
		path: "/:lang/receivable-statement/:uuid",
		component: ReceivableStatement,
		name: "receivable_statement",
	},
	{
		path: "/:lang/reload",
		component: ReloadV2,
		name: "reloadV2",
		meta: { middlewares: ["co-7601-reload-flow-v2"] },
		children: [
			{
				path: "/:lang/reload/:step",
				component: ReloadV2,
				name: "reloadV2-step",
				meta: { middlewares: ["co-7601-reload-flow-v2", "with-reload-wallet"]},
			},
		],
	},
	{ path: "/:lang/map", component: InteractiveMap, name: "map" },
	{ path: "/:lang/sitemap", component: Sitemap, name: "sitemap" },
	{ path: "*", component: Error404, name: "error404" },
];

export { routes }
