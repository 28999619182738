<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else :to="{ name: 'account_section_sub', params: { section: 'connected', sub: value.data.id } }">
		<v-list-item>
			<v-list-item-icon>
				<v-avatar size="76" color="secondaryLight">
					<v-img :src="value.data.image" size="76" contain />
				</v-avatar>
			</v-list-item-icon>
			<v-list-item-content>
				<h5 class="caption text-uppercase text--disabled" style="line-height: 1rem" v-text="$t('account.connected.profile')"></h5>
				<h3 class="title mb-1 secondaryDark--text" style="line-height: 1.5rem" v-text="value.data.firstname"></h3>
				<div class="overline font-weight-light text-none" style="line-height: 1rem" v-text="$options.filters.completeUserName(value)"></div>
			</v-list-item-content>
		</v-list-item>

		<div class="px-4 pb-4 d-flex justify-end">
			<v-list-item-action-text>
				<router-link class="text-decoration-none d-flex align-center" :to="{ name: 'account_section_sub', params: { section: 'connected', sub: value.data.id } }" @clic.stop>
					<span v-text="$t('account.connected.viewDetails')"></span>
					<v-icon right x-small>mdi-arrow-right</v-icon>
				</router-link>
			</v-list-item-action-text>
		</div>
	</v-card>
</template>

<script>
import { BaseModel } from '@connectngo/sdk';

export default {
	name: 'ConnectedAccountCard',

	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
