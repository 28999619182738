var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.skeleton)?_c('v-skeleton-loader',{attrs:{"type":"card-heading,list-item-two-line,actions"}}):_c('v-card',_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[(!_vm.hideTitle)?_c('v-card-title',{staticClass:"text-left secondaryDark--text font-weight-bold",domProps:{"textContent":_vm._s(_vm.$t('cashlessAssignCard.title'))}}):_vm._e(),_c('v-card-text',[(_vm.value.results.length === 0)?_c('v-alert',{attrs:{"text":"","prominent":"","type":"info"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cashlessAssignCard.empty'))}})]):_vm._e(),_vm._l((_vm.value.results),function(item,itemIdx){return _c('div',{key:itemIdx},[(itemIdx > 0)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-icon',{staticClass:"mr-4",attrs:{"x-large":""}},[_vm._v("mdi-cash-usd-outline")]),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"caption text--disabled text-uppercase",domProps:{"textContent":_vm._s(_vm.$t('cashlessAssignCard.subtitle'))}}),_c('h5',{staticClass:"font-italic",domProps:{"textContent":_vm._s(_vm.$options.filters.tsToFormat(item.data.created_at, _vm.$t('date.format.humanSmall')))}})]),_c('strong',[_c('span',{class:{
						'success--text': item.data.amount > 0,
						'error--text': item.data.amount < 0,
					},domProps:{"textContent":_vm._s(_vm.$t('virtualWalletDetailsCard.balance', {
						balance: item.data.amount > 0 ? ('+' + item.data.amount) : item.data.amount < 0 ? ('-' + item.data.amount) : item.data.amount,
						type: _vm.$options.filters.translatable(item.data.cashless_type.name, item.data.cashless_type.name_i18n, _vm.$i18n.locale),
					}))}})])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }