<template>
	<component v-bind="pages[$route.name || 'index']"></component>
</template>

<script>
import Index from '@/routes/App/Account/Wallet/Index'
import Details from '@/routes/App/Account/Wallet/Details'
import Memories from '@/routes/App/Account/Wallet/Memories'

export default {
	name: 'Wallet',
	metaInfo() {
		return {
			title: this.$i18n.t('route.account_wallet'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_wallet') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},
	data: () => ({
		pages: {},
	}),

	created() {
		this.$route.meta.section = this.$i18n.t('route.account_wallet');
		if (this.$route.meta.sub) {
			this.$route.meta.sub = this.$root.user.data.firstname;
		}

		this.pages = {
			account_section: { is: Index },
			account_section_sub: { is: Details },
			account_section_sub_action: { is: Memories },
		};
	},
}
</script>
