<template>
	<v-row>
		<v-col :style="regionStyle">
			<v-autocomplete
				ref="region"
				v-model="_region"
				:items="regions"
				:label="$t('phoneField.region')"
				item-text="name"
				item-value="country_code"
				outlined
				return-object
				hide-details
				@change="handleRegionChange"
			>
				<template #selection="{ item }">
					<div>
						<div class="d-flex align-center" style="gap: 1rem">
							<span v-text="item.flag"></span>
							<span v-text="'+' + item.dial_code"></span>
						</div>
					</div>
				</template>
				<template #item="{ item }">
					<div class="d-flex align-center w-100 py-3" style="gap: 1rem">
						<span v-text="item.flag"></span>
						<div>
							<div v-text="item.name"></div>
							<div class="font-weight-bold" v-text="'+' + item.dial_code"></div>
						</div>
					</div>
				</template>
			</v-autocomplete>
		</v-col>
		<v-col :style="phoneStyle">
			<v-text-field
				ref="number"
				v-model="number"
				v-mask="mask"
				v-on="$listeners"
				@keyup="updateInput"
				:disabled="!region.dial_code"
				outlined
				:rules="$attrs.rules"
                :label="label"
			></v-text-field>
		</v-col>
	</v-row>
</template>

<script>
import phoneRegion from '@/assets/phone-region.json';
import phoneMask from '@/assets/phone-mask.json';

export default {
	name: 'PhoneField',

	props: {
		value: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
	},

	data: () => ({
		regions: phoneRegion,
		region: {},
		number: '',
	}),

	computed: {
		_region: {
			get() {
				return this.region || {};
			},
			set(value) {
				this.region = value;
			},
		},
		mask () {
			const mask = phoneMask[this._region.country_code]
			const cleanMask = []
			if (!mask) {
				return ''
			}

			if (typeof mask === 'object') {
				for (const maskKey in mask) {
					cleanMask.push(this.maskCleaning(mask[maskKey]))
				}
			} else {
				cleanMask.push(this.maskCleaning(mask))
			}
			return cleanMask
		},

		regionStyle() {
			return {
				flex: 0,
				minWidth: this.$vuetify.breakpoint.mdAndUp
					? '10rem'
					: '100%',
			};
		},

		phoneStyle() {
			return {
				minWidth: this.$vuetify.breakpoint.mdAndUp
					? null
					: '100%',
			};
		},
	},

	watch: {
		region() {
			this.updateInput();
		},
		number() {
			this.updateInput();
		}
	},

	methods: {
		handleRegionChange() {
			this.$refs.region.blur();
			setTimeout(() => {
				this.$refs.number.$el.querySelector('input').focus();
			})
		},
		updateValues() {
			const value = (this.value || '').trim();
			const firstSpaceIdx = value.indexOf(' ');
			if (firstSpaceIdx !== -1) {
				const possibleRegion = value.substring(0, firstSpaceIdx);
				const region = phoneRegion.find(region => region.dial_code === parseInt(possibleRegion));
				this.region = region || {};
				this.number = value.substring(this.region ? firstSpaceIdx : 0);
			} else {
				this.number = value;
			}
		},
		updateInput() {
			this.$emit('input', ((
				this.region.dial_code
					? ('+' + (this.region.dial_code || ''))
					: ''
			) + ' ' + (this.number || '')).trim()
			);
		},
		maskCleaning (mask) {
			const replacedMask = mask.replaceAll(/[^#\-() ]*/gim, '')
			const cleanMask = replacedMask.indexOf('-') === 0
				? replacedMask.substring(1)
				: replacedMask
			return cleanMask.replaceAll('()', '')
		},

		setDefaultCountryCode() {
			const countryCode = this.$root.websiteConfig.data.fields?.default_country_code;
			if (countryCode) {
				const defaultValueRegion = phoneRegion.find(region => region.country_code?.toUpperCase() === countryCode?.toUpperCase());
				this._region = defaultValueRegion
			}
		},
	},
	created() {
		this.setDefaultCountryCode();
		this.updateValues();
	},
}
</script>
