<template>
	<v-container v-bind="$attrs" v-on="$listeners">
		<v-row>
			<v-col cols="12" md="6">
				<v-card>
					<v-card-text>
						<v-alert text prominent type="success" class="mb-0">
							<span
								v-text="$t('invoice.payment.completed')"
							></span>
						</v-alert>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6">
				<SummaryOrderCard
					:cart.sync="invoice"
					:payments.sync="payments"
					:skeleton="skeleton"
				/>
			</v-col>
		</v-row>

		<v-btn
			class="mt-8"
			color="button"
			block
			large
			:disabled="!canContinue"
			:loading="loading"
			@click="handleContinueClick"
		>
			<span v-text="$t('btn.continue')"></span>
			<v-icon right>mdi-arrow-right</v-icon>
		</v-btn>
	</v-container>
</template>

<script>
import SummaryOrderCard from "@/components/SummaryOrderCard";
import { TransactionModel, PaymentModel } from "@connectngo/sdk";
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
	name: "Payment",

	components: { SummaryOrderCard },

  mixins : [ReloadV2Mixin],

	props: {
		invoice: {
			type: TransactionModel,
			default: () => new TransactionModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		loading: false,
		skeleton: true,
	}),

	computed: {
		_invoice: {
			get() {
				return this.invoice;
			},
			set(value) {
				this.$emit("update:invoice", value);
			},
		},
		canContinue() {
			return !this.loading && !this.skeleton;
		},
	},

	methods: {
		handleContinueClick() {
      if(!this.reloadV2FfEnabled) {
        this.$router.push({
          name: "invoice_step",
          params: { uuid: this.invoice.data.uuid, step: "reload" },
        });
      } else {
        this.$router.push({
          name: "invoice_step",
          params: { step: "confirmation" },
        });
      }
		},
		load() {
			this.skeleton = false;
		},
	},

	created() {
		this.load();
	},
};
</script>
