<template>
	<Modal v-model="_visible"  :noClose="true" :persistent="true">
		<template #body>
			<div class="text-center align-center justify-center px-6">
				<h3 class="mb-6" v-if="text.length > 0">{{text}}</h3>
				<v-expand-transition>
					<v-progress-linear indeterminate class="mb-2"></v-progress-linear>
				</v-expand-transition>
			</div>
		</template>
		<template #footer>
		</template>
	</Modal>

</template>

<script>
import Modal from '@/components/Modal';
import Loading from '@/routes/Loading'
export default {
	name: 'LoadingModal',
	components: { Modal, Loading },

	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: "",
		}
	},

	computed: {
		_visible: {
			get() {
				return this.loading
			},
			set(value) {
				this.$emit('handleCancelClick')
			},
		},
	},
}
</script>
