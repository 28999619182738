<template>
	<v-sheet color="secondaryLight" style="gap: 1rem" class="pa-4 d-flex align-center" v-bind="$attrs" v-on="$listeners"
			 flat>
		<div v-if="creditCardImage">
			<img :src="creditCardImage" style="width: 4rem; float: left" :alt="$t('creditCardPreview.creditCard')"/>
		</div>
		<div class="d-flex flex-column justify-center" style="flex: 1">
			<h5 class="caption text--disabled" v-text="$t('creditCardPreview.creditCard')"></h5>
			<h3 class="body-1 font-weight-bold text-uppercase" v-text="creditCardTitle"></h3>
			<h5 class="caption text--disabled" v-text="$t('creditCardPreview.expiresOn', {date:expiryDate})"></h5>
		</div>
	</v-sheet>
</template>

<script>
export default {
	name: 'CreditCardPreview',

	props: {
		creditCardTitle: {
			type: String,
			default: '',
		},
		creditCardImage: {
			type: String,
			default: null,
		},
		expiryDate: {
			type: String,
			default: null,
		},
		skeleton: {
			type: Boolean,
			default: false,
		}
	},
}

</script>
