<template>
	<div>
		<Calendar
			:product="finalProduct"
			:event-group-ids="eventGroupIds"
			:hide-time-slots="false"
			class="mb-4"
			v-model="selectedEvent"
			:unavailableSlots="unavailableSlots"
			@daySelect="daySelected"
		>
			<template v-slot:prepend-month>
				<span class="title" v-text="$t('calendar.dateOfVisit')"></span>
			</template>
		</Calendar>

		<v-card class="pa-4" outlined>
			<Product
				:key="product.guid +'_' +index +'_tagProduct'"
				v-for="(product, index) in eventGroupProducts"
				:class="{'mt-8': index > 0}"
				:product="product"
				product-type="tag"
				:skeleton="skeleton"
				:day-selected.sync="selectedDay"
				@addDisableModifierData="addDisableModifierData"
				:disabledModifier="disabledModifier"
				@handleSlotClick="handleSlotClick"
				:event-id.sync="selectedEvent"
			/>
		</v-card>
	</div>
</template>

<script>
import Calendar from "@/components/Calendar";
import moment from "moment";
import Product from "@/components/Product";
import {EventBus} from "@connectngo/sdk";
export default {
	name: "EventGroupCalendarProducts",
	components: {Product, Calendar},
	props: {
		eventGroupProducts : {
			type: Array,
			required: true
		}
	},

	data: () => ({
		selectedDay: null,
		availableProducts: {},
		selectedEvent : null,
		eventId:null,
		disabledModifier: [],
		skeleton: false,
		unavailableSlots: [],
		timeSlotEventBus : null,
		finalProduct : {},
	}),

	created() {
		this.init();
	},
	computed: {
		eventGroupIds() {
			let arr = this.eventGroupProducts.map(products => products.data.event_group.id);
			return arr.filter((item, index) => arr.indexOf(item) === index);
		}
	},
	mounted() {
		this.timeSlotEventBus = EventBus.subscribe(
			"timeSlotPickedForGroup"+this.eventGroupId,
			this.eventSelected
		);
	},
	destroyed() {
		this.timeSlotEventBus.unsubscribe();
	},

	methods: {
		init() {
			const unavailableSlot = this.eventGroupProducts.map((product) => {
					this.finalProduct = product;
					return {
						unavailable_before:  product.data.unavailable_before,
						eventGroupId: JSON.stringify(product.data.event_group?.id)
					};
				});
			this.unavailableSlots = [...new Set(unavailableSlot)];
		},
		daySelected(value) {
			this.selectedDay = value;
			this.availableProducts = Object.assign({}, this.eventGroupProducts);
		},
		eventSelected(val) {
			this.selectedEvent = val.slot;
		},
		addDisableModifierData(data) {
			this.disabledModifier.push(data);
			this.disabledModifier =  Array.from(new Set(this.disabledModifier.map(JSON.stringify))).map(JSON.parse);
		},
		handleSlotClick(slotMoment, eventGroup) {
			if(this.disabledModifier.length > 0) {
				this.disabledModifier.forEach((data,index) => {
					const disabledModifierUnavailableBefore = eventGroup.data.modifiers.find(modifier => modifier.data.id === data.slotId)?.data.unavailable_before;
					let disabledSlot = slotMoment
						.isAfter(
							moment.unix(disabledModifierUnavailableBefore)
						)
					if(disabledSlot) {
						this.disabledModifier[index]['available'] = true;
					} else {
						this.disabledModifier[index]['available'] = false;
					}
				});
			}
		},
	}
}
</script>

<style scoped>

</style>
