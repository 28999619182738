<template>
	<v-sheet color="transparent" tile>
		<v-btn color="primary" to="/" data-test-selector="error_actions_back" rounded large>
			<v-icon left>mdi-home</v-icon>
			<span v-text="$t('btn.homePage')"></span>
		</v-btn>
	</v-sheet>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({

	name: 'ErrorActions',

	methods: {

		goBack() {
			this.$router.back();
		},
	},
});
</script>
