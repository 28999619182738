<template>
	<v-skeleton-loader v-if="loading" type="article,list-item,list-item,list-item"></v-skeleton-loader>
	<v-card v-else-if="$root.user.data.id && !loading" class="mx-auto my-8 pa-6 full-width" max-width="794"
		color="#F2F2F2" fill-height>
		<template v-if="!showLoginModal">
			<v-card-title class="ma-0 pa-0">
				<div class="d-flex justify-space-between align-center full-width ma-0 pa-0">
					<div>
						<h3 class="text-left open-sans-font font-weight-regular" :class="{ 'subtitle-2': isMobile }">
							{{ $t('reservation.detail', { resId: reservation.data.reservation_id }) }}
						</h3>
						<p v-if="Paid || Cancelled" class="caption text-left open-sans-font font-weight-regular ma-0">{{
								$t('reservation.date', { date: reservation.data.created_at })
						}} <span class="font-weight-bold" v-if="Paid">{{ $t('reservation.fully_paid') }}</span></p>
					</div>
					<div class="px-2 py-1 res-status-container text-right">
						<p class="pa-0 ma-0 caption font-weight-regular open-sans-font black--text">{{
								$t('reservation.status')
						}}</p>
						<p class="pa-0 ma-0 headline open-sans-font font-weight-bold"
							:class="[{ 'subtitle-2': isMobile }, statusData[reservation.data.status].color]"
							v-text="statusData[reservation.data.status].text"></p>
					</div>
				</div>
			</v-card-title>
			<v-card-text class="mt-6 pa-0">
				<div class="d-flex justify-space-between align-sm-center full-width ma-0 pa-0  flex-sm-row flex-column"
					:class="{ '': isMobile }">
					<div class="white pa-3 mb-6 mb-sm-0 res-date-container" :class="{ 'full-width': isMobile }">
						<p class="pa-0 ma-0 grey--text text--darken-4 caption open-sans-font">
							<span v-for="resInfo in reservationInfo" v-if="resInfo.condition">{{ resInfo.text
							}}<br></span>
							<span v-if="reservation.data.quote_number">
								{{ $t('reservation.quote_number') }} :
								{{ reservation.data.quote_number }}
							</span>
						</p>
					</div>
					<div class="pa-3 res-date-container text-left white"
						:class="{ 'full-width height_auto': isMobile }">
						<div class="pa-0 ma-0 caption grey--text text--darken-4 open-sans-font">
							<p class="pa-0 ma-0" v-if="account.data.id">{{ $t('reservation.client_id', { id: account.data.id }) }}</p>
							<p class="pa-0 ma-0" v-if="account.data.company_name">{{ $t('reservation.company_name', { name: account.data.company_name }) }}</p>
							<p class="pa-0 ma-0" v-if="account.data.firstname">{{ account.data.firstname }} {{ account.data.lastname }}</p>
							<p class="pa-0 ma-0" v-if="account.data.address_info && account.data.address_info.length > 0">{{ account.data.address_info.address1 }}</p>
							<p class="pa-0 ma-0" v-if="account.data.address_info && account.data.address_info.length > 0">{{ account.data.address_info.city }} , {{ account.data.address_info.zipcode_postalcode }}</p>
						</div>
					</div>
				</div>

				<div class="d-flex justify-space-between full-width my-6 pa-6 white"
					:class="{ 'flex-column': CancelledResponsive1, 'align-center': CancelledResponsive2 }">
					<div :class="{ 'full-width': CancelledResponsive1 }">
						<p v-if="Cancelled || Quote || Confirmed"
							class="pa-0 ma-0 black--text font-weight-bold subtitle-1 open-sans-font"
							v-text="$t(statusData[reservation.data.status].reservationMessage)">
						</p>

						<p v-if="!Cancelled && !Quote" class="pa-0 ma-0 black--text caption open-sans-font"
							v-text="statusData[reservation.data.status].balanceStatusText"></p>
						<p v-if="Quote" class="pa-0 ma-0 grey--text font-weight-bold caption open-sans-font">
							{{ $t('reservation.non_refundable') }} <a v-if="false" href="#" class="black--text">{{
									$t('reservation.more_info')
							}}</a>
						</p>
					</div>
					<v-card-actions class="pa-0"
						:class="{ 'full-width d-flex justify-end mt-3': CancelledResponsive1, 'align-end flex-column': windowWidth < 480 }">
						<v-btn v-if="Paid" color="light-blue" class="px-3 py-5 ma-0 ml-3 font-weight-bold caption"
							outlined @click="onViewProductCodesClick()">
							<v-icon class="pr-1">mdi-file-pdf</v-icon>
							{{ $t('btn.product_codes') }}
						</v-btn>
						<v-btn color="green" class="px-3 py-5 ma-0 mr-3 font-weight-bold caption"
							:class="{ 'ml-3': Paid, 'mb-4': windowWidth < 480 }"
							outlined @click="onViewPdfClick()">
							<v-icon class="pr-1">mdi-file-pdf</v-icon>
							{{ $t('btn.pdf') }}
						</v-btn>
						<v-btn v-if="(Quote || Confirmed) && reservation.data.balance > 0 && enableSelfPayment" :class="{ 'mb-4': windowWidth < 480 }" @click="handleSelfPay()"
							class="px-3 py-5 ma-0 green lighten-1 white--text font-weight-bold caption">
							<v-icon class="pr-1">{{ Confirmed && 'mdi-basket' || 'mdi-check-circle' }}</v-icon>
							{{ $t('btn.pay_reservation') }}
						</v-btn>
					</v-card-actions>
				</div>

				<SummaryOrderCard :cart.sync="reservation" :payments.sync="payments" :skeleton="skeleton"
					:showDepositSection="true" class="pa-0 py-sm-12 pa-6 pa-print-0 mb-8 full-width" />
				<SelfPayDialog
					:visible.sync="selfPayDialog.visible"
					:max-width="400"
					:cart="reservation"
					@completed="completedPay"
				/>

				<div v-if="Notes" class="full-width my-6 px-6 pt-6 pb-9 white">
					<p class="black--text headline open-sans-font" :class="{ 'subtitle-1': isMobile }"
						v-text="$t('reservation.note_title')"></p>
					<div class="pa-2 mx-3" style="border: 1px solid #E0E0E0">
						<p class="black--text ma-0 pa-0 subtitle-1 open-sans-font" :class="{ 'caption': isMobile }"
							v-text="Notes">
						</p>
					</div>
				</div>
			</v-card-text>
			<PdfModal @handleDownloadClick="onDownloadPdfClick" @handleCancelClick="cancelPdfModal" :showPdf="showPdf"
				:pdfTitle="pdfTitle" :pdfData="pdfData"></PdfModal>
		</template>
		<SwitchAccountModal :showLoginModal="showLoginModal" :resEmail="account.data.email"
			@logOutEvent="handleLogoutClick" @cancelClick="() => redirectToLogin('home')"></SwitchAccountModal>
	</v-card>
</template>

<script>
import SummaryOrderCard from '@/components/SummaryOrderCard';
import KYC from '@/components/KYC.vue';
import Modal from '@/components/Modal';
import PdfModal from '@/components/Modals/PdfModal';
import SwitchAccountModal from '@/components/Modals/SwitchAccountModal'
import { WindowWidthMixin } from '@/mixins/ProductMixin';
import globalVariables from '@/global';
import { EComService, EventBus, TransactionModel, PaymentModel, AccountModel, BaseModel } from '@connectngo/sdk';
import SelfPayDialog from '@/routes/App/Reservation/SelfPayDialog'
export default {
	name: "Reservation",
	mixins: [WindowWidthMixin],
	components: { SummaryOrderCard, KYC, Modal, PdfModal, SwitchAccountModal, SelfPayDialog },

	data: () => ({
		loading: false,
		skeleton: true,
		reservation: new TransactionModel(),
		payments: new PaymentModel(),
		account: new AccountModel(),
		transactionPdf: new BaseModel(),
		productsPdf: new BaseModel(),
		pdfData: null,
		pdfTitle: null,
		showPdf: false,
		pdfType: 'RESERVATION',
		pdfCustomerType: 'INDIVIDUAL',
		showLoginModal: false,
		enableSelfPayment: false,
		reservationInfo: Array,
		selfPayDialog: {
			visible: false,
		},
	}),

	watch: {
		'$root.user.data.id': {
			handler(value) {
				if (!value) {
					this.redirectToLogin('login');
				}
			}
		},
	},

	methods: {

		handlePrintClick() {
			window.print();
		},

		handleSelfPay() {
			Object.assign(this.selfPayDialog, {
				visible: true,
			})
		},

		shouldAutoComplete(paymentResponseArgs = null)
		{
			return !this.$root.websiteConfig.data.tenant.fields_data.reservation_manual_convert
				&&
				(
					(
						this.reservation.data.became_invoice_at === null
						&& this.reservation.data.can_be_completed === true
					)
					|| paymentResponseArgs?.paid === true
				);
		},

		completedPay(args) {
			if(this.shouldAutoComplete(args)){
				this.loading = true;
				Promise.all([
					new EComService().completeReservation(this.reservation.data.uuid),
				]).then(() => {
						Object.assign(this.selfPayDialog, {
							visible: false,
						});
						this.load(true);
					})
					.catch(reason => {
						this.$handleError(this, reason);
					} )
					.finally(() => (this.loading = false));
			}else{
				Object.assign(this.selfPayDialog, {
					visible: false,
				});
				this.load(true);
			}
		},

		triggerSelfPayDialog() {
			Object.assign(this.selfPayDialog, {
				visible: true
			});
		},

		load(resetRouteAfter3ds = false) {
			if (resetRouteAfter3ds && this.$route.name !== 'reservation') {
				this.$router.push({
					name: "reservation",
					params: this.$route.params
				});
			}
			this.loading = true;
			this.enableSelfPayment = this.$root.websiteConfig.data.tenant.fields_data.disable_full_deposit_payment == '0' ? true : false;
			this.reservationDepositPercentage = this.$root.websiteConfig.data.tenant.fields_data.reservation_deposit_percentage;

			return Promise.all([
				new EComService().getReservationDetails(this.$route.params.resId),
			])
				.then(([reservation]) => {
					Object.assign(this, {
						reservation,
						payments: new PaymentModel({
							payments: reservation.data.allPayments,
						}),
						account: new AccountModel(reservation.data.account)
					});

					this.reservation.deposit = false;

					if(this.reservationDepositPercentage != '0'){
						this.reservation.deposit = true;
						this.reservation.depositAmount = this.reservation.applyDepositPercentage(this.reservationDepositPercentage);
					}

					if (this.$root.user.data.email !== this.account.data.email) {
						this.showLoginModal = true;
					}
					this.skeleton = false;
					this.assignData();

					if (this.shouldAutoComplete()) {
						this.completedPay(null)
					}
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},

		formatDate(date) {
			return this.$options.filters.tsToFormat(date, this.$t('date.format.ymd'));
		},

		onViewProductCodesClick() {
			this.pdfData = this.productsPdf
			this.pdfTitle = this.$t('btn.product_codes')
			this.showPdf = true;
		},

		onViewPdfClick() {
			if (!this.transactionPdf) {
				this.$handleError(this,{ message:  this.$t('alert.genericError')});
				return;
			}
			window.open(this.transactionPdf.data.url);
		},

		onDownloadPdfClick() {
			if (!this.pdfData) return;
			const byteCharacters = atob(this.pdfData.data.base_64);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const file = new Blob([byteArray], { type: 'application/pdf;base64' });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		},

		cancelPdfModal() {
			this.showPdf = false;
			this.pdfData = null;
			this.pdfTitle = null;
		},

		apiForInvoice() {
			return Promise.all([
				new EComService().getProductsPdf(this.$route.params.resId),
				new EComService().getTransactionPdf(this.$route.params.resId, this.pdfType, this.pdfCustomerType),
			])
				.then(([productsPdf, transactionPdf]) => {
					Object.assign(this, { productsPdf });
					Object.assign(this, { transactionPdf });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false))
		},

		redirectToLogin(page) {
			if (page === 'login') {
				this.$router.push({ name: 'res-login', params: { resId: this.$route.params.resId } });
			} else {
				this.$router.push({ name: 'home' });
			}
		},

		handleLogoutClick() {
			EventBus.publish('RES_LOGOUT');
		},

		onResize() {
			this.isMobile = window.innerWidth < 600;
			this.windowWidth = window.innerWidth;
		},

		assignData() {
			this.reservationInfo = [
				{
					condition: this.Quote || this.Confirmed,
					text: this.$t('reservation.date', { date: this.formatDate(this.reservation.data.created_at) })
				},
				{
					condition: this.Paid || this.Cancelled,
					text: this.$t('reservation.invoice', { resId: this.reservation.data.reservation_id })
				},
				{
					condition: this.Paid,
					text: this.$t('reservation.paid_on', { date: this.formatDate(this.reservation.data.became_invoice_at) })
				},
				{
					condition: true,
					text: this.$t('reservation.visiting_date', { date: this.formatDate(this.reservation.data.reservation_date) })
				},
				{
					condition: this.Cancelled,
					text: this.$t('reservation.cancellation_date', { date: this.formatDate(this.reservation.data.deleted_at) })
				},
			];
		}
	},

	computed: {
		Quote() {
			return this.reservation.data.status === 'QUOTE';
		},
		Confirmed() {
			return this.reservation.data.status === 'CONFIRMED';
		},
		Paid() {
			return this.reservation.data.status === 'PAID';
		},
		Cancelled() {
			return this.reservation.data.status === 'CANCELLED';
		},

		CancelledResponsive1() {
			return !this.Cancelled && this.windowWidth < 790 || this.windowWidth < 480;
		},

		CancelledResponsive2() {
			return this.windowWidth > 789 || this.Cancelled && this.windowWidth > 480;
		},

		Notes() {
			return this.reservation.data.fields.note != '' && this.reservation.data.fields.note;
		},
		statusData(){
			return {
				QUOTE: {
					text: this.$t('reservation.quote'),
					balanceStatusText: this.$t('reservation.confirm_quote', { date: '11/14/2022' }),
					color: 'purple--text',
					reservationMessage : 'reservation.not_reserved',
				},
				CONFIRMED: {
					text: this.$t('reservation.confirmed'),
					balanceStatusText: this.$t('reservation.pay_balance'),
					color: 'warning--text',
					reservationMessage : 'reservation.reservation_confirmed',
				},
				PAID: {
					text: this.$t('reservation.paid'),
					balanceStatusText: this.$t('reservation.paid_and_reserved'),
					color: 'success--text',
					reservationMessage : '',
				},
				CANCELLED: {
					text: this.$t('reservation.cancelled'),
					color: 'error--text',
					reservationMessage : 'reservation.reservation_cancelled',
				},
			};
		},
	},

	created() {
		this.loading = false;
		this.load();
		if (!this.$root.user.data.id) {
			this.redirectToLogin('login');
		}
		this.apiForInvoice();
		if (this.$route.name === 'reservation_3ds') {
			this.triggerSelfPayDialog();
		}
	},
}
</script>

<style lang="scss" scoped>
.res-status-container {
	background-color: #fff;
}

.res-date-container {
	height: 114px;
	width: calc(50% - 12px);
}
</style>
