<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners" :to="_route">
		<v-list-item>
			<v-list-item-icon>
				<QrCode v-if="value.data.wallet_type.issuing === 'INTERNAL_BARCODE'" v-model="value.data.uid.uid" size="76" />
				<v-avatar v-else size="76" color="secondaryLight">
					<v-img :src="$root.websiteConfig.data.images['wallet-uid-image']" size="76" contain />
				</v-avatar>
			</v-list-item-icon>
			<v-list-item-content>
				<h5 class="caption text-uppercase text--disabled" style="line-height: 1rem" v-text="$t('account.wallet.subtitle')"></h5>
				<h3 class="title mb-1 secondaryDark--text" style="line-height: 1.5rem" v-text="$options.filters.translatable(value.data.wallet_type.data.name, value.data.wallet_type.data.name_i18n, $i18n.locale)"></h3>
				<div class="d-flex align-center">
					<v-icon style="flex: 0" class="mr-1" small>mdi-pound</v-icon>
					<strong v-text="$t('account.wallet.uid', {
						uid: value.getUidLabel()
					})"></strong>
				</div>
				<DigitalWallet
					v-if="value.data.wallet_type.data.issuing === 'INTERNAL_BARCODE'"
					:source="'wallet'"
					:itemId="value.data.id"
					class="my-3"
				/>
			</v-list-item-content>
		</v-list-item>

		<div v-if="!hideLink" class="px-4 pb-4 d-flex justify-end">
			<v-list-item-action-text>
				<router-link class="text-decoration-none d-flex align-center" :to="_route" @click.stop>
					<span v-text="$t('account.wallet.viewDetails')"></span>
					<v-icon right x-small>mdi-arrow-right</v-icon>
				</router-link>
			</v-list-item-action-text>
		</div>
	</v-card>
</template>

<script>
import QrCode from '@/components/QrCode';
import { WalletModel } from '@connectngo/sdk';
import DigitalWallet from '@/components/DigitalWallet/DigitalWallet'

export default {
	name: 'VirtualWalletCard',

	components: { QrCode, DigitalWallet },

	props: {
		value: {
			type: WalletModel,
			default: () => new WalletModel(),
		},
		route: {
			type: [Function, Object],
			default: null,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		hideLink: {
			type: Boolean,
			default: false,
		},
		userName: {
			type: String,
			default: () => '',
		},
	},

	computed: {
		_route() {
			return this.route
				? (this.route instanceof Function ? this.route(this.value) : this.route)
				: { name: 'account_section_sub', params: {
					section: 'wallet',
					sub: this.value.data.id,
				} };
		},
	},
}
</script>
