<template>
	<Modal
		v-model="_visible"
		v-bind="$attrs"
		v-on="$listeners"
		@close="cancelModal"
		:max-width="modalMaxWidth"
		footerClass="py-8 px-0 buttons-container"
		:title="title"
		:hideHeader="hideHeader"
		headerClass="modal-title systemDark1--text"
		:modalClass="modalClass"
		id="combo-modal-experimental"
	>
		<template #body>
			<component
				:key="componentKey"
				:is="currentComponent"
				v-bind="currentProps"
				:cancelModal="cancelModal"
				@showList="showList"
				@showDetail="showDetailHandler"
				@replace-disabled="handleReplaceDisabled"
				@loading="loading = $event"
			/>
		</template>
		<!-- Footer buttons -->
		<template #buttons v-if="isDesktopBreakpoint || showDetail">
			<v-btn
				class="btn btn--cancel mediumGreen--text"
				text
				x-large
				@click="cancelModal"
				:disabled="loading"
			>
				<span class="w-100" v-text="cancelButtonText"></span>
			</v-btn>
			<v-btn
				class="btn btn--primary mediumGreen white--text"
				:loading="loading"
				:disabled="disabled || loading"
				x-large
				@click="$refs.comboItem.applyClicked()"
			>
				<span class="w-100" v-text="primaryButtonText"></span>
			</v-btn>
		</template>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal';
import ComboItem from '@/components/ComboItemExperimental';
import { Carousel, Slide } from 'vue-carousel';
import CombosList from '@/components/CombosList';

export default {
	name: 'ComboModalExperimental',
	components: {
		Modal,
		ComboItem,
		Carousel,
		Slide,
		CombosList
	},
	props: {
		showComboModal: {
			type: Boolean,
			default: false,
		},
		combos: {
			type: Array,
			required: false,
			default: [],
		},
	},

	data() {
		return {
			loading: false,
			allDone: false,
			currentComponent: 'CombosList',
			selectedCombo: null,
			showDetail: false,
			componentKey: 0,
			disabled: true,
			beforeOpen: false,
		}
	},

	mounted() {
		this.beforeOpen = true;
		if (this.combos.length === 1) {
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos[0];
			this.showDetail = true;
		}
	},

	methods: {
		cancelModal() {
			if (this.combos.length > 1) {
				this.showDetail = false;
				this.currentComponent = 'CombosList';
				this.componentKey++;
			} else {
				this.currentComponent = 'ComboItem';
				this.componentKey++;
				this.showDetail = true;
			}
			this.beforeOpen = false;
			this.$emit('handleComboModalCloseClick');
			this.loading = false;
		},
		handleReplaceDisabled(value) {
			this.disabled = value;
		},
		refreshCarousel() {
    		this.$nextTick(() => {
      			const event = new Event('resize');
      			window.dispatchEvent(event);
    		});
  		},
		showDetailHandler(comboId) {
			this.showDetail = true;
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos.find(combo => combo.data.id === comboId);
		},
		showList() {
			this.currentComponent = 'CombosList';
			this.showDetail = false;
			this.componentKey++;
		},
	},

	computed: {
		_visible: {
			get() {
				return this.showComboModal;
			},
			set() {
				this.$emit('handleComboModalCloseClick');
			},
		},
		modalClass() {
			if (this.isDesktopBreakpoint && !this.showDetail) {
				return 'px-12 pt-8';
			} else if (this.isDesktopBreakpoint && this.showDetail) {
				return 'px-8 pt-8';
	 		} else if (!this.isDesktopBreakpoint) {
				return 'px-6 pt-6';
			}
		},
		primaryButtonText() {
			return this.$i18n.t("btn.replace");
		},
		cancelButtonText() {
			return this.$i18n.t("btn.cancel");
		},
		hideHeader() {
			return this.showDetail && this.combos.length > 1
		},
		title() {
			if (this.combos.length > 1 && !this.showDetail) {
				return this.$t('comboModal.modal_title_multiple');
			} else if (this.combos.length === 1 && this.isDesktopBreakpoint) {
				return this.$t('comboModal.modal_title_single');
			} else if (this.combos.length === 1 && !this.isDesktopBreakpoint) {
				return this.$t('comboModal.modal_title_multiple');
			}
		},
		modalMaxWidth() {
			if (!this.showDetail && this.isDesktopBreakpoint) {
				return 1300;
			} else if (this.showDetail && this.isDesktopBreakpoint) {
				return 969;
			} else {
				return 500;
			}
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		},
		currentProps() {
			if (this.currentComponent === 'ComboItem') {
				return {
					combo: this.selectedCombo.data,
					showDetail: this.showDetail,
					cancelModal: this.cancelModal,
					isSingleCombo: this.combos.length === 1,
					beforeOpen: this.beforeOpen,
					ref: 'comboItem',
				}
			} else if (this.currentComponent === 'CombosList') {
				return {
					combos: this.combos,
					showComboModal: this.showComboModal,
					cancelModal: this.cancelModal,
					key: this.componentKey
				}
			}
		}
	},
	updated() {
		this.refreshCarousel()
	},
	created() {
		if (this.combos.length === 1) {
			this.currentComponent = 'ComboItem';
			this.selectedCombo = this.combos[0];
		}
	},
	watch: {
		modalMaxWidth: {
			handler: function (val) {
				this.refreshCarousel();
				this.componentKey++;
			},
		},
		showDetail(newVal) {
			if (newVal === false) {
				this.disabled = true;
			}
		}
	}
}
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 95%;
	width: auto;
}

#combo-modal-experimental {
	.combo-container {
		> h3 {
			font-size: 22px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
		}
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.modal-title {
		padding-top: 0px !important;
		div {
			flex: 1;
			text-align: center;
			.headline {
				font-size: 22px;
				font-weight: 600 !important;
				line-height: 28px;
			}
		}
	}

	.buttons-container > div {
		gap: 16px !important;
	}

	.btn {
		height: 32px;
		border-radius: 4px;
		border: 1px;
		opacity: 0.5px;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		text-align: center;
		text-transform: none;
		min-width: 235px;
		min-height: 40px;
		line-height: 40px;

		&--cancel {
			border: 1px solid #48C4C5 !important;
		}

		&--cancel:disabled {
			border: 1px solid #48C4C5 !important;
			color: #48C4C5 !important;
			opacity: 50%;
		}
		&--primary:disabled {
			background-color: #48C4C5 !important;
			color: #fff !important;
			opacity: 50%;
		}
	}


	@media screen and (max-width: 600px) {
		.modal-title {
			padding-left: 0px;
		}

		.modal-title .headline {
			font-size: 22px;
			font-weight: 600;
			line-height: 28px;
			text-align: left;
		}
		.btn {
			width: 100%;
			&--primary {
				order: 1;
				border-radius: 4px;
				justify-content: space-between;
				&:disabled {
					color: #fff;
					background-color: #48C4C5 !important;
					opacity: 50%;
				}
			}
			&--cancel {
				order: 2;
				border: 1px solid #48C4C5 !important;
				justify-content: space-between;
			}
		}
		span {
			text-transform: none;
			text-align: center;
		}
	}
}
</style>
