<template>
	<v-card class="">
		<section :class="{'px-2 pt-2': isDesktopBreakpoint }">
			<v-row class="ma-0">
				<v-col :cols="isDesktopBreakpoint ? 6 : 12" class="image-container">
					<div class="promotion-tag secondary">Promotion</div>
					<v-img :src="imageSrc" class="rounded-lg"></v-img>
				</v-col>
				<v-col :cols="isDesktopBreakpoint ? 6 : 12" class="d-flex flex-column info-container">
					<span class="systemDark2--text">FOOD AND BEVERAGE</span>
					<a href="#" class="primary--text">{{ title }}</a>
					<span class="d-flex align-center systemDark2--text">RECHARGEABLE PRODUCT <v-icon class="ml-2">mdi-information-outline</v-icon></span>
				</v-col>
			</v-row>
			<v-row class="ma-0">
				<v-col>
					<p class="desc systemDark2--text">
						Enjoy our multiple on-site restaurant that will satisfy all your
						cravings while enjoying our many rides.
					</p>
				</v-col>
			</v-row>
		</section>
		<section :class="[{ 'px-5 pb-5': isDesktopBreakpoint }, priceSectionClassname ]">
			<v-row class="ma-0">
				<v-col :cols="isDesktopBreakpoint ? 7 : 12" class="price-container-1">
					<p>
						<span v-if="showDiscountLabel && !isDesktopBreakpoint" class="discount__wrapper secondaryLight systemDark1--text">{{ discount }}% off</span>
						<span class="original-price mr-4">{{ $options.filters.currency(originalPrice) }}$</span>
						<span class="actual-price secondaryDark--text">
							{{ isDesktopBreakpoint ? $t('product.priceEach', { price: $options.filters.currency(actualPrice) }) : $options.filters.currency(actualPrice) }}
						</span>
					</p>
				</v-col>
				<v-col :cols="isDesktopBreakpoint ? 5 : 12">
					<InputNumeric
						:min="0"
						v-model="quantity"
						:max="10"
						:increment="1"
						:value="0"
						:readonly="false"
						:large="false"
						classname="input-numeric"
					/>
					<div class="text--small mt-1">Quantity (Max. 8) </div>
				</v-col>
			</v-row>
			<v-row class="ma-0">
				<v-col cols="7" v-if="isDesktopBreakpoint">
					<div class="text--large d-flex align-center price-container">
						<span class="text--large">{{ $options.filters.currency(actualPrice) }}</span>
						<span v-if="!showDiscountLabel" class="text--green">({{ discount }}% Off)</span>
						<div v-if="showDiscountLabel" class="discount__wrapper background">{{ discount }}% Off</div>
					</div>
				</v-col>
				<v-col :cols="isDesktopBreakpoint ? 5 : 12">
						<v-btn
							data-testid="addToCart"
							class="button"
							block
							@click="handleAddClick"
						>
							<span v-text="$t('btn.addToCart')"></span>
						</v-btn>
				</v-col>
			</v-row>
		</section>
	</v-card>
</template>

<script>
import InputNumeric from "@/components/InputNumeric.vue";
export default {
	name: "OtherProduct",
	components: {InputNumeric},
	props: {
		imageSrc: {
			type: String,
			default: ""
		},
		title: {
			type: String,
			default: ""
		},
		originalPrice: {
			type: Number,
			default: 0
		},
		actualPrice: {
			type: Number,
			default: 0
		},
		discount: {
			type: Number,
			default: 0
		},
		showDiscountLabel: {
			type: Boolean,
			default: false
		},
		priceSectionClassname: {
			type: String,
			default: ""
		},
	},
	computed: {
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		}
	},
	data() {
		return {
			quantity: 0,
		}
	},
	methods: {
		handleAddClick() {
			EventBus.$emit("add-to-cart", {
				quantity: this.quantity
			});
		}
	}
};
</script>
<style scoped lang="scss">

section:first-child {
	background-color: #fff;
}

section:nth-child(2) {
	background-color: #F8F8F8;
}

.image-container {
  	position: relative;
	.promotion-tag {
  		position: absolute;
  		top: 26px;
  		left: 24px;
  		color: #fff; /* Change this to your preferred text color */
  		padding: 5px;
		z-index: 100;
		top: 26.71px;
		left: 24.41px;
		width: 69px;
		height: 23px;
		padding: 5.83px 9.32px 5.83px 9.32px;
		border-radius: 4.66px;
		font-size: 9px;
		font-weight: 600;
		line-height: 11px;
		letter-spacing: 0em;
		text-align: left;
		color: #000;
	}
}

.original-price, .actual-price {
	font-size: 16px;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0px;
	text-align: left;
}

.original-price {
	color: #DA291C;
	text-decoration: line-through;
}

::v-deep .input-numeric fieldset {
	border: 2px solid #009AA6 !important;
}

.text--small {
	font-size: 11px;
	font-weight: 400;
	line-height: 13px;
	letter-spacing: 0.20000000298023224px;
	text-align: right;
}

.desc {
	font-size: 12px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
}

.price-container {
	gap: 16px;
	.text--large {
		font-size: 24px;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0px;
		text-align: left;
	}

	.text--green {
		color: #219653;
	}

	.discount__wrapper {
		padding: 7px 10px 7px 10px;
		border-radius: 5.05px;
		gap: 8.42px;
		color: #00AC78;
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		height: 100%;
	}
}


.info-container {
	gap: 12px;
	a {
		color: #009AA6;
		font-size: 18px;
		font-weight: 700;
		line-height: 21px;
		letter-spacing: 0px;
		text-align: left;
	}
	> span:first-child {
		font-size: 10px;
		font-weight: 400;
		line-height: 12px;
		letter-spacing: 0px;
		text-align: left;
	}
	> span:nth-child(3) {
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		letter-spacing: 0px;
		text-align: left;
	}

	.v-icon {
		width: 15.1px;
		height: 15.1px;
		line-height: 20px;
		color: #009AA6;
	}
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
}

.row {
	margin: 0 !important;
}

.row:first-child {
	display: flex;
	align-items: center;
}

.v-btn {
	width: 100%;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0.10000000149011612px;
	text-align: center;
	text-transform: none;
}

@media screen and (max-width: 600px) {
	.discount__wrapper {
		width: 84px;
		height: 32px;
		padding: 8px 16px 8px 16px;
		border-radius: 16px;
		gap: 8px;

		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}

	.actual-price {
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		text-align: right;
	}
}
</style>
