<template>
    <v-skeleton-loader v-if="skeleton" type="image"></v-skeleton-loader>
	<v-card data-testid="productTag" v-else v-bind="$attrs">
		<v-img :aspect-ratio="16/9" :src="background">
			<v-overlay v-model="overlay" :opacity="opacity" absolute :class="{
				'pa-4': true
			}">
				<v-btn data-testid="prodTagBtn" :to="to" :href="href" class="px-md-12 btn-wrap-text" x-large outlined v-on="$listeners">
					<span v-html="formattedTag"></span>
				</v-btn>
			</v-overlay>
		</v-img>
	</v-card>
</template>

<script>
export default {
	name: 'ProductTag',

	props: {
		background: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: 'Click here',
		},
		name_i18n: {
			type: [Object,String],
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		opacity: {
			type: Number,
			default: 0.3,
		},
		to: {
			type: [Object,String],
			default: null,
		},
		href: {
			type: String,
			default: null,
		},
		minHeight: {
			type: Number,
			default: 300,
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		overlay: true,
	}),

	computed: {
		formattedTag() {
			let rawName = this.$options.filters.translatable(this.name, this.name_i18n, this.$i18n.locale);
			return this.addLineBreaksEveryNChars(rawName, 30);
		}
	},

	methods: {
		addLineBreaksEveryNChars(text, n) {
			let result = '';
			let currentLength = 0;

			text.split(' ').forEach(word => {
				if (currentLength + word.length > n) {
					result += '<br>';
					currentLength = 0;
				}
				result += word + ' ';
				currentLength += word.length + 1;
			});

			return result.trim();
		}
	}
}
</script>

<style lang="scss" scoped>
.v-btn {
	backdrop-filter: blur(10px);
}
.v-skeleton-loader ::v-deep .v-skeleton-loader__image {
    height: 100%;
}
/* Wrap text in button */
.btn-wrap-text {
	height: auto !important;
	max-width: 100%;
	white-space: normal;
	&.v-btn:not(.v-btn--round).v-size--x-large {
		padding: 14px 16px;
	}
}
</style>
