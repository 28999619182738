<template>
	<span v-text="remaining"></span>
</template>

<script>
import moment from 'moment';

let updateInterval;

export default {
	name: "TimestampCountdown",

	props: {
		value: {
			type: Number,
			default: 0,
		},
	},

	data: () => ({
		remaining: 0,
	}),

	methods: {
		updateTime() {
			const diffTime = this.value - new Date().getTime();
			const duration = moment.duration(diffTime, 'milliseconds');
			this.remaining = diffTime > 0
				? (
					(duration.hours() > 0 ? duration.hours() < 10 ? '0' + duration.hours() : duration.hours() + ':' : '')
					+ (duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes())
					+ ':' + (duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds())
				)
				: 0;
		},
	},

	created() {
		updateInterval = setInterval(this.updateTime, 1000);
		this.updateTime();
	},

	destroyed() {
		clearInterval(updateInterval);
	},
}
</script>
