var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('AccountCard',{attrs:{"skeleton":_vm.skeleton,"route-edit":{ name: 'account_section_sub_action', params: { section: 'connected', sub: _vm.$route.params.sub, action: 'edit' } }},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}}),_c('TransactionHistoryListingCard',{staticClass:"mt-8",attrs:{"skeleton":_vm.skeleton,"route-all":{
					name: 'account_section_sub_action',
					params: {
						section: 'connected',
						sub: _vm.$route.params.sub,
						action: 'transactions'
					}
				},"route":item => ({
					name: 'account_section_sub_action_param1',
					params: {
						section: 'connected',
						sub: this.$route.params.sub,
						action: 'transactions',
						param1: item.data.uuid
					}
				})},model:{value:(_vm.transactionHistory),callback:function ($$v) {_vm.transactionHistory=$$v},expression:"transactionHistory"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('VirtualWalletListingCard',{attrs:{"value":_vm.virtualWallets,"skeleton":_vm.skeleton,"user":_vm.account,"route":item => ({
				name: 'account_section_sub_action_param1',
				params: {
					section: 'connected',
					sub: this.$route.params.sub,
					action: 'wallet',
					param1: item.data.id
				}
			}),"route-all":{ name: 'account_section_sub_action', params: { section: 'connected', sub: _vm.$route.params.sub, action: 'wallet' } }},on:{"created":_vm.handleVirtualWalletCreated}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }