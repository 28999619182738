<template>
	<v-card>
		<v-card-title class="text-left">
			<v-row>
				<v-col cols="12" md="6" class="d-flex align-center">
					<i18n path="mediaGallery.totals" tag="span">
						<strong v-text="_count.videos"></strong>
						<strong v-text="_count.photos"></strong>
					</i18n>
				</v-col>
				<v-col cols="12" md="6" class="d-flex align-center">
					<v-select
						v-model="filter"
						:items="filterItems"
						:label="$t('account.memories.filterBy')"
						prepend-inner-icon="mdi-filter-variant"
						outlined
						hide-details
					/>
				</v-col>
			</v-row>
		</v-card-title>

		<MediaGallery
			v-model="_filtered"
			:skeleton="skeleton"
		/>
	</v-card>
</template>

<script>
import MediaGallery from '@/components/MediaGallery';
import moment from 'moment';
import { PaginationModel, EComService, WalletModel } from '@connectngo/sdk';

export default {
	name: 'Memories',

	components: { MediaGallery },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_memories'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_memories') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		loading: false,
		skeleton: true,
		filter: null,
		filterItems: [],
		details: new WalletModel(),
		memories: new PaginationModel(),
		modal: {
			visible: false
		},
	}),

	computed: {
		_filtered() {
			return new PaginationModel(this.memories.results.filter(item => !this.filter || item.data.media_type.indexOf(this.filter) === 0));
		},
		_count() {
			return {
				photos: this._filtered.results.reduce((reducer, item) => reducer + (item.data.media_type.indexOf('image') === 0 ? 1 : 0), 0),
				videos: this._filtered.results.reduce((reducer, item) => reducer + (item.data.media_type.indexOf('video') === 0 ? 1 : 0), 0),
			}
		},
	},

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getVirtualWallets(this.$root.user.data.id),
				new EComService().getMediaList(this.$route.params.sub),
			])
				.then(([wallets, memories]) => {
					const details = wallets.results.find(wallet => wallet.data.id.toString() === this.$route.params.sub.toString());
					Object.assign(this, { details, memories });

					this.$route.meta.sub = details.getUidLabel();
					this.$breadcrumbs.refresh();

					this.filterItems = [
						{ text: this.$i18n.t('memories.filterBy.mix'), value: null },
						{ text: this.$i18n.t('memories.filterBy.photo'), value: 'image' },
						{ text: this.$i18n.t('memories.filterBy.video'), value: 'video' },
					];

					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		}
	},

	created() {
		this.load();
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.$route.meta.action = this.$i18n.t('account.wallet.memories');
	},
}
</script>=
