<template>
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item" />
	<v-form v-else :disabled="paying" data-test-selector="payment_form_payment_form" v-model="formPaymentValid" @submit.prevent="handlePayClick">

		<PaymentProcessOverlay
			:paying.sync="paying"
			:success.sync="success"
			:error.sync="error"
			@closed="errorClosed"
		/>
		<div v-if="!threeDsChallenge">
			<div v-if="threeDs">
				<v-row class="mb-0 mt-0">
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="ccfirstname">{{$t('input.firstName')}}</label>
						<v-text-field
							v-model="data.account.firstName"
							id="ccfirstname"
							name="ccfirstname"
							prepend-inner-icon="mdi-account"
							:rules="[rules.required]"
							:error-messages="formErrors.firstName"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="given-name"
							outlined
							@input="formErrors = {}"
						></v-text-field>
					</v-col>
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="cclastname">{{$t('input.lastName')}}</label>
						<v-text-field
							v-model="data.account.lastName"
							id="cclastname"
							name="cclastname"
							prepend-inner-icon="mdi-account"
							:rules="[rules.required]"
							:error-messages="formErrors.lastName"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="family-name"
							outlined
							@input="formErrors = {}"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="mb-0 mt-0">
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="ccemail">{{$t('input.email')}}</label>
						<v-text-field
							v-model="data.account.email"
							id="ccemail"
							name="ccemail"
							prepend-inner-icon="mdi-email"
							:rules="[rules.required, rules.email]"
							:error-messages="formErrors.email"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="email"
							outlined
							@input="formErrors = {}"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="mb-0 mt-0">
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="ccaddress">{{$t('input.address')}}</label>
						<AddressAutocompleteField
							v-model="data.account.address1"
							id="ccaddress"
							name="ccaddress"
							prepend-inner-icon="mdi-map-marker"
							:rules="[rules.required]"
							:error-messages="formErrors.address1"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="address-line1"
							outlined
							:loading="skeleton"
							@update="handleAddressesFound"
							@input="formErrors = {}"
						></AddressAutocompleteField>
					</v-col>
				</v-row>

				<v-row class="mb-0 mt-0">
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="cccity">{{$t('input.city')}}</label>
						<v-text-field
							v-model="data.account.city"
							id="cccity"
							name="cccity"
							prepend-inner-icon="mdi-map-marker"
							:rules="[rules.required]"
							:error-messages="formErrors.city"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="address-level2"
							outlined
							@input="formErrors = {}"
						></v-text-field>
					</v-col>
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label">{{$t('input.country')}}</label>
							<CountrySelector
								v-model="data.account.country"
								:guess="data.account.country"
								:disabled="paying"
							/>
					</v-col>
					<v-col class="pt-0 pb-2">
						<label class="text--primary payment-field-label" for="cczip">{{$t('input.ccZip')}}</label>
						<v-text-field
							v-model="data.account.zip"
							id="cczip"
							name="cczip"
							prepend-inner-icon="mdi-map-marker"
							:rules="[rules.zipPostal]"
							:error-messages="formErrors.zip"
							:autofocus="$vuetify.breakpoint.mdAndUp"
							:disabled="paying"
							autocomplete="postal-code"
							outlined
							@input="formErrors = {}"
						></v-text-field>
					</v-col>
				</v-row>
			</div>

			<v-row class="mb-1">
				<v-col class="pt-0 pb-2">
					<label class="text--primary payment-field-label" for="ccname">{{$t('input.ccName')}}</label>
					<v-text-field
						v-model="data.account.name"
						id="ccname"
						name="ccname"
						prepend-inner-icon="mdi-account"
						:rules="[rules.required]"
						:error-messages="formErrors.name"
						:autofocus="$vuetify.breakpoint.mdAndUp"
						:disabled="paying"
						autocomplete="cc-name"
						outlined
						@input="formErrors = {}"
					></v-text-field>
				</v-col>
			</v-row>

			<div class="nmi-field-container">
				<v-row class="mb-1">
					<v-col class="pt-0 pb-2">
						<div class="payment-field-label"><label class="text--primary">{{$t('input.ccNumber')}}</label></div>
						<v-text-field
							outlined
							disabled
							name="ccnumber"
							prepend-inner-icon="mdi-credit-card-outline"
							autocomplete="cc-number"
						></v-text-field>

						<div class="payment-field w-100" id="ccnumber"></div>
						<div class="error--text payment-field-error text-caption">{{formErrorsNmi.ccnumber.message}}</div>
					</v-col>

				</v-row>
			</div>

			<div class="nmi-field-container">
				<v-row class="mb-1">
					<v-col class="pt-0 pb-1">
						<div class="payment-field-label"><label class="text--primary">{{$t('input.ccExp')}}</label></div>
						<v-text-field
							outlined
							disabled
							name="ccexp"
							prepend-inner-icon="mdi-credit-card-outline"
							autocomplete="cc-exp"
						></v-text-field>

						<div class="payment-field" id="ccexp" style="width:48%;"></div>
						<div class="error--text payment-field-error text-caption">{{formErrorsNmi.ccexp.message}}</div>
					</v-col>


					<v-col class="pt-0 pb-1">
						<div class="payment-field-label"><label class="text--primary">{{$t('input.ccCvc')}}</label></div>
						<v-text-field
							outlined
							disabled
							name="cvv"
							prepend-inner-icon="mdi-credit-card-outline"
							autocomplete="cc-csc"
						></v-text-field>

						<div class="payment-field" id="cvv" style="width:48%;"></div>
						<div class="error--text payment-field-error text-caption">{{formErrorsNmi.cvv.message}}</div>
					</v-col>

				</v-row>
			</div>
			<v-checkbox v-model="data.readTerms" :disabled="paying" name="read_terms" class="mt-0" :rules="[rules.required]">
				<template v-slot:label>
				<span>
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
				</span>
				</template>
			</v-checkbox>

			<div class="g-recaptcha"
				 v-if="enableCaptcha"
				 data-callback="onReCaptchaSuccess"
				 :data-sitekey="$root.websiteConfig.data.gcp_recaptcha"
				 data-action="PAYMENT"></div>

			<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canPay" :loading="paying" block x-large>
				<span v-text="$t('btn.pay')"></span>
			</v-btn>
		</div>
		<div id="threeDSMountPoint" class="text-center" :class="threeDsChallenge ? '' : 'd-none'"></div>
	</v-form>
</template>

<script>
	import {
		Rules,
		PaymentService,
		EventBus,
		CartModel,
		BaseModel,
		EComService,
	} from "@connectngo/sdk";
	import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";
	import AddressAutocompleteField from "@/components/AddressAutocompleteField";
	import CountrySelector from "@/components/CountrySelector/CountrySelector"
	import CaptchaMixin from "@/mixins/CaptchaMixin";

	export default {
		name: "NmiForm",

		mixins: [CaptchaMixin],

		components: {
			PaymentProcessOverlay,
			AddressAutocompleteField,
			CountrySelector
		},

		props: {
			cart: {
				type: CartModel,
				default: () => new CartModel(),
			},
			engine: {
				type: BaseModel,
				default: () => new BaseModel(),
			},
			skeleton: {
				type: Boolean,
				default: false,
			},
			balance: {
				type: Number,
				default: null,
			},
		},

		data: () => ({
			paying: false,
			success: false,
			error: false,
			formPaymentValid: false,
			threeDs: false,
			threeDsChallenge: false,
			threeDSecureInterface: null,
			data: {
				readTerms: false,
				account: {
					name: "",
					firstName: "",
					lastName: "",
					email: "",
					country: "",
					address1: "",
					zip: "",
				},
			},
			formErrors: {
				name: "",
			},
			formErrorsNmi: {
				ccnumber: { valid: false, message: "" },
				ccexp: { valid: false, message: "" },
				cvv: { valid: false, message: "" },
			},
			rules: {},
			paymentLockSeconds: 0,
			gateway: null,
		}),

		computed: {
			canPay() {
				return (
					this.formPaymentValid &&
					!this.paying &&
					Object.values(this.formErrorsNmi).every((x) => x.valid) &&
					this.data.readTerms
				);
			},
		},

		methods: {
			errorClosed() {
				location.reload()
			},
			processThreeDs(collectResponse, ctx) {
				let data = {
					paymentToken: collectResponse.token,
					currency: ctx.$root.websiteConfig.data.tenant.currency.code,
					amount: this.cart.data.amountToPay,
					email: ctx.data.account.email,
					city: ctx.data.account.city,
					address1: ctx.data.account.address1,
					firstName: ctx.data.account.firstName,
					lastName: ctx.data.account.lastName,
					postalCode: ctx.data.account.zip,
					country: ctx.data.account.country,
					browserJavaEnabled: String(false),
					browserJavascriptEnabled: String(true),
					browserLanguage: this.$i18n.locale,
					browserColorDepth: String(window.screen.colorDepth),
					browserScreenHeight: String(window.screen.height),
					browserScreenWidth: String(window.screen.width),
					browserTimeZone: String(new Date().getTimezoneOffset()),
					deviceChannel: 'Browser'
				};

				const errorCb = (e) => {
					ctx.loading = false;
					ctx.threeDsChallenge = false;
					ctx.error = true;

					if (this.threeDSecureInterface !== null) {
						this.threeDSecureInterface.unmount();
						this.init();
					}

					this.blockUi(false)
					this.paying = false
				};

				if (this.gateway === null) {
					this.gateway = window.Gateway.create(
						this.engine.data.fields.GATEWAY_KEY
					);
					this.gateway.on("error", errorCb);
				}

				if (this.threeDSecureInterface !== null) {
					this.threeDSecureInterface.unmount();
				}

				const threeDS = this.gateway.get3DSecure();
				this.threeDSecureInterface = threeDS.createUI(data);
				this.threeDSecureInterface.start("#threeDSMountPoint");

				this.threeDSecureInterface.on("challenge", function (e) {
					ctx.loading = false;
					ctx.threeDsChallenge = true;
					ctx.paying = false;
					ctx.blockUi(false);
				});

				this.threeDSecureInterface.on("error", errorCb);

				this.threeDSecureInterface.on("complete", function (e) {
					ctx.loading = true;
					data = {
						...data,
						collectJsToken: collectResponse.token,
						tenantEngineId: ctx.engine.data.id,
						cartUuid: ctx.cart.data.uuid,
						number: collectResponse.card.number,
						cardType: collectResponse.card.type,
					};
					const threeDsData = {
						cavv: e.cavv,
						xid: e.xid,
						eci: e.eci,
						cardHolderAuth: e.cardHolderAuth,
						threeDsVersion: e.threeDsVersion,
						directoryServerId: e.directoryServerId,
						email: ctx.data.account.email,
						city: ctx.data.account.city,
						address1: ctx.data.account.address1,
						firstName: ctx.data.account.firstName,
						lastName: ctx.data.account.lastName,
						postalCode: ctx.data.account.zip,
						country: ctx.data.account.country,
					};
					ctx.paying = true;
					ctx.processPayment(data, ctx, threeDsData);
				});

				this.threeDSecureInterface.on("failure", errorCb);
			},
			processPayment(data, ctx, threeDsData = {}) {
				if (this.threeDSecureInterface !== null) {
					this.threeDSecureInterface.unmount();
				}

				ctx.threeDsChallenge = false;
				ctx.loading = true;

				new PaymentService()
					.processNmi(
						data.tenantEngineId,
						data.cartUuid,
						data.amount,
						data.name,
						data.number,
						data.cardType,
						data.collectJsToken,
						threeDsData,
						this.cart.deposit
					)
					.then((response) => {
						this.handlePaymentSuccess(response);
					})
					.catch((error) => {
						this.handlePaymentError(error);
					});
			},
			loadScriptWithDataAttribute(src, dataAttributes) {
				return new Promise(function (resolve, reject) {
					if (document.querySelector('script[src="' + src + '"]')) {
						resolve();
						return;
					}
					const el = document.createElement("script");
					el.type = "text/javascript";
					el.async = true;
					el.src = src;
					dataAttributes.forEach(function (dataAttribute) {
						Object.keys(dataAttribute).forEach((dataAttributeKey) => {
							el.dataset[dataAttributeKey] =
								dataAttribute[dataAttributeKey];
						});
					});

					el.addEventListener("load", resolve);
					el.addEventListener("error", reject);
					el.addEventListener("abort", reject);

					document.head.appendChild(el);
				});
			},
			handlePayClick() {
				this.pay();
			},
			handleTermsClick() {
				EventBus.publish("OPEN_PAGE", "terms");
			},
			pay() {
				this.blockUi();
				this.paying = true;
				window.CollectJS.startPaymentRequest();
			},
			handlePaymentSuccess(response) {
				this.success = true;
				//We need to maintain the timeout when paying first with a gift card and then with a credit card.
				setTimeout(() => {
					this.success = false;
					this.$emit("completed", response);
				}, 500);
				this.paying = false;
				this.blockUi(false);
			},
			handlePaymentError(error) {
				if (this.$isAlreadyPaid(error)) {
					this.paying = false;
					this.$emit("completed");
				}

				if (
					this.$isAlreadyLocked(error) &&
					this.paymentLockSeconds < error.timeout
				) {
					this.checkPaymentLock();
				} else {
					this.paying = false;
					this.error = true;
					this.loadCaptcha();
					this.$handleError(this, error, this.formErrors, false);
				}
				this.blockUi(false);
			},
			checkPaymentLock() {
				return new EComService()
					.getPaymentLockStatus(this.cart.data.uuid)
					.then((response) => {
						this.paymentLockSeconds = 0;
						this.handlePaymentSuccess(response);
					})
					.catch((error) => {
						this.paymentLockSeconds += 2;
						setTimeout(() => {
							this.paying = true;
							this.handlePaymentError(error);
						}, 2000);
					});
			},
			loadCollectJsConfig() {
				let __self = this;
				window.CollectJS.configure({
					variant: "inline",
					customCss: {
						height: "56px",
						color: "#797979",
						"font-size": "15px",
						"padding-left": "40px",
						"border-radius": "5px",
						width: "100%",
						border: "2px solid gray",
					},
					focusCss: {
						"border-color": __self.$vuetify.theme.isDark
							? __self.$vuetify.theme.themes.dark.primary
							: __self.$vuetify.theme.themes.light.primary,
						"border-style": "solid",
						"border-width": "2px",
					},
					fields: {
						ccnumber: {
							selector: "#ccnumber",
						},
						ccexp: {
							selector: "#ccexp",
						},
						cvv: {
							selector: "#cvv",
						},
					},
					callback: (e) => {
						this.loading = true;

						if (this.threeDs) {
							this.processThreeDs(e, __self);
						} else {
							const data = {
								tenantEngineId: this.engine.data.id,
								cartUuid: this.cart.data.uuid,
								amount: this.cart.data.amountToPay,
								name: this.data.account.name,
								number: e.card.number,
								cardType: e.card.type,
								collectJsToken: e.token,
							};
							this.processPayment(data, __self);
						}
					},
					validationCallback: function (fieldName, valid, message) {
						__self.$set(
							__self.formErrorsNmi[fieldName],
							"message",
							valid ? "" : message
						);
						__self.$set(
							__self.formErrorsNmi[fieldName],
							"valid",
							valid
						);
					},
					fieldsAvailableCallback: () => {
						__self.loading = false;
					},
				});
			},
			handleAddressesFound(address) {
				this.data.account.address1 = address.name;
				this.data.account.country = address.country_code;
				this.data.account.city = address.locality;
				this.data.account.zip = address.postal_code;
			},
			init() {
				this.threeDs = !!+this.engine.data.fields.THREE_DS;
				this.loadScriptWithDataAttribute(
					"https://secure.nmi.com/token/Collect.js",
					[{ tokenizationKey: this.engine.data.fields.TOKENIZATION_KEY }]
				)
					.then((data) => {
						if (this.threeDs) {
							this.loadScriptWithDataAttribute(
								"https://secure.nmi.com/js/v1/Gateway.js",
								[]
							)
								.then((data) => {
									this.loadCollectJsConfig();
								})
								.catch((error) => {
									this.handleError(error);
								});
						} else {
							this.loadCollectJsConfig();
						}
					})
					.catch((error) => {
						this.handleError(error);
					});
			},
			blockUi(toggle = true) {
				const elem = document.getElementById("app");

				if (toggle) {
					elem.classList.add("block-ui");
					document.onkeydown = (e) => false;
				} else {
					elem.classList.remove("block-ui");
				}
			},
			fillForm() {
				this.data.account.firstName = this.$root.user.data.firstname;
				this.data.account.lastName = this.$root.user.data.lastname;
				this.data.account.email = this.$root.user.data.email;
				this.data.account.address1 = this.$root.user.data.address.address1;
				this.data.account.country = this.$root.user.data.address.country;
				this.data.account.city = this.$root.user.data.address.city;
				this.data.account.zip =
					this.$root.user.data.address.zipcode_postalcode;
			},
		},
		created() {
			this.loading = true;
			this.fillForm();
			this.rules = {
				required: (value) =>
					Rules.required(value) || this.$t("rules.required"),
				zipPostal: (value) =>
					Rules.zipCode(value) ||
					Rules.postalCode(value) ||
					this.$t("rules.creditCardZipPostal"),
				email: (value) => Rules.email(value) || this.$t("rules.email"),
			};
			this.init();
		},
	};
</script>

<style>
	.nmi-field-container {
		position: relative;
		height: 122px;
	}

	.payment-field-label {
		height: 20px;
	}

	.payment-field {
		position: absolute;
		top: 20px;
		z-index: 1;
	}

	.nmi-field-container .v-text-field__details {
		display: none;
	}

	.payment-field-error {
		margin-left: 12px;
		height: 20px;
	}

	.v-messages__wrapper {
		min-height: 20px;
	}

	.payment-field iframe {
		height: 56px !important;
	}

	#threeDSMountPoint iframe {
		overflow-y: visible;
	}

	.nmi-field-container fieldset {
		border: unset !important;
	}

	fieldset {
		border: 2px solid rgb(128, 128, 128) !important;
	}
</style>
