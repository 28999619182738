<template>
	<v-container>
		<v-row>
			<v-col cols="12" class="d-flex flex-column justify-center">
				<h2 class="display-1 font-weight-light" v-text="$t('account.wallet.title', {
					name: $root.user.data.firstname,
				})"></h2>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="5">
				<VirtualWalletDetailsCard
					v-model="details"
					:cashless="balances"
					:skeleton="skeleton"
				/>

				<v-card class="mt-6" v-if="$root.websiteConfig.data.experiential_module">
					<v-card-title class="pb-0" v-text="$t('account.wallet.memories')"></v-card-title>
					<MediaGallery
						v-model="memories"
						:skeleton="skeleton"
						dense
						no-grouping
					/>
					<v-card-actions>
						<v-btn color="primary" block :to="{
							name: 'account_section_sub_action',
							params: {
								section: 'wallet',
								sub: $route.params.sub,
								action: 'memories',
							}
						}">
							<span v-text="$t('account.wallet.mediaGallery.goToBtn')"></span>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="7">
				<VirtualWalletContent
					:admissions="admissions"
					:cashless="cashless"
					:addons="addons"
					:prePurchasedItems="prepurchasedItems"
					:skeleton="skeleton"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VirtualWalletDetailsCard from '@/components/VirtualWalletDetailsCard';
import VirtualWalletContent from '@/components/VirtualWalletContent';
import MediaGallery from '@/components/MediaGallery';
import { WalletModel, PaginationModel, EComService } from '@connectngo/sdk';

export default {
	name: 'Details',

	metaInfo() {
		return {
			title: this.$i18n.t('account.wallet.title', { name: this.$root.user.data.firstname }),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('account.wallet.title', { name: this.$root.user.data.firstname }) },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	components: { VirtualWalletDetailsCard, VirtualWalletContent, MediaGallery },

	data: () => ({
		skeleton: true,
		loading: false,
		details: new WalletModel(),
		balances: new PaginationModel(),
		admissions: new PaginationModel(),
		cashless: new PaginationModel(),
		prepurchasedItems: new PaginationModel(),
		addons: new PaginationModel(),
		memories: new PaginationModel(),
	}),
	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getVirtualWallets(this.$root.user.data.id),
				new EComService().getMediaList(this.$route.params.sub),
				new EComService().getVirtualWalletCashlessBalances(this.$route.params.sub),
				new EComService().getVirtualWalletTickets(this.$route.params.sub, true),
				new EComService().getVirtualWalletCashlessLogs(this.$route.params.sub),
				new EComService().getVirtualWalletAddonBalances(this.$route.params.sub),
				new EComService().getVirtualWalletPrepurchasedItemLogs(this.$route.params.sub),
			])
				.then(([wallets, memories, balances, admissions, cashless, addons, prepurchasedItems]) => {
					const details = wallets.results.find(wallet => wallet.data.id == this.$route.params.sub);
					Object.assign(this, { details, memories, balances, admissions, cashless, prepurchasedItems, addons });

					this.$route.meta.sub = details.getUidLabel();
					this.$breadcrumbs.refresh();

					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
