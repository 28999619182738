<template>
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners" no-close>
		<template #body>
			<div class="text-center" v-if="interactiveMap">
				<v-card-text class="px-0 px-md-4">
					<h1 class="display-1" v-text="$t('interactiveMap.modalTitle')"></h1>
					<v-container class="cross-sell-container">
						<InteractiveMapButton :interactive-map="interactiveMap" :on-click="() => {_visible = false}" />
					</v-container>
				</v-card-text>

				<v-divider v-if="products.results.length > 0" class="mb-4 mx-7"></v-divider>
			</div>
			<div  v-if="products.results.length > 0" class="text-center">
				<h1 class="display-1" v-text="$t('crossSellModal.title')"></h1>
				<p v-text="$t('crossSellModal.body')"></p>
			</div>

			<v-card-text data-testid="xSellModal" v-if="products.results.length > 0" class="px-0 px-md-4">
				<v-container class="cross-sell-container">
					<v-row class="mt-6">
						<v-col
							cols="12"
							md="6"
							:key="product.guid"
							v-for="product in products.results"
						>
							<Product
								:product="product"
								:skeleton="skeleton"
								:is-cross-sell="true"
								:event-id.sync="events[product.guid]"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</template>
		<template #actions>
			<div class="d-flex w-100 justify-end" style="gap: 0.5rem">
				<v-btn
					data-testid="xSellCancel"
					color="button"
					data-test-selector="crossSellModal_cancel"
					:disabled="loading"
					:loading="loading"
					outlined
					@click="_visible = false"
				>
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
				<v-btn
					data-testid="xSellContinue"
					type="submit"
					color="button"
					data-test-selector="crossSellModal_add"
					:disabled="loading"
					:loading="loading"
					@click="handleContinueClick"
				>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from "./Modal";
import Product from "./Product";
import { PaginationModel } from "@connectngo/sdk";
import InteractiveMapButton from "@/components/InteractiveMap/InteractiveMapButton";

export default {
	name: "CrossSellModal",

	components: {InteractiveMapButton, Modal, Product },

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		products: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		events: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			loading: false,
			skeleton: false,
		};
	},

	computed: {
		_visible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit("update:visible", value);
			},
		},
		interactiveMap() {
			return this.$root.websiteConfig.data.interactive_map;
		}
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},
		handleContinueClick() {
			this._visible = false;
			this.$emit("continue");
		},
		load() {
			this.skeleton = false;
			this.loading = false;
		},
	},

	created() {
		this.load();
	},
};
</script>
