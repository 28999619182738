export default {
	ab: {
		english: 'Abkhazian',
	},
	aa: {
		english: 'Afar',
	},
	af: {
		english: 'Afrikaans',
	},
	sq: {
		english: 'Albanian',
	},
	am: {
		english: 'Amharic',
	},
	ar: {
		english: 'Arabic',
	},
	hy: {
		english: 'Armenian',
	},
	as: {
		english: 'Assamese',
	},
	ay: {
		english: 'Aymara',
	},
	az: {
		english: 'Azerbaijani',
	},
	ba: {
		english: 'Bashkir',
	},
	eu: {
		english: 'Basque',
	},
	bn: {
		english: 'Bengali',
	},
	dz: {
		english: 'Bhutani',
	},
	bh: {
		english: 'Bihari',
	},
	bi: {
		english: 'Bislama',
	},
	br: {
		english: 'Breton',
	},
	bg: {
		english: 'Bulgarian',
	},
	my: {
		english: 'Burmese',
	},
	be: {
		english: 'Byelorussian',
	},
	km: {
		english: 'Cambodian',
	},
	ca: {
		english: 'Catalan',
	},
	zh: {
		english: 'Chinese',
	},
	co: {
		english: 'Corsican',
	},
	hr: {
		english: 'Croatian',
	},
	cs: {
		english: 'Czech',
	},
	da: {
		english: 'Danish',
	},
	nl: {
		english: 'Dutch',
	},
	en: {
		english: 'English',
	},
	eo: {
		english: 'Esperanto',
	},
	et: {
		english: 'Estonian',
	},
	fo: {
		english: 'Faeroese',
	},
	fj: {
		english: 'Fiji',
	},
	fi: {
		english: 'Finnish',
	},
	fr: {
		english: 'French',
	},
	fy: {
		english: 'Frisian',
	},
	gd: {
		english: 'Gaelic',
	},
	gl: {
		english: 'Galician',
	},
	ka: {
		english: 'Georgian',
	},
	de: {
		english: 'German',
	},
	el: {
		english: 'Greek',
	},
	kl: {
		english: 'Greenlandic',
	},
	gn: {
		english: 'Guarani',
	},
	gu: {
		english: 'Gujarati',
	},
	ha: {
		english: 'Hausa',
	},
	iw: {
		english: 'Hebrew',
	},
	hi: {
		english: 'Hindi',
	},
	hu: {
		english: 'Hungarian',
	},
	is: {
		english: 'Icelandic',
	},
	in: {
		english: 'Indonesian',
	},
	ia: {
		english: 'Interlingua',
	},
	ie: {
		english: 'Interlingue',
	},
	ik: {
		english: 'Inupiak',
	},
	ga: {
		english: 'Irish',
	},
	it: {
		english: 'Italian',
	},
	ja: {
		english: 'Japanese',
	},
	jw: {
		english: 'Javanese',
	},
	kn: {
		english: 'Kannada',
	},
	ks: {
		english: 'Kashmiri',
	},
	kk: {
		english: 'Kazakh',
	},
	rw: {
		english: 'Kinyarwanda',
	},
	ky: {
		english: 'Kirghiz',
	},
	rn: {
		english: 'Kirundi',
	},
	ko: {
		english: 'Korean',
	},
	ku: {
		english: 'Kurdish',
	},
	lo: {
		english: 'Laothian',
	},
	la: {
		english: 'Latin',
	},
	lv: {
		english: 'Latvian',
	},
	ln: {
		english: 'Lingala',
	},
	lt: {
		english: 'Lithuanian',
	},
	mk: {
		english: 'Macedonian',
	},
	mg: {
		english: 'Malagasy',
	},
	ms: {
		english: 'Malay',
	},
	ml: {
		english: 'Malayalam',
	},
	mt: {
		english: 'Maltese',
	},
	mi: {
		english: 'Maori',
	},
	mr: {
		english: 'Marathi',
	},
	mo: {
		english: 'Moldavian',
	},
	mn: {
		english: 'Mongolian',
	},
	na: {
		english: 'Nauru',
	},
	ne: {
		english: 'Nepali',
	},
	no: {
		english: 'Norwegian',
	},
	oc: {
		english: 'Occitan',
	},
	or: {
		english: 'Oriya',
	},
	om: {
		english: 'Oromo',
	},
	ps: {
		english: 'Pashto',
	},
	fa: {
		english: 'Persian',
	},
	pl: {
		english: 'Polish',
	},
	pt: {
		english: 'Portuguese',
	},
	pa: {
		english: 'Punjabi',
	},
	qu: {
		english: 'Quechua',
	},
	rm: {
		english: 'Rhaeto-Romance',
	},
	ro: {
		english: 'Romanian',
	},
	ru: {
		english: 'Russian',
	},
	sm: {
		english: 'Samoan',
	},
	sg: {
		english: 'Sangro',
	},
	sa: {
		english: 'Sanskrit',
	},
	sr: {
		english: 'Serbian',
	},
	sh: {
		english: 'Serbo-Croatian',
	},
	st: {
		english: 'Sesotho',
	},
	tn: {
		english: 'Setswana',
	},
	sn: {
		english: 'Shona',
	},
	sd: {
		english: 'Sindhi',
	},
	si: {
		english: 'Singhalese',
	},
	ss: {
		english: 'Siswati',
	},
	sk: {
		english: 'Slovak',
	},
	sl: {
		english: 'Slovenian',
	},
	so: {
		english: 'Somali',
	},
	es: {
		english: 'Spanish',
	},
	su: {
		english: 'Sudanese',
	},
	sw: {
		english: 'Swahili',
	},
	sv: {
		english: 'Swedish',
	},
	tl: {
		english: 'Tagalog',
	},
	tg: {
		english: 'Tajik',
	},
	ta: {
		english: 'Tamil',
	},
	tt: {
		english: 'Tatar',
	},
	te: {
		english: 'Tegulu',
	},
	th: {
		english: 'Thai',
	},
	bo: {
		english: 'Tibetan',
	},
	ti: {
		english: 'Tigrinya',
	},
	to: {
		english: 'Tonga',
	},
	ts: {
		english: 'Tsonga',
	},
	tr: {
		english: 'Turkish',
	},
	tk: {
		english: 'Turkmen',
	},
	tw: {
		english: 'Twi',
	},
	uk: {
		english: 'Ukrainian',
	},
	ur: {
		english: 'Urdu',
	},
	uz: {
		english: 'Uzbek',
	},
	vi: {
		english: 'Vietnamese',
	},
	vo: {
		english: 'Volapuk',
	},
	cy: {
		english: 'Welsh',
	},
	wo: {
		english: 'Wolof',
	},
	xh: {
		english: 'Xhosa',
	},
	ji: {
		english: 'Yiddish',
	},
	yo: {
		english: 'Yoruba',
	},
	zu: {
		english: 'Zulu',
	},
}
