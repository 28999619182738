<template>
	<v-container>
		<v-row class="mb-8">
			<v-col cols="12" class="d-flex flex-column justify-center">
				<h2 class="display-1 font-weight-light" v-text="$t('account.memories.title', {
					name: $root.user.data.firstname,
				})"></h2>
			</v-col>
		</v-row>
		<v-alert v-if="items.results.length === 0" text prominent type="info">
			<span v-text="$t('account.memories.empty')"></span>
		</v-alert>
		<v-row>
			<v-col :key="item.guid" v-for="item in items.results" cols="12" lg="6">
				<VirtualWalletCard
					:value="item"
					:user-name="$root.user.data.firstname"
					:skeleton="skeleton"
					:route="item => ({
						name: 'account_section_sub_action',
						params: {
							section: 'wallet',
							sub: item.data.id,
							action: 'memories',
						}
					})"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import VirtualWalletCard from '@/components/VirtualWalletCard';
import { PaginationModel, EComService, WalletModel } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { VirtualWalletCard },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_memories'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_memories') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		skeleton: true,
		loading: false,
		items: new PaginationModel([{}, {}, {}], WalletModel),
	}),

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getVirtualWallets(this.$root.user.data.id),
			])
				.then(([walletAccounts]) => {
					Object.assign(this, { items: walletAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
