var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.qrCode)?_c('v-icon',{style:({
	minWidth: _vm.size + 'px',
	float: 'left',
}),attrs:{"size":_vm.size / 2}},[_vm._v("mdi-alert-outline")]):_c('img',_vm._g(_vm._b({style:({
	minWidth: _vm.size + 'px',
	float: 'left',
}),attrs:{"src":_vm.qrCode,"width":_vm.size,"height":_vm.size,"alt":"QR"}},'img',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }