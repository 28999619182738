<template>
<div>
	<v-switch
		v-if="field.data.type === 'BOOLEAN'"
		v-model="_value"
		true-value="1"
    	false-value="0"
		v-bind="$attrs"
		v-on="$listeners"
		aria-required="true"
		:rules="[...required ? [_rules.required] : []]"
		class="pt-0 mt-0"
	/>
	<v-row v-else-if="field.data.type === 'DATETIME'">
		<v-col cols="12" md="6">
			<v-menu
				ref="date"
				v-model="menuDate"
				:rules="[...required ? [_rules.required] : []]"
				:close-on-content-click="false"
				close-on-click
			>
				<template #activator="{ on, attrs }">
					<v-text-field
						v-model="datetime.date"
						v-bind="{ ...attrs, ...$attrs }"
						v-on="{ ...on, ...$listeners }"
					></v-text-field>
				</template>
				<v-date-picker
					v-model="datetime.date"
					:locale="$i18n.locale"
					@input="menuDate = false"
				></v-date-picker>
			</v-menu>
		</v-col>
		<v-col cols="12" md="6">
			<v-menu
				ref="time"
				v-model="menuTime"
				:close-on-content-click="false"
				:return-value.sync="datetime.time"
				:rules="[...required ? [_rules.required] : []]"
				close-on-click
			>
				<template #activator="{ on, attrs }">
					<v-text-field
						v-model="datetime.time"
						v-bind="{ ...attrs, ...$attrs }"
						v-on="{ ...on, ...$listeners }"
						:label="$t('placeholder.time')"
					></v-text-field>
				</template>
				<v-time-picker
					v-if="menuTime"
					v-model="datetime.time"
					full-width
					@click:minute="$refs.time.save(datetime.time)"
				></v-time-picker>
			</v-menu>
		</v-col>
	</v-row>
	<v-menu
		ref="date"
		v-else-if="field.data.type === 'DATE'"
		v-model="menuDate"
		:close-on-content-click="false"
		close-on-click
	>
		<template #activator="{ on, attrs }">
			<v-text-field
				v-model="datetime.date"
				:rules="[...required ? [_rules.required] : []]"
				v-bind="{ ...attrs, ...$attrs }"
				v-on="{ ...on, ...$listeners }"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="_value"
			:locale="$i18n.locale"
			@input="menuDate = false"
		></v-date-picker>
	</v-menu>
	<v-menu
		ref="time"
		v-else-if="field.data.type === 'TIME'"
		v-model="menuTime"
		:close-on-content-click="false"
		:return-value.sync="_value"
		:rules="[...required ? [_rules.required] : []]"
		close-on-click
	>
		<template #activator="{ on, attrs }">
			<v-text-field
				v-model="_value"
				v-bind="{ ...attrs, ...$attrs }"
				v-on="{ ...on, ...$listeners }"
			></v-text-field>
		</template>
		<v-time-picker
			v-if="menuTime"
			v-model="_value"
			full-width
			@click:minute="$refs.time.save(_value)"
		></v-time-picker>
	</v-menu>
	<PhoneField
		v-else-if="field.data.type === 'PHONE'"
		v-model="_value"
		v-bind="$attrs"
		v-on="$listeners"
		:rules="[...required ? [_rules.required] : [], _rules.phone_region]"
	/>
	<v-text-field
		v-else-if="field.data.type === 'NUMBER'"
		v-model="_value"
		v-bind="$attrs"
		v-on="$listeners"
		:rules="[...required ? [_rules.required] : []]"
		type="number"
	></v-text-field>
	<v-text-field
		v-else-if="field.data.type === 'EMAIL'"
		v-model="_value"
		v-bind="$attrs"
		v-on="$listeners"
		:rules="[...required ? [_rules.required] : [], _rules.email]"
	></v-text-field>
	<v-textarea
		v-else-if="field.data.type === 'TEXT'"
		v-model="_value"
		v-bind="$attrs"
		:rules="[...required ? [_rules.required] : []]"
		v-on="$listeners"
	></v-textarea>
	<v-select
		v-else-if="field.data.type === 'OPTIONS'"
		v-model="_value"
		:rules="[...required ? [_rules.required] : []]"
		v-bind="$attrs"
		v-on="$listeners"
		:items="options"
	></v-select>

	<!-- DEFAULT: STRING -->
	<v-text-field
		v-else
		v-model="_value"
		:rules="[...required ? [_rules.required] : []]"
		v-bind="$attrs"
		v-on="$listeners"
	></v-text-field>
	</div>
</template>

<script>
import moment from 'moment';
import PhoneField from '@/components/PhoneField';
import { BaseModel, Rules } from '@connectngo/sdk';

export default {
	name: 'CustomField',

	components: { PhoneField },

	props: {
		value: {
			type: String,
			default: '',
		},
		field: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		rules: {
			type: Object,
			default: () => {}
		},
		required: Boolean,
	},

	data: () => ({
		menuDate: false,
		menuTime: false,
		internalRules: {},
	}),

	computed: {
		options() {
			return this.field.data.options.map((option, optionIdx) => ({
				text: this.$options.filters.translatable('', option),
				value: option
			}));
		},
		datetime: {
			get () {
				const date = moment(this.value);
				return {
					date: this.value && date.format('YYYY-MM-DD') || '',
					time: this.value && date.format('HH:mm:ss') || '',
				};
			},
			set (value) {
				const input = value.date + ' ' + value.time;
				this.$emit('input', input)
			},
		},
		_value: {
			get () {
				return this.value
			},
			set (value) {
				this.$emit('input', value, this.field.data.name)
			},
		},
		_rules() {
			return Object.assign({required: this.required}, this.rules, this.internalRules);
		},
	},

	created() {
		this.internalRules.phone_region = value => (value instanceof String ? value : '').indexOf('+') === -1 || this.$t('rules.phoneRegion')
		this.internalRules.email = value => Rules.email(value) || this.$t('rules.email');
	},
}
</script>
