<template>
	<span class="mapItem" :class="{'unavailable' : !available, 'mapItemProduct' : item.mappable_type === productType, 'mapItemMap elevation-6' : item.mappable_type === mapType}"
		  :style="`background-color:${itemBackgroundColor};top:${item.attributes.top};left:${item.attributes.left};color:${textColor};zoom:${labelScale}`"
		  @click="itemClicked(item)"
		  v-text="$options.filters.translatable(item.name, item.name_i18n, $i18n.locale)"
	>
				</span>
</template>

<script>

export default {
	name: "MapItem",
	props : {
		item  : {
			type : Object,
			required : true,
		},
		date : {
			type : String,
			default : ""
		},
		productEvents  : {
			type : Object,
			default : () => {return {}},
		},
		labelScale : {
			type: Number,
			default : 1,
		}
	},
	data() {
		return {
			mapType : "EverestShared\\Models\\InteractiveMap",
			productType : "EverestShared\\Models\\Product",
			soldout : false,
			closed : false,
		}
	},
	beforeMount() {
		if(this.item.mappable_type === this.productType && this.item.mappable.event_group) {
			const events = this.productEvents[this.item.mappable.event_group.id];
			if(events) {
				this.closed = events.data.closed;
				this.soldout =  events.data.available === 0;
			} else {
				this.closed = true;
				this.soldout = true;
			}
		}
	},
	computed : {
		available() {
			return this.item.mappable_type === this.mapType || (!this.soldout && !this.closed);
		},
		itemBackgroundColor() {
			if(this.item.mappable_type === this.productType && this.item.mappable.event_group) {
				return this.available ? "#6C9A37" : "#C43C30";
			} else {
				return this.item.attributes.bg_color;
			}
		},
		textColor() {
			const hex = this.itemBackgroundColor.replace('#', '');
			const r = parseInt(hex.slice(0, 2), 16);
			const g = parseInt(hex.slice(0, 2), 16);
			const b = parseInt(hex.slice(0, 2), 16);
			const hsp = Math.sqrt(
				0.299 * (r * r) +
				0.587 * (g * g) +
				0.114 * (b * b)
			);
			const threshold = 200
			return hsp > threshold ? '#000000' : '#ffffff'
		},
	},
	methods : {
		itemClicked() {
			return this.item.mappable_type === this.productType ?
				this.$emit("productItemClicked", this.item) : this.$emit("mapItemClicked", this.item);
		},
	},
}
</script>

<style scoped>
	.mapItem {
		font-weight: bold;
		cursor:pointer;
		padding:5px;
		position:absolute;
	}

	.mapItemMap {
		padding: 10px 20px;
		border-radius: 10px;
		font-size: 16px;
	}

	@media all and (max-width: 599px) {
		.mapItem {
			font-size: 14px;
		}
	}
</style>
