<template>
  <v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions"></v-skeleton-loader>
	<v-form v-else id="editProfileCard" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation @submit="handleUpload">
		<v-card v-bind="$attrs" v-on="$listeners">
			<v-card-title class="text-left">
				<span v-text="$t('account.edit.profile.title')"></span>
			</v-card-title>
			<v-card-text>

				<v-expand-transition>
					<v-alert v-if="saved" type="success" prominent dismissible>
						<span v-text="$t('alert.infoUpdated')"></span>
					</v-alert>
				</v-expand-transition>

				<template v-if="!hasImage" class="mb-8">
					<h4 class="mb-4" v-text="$t('account.edit.profile.photo')"></h4>
					<p v-text="$t('account.edit.profile.explication')"></p>
					<p class="font-weight-bold" v-text="$t('account.edit.profile.warning')"></p>
				</template>

				<div class="text-center mb-8">
					<v-skeleton-loader v-if="skeleton" type="image" />
					<v-avatar v-else size="128">
						<v-img :src="_value.data.image" />
						<v-overlay v-if="uploading" absolute>
							<v-progress-circular indeterminate />
						</v-overlay>
					</v-avatar>
				</div>

				<v-btn v-if="!hasImage" color="secondaryDark" :disabled="!canUpload" outlined block @click="handleUpload">
					<v-icon left>mdi-cloud-upload-outline</v-icon>
					<span v-text="$t('btn.upload')"></span>
				</v-btn>

				<v-alert v-else text prominent type="info">
					<span v-text="$t('editProfileCard.alreadyUploaded')"></span>
				</v-alert>
			</v-card-text>
		</v-card>
	</v-form>
</template>

<script>
import convert from 'image-file-resize';
import {Rules, AccountModel, File, EComService} from '@connectngo/sdk';

export default {
	name: 'EditProfileCard',

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		uploading: false,
		saved: false,
		formIsValid: true,
		formErrors: {},
		data: {
			firstname: '',
		},
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		hasImage() {
			return this.value.data.image.indexOf('data:image/png;base64') !== 0;
		},
		canSave() {
			return !this.loading && this.formIsValid && !this.skeleton && !this.uploading;
		},
		canSubmit() {
			return !this.loading && !this.skeleton && !this.uploading;
		},
		canUpload() {
			return !this.loading && !this.skeleton && !this.uploading;
		},
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
			};
		},
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},
		handleUpload() {
			File.promptFileDialog(files => {
				File.toBase64(files[0]).then(event => {
					return Promise.all([
						new EComService().updateAvatar(this.value.data.id, event.currentTarget.result),
					])
						.then(([account]) => {
							this._value.data.image = account.data.image;
							this.$snack(this.$i18n.t('editProfileCard.imageUploaded'));
						})
						.catch(reason => this.$handleError(this, reason))
						.finally(() => (this.uploading = false));
				}).catch(error => this.$handleError(this, error));
			}, 'image/*');
		},
	},
}
</script>
