<template>
	<v-skeleton-loader v-if="skeleton" type="card" />
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-expansion-panels accordion>
			<v-expansion-panel>
				<v-expansion-panel-header data-testid="discountSection">
					<v-card-title class="pa-0">
						<span v-text="$t('rebateCard.title')"></span>
					</v-card-title>
				</v-expansion-panel-header>
				<v-expansion-panel-content id="expansion-content">
					<template v-if="hasDiscount">
						<v-card-text >
							<v-alert class="mb-0" :key="discounts[rebateKey].guid" v-for="rebateKey in Object.keys(discounts)" color="info" close-icon="mdi-trash-can-outline" prominent text>
								<h3 class="text-h6 text-uppercase" v-text="$t('rebateCard.applied.title')"></h3>
								<div class="d-flex">
									<v-alert class="mt-3 mb-0" style="flex: 1" color="info" icon="mdi-piggy-bank" outlined>
										<h3 v-if="!discounts[rebateKey].benefitName" class="text-h6 text-uppercase" v-text="$options.filters.translatable(discounts[rebateKey].name, discounts[rebateKey].name_i18n, $i18n.locale)"></h3>
										<h3 v-else class="text-h6 text-uppercase" v-text="$options.filters.translatable(discounts[rebateKey].benefitName, discounts[rebateKey].name_i18n, $i18n.locale)"></h3>
										<p class="ma-0" v-text="$t('rebateCard.applied.code', {
											code: discounts[rebateKey].code
										})"></p>
									</v-alert>
									<v-btn class="my-2 ml-3" color="info" icon @click="handleRebateInput($event, discounts[rebateKey])">
										<v-icon>mdi-trash-can</v-icon>
									</v-btn>
								</div>
							</v-alert>
						</v-card-text>
					</template>
					<template v-else>
						<v-card-text class="text-center">
							<p v-text="$t('rebateCard.desc')"></p>

							<v-form v-model="formRebateIsValid" @submit.prevent="handleSubmitRebate">
								<v-row>
									<v-col style="flex: 1">
										<v-text-field
											data-testid="discountInput"
											v-model="code"
											color="primary"
											placeholder="XXXXXX"
											:disabled="applyingRebate || haveBenefitDiscount"
											:error-messages="formRebateErrors['codes.0'] || formRebateErrors['error']"
											outlined
											@input="formRebateErrors = {}"
										></v-text-field>
									</v-col>
									<v-col style="flex: 0; margin-top: 2px">
										<v-btn data-testid="submitDiscount" type="submit" color="primary" x-large :loading="applyingRebate" :disabled="!formRebateIsValid || applyingRebate || haveBenefitDiscount">
											<v-icon>mdi-arrow-right</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-form>


						</v-card-text>
					</template>
					<template v-if="!hasDiscount && $root.websiteConfig.data.fields.hide_benefits !== '1'">
						<v-divider class="mb-6" />
						<v-card-title class="pt-0">
							<span v-text="$t('rebateCard.walletBenefitsDiscounts')"></span>
						</v-card-title>
						<v-card-text>

							<v-btn v-if="!haveBenefitDiscount" color="primary" block large :disabled="loading" @click="handleBenefitClick">
								<v-icon left>mdi-ticket-outline</v-icon>
								<span v-text="$t('rebateCard.haveMediaOrQR', {
									media: $options.filters.translatable($root.websiteConfig.data.fields.media_name, $root.websiteConfig.data.fields.media_name_i18n, $i18n.locale),
								})"></span>
							</v-btn>
							<v-form v-else-if="!benefitsRetrieved" v-model="formValidateBenefitIsValid" @submit.prevent="handleValidateBenefit">
								<v-text-field
									v-model="benefitData.uid"
									:label="$t('input.uid')"
									:error-messages="formValidateBenefitErrors.uid"
									:disabled="loading"
									:placeholder="$t('input.uid.placeholder')"
									color="primary"
									outlined
									autofocus
									@input="formValidateBenefitErrors = {}"
								></v-text-field>
								<v-text-field
									v-if="showSerialInput"
									v-model="benefitData.serial"
									:label="$t('input.serial')"
									:placeholder="$t('input.serial.placeholder')"
									:error-messages="formValidateBenefitErrors.uid"
									:disabled="loading"
									color="primary"
									outlined
									autofocus
									@input="formValidateBenefitErrors = {}"
								></v-text-field>

								<v-row>
									<v-col cols="12" md="6">
										<v-btn type="submit" color="primary" x-large block :loading="validatingBenefit" :disabled="!canValidateBenefits" @click="handleValidateBenefit">
											<span v-text="$t('btn.validate')"></span>
										</v-btn>
									</v-col>
									<v-col cols="12" md="6">
										<v-btn outlined x-large block :disabled="validatingBenefit" @click="haveBenefitDiscount = false">
											<span v-text="$t('btn.cancel')"></span>
										</v-btn>
									</v-col>
								</v-row>
							</v-form>
							<template v-if="benefitsRetrieved">

								<h3 class="font-weight-light" v-text="$t('rebateCard.benefitsRetrievedTitle')"></h3>
								<div class="my-4">
									<v-select
										v-model="benefitData.selectedBenefit"
										:items="availableBenefits.filter(obj => obj.benefit_id !== null)"
										:label="$t('rebateCard.availableBenefits')"
										:error-messages="formApplyBenefitErrors.selected"
										item-text="name"
										prepend-inner-icon="mdi-sort"
										return-object
										outlined
										hide-details
										@input="formApplyBenefitErrors = {}"
									/>
								</div>

								<v-row class="mt-4">
									<v-col cols="12" md="6">
										<v-btn color="primary" x-large block :loading="applyingBenefit" :disabled="!canApplyBenefits" @click="handleBenefitApplyClick">
											<span v-text="$t('btn.confirm')"></span>
										</v-btn>
									</v-col>
									<v-col cols="12" md="6">
										<v-btn color="primary" block x-large outlined :disabled="applyingBenefit" @click="resetBenefitForms">
											<span v-text="$t('btn.cancel')"></span>
										</v-btn>
									</v-col>
								</v-row>
							</template>
						</v-card-text>
					</template>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-card>
</template>

<script>
import {EComService, CartModel, EventBus} from '@connectngo/sdk';
import globalVariables from "@/global";

export default {
	name: 'RebateCard',

	props: {
		skeleton: {
			type: Boolean,
			default: false,
		},
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
	},

	data: () => ({
		loading: false,
		formRebateIsValid: false,
		formValidateBenefitIsValid: false,
		formRebateErrors: {},
		formValidateBenefitErrors: {},
		formApplyBenefitErrors: {},
		applyingRebate: false,
		validatingBenefit: false,
		applyingBenefit: false,
		code: '',
		haveBenefitDiscount: false,
		benefitData: {
			uid: '',
			serial: '',
			selectedBenefit: '',
			selectedProduct: ''
		},
		availableBenefits: [],
		benefitsRetrieved: false,
	}),

	computed: {
		canValidateBenefits() {
			return this.formValidateBenefitIsValid && this.benefitData.uid && !this.validatingBenefit;
		},
		canApplyBenefits() {
			return this.benefitData.selectedBenefit && !this.applyingBenefit;
		},
		benefitList() {
			const list = [];
			this.availableBenefits.forEach(benefit => {
				list[benefit.data.benefit_id.toString()] =  this.$options.filters.translatable(benefit.data.name, benefit.data.name_i18n, this.$i18n.locale);
			});
			return list;
		},
		products() {
			return this.cart.data.items.map(item => {
				return this.$options.filters.translatable(item.data.product.name, item.data.product.name_i18n, this.$i18n.locale);
			})
		},
		discounts() {
			return this.cart.getAppliedDiscounts();
		},
		hasDiscount() {
			return Object.keys(this.discounts).length !== 0;
		},
		showSerialInput() {
			const barcodeRegEx = /\bW\w{2}-\w{4}-\w{4}-\w{4}\b/;
			return ! barcodeRegEx.test(this.benefitData.uid);
		}
	},

	methods: {
		handleRebateInput(value, rebate) {
			this.$confirm(
				this.$i18n.t('rebateCard.removeDiscount.title'),
				this.$i18n.t('rebateCard.removeDiscount.body'),
				this.$i18n.t('btn.remove'),
				(modal, btn) => {
					btn.attrs.loading = true;
					modal.disabled = true;

					new EComService().removeRebate(rebate.id)
						.then(cart => {
							modal.visible = false;
							globalVariables.cart = cart;
							this.code = '';
							this.haveBenefitDiscount = false;
							this.$snack(this.$i18n.t('rebateCard.removeDiscount.snack'));
							EventBus.publish('CART_UPDATED', cart);
						})
						.catch(reason => this.$handleError(this, reason))
						.finally(() => btn.attrs.loading = false);
				}, {
					dark: true,
				}
			);
		},
		handleBenefitClick() {
			this.haveBenefitDiscount = true;
			Object.assign(this.benefitData, {
				uid: '',
				serial: '',
			});
		},
		handleSubmitRebate() {
			this.applyingRebate = true;
			new EComService().applyRebate(this.code)
				.then(cart => {
					globalVariables.cart = cart;
					this.code = '';
					this.haveBenefitDiscount = false;
					EventBus.publish('CART_UPDATED', cart);
					this.$snack(this.$i18n.t('rebateCard.appliedSnack'));
				})
				.catch(reason => this.$handleError(this, reason, this.formRebateErrors, false))
				.finally(() => (this.applyingRebate = false));
		},
		handleValidateBenefit() {
			this.validatingBenefit = true;
			new EComService().getWalletBenefits(this.benefitData.uid, this.benefitData.serial)
				.then(benefits => {
					this.availableBenefits = benefits.results.map(benefit => {
						return benefit.data;
					}).filter(obj => obj.benefit_id !== null);

					if (this.availableBenefits.length > 0) {
						this.benefitsRetrieved = true;
					} else {
						this.formValidateBenefitErrors.uid = this.$i18n.t('rebateCard.benefitNotFound');
					}
				})
				.catch(() => {
					this.formValidateBenefitErrors.uid = this.$i18n.t('rebateCard.invalidUid');
				})
				.finally(() => (this.validatingBenefit = false));
		},
		handleBenefitApplyClick() {
			this.applyingBenefit = true;
			new EComService().applyBenefitRebate(this.benefitData.selectedBenefit.benefit_id, this.benefitData.selectedBenefit.wallet_id)
				.then(cart => {
					this.haveBenefitDiscount = false;
					EventBus.publish('CART_UPDATED', cart);
					this.$snack(this.$i18n.t('rebateCard.benefitApplied'));
					this.resetBenefitForms();
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.applyingBenefit = false));
		},
		resetBenefitForms() {
			this.haveBenefitDiscount = false;
			this.benefitsRetrieved = false;
		}
	},
}
</script>
<style scoped lang="scss">
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>
