<template>
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners">
		<template #body>
			<v-container>
				<p v-text="$t('shareDialog.manuallyShare')"></p>

				<v-text-field ref="link" v-model="url" :label="$t('shareDialog.link')" class="my-3" outlined dense hide-details readonly>
					<template #append>
						<div class="d-flex align-center" style="height: 1.6rem">
							<v-btn text x-small @click="handleCopyLinkClick">
								<span v-text="$t('btn.copy')"></span>
							</v-btn>
						</div>
					</template>
				</v-text-field>

				<p v-text="$t('shareDialog.orShareSocialNetwork')"></p>

				<v-row>
					<v-col v-for="(item, linkIdx) in links" :key="linkIdx" :cols="6" :md="3">
						<v-tooltip bottom>
							<template #activator="{ on, attrs }">
								<v-btn :href="item.url" target="_blank" :color="item.color" x-large block outlined v-bind="attrs" v-on="on">
									<v-icon large :color="item.color" v-text="item.icon"></v-icon>
								</v-btn>
							</template>
							<span v-text="$t('shareDialog.shareOn', { name: item.title })"></span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</Modal>
</template>

<script>
import Modal from './Modal';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'shareDialog',

	components: { Modal },

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		text: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			default: '',
		},
	},

	computed: {
		_visible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit('update:visible', value);
			},
		},
		links() {
			return [
				{ icon: 'mdi-facebook', color: 'facebook', title: 'Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url) },
				{ icon: 'mdi-twitter', color: 'twitter', title: 'Twitter', url: 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.url) },
				// { icon: 'mdi-facebook', color: 'facebook', title: 'Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url) + '&title=' + encodeURIComponent(this.getConfigText('content')) },
				// { icon: 'mdi-twitter', color: 'twitter', title: 'Twitter', url: 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.getConfigText('content') + ' ' + this.url) },
			];
		},
	},

	methods: {
		handleCopyLinkClick() {
			const element = this.$refs.link.$refs.input;
			element.select();
			element.setSelectionRange(0, 99999);
			document.execCommand('copy');
			this.$emit('copied', element.value);
		},
		getConfigText(type) {
			const item = this.$root.socialSharing.results.find(item => item.data.type === type);
			return item
				? item.data.text_i18n[this.$i18n.locale] || item.data.text
				: this.defaultText;
		},
	},
};
</script>
