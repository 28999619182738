<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-title class="text-left font-weight-bold" v-text="$t('virtualWalletContent.title')"></v-card-title>
		<v-card-text>
			<v-tabs v-model="tab" grow>
				<v-tabs-slider></v-tabs-slider>
				<v-tab v-for="item in _items" :key="item.title">
					<span v-text="item.title"></span>
				</v-tab>
			</v-tabs>
			<v-tabs-items class="mt-8" v-model="tab">
				<v-tab-item v-for="item in _items" :key="item.title">
					<component :is="item.is" v-bind="item.attrs" v-on="item.on" :skeleton="skeleton"></component>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
	</v-card>
</template>

<script>
import VirtualWalletAdmissionListing from '@/components/VirtualWalletAdmissionListing';
import VirtualWalletCashlessListing from '@/components/VirtualWalletCashlessListing';
import VirtualWalletAddOnListing from '@/components/VirtualWalletAddOnListing';
import VirtualWalletPrePurchasedListing from '@/components/VirtualWalletPrePurchasedListing';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletContent',

	props: {
		admissions: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		cashless: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		addons: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		prePurchasedItems: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		tab: null,
	}),
	computed: {
		_items() {

			const items = [
				{ is: VirtualWalletAdmissionListing, attrs: { value: this.admissions, disabled: this.admissions.results.length === 0 }, title: this.$t('virtualWalletAdmissionListing.title') },
				{ is: VirtualWalletAddOnListing, attrs: { value: this.addons, disabled: this.addons.results.length === 0 }, title: this.$t('virtualWalletAddOnListing.title') },
				{ is: VirtualWalletPrePurchasedListing, attrs: { value: this.prePurchasedItems, disabled: this.prePurchasedItems.results.length === 0 }, title: this.$t('virtualWalletPrePurchasedListing.title') },
			];

			if (this.$root.websiteConfig.data.fields.active_cashless === '1') {
				items.splice(1,0, { is: VirtualWalletCashlessListing, attrs: { value: this.cashless, disabled: this.cashless.results.length === 0 }, title: this.$t('virtualWalletCashlessListing.title') });
			}

			return items;
		}
	}
}
</script>
