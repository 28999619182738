<template>
	<v-container v-bind="$attrs" v-on="$listeners">
		<v-form id="assignment" ref="form" :disabled="loading" data-test-selector="cart_assignment_form" v-model="formAssignmentValid" @submit.prevent="handleContinueClick">
			<h1 :class="{ 'text-h4 text-center mb-12': true }" v-text="$t('cart.assignment.title')"></h1>

			<v-row class="justify-center">
				<v-col cols="12" md="6">
					<SummaryOrderCard :cart.sync="cart" :payments.sync="payments" :skeleton="skeleton" />
				</v-col>
				<v-col cols="12" md="6">
					<v-card>
						<v-card-title v-text="$t('cart.assignment.choose.title')"></v-card-title>
						<v-card-text>

							<v-skeleton-loader v-if="skeleton" type="card-heading,list-item"></v-skeleton-loader>

							<v-alert v-else-if="cart.data.items.length === 0" class="ma-0" type="warning" text>
								<span v-text="$t('cart.assignment.choose.noProduct')"></span>
							</v-alert>

							<div :key="item.guid" v-for="(item, index) in assignments.results">
								<v-divider v-if="index > 0" class="my-4" />
								<Product :product="item.data.product" :skeleton="skeleton" :display-price="false" minimal flat />
								<ProductAssigner :index="index" ref="productAssigners" v-model="assignments[index]" :cartDetail="item" class="mt-4" :users.sync="subAccounts" :rules="[rules.required]" :skeleton="skeleton" no-wristband outlined />
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-btn class="mt-8" color="button" block large :disabled="!canContinue" :loading="loading" @click="handleContinueClick">
				<span v-text="$t('btn.continue')"></span>
				<v-icon right>mdi-arrow-right</v-icon>
			</v-btn>

			<v-btn class="mt-4" color="button" outlined block large :disabled="loading" @click="handleCancelClick">
				<span v-text="$t('btn.back')"></span>
			</v-btn>
		</v-form>
	</v-container>
</template>

<script>
import SummaryOrderCard from '@/components/SummaryOrderCard';
import Product from '@/components/Product';
import ProductAssigner from '@/components/ProductAssigner';
import { Rules, EComService, CartModel, PaginationModel, PaymentModel } from '@connectngo/sdk';

export default {
	name: 'Assignment',

	components: { SummaryOrderCard, Product, ProductAssigner },

	props: {
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		loading: false,
		skeleton: true,
		formAssignmentValid: false,
		subAccounts: new PaginationModel(),
		assignments: new PaginationModel(),
		rules: {},
	}),

	computed: {
		_cart: {
			get() {
				return this.cart;
			},
			set(value) {
				this.$emit('update:cart', value);
			},
		},
		canContinue() {
			let isValid = true;
			if (undefined !== this.$refs.productAssigners) {
				isValid = this.$refs.productAssigners.every(pa => pa.state === 'success');
			}


			return !this.loading && this.formAssignmentValid && isValid;
		},
	},

	methods: {
		handleContinueClick() {
			this.formErrors = {};
			if (this.$refs.form.validate()) {
				this.$router.push({ name: 'cart_step', params: { step: 'payment' } });
			}
		},
		handleRetrieveAtParkClick() {
			this.$router.push({ name: 'cart_step', params: { step: 'payment' } });
		},
		handleCancelClick() {
			this.$router.push({ name: 'cart_step', params: { step: 'identification' } });
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getConnectedAccounts(),
				new EComService().getAssignments(),
			])
				.then(([subAccounts, assignments]) => {
					subAccounts.results.unshift(this.$root.user);
					this.subAccounts = subAccounts;
					this.assignments = assignments;
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
		};

		this.load();
	},
}
</script>
