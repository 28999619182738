import globalVariables from "@/global";

const ConfigFieldsMixin = {
	methods: {
		getTranslatedField(name: string, locale: string): string | null {
			try {
				let value = globalVariables.websiteConfig.data.fields[name];
				if (!value) {
					return null;
				}

				if (typeof value === 'string') {
					value = JSON.parse(value);
				}

				return value[locale];
			} catch (error) {
				return null;
			}
		},
		getBooleanField(name: string): boolean {
			return !!parseInt(globalVariables.websiteConfig.data.fields[name])
		},
	}
}

export default ConfigFieldsMixin;
