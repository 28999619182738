<template>
	<v-container>
		<v-row class="mb-8">
			<v-col cols="12" md="6" class="d-flex flex-column justify-center">
				<h2 v-if="$route.params.is_b2b" class="display-1 font-weight-light" v-text="$root.user.data.company_name ? $t('account.transactions.title', {
					name: $root.user.data.company_name,
				}) : $t('account.company_missing')"></h2>
				<h2 v-else class="display-1 font-weight-light" v-text="$t('account.transactions.title', {
					name: $root.user.data.firstname,
				})"></h2>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-end align-center">
				<v-select
					v-model="sortBy"
					:items="sortByItems"
					:label="$t('account.transactions.sortByLabel')"
					prepend-inner-icon="mdi-sort"
					solo
					hide-details
				/>
				<v-btn icon @click="goToPrevPage" :disabled="currentPage <= 1">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<span style="text-align: center" v-text="$t('btn.pagination.page', {currentPage:  currentPage, totalPages: totalPages})">
				</span>
				<v-btn icon @click="goToNextPage" :disabled="currentPage >= totalPages">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-alert v-if="transactions.results.length === 0" text prominent type="info">
			<span v-text="$t('account.transactions.empty')"></span>
		</v-alert>
		<v-row v-else>
			<v-col :key="transactionIdx" v-for="(transaction, transactionIdx) in _transactions" cols="12">
				<TransactionCard
					:value="transaction"
					:skeleton="skeleton"
					:to="{ name: 'account_section_sub', params: { section: 'transactions', sub: transaction.data.uuid } }"
					:route="{ name: 'account_section_sub', params: { section: 'transactions', sub: transaction.data.uuid } }"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TransactionCard from '@/components/TransactionCard';
import { PaginationModel, EComService } from '@connectngo/sdk';

export default {
	name: 'Index',

	metaInfo() {
		return {
			title: this.$i18n.t('account.transactions.title', { name: this.$root.user.data.firstname }),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('account.transactions.title', { name: this.$root.user.data.firstname }) },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	components: { TransactionCard },

	data: () => ({
		loading: false,
		skeleton: true,
		sortBy: 'created_at,desc',
		transactions: new PaginationModel([{}, {}, {}]),
		currentPage: 1,
		totalPages: 1,
	}),

	watch: {
		sortBy(newSortOrder) {
			this.sortBy = newSortOrder;
			this.currentPage = 1; // Reset to first page on sort change
			this.load();
		},
	},

	computed: {
		_transactions() {
			return this.transactions.results;
		},
		sortByItems() {
			return [
				{ text: this.$i18n.t('account.transactions.sortDateDesc'), value: 'created_at,desc' },
				{ text: this.$i18n.t('account.transactions.sortDateAsc'), value: 'created_at,asc' },
				{ text: this.$i18n.t('account.transactions.sortTotalDesc'), value: 'amount,desc' },
				{ text: this.$i18n.t('account.transactions.sortTotalAsc'), value: 'amount,asc' },
			];
		},
	},

	methods: {
		load() {
			this.loading = true;
			this.skeleton = true;
			return Promise.all([
				new EComService().getTransactionHistory(this.$root.user.data.id, {
					page: this.currentPage,
					sort_column: this.getSortColumn(),
					sort: this.getSort(),
				}),
			])
				.then(([transactions]) => {
					this.totalPages = transactions.pagination.last_page;
					this.currentPage = transactions.pagination.current_page;
					Object.assign(this, { transactions });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
		goToPrevPage() {
			this.currentPage -= 1;
			this.load();
		},
		goToNextPage() {
			this.currentPage += 1;
			this.load();
		},
		getSortColumn() {
			return this.sortBy.split(',')[0];
		},
		getSort() {
			return this.sortBy.split(',')[1];
		},
	},

	created() {
		this.load();
	},
}
</script>
