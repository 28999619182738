<template>
  <Page color="neutralLight" no-sidebar>
    <v-skeleton-loader v-if="loading" type="article,list-item,list-item,list-item"></v-skeleton-loader>
    <v-container v-else class="py-md-0 pt-md-6 pa-print-0">

      <div class="col-12">
        <h2 class="mb-2" v-text="$t('receivableStatement.statement_of_account')" />
        <div class="row">
          <div class="col-sm-6 col-12 mt-2">
            <v-card  class="px-4 py-4">
              <h4 class="">{{ account.firstname }} {{ account.lastname }}</h4>
              <p v-if="address" class="font-weight-light mb-0">
                <span v-if="address.company">{{address.company}} <br></span>
                {{address.address1}} <br>
                <span v-if="address.address2">{{address.address2}} <br></span>
                {{address.city}}, {{address.zipcode_postalcode}}
              </p>
            </v-card>
          </div>
          <div class="col-sm-6 col-12 mt-2">
            <v-card  class="px-4  py-4">
              <h4 class="font-weight-light"> {{$t('receivableStatement.balance')}} : <span class="font-weight-bold">{{ $options.filters.currency(balance) }}</span> </h4>
              <h4 class="font-weight-light"> {{$t('receivableStatement.number_of_transactions')}} : <span class="font-weight-bold">{{ transactions.length }}</span> </h4>
            </v-card>
          </div>
        </div>
      </div>

      <div class="col-12">
        <v-card  class="px-4 pt-2 pb-3">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-left">#</th>
            <th class="text-left">{{ $t('receivableStatement.date') }}</th>
            <th class="text-left">{{ $t('receivableStatement.description') }}</th>
            <th class="text-left">{{ $t('receivableStatement.amount') }}</th>
            <th class="text-left">{{ $t('receivableStatement.payments') }}</th>
            <th class="text-left">{{ $t('receivableStatement.balance') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="transactions.length > 0"  v-for="transaction in transactions">
            <td class="font-weight-bold">{{ transaction.transaction_id }}</td>
            <td>{{ $options.filters.timestampToDateOnly(transaction.created_at) }}</td>
            <td>
              <ul class="my-1">
                <li v-for="product in transaction.products_details">{{ product.quantity }} x {{ $options.filters.translatable(product.name, product.name_i18n, $i18n.locale)}}</li>
              </ul>
            </td>
            <td class="font-weight-bold">{{ $options.filters.currency(transaction.amount) }}</td>
            <td>
              <span v-if="transaction.successful_balance_payments.length === 0">{{$t('receivableStatement.no_payments')}}</span>
              <ul v-else  class="my-1">
                <li v-for="payment in transaction.successful_balance_payments">
                  <span>{{$options.filters.currency(payment.amount)}} {{payment.type}}</span><br>
                  <span v-if="payment.details"><em > {{payment.details}}</em> <br></span>
                  {{$options.filters.timestampToDateOnly(payment.created_at)}}
                </li>
              </ul>
            </td>
            <td class="font-weight-bold">{{ $options.filters.currency(transaction.receivable_amount) }}</td>
          </tr>
          <tr v-else>
            <td colspan="6" class="text-center">{{$t('receivableStatement.no_transactions')}}</td>
          </tr>
          </tbody>
        </v-simple-table>
        </v-card>
      </div>
    </v-container>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import { EComService } from '@connectngo/sdk'

export default {
  name : 'ReceivableStatement',
  components : {Page},

  data : () => ({
    loading : false,
    account : null,
    address : null,
    balance : null,
    transactions : null,
  }),

  created () {
    this.getStatementData();
  },

  methods : {
    getStatementData() {
      this.loading = true;
      return new EComService().getAccountReceivableStatement(this.$route.params.uuid).then(data => {
        this.account = data.data.account;
        this.address = data.data.address;
        this.balance = data.data.balance;
        this.transactions = data.data.transactions;

      }).catch(reason => this.$handleError(this, reason))
          .finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>

</style>
