<template>
    <CashlessAssignCard :value="value" class="pa-0" flat readonly hide-title />
</template>

<script>
import CashlessAssignCard from '@/components/CashlessAssignCard';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletCashlessListing',

    components: { CashlessAssignCard },

    props: {
        value: {
            type: PaginationModel,
            default: () => new PaginationModel(),
        },
    },
}
</script>
