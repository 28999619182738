<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item,list-item,list-item,list-item,list-item,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-title class="pb-0" style="justify-content: start !important">
			<span v-text="$t('transactionHistoryListingCard.title')"></span>
		</v-card-title>
		<v-card-text v-if="value.results.length === 0" class="mt-4">
			<v-alert text prominent type="info">
				<span v-text="$t('transactionHistoryListingCard.empty')"></span>
			</v-alert>
		</v-card-text>
		<v-list v-else>
			<v-list-item :key="itemIdx" v-for="(item, itemIdx) in value.results" :to="route(item)">
				<v-list-item-icon>
					<v-icon color="secondaryDark" x-large>mdi-file-percent-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<div class="caption text--disabled" v-text="$options.filters.tsToFormat(item.data.created_at, $t('date.format.ymd'))"></div>
					<h4 class="secondaryDark--text" v-text="$t('transactionHistoryListingCard.number', {
						number: item.data.transaction_id.toUpperCase(),
					})"></h4>
				</v-list-item-content>
				<v-list-item-action class="text-right">
					<span v-text="$options.filters.currency(item.data.total)"></span>
				</v-list-item-action>
			</v-list-item>

			<v-list-item class="justify-end">
				<v-list-item-action-text>
					<router-link class="text-decoration-none d-flex align-center" :to="routeAll" @click.stop>
						<span v-text="$t('transactionHistoryListingCard.sellAll')"></span>
						<v-icon right x-small>mdi-arrow-right</v-icon>
					</router-link>
				</v-list-item-action-text>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'TransactionHistoryListingCard',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		route: {
			type: Function,
			default: (item) => ({
				name: 'account_section_sub',
				params: {
					section: 'transactions',
					sub: item.data.uuid
				}
			}),
		},
		routeAll: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'transactions' } }),
		},
	},
}
</script>
