<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-title v-if="!hideTitle" class="text-left secondaryDark--text font-weight-bold" v-text="$t('prePurchasedAssignCard.title')"></v-card-title>
		<v-card-text>
            <v-alert v-if="value.results.length === 0" text prominent type="info">
                <span v-text="$t('prePurchasedAssignCard.empty')"></span>
            </v-alert>
			<div :key="itemIdx" v-for="(item, itemIdx) in value.results">
				<v-divider v-if="itemIdx > 0" class="my-4" />

				<div class="d-flex align-center justify-space-between">
					<v-icon class="mr-4" x-large>mdi-food</v-icon>

					<div style="flex: 1">
						<div class="caption text--disabled text-uppercase" v-text="$t('prePurchasedAssignCard.subtitle')"></div>
						<h5 class="font-italic" v-text="$options.filters.tsToFormat(item.data.created_at * 1000, $t('date.format.humanSmall'))"></h5>
					</div>

					<strong>
						<span :class="{
							'success--text': item.data.amount > 0,
							'error--text': item.data.amount < 0,
						}" v-text="$t('virtualWalletDetailsCard.balance', {
							balance: item.data.amount > 0 ? ('+' + item.data.amount) : item.data.amount < 0 ? ('-' + item.data.amount) : item.data.amount,
							type: $options.filters.translatable(item.data.prepurchaseitem_type.name, item.data.prepurchaseitem_type.name_i18n, $i18n.locale),
						})"></span>
					</strong>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { PaginationModel, Rules } from '@connectngo/sdk';

export default {
	name: 'PrePurchasedAssignCard',

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		subAccounts: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		hideTitle: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		assignments: {},
		rules: {},
	}),

	created() {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
		};
	},
}
</script>
