<template>
	<AddOnAssignCard :value="value" class="pa-0" flat hide-title />
</template>

<script>
import AddOnAssignCard from '@/components/AddOnAssignCard';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletAddOnListing',

	components: { AddOnAssignCard },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
	},
}
</script>
