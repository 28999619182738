<template>
	<v-snackbar v-bind="$attrs" v-on="$listeners" :color="snack.color" v-model="snack.visible">
		<div class="d-flex align-center">
			<v-icon class="white--text" v-text="snack.icon" left></v-icon>
			<span data-testid="snackMessage" v-text="snack.content" class="mr-4"></span>
			<v-btn text @click="snack.visible = false" data-test-selector="snack_close">
				{{ $t('modal.close') }}
			</v-btn>
		</div>
	</v-snackbar>
</template>

<script>
export default {
	name: "Snack",

	props: {
		snack: {
			type: Object,
			default: () => ({
				visible: false,
				color: 'primary',
				content: '',
				icon: 'mdi-check',
			}),
		}
	},
}
</script>
