var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12"}},[_c('h2',{staticClass:"display-1 font-weight-light",domProps:{"textContent":_vm._s(_vm.$t('account.wallet.title', {
				name: _vm.$root.user.data.firstname,
			}))}})])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('VirtualWalletDetailsCard',{attrs:{"cashless":_vm.balances,"skeleton":_vm.skeleton},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),(_vm.$root.websiteConfig.data.experiential_module)?_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',{staticClass:"pb-0",domProps:{"textContent":_vm._s(_vm.$t('account.wallet.memories'))}}),_c('MediaGallery',{attrs:{"skeleton":_vm.skeleton,"dense":"","no-grouping":""},model:{value:(_vm.memories),callback:function ($$v) {_vm.memories=$$v},expression:"memories"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","to":{
						name: 'account_section_sub_action',
						params: {
							section: 'wallet',
							sub: _vm.$route.params.sub,
							action: 'memories',
						}
					}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('account.wallet.mediaGallery.goToBtn'))}})])],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('VirtualWalletContent',{attrs:{"admissions":_vm.admissions,"cashless":_vm.cashless,"addons":_vm.addons,"prePurchasedItems":_vm.prepurchasedItems,"skeleton":_vm.skeleton}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }