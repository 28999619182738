<template>
	<v-expansion-panels v-model="panel" multiple flat>
		<v-expansion-panel class="px-0">
			<v-expansion-panel-header>
				<span v-text="$t('virtualWallAdmissionListing.active')"></span>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<AdmissionAssignCard :value="value" class="pa-0" flat hide-title />
			</v-expansion-panel-content>
		</v-expansion-panel>
		<v-expansion-panel>
			<v-expansion-panel-header>
				<span v-text="$t('virtualWallAdmissionListing.expired')"></span>
			</v-expansion-panel-header>
			<v-expansion-panel-content class="opacity-66">
				<AdmissionAssignCard :value="value" class="pa-0" expired readonly flat hide-title />
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import AdmissionAssignCard from '@/components/AdmissionAssignCard';
import { PaginationModel } from '@connectngo/sdk';

export default {
	name: 'VirtualWalletAdmissionListing',

	components: { AdmissionAssignCard },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
	},

	data: () => ({
		panel: [0]
	}),
}
</script>
