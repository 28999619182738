<template>
	<v-sheet color="transparent" :class="customClassname">
		<slot v-if="!inProduct" name="prepend-month"></slot>
		<v-container v-if="!hideDaySelection && isDesktopBreakpoint && !showDatePicker" :class="{'px-0': !inProduct}" fluid>
			<v-row>
				<v-col cols="2" class="d-flex align-center py-1">
					<v-btn color="secondaryDark" width="27" height="27" icon :disabled="!canGoBack" @click="handlePreviousClick" outlined>
						<v-icon size="16" height="8">mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8" class="py-1">
					<v-dialog
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						:return-value.sync="month"
						transition="scale-transition"
						offset-y
						width="290px"

					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="d-flex align-center justify-space-between mx-auto py-1" color="secondaryDark" @click="() => monthlyMappedData.length <= 0 && handleDatePickerMonthlyData(tenantNow)" :disabled="!canChangeDate" text v-bind="attrs" v-on="on">
								<span v-text="formattedMonth" />
								<v-icon class="pl-1 calendar__icon" small>mdi-calendar-month</v-icon>
							</v-btn>
						</template>
						<v-date-picker
							v-model="date"
							ref="mainDatePicker"
							:min="formattedTodayDate"
							:events="colorCodeEventDates"
							:disabled="loading || loadingColors"
							:locale="$i18n.locale"
							:first-day-of-week="firstDayOfWeek"
							:allowed-dates="allowedDates"
							scrollable
							color="primary"
							header-color="secondaryDark"
							:picker-date.sync="pickerDate"
							@input="handleDatePickerDayChange"
							@update:picker-date="handleDatepickerPopupMonthChange"
						>
							<div class="w-100">
								<v-expand-transition>
									<v-progress-linear v-if="loadingColors" indeterminate class="mb-2"></v-progress-linear>
								</v-expand-transition>
								<v-btn text :disabled="loading || loadingColors" block @click="menu = false">
									<span v-text="$t('btn.close')"></span>
								</v-btn>
							</div>
						</v-date-picker>
					</v-dialog>
				</v-col>
				<v-col cols="2" class="d-flex align-center justify-end">
					<v-btn color="secondaryDark" icon :disabled="!canGoNext" @click="handleNextClick" outlined width="27" height="27">
						<v-icon size="16" class="calendar__icon">mdi-arrow-right</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row class="mt-4 dates__container" dense v-if="isDesktopBreakpoint">
				<v-col v-for="(item, index) in dates" :key="index" style="flex: 1">
					<v-btn
						class="pa-0 elevation-0 calendar__btn"
						style="min-height: 4.33rem"
						block
						:outlined="selectedDay !== item.formattedDay"
						:disabled="checkClosedSlot(item)"
						@click="handleDaySelectClick(item)"
						:class="{'calendar__btn': true ,'selected': selectedDay === item.formattedDay, 'disabled': checkClosedSlot(item)}"
					>
						<div class="text-center d-flex flex-column justify-center align-center calendar__btn--inner">
							<div class="mb-2" style="font-size: 0.6rem" v-text="item.date.locale(false).format('ddd')"></div>
							<div class="title font-weight-bold mt-n3 mb-n1" v-text="item.date.locale(false).format('DD')"></div>
							<v-skeleton-loader v-if="skeleton" type="text" height="13" width="100%"></v-skeleton-loader>
							<div v-else-if="checkClosedSlot(item)" style="font-size: 0.6rem" v-text="$t('calendar.closed')"></div>
							<div v-else-if="formattedTodayDate === item.date.format('YYYY-MM-DD')" class="mx-n3" style="font-size: 0.5rem" v-text="$t('calendar.today')"></div>
							<div
								v-else
								:style="{
									height: '0.5rem',
									width: '0.5rem',
									borderRadius: '50%',
									backgroundColor: getEventColorCode(events(item.date.format('YYYY-MM-DD')))
								}">
							</div>
						</div>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<template v-else>
			<v-date-picker
				width="100%"
				:min="formattedTodayDate"
				class="hide-header"
				view-mode="month"
				ref="mainDatePicker"
				:events="colorCodeEventDates"
				:picker-date.sync="pickerDate"
				v-model="date"
				:disabled="loading || loadingColors"
				:allowed-dates="allowedDates"
				:locale="$i18n.locale"
				color="primary"
				header-color="secondaryDark"
				scrollable
				@input="handleDayChange"
				@update:picker-date="handleDatepickerMonthChange"
			>
				<div class="w-100">
					<v-expand-transition>
						<v-progress-linear v-if="loadingColors" indeterminate></v-progress-linear>
					</v-expand-transition>
				</div>
			</v-date-picker>
		</template>
		<slot v-if="!inProduct" name="append-month"></slot>

		<slot v-if="!inProduct"  name="prepend-day"></slot>
		<slot v-if="!inProduct" name="append-day"></slot>
	</v-sheet>
</template>

<script>
import moment from 'moment';
import momentTZ from 'moment-timezone';
import CalendarSlot from '@/components/CalendarSlot';
import {EComService, EventBus, ProductModel } from '@connectngo/sdk';
import globalVariables from "@/global";
import { DebounceMixin } from "@/mixins/ProductMixin";

export default {
	name: 'CalendarCombo',

	mixins: [DebounceMixin],

	components: { CalendarSlot },

	props: {
		value: {
			type: Number,
			default: null,
		},
		eventGroupIds: {
			type: Array,
			default: () => ([]),
		},
		unavailableSlots: {
			type: Array,
			default: () => ([]),
		},
		inProduct: {
			type: Boolean,
			default: false
		},
		hideDaySelection: {
			type: Boolean,
			default: false
		},
		daySelected: {
			type: String,
			default: ''
		},
		hideTimeSlots: {
			type: Boolean,
			default: false
		},
		globalDate: {
			type: String,
			default: ''
		},
		product: {
			type: ProductModel,
			default: () => new ProductModel(),
		},
		customClassname: {
			type: String,
			default: '',
		},
		events: {
			type: Function,
			default: () => {},
		},
		getReschedulableEvents: {
			type: Function,
			required: false,
			default: () => {},
		},
		showDatePicker: {
			type: Boolean,
			default: false,
		},
		combo: {
			type: Object,
			default: () => {}
		}
	},

	data: () => ({
		menu: false,
		month: null,
		selectedDay: null,
		date: null,
		dates: [],
		currentSlots: [],
		slots: [],
		populatedMonth: null,
		combosPerDate: {},
		loading: false,
		skeleton: true,
		loadingColors: false,
		mappedData: [],
		monthlyMappedData: [],
		currentState: null,
		pickerDate: null,
		disabledSlot: [],
		comboSlots: [],
		selectFirstAvailSlot: null,
		selectOption: null,
		hideTitle: false,
		isCalendarInitiated: false,
	}),

	emits: ['update:current-slots', 'daySelect'],

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		},
		_eventGroupIds() {
			return this.combo_products?.map(product => product?.event_group?.id).filter(eventGroupId => eventGroupId !== null && eventGroupId !== 'no_event');
		},
		_unavailableSlots() {
			return this.unavailableSlots.sort((x,y)=> {
				if (x.unavailable_before > y.unavailable_before) {
					return 1;
				}
				if (x.unavailable_before < y.unavailable_before) {
					return -1;
				}
				return 0;
			})[0];
		},
		tenantNow() {
			return this.dateToTenantTimezone().locale('en');
		},
		todayOpenHour() {
			const todayData = this.monthlyMappedData.find(data => data.date === this.formattedTodayDate);
			return todayData?.items?.length > 0 && this.hourDiff(this.tenantNow, todayData.items[todayData.items.length - 1].to);
		},
		formattedTodayDate() {
			return this.tenantNow.clone().startOf('day').format('YYYY-MM-DD');
		},
		formattedMonth() {
			return this.date ? moment(this.date).format('MMMM YYYY') : moment().format('MMMM YYYY');
		},
		firstDayOfWeek() {
			return moment().localeData().firstDayOfWeek();
		},
		canGoBack() {
			return !this.loading && this.dates.length > 0 && this.dates[0].date.format('YYYY-MM-DD') > moment(this.tenantNow.clone().startOf('day')).locale('en').format('YYYY-MM-DD');
		},
		canGoNext() {
			return !this.loading;
		},
		canChangeDate() {
			return !this.loading;
		},
		showAvailability() {
			return parseInt(globalVariables.websiteConfig.data.fields.show_tickets_availabilities) ? true : false;
		},
		slotsForSelectDisplay() {
			return this.currentSlots.map(item => {
				let label = item.from.format('LT') + ' - ' + item.to.format('LT');
				if(this.showAvailability) {
					label += ` (${this.$tc('calendar.places', item.amountLeft, {
						amount: item.amountLeft
					})})`;
				}

				return {
					value : item.id,
					disabled : item.amountLeft <= 0,
					text : label,
				}
			})
		},
		combo_products() {
			if (!this.combo?.data?.combo_products && !this.combo?.related_combos) {
				return [];
			}
			// Get the related products either from related combos or combo products
			if (!this.combo?.data?.combo_products && this.combo?.related_combos) {
				return this.combo?.related_combos
					?.map(related_combo => related_combo?.data?.product?.data)
					?.filter(product => product?.id && product?.event_group?.id);
			}
			return this.combo?.data?.combo_products?.filter(combo_product => combo_product.id && combo_product.event_group?.id);
		},
	},

	created() {
		if (this.daySelected) {
			this.populateEventsColorCodes(this.daySelected);
			this.getEventAndComboAvailabilities(
				this.dateToTenantTimezone(moment(this.daySelected).toDate()).startOf('day').unix(),
				this.dateToTenantTimezone(moment(this.daySelected).toDate()).endOf('day').unix(),
				true
			);
		} else if(this.globalDate) {
			this.populateEventsColorCodes(this.globalDate);
			this.getEventAndComboAvailabilities(
				this.dateToTenantTimezone(moment(this.globalDate).toDate()).startOf('week').unix(),
				this.dateToTenantTimezone(moment(this.globalDate).toDate()).add(1, 'week').endOf('week').unix(),
				true
			);
		}
	},

	methods: {
		handleItemMounted() {
			this.debounce(()=> this.currentSlots.map(item => {
				if(!this.disabledSlot.includes(item.id)) {
					this.handleMountedSlot(item);
				}
			}),500);
		},
		dateToTenantTimezone(date = new Date()) {
			const now = momentTZ(date);
			const serverOffset = now.utcOffset();
			now.tz(this.$root.websiteConfig.data.tenant.timezone);

			const localOffset = now.utcOffset();
			const diffInMinutes = serverOffset - localOffset;
			const tenantDate = moment(date);
			tenantDate.add(-diffInMinutes, 'minutes');

			return tenantDate.clone();
		},
		hourDiff(currentTime, eventEndDate) {
			return eventEndDate.isAfter(currentTime)
		},
		availableSlot(item, slot) {
			return (
				item?.date === this.formattedTodayDate
				&& this.hourDiff(this.tenantNow, slot?.to)
			)
			|| item?.date !== this.formattedTodayDate;
		},
		allowedDates(val) {
    		if (this.todayOpenHour || val > this.formattedTodayDate) {
        		if (this.combosPerDate[val]) {
            		return this.combosPerDate[val] === 'green';
        		}
        		return true;
    		}
    		return false;
		},
		handlePreviousClick() {
			const date = moment(this.date).startOf('week').subtract(1, 'week');

			// Select day in prev week only if global set for calendar display
			this.selectedDay = !this.globalDate ? null : date.format('YYYY-MM-DD');

			this.getEventAndComboAvailabilities(
				Math.max(date.startOf('week').unix(), moment().startOf('day').unix()),
				date.endOf('week').unix(),
				true
			).then(() => this.applyDate(date.format('YYYY-MM-DD')));
			// If this is rescheduling the events
			if (this.getReschedulableEvents && typeof this.getReschedulableEvents === 'function') {
				this.skeleton = true;
				this.getReschedulableEvents?.(date)?.then(() => this.skeleton = false);
			}
		},
		handleNextClick() {
			const date = moment(this.date).startOf('week').add(1, 'week');

			// Select day in next week only if global set for calendar display
			this.selectedDay = !this.globalDate  ? null : date.format('YYYY-MM-DD');

			this.getEventAndComboAvailabilities(
				date.clone().startOf('week').unix(),
				date.clone().add(1, 'week').endOf('week').unix(),
				true,
			).then(() => this.applyDate(date.format('YYYY-MM-DD')));
			// Get reschedulable events if this is rescheduling the events
			if (this.getReschedulableEvents && typeof this.getReschedulableEvents === 'function') {
				this.skeleton = true;
				this.getReschedulableEvents?.(date)?.then(() => {
					this.skeleton = false
				});
			}
		},
		handleDatePickerMonthlyData(date) {
			const Date = moment(date);
			this.getEventAndComboAvailabilities(
				Date.startOf('month').unix(),
				Date.endOf('month').unix(),
				true
			);
		},
		getEventAndComboAvailabilities(from_date, to_date, applySlots = true, showLoading = true) {
  			if (isNaN(from_date) || isNaN(to_date)) {
    			return;
  			}

			if (showLoading) {
  				this.loading = true;
  				this.skeleton = true;
			}

  			const eventsPromise = this.getEventAvailabilities(from_date, to_date, applySlots);
  			const combosPromise = this.getComboAvailabilities(from_date, to_date);

  			return Promise.all([eventsPromise, combosPromise])
    			.then(([eventsResponse, combosResponse]) => {
						if (applySlots) {
							this.handleSlotsChange(eventsResponse, combosResponse);
						}
      					return {
        					events: eventsResponse,
        					combos: combosResponse
      					};
    				})
    			.finally(() => {
					if (showLoading) {
						this.skeleton = false;
						this.loading = false;
					}
				});
		},
		handleDatePickerDayChange(date) {
			this.selectedDay = moment(date).locale('en').format('YYYY-MM-DD');
			this.getEventAndComboAvailabilities(
				moment(this.selectedDay).startOf('week').unix(),
				moment(this.selectedDay).add(1, 'week').endOf('week').unix(),
				true,
				false,
			).then(() => {
				this.selectedDay = moment(date).locale('en').format('YYYY-MM-DD');
				this.date = this.selectedDay;
				this.applyDate(this.selectedDay);
				this.$emit('daySelect', this.selectedDay);
				this.menu = false;
			})
		},
		handleDayChange(date) {
			this.selectedDay = moment(date).locale('en').format('YYYY-MM-DD');
			this.applyDate(this.selectedDay);
			this.$emit('daySelect', this.selectedDay);
			this.menu = false;
		},
		handleDaySelectClick(item) {
			this.selectedDay = item.date.locale('en').format('YYYY-MM-DD');
			this.applyDate(this.selectedDay);
			this.$emit('daySelect', this.selectedDay);
		},
		handleMountedSlot(slot) {
			if (this._unavailableSlots && this._eventGroupIds.includes(parseInt(this._unavailableSlots.eventGroupId))) {
				const disabledSlotTest = slot
					.from
					.isAfter(
						moment.unix(this._unavailableSlots.unavailable_before)
					)
				if (this._unavailableSlots.isUnavailableBefore || disabledSlotTest) {
					this.disabledSlot.push(slot.id)
				}
				if ((this._unavailableSlots.isUnavailableBefore || disabledSlotTest) && (!this.selectFirstAvailSlot || this.selectFirstAvailSlot === slot.id)) {
					this.handleSelectTimeSlotClick(slot)
					this.selectFirstAvailSlot = slot.id
				}
			} else if (!this._unavailableSlots) {
				this.disabledSlot.push(slot.id)
			}
			this.disabledSlot = [...new Set(this.disabledSlot)];
		},
		handleSelectTimeSlotClick(slot) {
			let group = this.hideDaySelection || (!this.hideDaySelection && !this.hideTimeSlots) ?
				this._eventGroupIds[0] : null;
			const eventInfo = {
				id: slot.id,
				eventGroup: group,
				slot: slot
			};

			EventBus.publish('timeSlotPickedForGroup'+group, slot);
			EventBus.publish('event', eventInfo);
			this._value = slot.id;

			let label = slot.from.format('LT') + ' - ' + slot.to.format('LT');
			this.selectOption = {
					value : slot.id,
					disabled : slot.amountLeft <= 0,
					text : label,
				};
		},
		handleSelectTimeSlotSelect(id) {
			const slot = this.currentSlots.find(item => item.id === id);
			this.handleSelectTimeSlotClick(slot);
		},
		init() {
			const slot = this.mappedData.find(slot => slot.items.find(item => item.id === this._value));
			if (slot) {
				this.selectedDay = slot.date;
			} else {
				if(this.globalDate !== "" && !this.selectedDay) {
						this.selectedDay = this.globalDate;
				}

				// Go to next available event only if no global date set
				if(this.globalDate === "") {
					const availableSlots = this.mappedData.filter(data => {
						return data.state !== 'closed' && data.items.length > 0;
					});
					if (availableSlots.length > 0) {
						this.selectedDay = availableSlots[0].date;
					} else {
						this.selectedDay = this.mappedData[0]?.date;
					}
				}
			}

			const isInitialEvent = true;

			if (!this.eventGroupId) {
				this.$emit('daySelect', this.selectedDay, isInitialEvent);
			} else {
				this.$emit('daySelect', this.selectedDay, this.eventGroupId, isInitialEvent);
			}

			if (slot) {
				this.$emit('change', slot);
				this._value = slot.id;
			}
			this.applyDate(this.selectedDay);
		},
		isValidDate(date) {
			const validDateRegex = /^\d{4}-\d{2}-\d{2}$/;
			return validDateRegex.test(date);
		},
		handleDatepickerPopupMonthChange(date) {
			if (this.$refs.mainDatePicker) {
				this.date = date;
				this.populateEventsColorCodes(this.$refs.mainDatePicker.tableDate);
			}
		},
		handleDatepickerMonthChange(date) {
			if (this.$refs.mainDatePicker) {
				if (!this.isValidDate(date)) {
					this.date = null;
					this.$emit('update:current-slots', []);
					this.$emit('daySelect', date);
					this.populateEventsColorCodes(this.$refs.mainDatePicker.tableDate);
				}
			}
		},
		populateEventsColorCodes(date){
			if(!date) {
				date =  this.month;
			}
			date = moment(date);
			if(this.populatedMonth === date.format('YYYY-MM')) {
				return
			}
			this.loadingColors = true;
			this.populatedMonth = date.locale('en').format('YYYY-MM');
			this.combosPerDate = {};
			const applySlots = this.isValidDate(date);
			this.getEventAndComboAvailabilities?.(date.startOf('month').unix(), date.endOf('month').unix(), applySlots, false)
				?.then((response) => {
					this.monthlyMappedData = this.getMappedData(response.events, response.combos);
					response?.combos.forEach((comboAvailability, index) => {
						const day = comboAvailability.data.day;
						if(!this.combosPerDate[day]) {
							this.combosPerDate[day] = comboAvailability.data.color;
						} else {
							this.combosPerDate[day] = comboAvailability.data.color;
						}
					})
				}).
				finally(() => this.loadingColors = false);
		},
		colorCodeEventDates(date) {
			const combosPerDate = this.combosPerDate[date];
			if(this.tenantNow.clone().startOf('day').unix() > moment(date).startOf('day').unix() || !combosPerDate) {
				return false;
			} else if (combosPerDate === 'red' || (!this.todayOpenHour && date === this.formattedTodayDate)) {
				return this.$vuetify.theme.themes.light.error;
			} else if (combosPerDate === 'warning' || combosPerDate === 'orange' || combosPerDate === 'yellow') {
				return this.$vuetify.theme.themes.light.warning;
			} else if (combosPerDate === 'green')  {
				return this.$vuetify.theme.themes.light.success;
			}
		},
		getEventColorCode(color) {
			if (color === 'green') {
				return this.$vuetify.theme.themes.light.success;
			} else if (color === 'orange') {
				return this.$vuetify.theme.themes.light.warning;
			} else if (color === 'red') {
				return this.$vuetify.theme.themes.light.error;
			} else if (color === 'black') {
				return this.$vuetify.theme.themes.light.systemDark1;
			}
			return color;
		},
		applyDate(date) {
			const selectedDate = this.daySelected ? this.daySelected : this.selectedDay;

			this.date = date;
			this.pickerDate = date;
			this.month = moment(date).locale('en').format('YYYY-MM');
			this.dates = this.getDatesFromWeek(date);

			let itemsOnSelectedDate = this.mappedData?.filter(slot => slot.date === selectedDate);
			if (itemsOnSelectedDate?.length === 0) {
				itemsOnSelectedDate = this.monthlyMappedData?.filter(slot => slot.date === selectedDate);
			}

			this.currentSlots = itemsOnSelectedDate?.map(item => ({
				...item,
				slots: item.items.filter(slot => this.availableSlot(item, slot)),
			}))?.map(slot => slot.items)?.flat();

			if (this.currentSlots.length === 1) {
				this._value = this.currentSlots[0].id;
				let group = this.hideDaySelection ? this._eventGroupIds[0] : null
				EventBus.publish('timeSlotPickedForGroup' + group, this.currentSlots[0])
			}

			this.$emit('update:current-slots', this.currentSlots);
			let filteredMappedData = this.mappedData.filter(slot => slot.date === selectedDate);
			if (filteredMappedData.length === 0) {
				filteredMappedData = this.monthlyMappedData.filter(slot => slot.date === selectedDate);
			}

			this.currentState = filteredMappedData.length > 0 ? filteredMappedData[0].state : 'closed';

			if(date) {
				this.populateEventsColorCodes(date);
				this._eventGroupIds.forEach(id => {
					EventBus.publish('eventGroupState'+ id, {
						eventGroupState: this.currentState
					});
				});
			}
		},
		getDatesFromWeek(date) {
			const days = [];
			const weekStart = moment(date).clone().startOf('week');

			for (let i = 0; i <= 6; i++) {
				const day = moment(weekStart).add(i, 'days');
				let mappedSlots = this.mappedData.filter(slot => slot.date === day.format('YYYY-MM-DD'));
				if (mappedSlots.length === 0) {
					mappedSlots = this.monthlyMappedData.filter(slot => slot.date === day.format('YYYY-MM-DD'));
				}
				const slots = day.format('YYYY-MM-DD') >= this.formattedTodayDate && mappedSlots || []

				days.push({
					date: day,
					formattedDay: day.format('YYYY-MM-DD'),
					state: (slots[0] || {}).state,
					slots,
				});
			}
			return days;
		},

		getDatesFromMonth(date) {
			const days = [];
			const monthStart = moment(date).clone().locale('en').startOf('month');
			for (let i = 0; i < monthStart.daysInMonth(); i++) {
				const day = moment(monthStart).locale('en').add(i, 'days');
				const slots =
					day.locale('en').format('YYYY-MM-DD') >= this.formattedTodayDate && this.mappedData.filter(slot => slot.date === day.locale('en').format('YYYY-MM-DD')) || [];

				days.push({
					date: day,
					formattedDay:day.locale('en').format('YYYY-MM-DD'),
					state: (slots[0] || {}).state,
					slots,
				})
			}
			return days;
		},
		getMappedData(eventSlots = [], comboSlots = []) {
  			return comboSlots.map(comboSlot => {
				const eventSlotsForDay = eventSlots.filter(slot => slot.data.day === comboSlot.data.day);

    			return {
      				date: comboSlot.data.day,
      				state: comboSlot.data.closed ? 'closed' : 'open',
      				color: comboSlot.data.color,
					items: eventSlotsForDay.flatMap(eventSlot => {
						return eventSlot.data.events.map(event => ({
    						id: event.data.id,
    						day: event.data.day || comboSlot.data.day,
    						from: this.dateToTenantTimezone(new Date(event.data.start_ts * 1000)),
    						to: this.dateToTenantTimezone(new Date(event.data.end_ts * 1000)),
    						period: event.data.period,
    						amountLeft: event.data.available || 0,
    						totalAmount: event.data.available || 0,
    						price_delta: event.data.price_delta || 0,
    						productId: eventSlot.productId,
    						eventGroupId: eventSlot.eventGroupId,
  						}))
					})
    			};
  			});
		},
		getComboAvailabilities(from_date, to_date) {
  			if (isNaN(from_date) || isNaN(to_date)) {
    			return;
  			}

			const comboId = this.combo?.data?.id || this.combo?.parent_product_id;

			if (!comboId) return;

			return new EComService().getComboAvailabilities(comboId, from_date, to_date)
				.then(response => {
					this.comboSlots = response.flat();
					return this.comboSlots;
				})
		},
		getEventAvailabilities(from_date, to_date, applySlots = false) {
			if (isNaN(from_date) || isNaN(to_date)) {
				return;
			}

			if (!this.combo_products) {
				return;
			}

			const promises = this.combo_products.map(combo_product => new EComService().getEventAvailabilities(
				combo_product?.event_group?.id,
				from_date,
				to_date,
				combo_product.id ?? null,
			).then(response => response.flat().map(resp => ({
				...resp,
				eventGroupId: combo_product?.event_group?.id,
				productId: combo_product?.id
			}))))

			return Promise.all(promises)
				.then(response => {
					if (applySlots) {
						this.slots = response.flat();
					}

					return response.flat();
				})
		},
		getEventNextAvailability() {
			return this.getEventAndComboAvailabilities(
				this.tenantNow.clone().startOf('day').unix(),
				this.tenantNow.clone().add(1, 'week').endOf('week').unix(),
				true
			)
				.catch(() => {
					this.loadEventAvailabilities();
				})
				.finally(() => {this.loading = false;});
		},
		initCalendar() {
			this.getEventAndComboAvailabilities?.(
				this.tenantNow.clone().startOf('day').unix(),
				this.tenantNow.clone().add(1, 'week').endOf('week').unix(),
				true
			)?.then(res => {
				this.loading =true;
				const emptyEvents = res?.events?.every(item => item.data.events.length === 0);
				this.dates = this.getDatesFromWeek(this.tenantNow.clone().startOf('week').toDate());
				if(this._eventGroupIds.length === 1 && (res?.events?.length === 0 || emptyEvents)) {
					// This api keeps returning the error 404 so I will skip this for now.
					// this.searchForNextEventAvailable();
					this.loading = false;
				} else {
					this.loading = false
				}
			})?.catch(() => {
				this.loadEventAvailabilities();
			})
		},
		searchForNextEventAvailable() {
			new EComService().getEventNextAvailability(this._eventGroupIds[0])
				.then(res => {
					if (res.data.start_datetime){
						const nextEventDate = this.dateToTenantTimezone(new Date(res.data.start_datetime * 1000));
						this.selectedDay = nextEventDate.format('YYYY-MM-DD');
						this.handleDatePickerDayChange(this.selectedDay);
					}
				})
				.catch(err => {
					// No events available in future
				}).finally(() => {this.loading = false;});
		},
		loadEventAvailabilities() {
			const from_date = this.dateToTenantTimezone(this.tenantNow.clone().startOf('week').toDate());
			const to_date = this.tenantNow.clone().endOf('week').unix();
			return this.getEventAndComboAvailabilities(from_date, to_date);
		},
		checkClosedSlot(item) {
			return item.slots.length === 0 && item.date.format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
			? !item.state || item.state === 'closed'
			: !item.slots.find(event => event.state === 'open');
		},
		handleSlotsChange(eventSlots, comboSlots) {
			const mappedData = this.getMappedData(eventSlots, comboSlots);

    		if(mappedData?.length > 27) {
      			this.monthlyMappedData = mappedData;
      			return;
    		}

    		this.mappedData = mappedData?.filter(data => {
      			return data.date === this.formattedTodayDate
        			? data.items.length > 0
        			: true
    			});

    		this.init();
		}
	},

	watch: {
		// Init calendar and populate event color codes once after combo is avaiable at page loads.
		combo_products: {
			immediate: true,
			handler(newValue) {
				if (
					(this.combo?.data?.id || this.combo?.id) &&
					Array.isArray(newValue) &&
					!this.isCalendarInitiated &&
					!this.globalDate &&
					!this.daySelected
				) {
					this.isCalendarInitiated = true;
					this.initCalendar();
					this.populateEventsColorCodes();
				}
			}
		},
		loading: {
			immediate: true,
			handler(val) {
				this.$emit('loading', val);
			}
		},
	},
}
</script>

<style scoped lang="scss">
.dates__container {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	> .col {
		min-width: 100%;
		> button {
			width: 100%;
		}
	}
}

.calendar__btn {
	border: 0.6px solid #009AA6;
	color: #009AA6;
	background-color: #fff !important;
	&.selected {
		background-color: #E37222 !important;
		color: #fff;
	}
	&.disabled {
		background-color: #fff !important;
		color: #C0BEBE;
		border: 0.6px solid #C0BEBE;
	}
}

@media screen and (max-width: 600px) {
	.calendar__btn {
		font-size: 12px;
		padding: 4px;
		border-radius: 50%;
		height: auto;
		min-height: auto;
	}
	.dates__container {
		grid-template-columns: repeat(7, 1fr);
	}
	::v-deep .v-date-picker-table {
		height: auto;
		min-height: auto;
		padding: 0;
	}

}

.calendar__label {
	font-size: 18px;
	font-weight: 600;
	text-decoration: underline;
	text-transform: capitalize;
}

::v-deep .v-picker.hide-header .v-picker__title {
	display: none;
}
</style>
