// @ts-nocheck

import { EComService, ComboModel, EventBus } from "@connectngo/sdk";
import globalVariables from "@/global";

const ComboMixin = {
	props: {
		// Define your props here
		combo: {
			type: Object,
			required: true,
			default: () => ({
				data: {
					combo_products: [],
				}
			}),
		},
	},
	data: () => ({
		// Define your data here
		loading: false,
		formErrors: {},
		quantity: 1,
		formErrors: {},
		selectedTimeslots: {},
	}),
	computed: {
		// Define your computed properties here
		products() {
			if (!this.combo?.data?.combo_products) {
				return [];
			}
			return this.combo.data.combo_products;
		},
		quantityBoundText() {
			const { min_quantity, max_quantity } = this.combo.data || {};
			if (!min_quantity && !max_quantity) return '';
    		let text = 'Max Quantity (';
    		if (min_quantity) {
      			text += `Min: ${min_quantity}`;
    		}
    		if (max_quantity) {
      			if (min_quantity) {
        			text += ', ';
      			}
      			text += `Max: ${max_quantity}`;
    		}
    		text += ')';
    		return text;
  		},
		allTimeslotsSelected() {
			const productsWithEventGroupId = this.products.filter(product => product.event_group?.id);
			return this.selectedTimeslots &&
				Object.values(this.selectedTimeslots).filter(item => item && item.id).length === productsWithEventGroupId.length;
		},
	},
	methods: {
		// Define your methods here
		async handleAddToCart() {
			const price = this.combo?.data?.price;

			this.loading = true;
			this.$emit("update:loading", true);

			const comboItems = this.products.map((product) => {
				const selectedTimeslot = this.selectedTimeslots?.[product.id ?? product?.data?.id];

				const eventId = selectedTimeslot?.id ?? null;
				return {
					productId: product.id,
					quantity: this.quantity,
					eventId,
				};
			});

			await new EComService()
				.addProductToCart(
					this.combo.data.id,
					this.quantity,
					this.combo.data?.event_group?.id,
					this.combo.data?.price,
					[],
					comboItems
				)
				.then((response) => {
					this.$snack(this.$i18n.t("product.cartUpdated"));
					this.formErrors = {};
					this.updateCart(response);
				})
				.catch((reason) => {
					this.$handleError(this, reason, this.formErrors);
				})
				.finally(() => {
					this.loading = false;
					this.$emit("update:loading", false);
				});
		},
		updateCart(model) {
			globalVariables.cart = model;
			EventBus.publish("CART_UPDATED", model);
		},
	},
};

export default ComboMixin;
