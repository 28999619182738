// @ts-nocheck
const FileMixin = {
	methods: {
		downloadBase64 (filename, contentType, data) {
			const blob = this.b64toBlob(data, contentType)
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = filename
			// Append to html for Firefox compatibility
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
		b64toBlob (b64Data, contentType = '', sliceSize = 512) {
			const byteCharacters = atob(b64Data)
			const byteArrays = []

			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			return new Blob(byteArrays, {type: contentType})
		},
	},
};

export default FileMixin;
