// @ts-nocheck
const PasswordConditions = (key = 'password') => ({

	computed: {

		passwordHasXCharsNoSpace() {
			return (this.data[key] || '').length >= 8 && !/\s/.test(this.data[key]);
		},

		passwordHasNumber() {
			return /\d/.test(this.data[key] || '');
		},

		passwordHasSpecialChar() {
			return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.data[key] || '');
		},

		passwordIsValid() {
			return this.passwordHasXCharsNoSpace
				&& this.passwordHasNumber
				&& this.passwordHasSpecialChar;
		},

		passwordConditions() {
			return [
				{ valid: this.passwordHasXCharsNoSpace, text: this.$i18n.t('mixins.passwordConditions.xCharsNoSpace', { amount: 8 }), },
				{ valid: this.passwordHasNumber, text: this.$i18n.t('mixins.passwordConditions.atLeast', { amount: 1 }), },
				{ valid: this.passwordHasSpecialChar, text: this.$i18n.t('mixins.passwordConditions.hasSpecialChar'), },
			];
		},
	},
});

export default PasswordConditions;
