<template>
	<v-skeleton-loader v-if="skeleton" type="image" />
	<v-img v-else :aspect-ratio="aspectRatio" :src="value" v-bind="$attrs" v-on="$listeners" cover></v-img>
</template>

<script>
export default {
	name: "Picture",

	props: {

		value: {
			type: String,
			default: null,
		},

		aspectRatio: {
			type: Number,
			default: null,
		},

		skeleton: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
