<template>
	<Page color="neutralLight" no-sidebar>
		<v-container class="py-md-0 pt-md-6 pa-print-0">
			<v-skeleton-loader
				v-if="skeleton"
				type="image"
				class="mt-8"
			></v-skeleton-loader>
			<component
				v-else
				v-bind="step"
				:invoice.sync="invoice"
				:payments.sync="payments"
			></component>
		</v-container>
	</Page>
</template>

<script>
import Page from "@/components/Page";
import Confirmation from "@/routes/App/Invoice/Confirmation";
import { EComService, TransactionModel, PaymentModel } from "@connectngo/sdk";

export default {
	name: "InvoiceV1",
	components: { Page },
	metaInfo() {
		return {
			title: this.$i18n.t("route.invoice"),
			meta: [
				{
					property: "og:title",
					content: this.$i18n.t("route.invoice"),
				},
				{ property: "og:url", content: window.location.href },
			],
		};
	},
	data: () => ({
		loading: false,
		skeleton: true,
		invoice: new TransactionModel(),
		payments: new PaymentModel(),
	}),
	computed: {
		step() {
			return {
				key: "confirmation",
				is: Confirmation,
				class: "mt-8 pa-0 text-center",
			};
		},
	},
	methods: {
		load() {
			this.loading = true;

			return Promise.all([
				new EComService().getTransactionDetailsByEncryptedId(
					this.$route.params.encryptedId
				),
			])
				.then(([invoice]) => {
					this.$router.push({
						params: { uuid: invoice.uuid },
					});
					Object.assign(this, {
						invoice,
						payments: new PaymentModel({
							payments: invoice.data.payments,
						}),
					});
					this.skeleton = false;
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},
	created() {
		this.load();
	},
};
</script>
