<template>
	<div>
		<v-card class="combo-item-content pa-4" :height="isDesktopBreakpoint ? '' : ''" :disabled="!hasPrice" style="box-shadow: none">
			<div class="pa-0 combo-border">
				<v-img
					v-if="combo.parent_product.data.image !== null"
					:src="combo.parent_product.data.image"
					class="w-100 combo-image"
					aspect-ratio="2"
				></v-img>
				<div class="pa-2 mt-1 d-flex flex-column">
					<div class="d-flex">
						<div class="d-flex flex-grow-1">
							<h3 class="text--primary combo-title combo-truncate-text">
								{{$options.filters.translatable(combo.parent_product.data.name,combo.parent_product.data.name_i18n, $i18n.locale)}}
							</h3>
						</div>
						<div v-if="isDesktopBreakpoint" class="d-flex">
							<div v-if="combo.parent_product.data.price !== null">
								<h2 class="font-weight-bold text--secondary">
									{{ $options.filters.currency(combo.parent_product.data.price) }}</h2>
							</div>
							<div v-else>
								<p class="card-text text-danger"><strong>{{ $t('product_price_not_set') }}</strong></p>
							</div>
						</div>
					</div>

					<v-expansion-panels flat class="pa-0">
						<v-expansion-panel class="pa-0">
							<v-expansion-panel-header class="pa-0">
								<div class="d-flex flex-column mt-4">
									<p class="mb-1 combo-description combo-truncate-text"
									   v-html="$options.filters.translatable(combo.parent_product.data.description, combo.parent_product.data.description_i18n, $i18n.locale)"/>
								</div>
							</v-expansion-panel-header>


							<v-expansion-panel-content class="pa-0">
								<div class="d-flex flex-column flex-grow-1 justify-end">

									<Calendar
										v-if="_eventGroupIds.length > 0"
										v-model="selectedEventID"
										:event-group-ids="_eventGroupIds"
										class="mb-4"
										@daySelect="daySelected"
									/>

									<div v-for="item in combo.related_combos" class="d-flex mt-4">

											<div class="d-flex justify-center" style="min-width:110px;">
												<img v-if="item.data.product.data.image !== null" :src="item.data.product.data.image"
													 alt="Product Image" style="max-width: 110px;max-height:90px;">
											</div>
											<div class="ml-4">
												<p class="ma-0 pa-0 font-weight-bold card-text text--secondary">
													{{ getTag(item.data) }}</p>
												<p class="ma-0 pa-0 font-weight-bold card-text text--primary">
													{{$options.filters.translatable(item.data.product.data.name, item.data.product.data.name_i18n, $i18n.locale)}}</p>
												<p class="ma-0 pa-0 mt-4 text-caption text-uppercase font-weight-bold">
													Quantity: {{ item.data.quantity }}</p>
											</div>
									</div>
								</div>
							</v-expansion-panel-content>

							<div v-if="!isDesktopBreakpoint" class="d-flex flex-column flex-grow-1 justify-end mt-4">
								<div v-if="combo.parent_product.data.price !== null">
									<h2 class="font-weight-bold text--secondary">
										{{ $options.filters.currency(combo.parent_product.data.price) }}</h2>
								</div>
							</div>

						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
			<div class="d-flex justify-space-around flex-column flex-md-row align-center mt-4 flex-grow-1 w-100">
				<v-btn color="primary" :loading="loading" :disabled="!canExchange" x-large @click="applyClicked">
					<v-icon left>mdi-cart-plus</v-icon>
					<span v-text="$t('btn.exchange')"></span>
				</v-btn>
				<v-btn text x-large @click="cancelClicked">
					<span v-text="$t('btn.nextTime')"></span>
				</v-btn>
			</div>
		</v-card>


	</div>
</template>
<script>
	import { EComService, EventBus, ComboModel } from "@connectngo/sdk";
	import globalVariables from "@/global";
	import Calendar from "@/components/Calendar";
	import Vue from 'vue';

	export default {
		name: "ComboItem",
		components: { Calendar },
		props: {
			combo: {
				type: ComboModel,
				required: true,
			},
		},
		data() {
			return {
				selectedEventID: null,
				selectedDay: null,
				skeleton: false,
				loading: false,
			};
		},
		methods: {
			/* Google Tag Manager */
			triggerGAAddEvent(quantity) {
				if (this.$gtm) {
					dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
					dataLayer.push({
						event: "add_to_cart",
						ecommerce: {
							items: [
								{
									item_id: this.combo.parent_product.data.id,
									item_name: this.$options.filters.translatable(
										this.combo.parent_product.data.name,
										this.combo.parent_product.data.name_i18n,
										this.$i18n.locale
									),
									currency:
										this.$root.websiteConfig.data.tenant.currency
											.code,
									index: 1,
									price: this.combo.parent_product.data.price,
									quantity: quantity,
								},
							],
						},
					});
				}
			},
			applyClicked() {
				if (!this.hasPrice) {
					return;
				}
				this.loading = true;

				const qty =
					this.getSalesGroupRequirement("min_quantity") ??
					this.getSalesGroupRequirement("increment") ??
					1;

				const data = {
					productId: this.combo.parent_product.data.id,
					quantity: qty,
				};
				this.applyComboRequest(data);
			},
			cancelClicked() {
				this.$emit("closeModal");
				this.loading = false;
			},
			applyComboRequest(data) {
				return new EComService()
					.addComboToCart(data)
					.then((model) => {
						this.formErrors = {};
						this.$snack(this.$i18n.t("product.cartUpdated"));
						this.updateCart(model);
						this.triggerGAAddEvent(data.quantity);
						this.$emit("closeModal");
						this.getAvailableCombos();
					})
					.catch((error) => this.$handleError(this, error))
					.finally(() => (this.loading = false));
			},
			getAvailableCombos() {
				EventBus.publish("COMBO_UPDATED", []);
				return new EComService()
					.getAvailableCombos()
					.then((combos) => {
						EventBus.publish("COMBO_UPDATED", combos.results);
					})
					.catch((reason) => {
						if (reason.response && reason.response.status !== 404) {
							Vue.prototype.$snack(reason.messages.error, "error");
						}
						EventBus.publish("COMBO_UPDATED", []);
					});
			},
			updateCart(model) {
				globalVariables.cart = model;
				EventBus.publish("CART_UPDATED", model);
			},
			getSalesGroupRequirements() {
				if (
					this.combo == null ||
					typeof this.combo.parent_product === "undefined"
				) {
					return null;
				}

				const parentProduct = this.combo.parent_product;

				if (
					typeof parentProduct.salesgroups === "undefined" ||
					parentProduct.salesgroups.length === 0
				) {
					return null;
				}

				return this.combo.parent_product.data.salesgroups[0].pivot;
			},
			getSalesGroupRequirement(property) {
				const salesGroupRequirement = this.getSalesGroupRequirements();

				if (salesGroupRequirement === null) {
					return null;
				}

				if (!salesGroupRequirement.hasOwnProperty(property)) {
					return "";
				}

				return salesGroupRequirement[property] ?? null;
			},
			getSalesGroupRequirementString(property, label, validate) {
				const value = this.getSalesGroupRequirement(property);

				if (!validate(value)) {
					return "";
				}

				return `${label}: ${value}`;
			},
			getTag(relatedCombo) {
				if (
					relatedCombo.product.data.tags &&
					relatedCombo.product.data.tags.length > 0
				) {
					return this.$options.filters.translatable(
						relatedCombo.product.data.tags[0].name,
						relatedCombo.product.data.tags[0].name_i18n,
						this.$i18n.locale
					);
				}
				return "Combo";
			},
			daySelected(value) {
				this.selectedDay = value;
			},
		},
		computed: {
			hasPrice() {
				return (
					this.combo.parent_product.data.price !== null &&
					this.combo.parent_product.data.price.is_variable !== null &&
					this.combo.parent_product.data.price.min_price !== null
				);
			},
			minQty() {
				return this.getSalesGroupRequirementString(
					"min_quantity",
					this.$t("min_quantity"),
					(v) => v > 0
				);
			},
			maxQty() {
				return this.getSalesGroupRequirementString(
					"max_quantity",
					this.$t("max_quantity"),
					(v) => v > 0
				);
			},
			increment() {
				return this.getSalesGroupRequirementString(
					"increment",
					this.$t("increment"),
					(v) => v > 0
				);
			},
			incrementValue() {
				if (this.combo.increment !== null) {
					return this.combo.increment;
				}
				return 1;
			},
			isDesktopBreakpoint() {
				return (
					this.$vuetify.breakpoint.lg ||
					this.$vuetify.breakpoint.xl ||
					this.$vuetify.breakpoint.md
				);
			},
			_eventGroupIds() {
				let arr = this.combo.related_combos
					.filter(
						(relatedCombo) =>
							relatedCombo.data.product.data.event_group_id &&
							relatedCombo.data.product.data.event_group_id !==
								"no_event"
					)
					.map(
						(relatedCombo) =>
							relatedCombo.data.product.data.event_group.id
					)
					.flat();

				return arr.filter((item, index) => arr.indexOf(item) === index);
			},
			canExchange() {
				if (this._eventGroupIds.length !== 0) {
					return !!this.selectedDay;
				}
				return true;
			},
		},
	};
</script>

<style scoped>
	.card {
		background: rgb(248, 249, 250);
	}

	.card:hover {
		opacity: 1 !important;
	}

	table img {
		width: 64px;
		height: 64px;
		object-fit: cover;
		border-radius: 50%;
	}

	table tr td:first-child {
		width: 64px;
	}

	table tr td {
		padding: 0;
		padding-top: 10px;
	}

	table tr {
		padding-top: 10px;
	}
</style>

<style>
	#combo-carousel .VueCarousel-wrapper {
		margin-bottom: 40px;
	}

	#combo-carousel .VueCarousel-navigation {
		z-index: 1;
	}

	#combo-carousel .VueCarousel-navigation--disabled {
		display: none !important;
	}

	#combo-carousel .VueCarousel-navigation-button {
		top: 98% !important;
	}

	#combo-carousel .VueCarousel-pagination {
		position: absolute !important;
		bottom: -1% !important;
	}

	#combo-carousel .VueCarousel-navigation-next {
		right: 40px !important;
	}

	#combo-carousel .VueCarousel-navigation-prev {
		left: 40px !important;
	}

	#combo-carousel .VueCarousel-navigation-button img {
		width: 15px !important;
	}

	#combo-carousel .VueCarousel-dot-container {
		margin-top: 0 !important;
	}

	#combo-carousel .VueCarousel-dot {
		width: 20px !important;
		height: 20px !important;
		margin-top: 0 !important;
	}

	#combo-carousel .promotion-label {
		position: absolute;
		background: #4dd9e6;
		border-radius: 5px;
		padding: 5px;
		top: 1%;
		left: 1%;
	}

	#combo-carousel .v-expansion-panel-content__wrap {
		padding: 0;
	}

	#combo-carousel .VueCarousel-slide {
		padding: 10px !important;
		height: 100%;
	}
	.combo-border {
		  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
		  0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		  border-radius: 5px;
	}

	.v-image.combo-image {
		border-radius : 5px 5px 0 0;
	}
	#combo-carousel .VueCarousel-slide .combo-border {
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
			0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		height: 100%;
		border-radius: 5px;
	}

	#combo-carousel .combo-truncate-text {
		-webkit-box-orient: vertical;
		line-height: 25px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
	}

	#combo-carousel .combo-title {
		height: 25px;
		-webkit-line-clamp: 1;
		line-clamp: 1;
	}

	#combo-carousel .combo-description {
		height: 100px;
		-webkit-line-clamp: 4;
		line-clamp: 4;
	}

	#combo-carousel .v-item--active .combo-description {
		height: auto !important;
		-webkit-line-clamp: unset !important;
		line-clamp: unset !important;
	}
</style>
