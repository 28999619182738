<template>
	<a :href="value" v-text="$t('goBackUp.label')" @click.prevent="$vuetify.goTo(value)"></a>
</template>

<script>
export default {
	name: 'GoBackUp',

	props: {
		value: {
			type: String,
			default: '#app',
		},
	},
}
</script>
