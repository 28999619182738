<template>
	<v-breadcrumbs v-bind="$attrs" v-on="$listeners" :items="breadcrumbs">
		<template #item="{ item }">
			<router-link v-if="item.name !== $route.name" class="title text-truncate" :style="style" v-text="$t('route.' + item.name, $route.meta)" :to="item"></router-link>
			<span v-else class="title text-truncate text-capitalize-fl" :style="style" v-text="$t('route.' + item.name, $route.meta)"></span>
		</template>
		<template #divider>
			<v-icon>mdi-chevron-right</v-icon>
		</template>
	</v-breadcrumbs>
</template>

<script>
import { EventBus } from '@connectngo/sdk';

export default {
	name: 'Breadcrumbs',

	data: () => ({
		breadcrumbs: [],
	}),

	computed: {
		style() {
			return {
				maxWidth: this.$vuetify.breakpoint.smAndDown ? '15rem' : null,
			};
		},
	},

	watch: {
		$route: {
			immediate: true,
			deep: true,
			handler() {
				this.$nextTick(() => this.load());
			},
		},
	},

	methods: {
		load() {
			this.breadcrumbs.splice(0, this.breadcrumbs.length);
			let route = this.$route.matched.find(route => route.name === this.$route.name);
			if (route) {
				this.breadcrumbs.push({ ...route });
				while (route.parent) {
					route = route.parent;
					route.meta = Object.assign({}, this.$route.meta, route.meta);
					this.breadcrumbs.push({ ...route });
				}
			}
			this.breadcrumbs.push(this.$router.options.routes.find(route => route.name === 'home') || {});
			this.breadcrumbs.reverse();
		},
	},

	created() {
		EventBus.subscribe('BREADCRUMB_REFRESH', this.load);
		this.load();
	},
}
</script>
