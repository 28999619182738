<template>
	<Page color="neutralLight" no-sidebar no-top-page>
		<v-container class="text-center">
			<v-skeleton-loader v-if="skeleton" type="image" class="mt-8"></v-skeleton-loader>
			<div v-else>
				<ProductMap :map-data="mapData" :date="dateSelected" @displayDateCalendar="displayCalendar" />
			</div>
		</v-container>

		<MapCalendarModal :visible="showCalendar" @dateChange="calendarDateChange" @mapCalendarModalClosed="mapCalendarModalClosed" />
	</Page>
</template>

<script>
import Page from '@/components/Page'
import ProductMap from "@/components/InteractiveMap/ProductMap";
import MapCalendarModal from "@/components/InteractiveMap/MapCalendarModal";

import moment from "moment";
import {WindowWidthMixin} from "@/mixins/ProductMixin";
export default {
	name: "ProductMapProto",
	components: {MapCalendarModal, Page, ProductMap},
	mixins: [ WindowWidthMixin],
	data: () => ({
		skeleton : true,
		childMapVisible : false,
		showProductModal : false,
		mapData : null,
		dateSelected : null,
		showCalendar : false,
		scale : 1
	}),
	beforeMount() {
		this.mapData = this.$root.websiteConfig.data.interactive_map;
		if(this.mapData) {
			this.showCalendar = true;
		} else {
			this.$router.push({ name: 'home' });
		}
	},
	computed : {
		mobileMapWidth() {
			return this.windowWidth - 24;
		},
		mobileMapHeight() {
			return this.windowHeight - 135;
		},
	},
	watch : {
		windowHeight(val) {
			this.skeleton = true;
			setTimeout(() => this.skeleton = false, 500)
		},
		windowWidth(val) {
			this.skeleton = true;
			setTimeout(() => this.skeleton = false, 500)
		},
	},
	methods : {
		calendarDateChange(date) {
			if(date) {
				this.dateSelected = date;
				this.showCalendar = false
				this.skeleton=false;
			} else {
				this.showCalendar = true;
			}
		},
		displayCalendar() {
			this.showCalendar = true;
		},
		mapCalendarModalClosed() {
			this.showCalendar = false;
			// Reopen modal if date removed
			if(!this.dateSelected || this.dateSelected === "") {
				this.showCalendar = true;
				this.skeleton=true;
			}
		}
	}
}
</script>

<style scoped>

</style>
