<template>
	<v-card :key="combo?.data?.id" flat>
		<template v-if="skeleton">
			<v-card-text>
				<v-skeleton-loader
					type="image"
				></v-skeleton-loader>
				<v-skeleton-loader
					class="mt-8"
					type="paragraph"
				></v-skeleton-loader>
			</v-card-text>
		</template>
		<template v-else>
			<v-card-text class="py-6 card-container" :class="cardTextClass">
				<v-col cols="12" class="pa-0" v-if="showProductCombination">
					<v-img :src="imageSource" cover :rounded="16" />
				</v-col>
				<h2
					class="text-h4 mb-2 title"
					:class="{ 'systemDark1--text': !showProductCombination, 'secondaryDark--text': showProductCombination }"
				>
					{{$options.filters.translatable(combo.data.name, combo.data.name_i18n, $i18n.locale)}}
				</h2>
				<!-- Price and readmore for mobile  -->
				<div v-if="!isDesktopBreakpoint && !hideReadmore" class="d-flex price-tag mr-md-2 mediumGrey--text justify-space-between align-center">
					<span class="price-tag--small mr-md-2 mediumGrey--text">
						{{ $t('combo.starting_from', { price: comboPriceText }) }}
					</span>
					<router-link :to="getComboLink(combo)" class="see-details-link grey--text">
						<span class="readmore" v-text="$t('combo.readmore')"/>
					</router-link>
				</div>
				<!-- Price -->
				<v-row
					class="center justify-space-between d-flex"
					v-if="isDesktopBreakpoint"
				>
					<v-col :cols="6" sm="3" class="justify-start d-flex" v-if="discount && isDesktopBreakpoint">
						<div class="text-h6 discount__text secondaryLight systemDark1--text">{{$t('combo.discountPercent', { discount })}}</div>
					</v-col>
					<v-col :cols="6" :sm="discount ? 9 : 12" class="d-flex align-end justify-end" style="gap: 16px">
						<p class="text-h6 mb-0 msrp" :class="{ 'flex-column d-flex ml-auto': !isDesktopBreakpoint }">
							<span class="mr-md-2 msrp red--text" :style="!isDesktopBreakpoint && { order: 2 }" v-if="discount">{{ $options.filters.currency(msrp) }}</span>
						</p>
						<p class="text-h6 mb-0">
							<span class="text--bold mr-md-2">{{
								combo?.data?.price && $options.filters.currency(
									combo?.data?.price
								) || 0
							}}</span>
						</p>
					</v-col>
				</v-row>
				<div
					v-else
					class="price-container d-flex align-center"
					:class="{ 'justify-space-between': discount, 'justify-end': !discount }"
				>
					<div class="mb-2 discount__text secondaryLight systemDark1--text" v-if="discount">
						{{$t('combo.discountPercent', { discount })}}
					</div>
					<div class="mb-2 msrp red--text" v-if="discount">
						{{ $options.filters.currency(msrp) }}
					</div>
					<div class="mb-2 price secondaryDark--text price-tag">
						{{ combo?.data?.price && $options.filters.currency(combo?.data?.price) || 0 }}
					</div>
				</div>

				<v-row v-if="showProductCombination">
					<v-col class="product-summary-container">
						<span>{{ $t('comboModal.this_includes') }}:</span>
						<span v-for="(product, index) in productsSummary" :key="index">
						{{ product.quantity }} {{ product.name }}
						</span>
					</v-col>
					<v-col class="d-flex align-end justify-end" v-if="isDesktopBreakpoint">
						<router-link :to="getComboLink(combo)" class="see-details-link">
							<span class="primary--text" v-text="$t('combo.readmore')"/>
						</router-link>
					</v-col>
				</v-row>
				<Calendar
					v-if="showCalendar"
					:event-group-ids="eventGroupIds"
					:combo="combo"
					:hide-time-slots="false"
					class="mb-4"
					v-model="selectedEvents[combo?.data?.id]"
					:unavailableSlots="unavailableSlots"
					@daySelect="(day) => this.selectedDay = day"
					@update:current-slots="handleUpdateCurrentSlots"
					@loading="isLoading => calendarLoading = isLoading"
				/>
				<div class="d-flex flex-columns expansion-panel">
					<product-timeslot-picker
						v-for="(product, index) in productTimeslots"
						:key="product?.id"
						:otherSelectedTimeslots="otherSelectedTimeslots[product?.id]"
						:product="{ data: product }"
						:time-slots="timeSlots.filter(timeSlot => timeSlot.productId === product?.id)"
						product-type="tag"
						:skeleton="skeleton"
						:comboId="combo?.data?.id"
						@option-selected="
							(timeSlot) => onOptionSelected(timeSlot, product.id, index)
						"
						:selected-index="index"
						:progress="`${index + 1}/${productTimeslots.length}`"
					/>
				</div>
				<div class="text-end">
					<InputNumeric
						:min="0"
						v-model="quantity"
						:max="10"
						:increment="1"
						:value="0"
						:readonly="false"
						:large="true"
						classname="input-numeric"
					/>
					<small class="text-end w-100" v-if="quantityBoundText">{{ quantityBoundText }}</small>
				</div>
				<v-divider></v-divider>
				<div class="d-flex flex-column grid-list-md">
					<v-row>
						<v-col>
							<span class="sub-total__label systemDark1--text" v-text="$t('combo.subTotal')" />
						</v-col>
						<v-col class="d-flex justify-end">
							<span class="sub-total__value systemDark1--text">{{ $options.filters.currency(subTotal) }}</span>
						</v-col>
					</v-row>
					<v-row v-if="savings">
						<v-col>
							<span class="sub-total__label systemDark1--text" v-text="$t('combo.savings')" />
						</v-col>
						<v-col class="d-flex justify-end">
							<span class="sub-total__value successGreen--text">{{ $options.filters.currency(savings) }}</span>
						</v-col>
					</v-row>
				</div>
				<v-btn
					large
					class="mt-4 card__btn"
					color="primary"
					@click="handleAddToCart"
					:disabled="loading || calendarLoading || !allTimeslotsSelected || quantity === 0"
					:loading="loading"
				>
					<v-icon left>mdi-cart-plus</v-icon>
					<span class="capitalize" v-text="$t('btn.addToCart')" />
				</v-btn>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>
import Calendar from "@/components/CalendarCombo.vue";
import ProductTimeslotPicker from "@/components/ProductTimeslotPicker.vue";
import { EventBus } from "@connectngo/sdk";
import InputNumeric from "@/components/InputNumeric.vue";
import ComboMixin from "@/mixins/ComboMixin";
import globalVariables from "@/global";

export default {
	name: "ComboCard",
	components: {
		Calendar,
		ProductTimeslotPicker,
		InputNumeric,
	},
	mixins: [ComboMixin],
	data() {
		return {
			currentSlots: [],
			selectedDay: null,
			otherSelectedTimeslots: {},
			calendarLoading: false,
		}
	},
	props: {
		combo: {
			type: Object,
			required: true,
			default: () => { data: {} }
		},
		unavailableSlots: {
			type: Array,
			default: () => [],
		},
		selectedEvents: {
			type: Object,
			default: () => ({}),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		showProductCombination: {
			type: Boolean,
			required: false,
			default: true,
		},
		cardTextClass: {
			type: String,
			required: false,
			default: ""
		},
		hideReadmore: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	computed: {
		timeSlots() {
			return this.currentSlots.map(slot => ({
				period: slot.period,
				amountLeft: slot.amountLeft,
				eventGroupId: slot.eventGroupId,
				productId: slot.productId,
				id: slot.id
			}))
		},
		imageSource() {
			return this.combo?.data?.image;
		},
		comboPriceText() {
			return this.combo?.data?.price && this.$options.filters.currency(
				this.combo?.data?.price
			) || 0
		},
		isDesktopBreakpoint() {
			return (
				this.$vuetify.breakpoint.lg ||
				this.$vuetify.breakpoint.xl ||
				this.$vuetify.breakpoint.md
			);
		},
		eventGroupIds() {
			return this.products.map(product => product.event_group?.id);
		},
		productTimeslots() {
			return this.products.filter(product => this.isTimebasedProduct(product));
		},
		showCalendar() {
			return this.combo?.data?.combo_products?.some(
				(product) => product?.event_group?.id
			);
		},
		productsSummary() {
			return this.products.map(product => {
				const comboQuantity = this.combo.data.combo_quantities.find(qty => qty.product_id === product.id);
				const quantity = comboQuantity ? comboQuantity.quantity : 0;
				return {
					name: this.$options.filters.translatable(product.name, product.name_i18n, this.$i18n.locale),
					quantity
				};
			});
		},
		msrp() {
			return this.combo.data?.msrp;
		},
		discount() {
			const msrp = this.combo.data?.msrp;
			const price = this.combo.data?.price;
			let dc = 0;
			if (msrp && price) {
				dc = Math.round(((msrp - price) / msrp) * 100);
			}
			if (dc < 0) dc = 0;
			return dc;
		},
		savings() {
			if (this.msrp && this.discount) {
				const savingsPerUnit = this.msrp - this.combo.data?.price;
				return savingsPerUnit * this.quantity;
			}
			return 0;
		},
		subTotal() {
			let st = this.combo.data?.price * this.quantity;
			if (!st) {
				st = 0;
			}
			return st;
		}
	},
	methods: {
		handleUpdateCurrentSlots(slots) {
			this.currentSlots = slots;
		},
		isTimebasedProduct(product) {
			return product.id && product.event_group?.id;
		},
		updateCart(model) {
			for (let index = 0; index < model?.items?.length; index++) {
				if (
					model?.items?.[index]?.productGroup ===
					this.product?.product_group
				) {
					this.actualQuantity = model?.items?.[index]?.quantity;
					this.quantity = model?.items?.[index]?.quantity;
					break;
				}
			}

			globalVariables.cart = model;
			EventBus.publish("CART_UPDATED", model);
		},
		getComboLink(combo) {
			return `/${this.$i18n.locale}/combo/${combo?.data?.slug}`;
		},
		onOptionSelected(selectedTime, productId, index) {
			if (!selectedTime) {
				this.$delete(this.selectedTimeslots, productId);
			} else {
				this.$set(this.selectedTimeslots, productId, { ...selectedTime, index });
			}
		},
		getOtherSelectedTimeslots(productId) {
			const timeslots = Object.keys(this.selectedTimeslots)
				.filter(id => Number(id) !== Number(productId) && this.selectedTimeslots[id])
				.map(id => this.selectedTimeslots[id]);
			return timeslots;
		},
	},
	created() {
		for (let product of this.products) {
			if (product?.id) {
				this.$set(this.otherSelectedTimeslots, product.id, []);
			}
		}
	},
	watch: {
		selectedDay: {
			deep: true,
			handler(newValue, oldValue) {
				if (newValue !== oldValue) {
					for (let product of this.products) {
						if (product?.id) {
							this.$set(this.selectedTimeslots, product.id, null);
							this.$set(this.otherSelectedTimeslots, product.id, []);
						}
					}
				}
			}
		},
		selectedTimeslots: {
			deep: true,
			handler(newValue) {
				for (let product of this.products) {
					if (product?.id) {
						this.$set(this.otherSelectedTimeslots, product.id, this.getOtherSelectedTimeslots(product.id));
					}
				}
			}
		}
	}
};
</script>

<style scoped lang="scss">
.v-image {
	border-radius: 16px;
}

h1 {
	font-size: 32px;
	font-weight: 400;
}

::v-deep .v-card {
	margin-top: 0px !important;
}

.msrp {
	text-decoration: line-through;
}

.text--bold {
	font-size: 40px;
	font-weight: 700;
}

.card-container {
	display: flex;
	flex-direction: column;
	gap: 32px;
	box-shadow: 0px 4px 20px 0px #00000040;
}

.discount__text {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 9px 14px 9px 14px;
	border-radius: 6.78px;
	width: fit-content;
}

section {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	&.intro {
		margin-top: 2rem;
		h2 {
			font-size: 30px;
			font-weight: 700;
		}
		ul {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			font-size: 25px;
			font-weight: 400;
		}
	}
}

.title {
	font-size: 30px;
	font-weight: 500;
}

.product-summary-container {
	display: flex;
	flex-direction: column;
	span:first-child {
		font-weight: 700;
	}
	ul {
		padding-left: 0px;
	}
}

.see-details-link {
	font-size: 22px;
	font-weight: 600;
}

.v-card__text {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.expansion-panel {
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
}

::v-deep .other-product__price-section {
	background-color: #fff !important;
	.price-container-1 .original-price, .price-container-1 .actual-price {
		font-size: 20px;
		font-style: italic;
		font-weight: 700 !important;
		line-height: 23px;
		letter-spacing: 0px;
		text-align: left;
	}
}

::v-deep .carousel .VueCarousel-inner {
	gap: 24px;
}

.slide {
	max-width: 40%;
}

::v-deep .input-numeric {
	margin-top: 24px;
	fieldset {
		width: 100%;
		border: 1px solid #009AA6 !important;
		border-radius: 8px;
	}
}

.sub-total {
	&__label {
		font-size: 22px;
		font-weight: 600;
		line-height: 28px;
		text-align: center;
	}
	&__value {
		font-size: 25px;
		font-weight: 700;
		line-height: 29.3px;
		text-align: left;
	}
}

.card__btn {
	min-height: 64px;
	padding: 12px 24px 12px 24px;
	border-radius: 8px;
	color: #fff;
	font-size: 25px !important;
	font-weight: 700;
	letter-spacing: 0.1px;
	line-height: 29px;
	text-align: center;
	font-style: normal;
	text-transform: none;
	.v-icon {
		font-size: 30px;
		margin-right: 12px;
	}
}

.capitalize {
	text-transform: capitalize;
}

@media screen and (max-width: 600px) {
	.title {
		font-size: 22px;
		font-weight: 600;
		line-height: 34px;
		text-align: left;
	}

	.discount__text {
		font-size: 14px !important;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
	}

	.price-tag {
		font-size: 17.5px;
		line-height: 22.75px;
		font-weight: 600;
		letter-spacing: 0.1599999964237213px;
		text-align: right;
		&--small {
			font-size: 11.2px;
			font-weight: 400;
			line-height: 16.8px;
			letter-spacing: 0.16px;
			text-align: left;
		}
	}

	.msrp {
		font-size: 14px;
		font-weight: 600;
		line-height: 17px;
		text-align: left;
		text-align: right;
	}

	.readmore {
		font-size: 17.5px;
		font-weight: 600;
		line-height: 22.75px;
		text-align: left;
	}
}

</style>
