import { EComWebsiteModel, AccountModel, CartModel, PaginationModel } from '@connectngo/sdk';
import logo from '@/assets/images/logo.svg';
import logoDark from '@/assets/images/logo_dark.svg';
import ticketOffice from '@/assets/images/ticket-office.svg';
import firstTime from '@/assets/images/first-time.svg';
import directAccess from '@/assets/images/direct-access.svg';
import media from '@/assets/images/media.png';
import almostDone from '@/assets/images/almost-done.svg';
import mediaLocation from '@/assets/images/media-location.png';

const globalVariables: any = {
	vm: null,
	error: {},
	tags: null,
	tenantId: 1,
	salesgroupId: 1,
	accountLinks: [],
	user: new AccountModel(),
	cart: new CartModel(),
	combos: new PaginationModel(),
	socialSharing: new PaginationModel(),
	websiteConfig: new EComWebsiteModel({
		images: {
			logo,
			'logo-dark': logoDark,
			ticketOffice,
			firstTime,
			directAccess,
			media,
			almostDone,
			mediaLocation,
		},
	}),
    snack: {
        visible: false,
        color: 'primary',
        content: '',
        icon: 'mdi-warning',
    },
	modal: {
        visible: false,
		disabled: false,
        title: 'Undefined',
        body: '',
		buttons: [],
		attrs: {},
    },
	interactiveMapModalShown : false,
	interactiveMapProductIds : []
};

export default globalVariables;
