<template>
	<Page class="d-flex align-center fill-height" no-sidebar no-top-page>
		<v-container style="max-width: 60rem">
			<v-card class="d-flex flex-column align-center justify-center text-center w-100 pb-0" style="padding-top: 5rem;">

				<div class="px-12">
					<!-- TITLE -->
					<h1 class="text-uppercase secondaryDark--text" v-text="$t('mode.' + $route.params.mode + '.title')"></h1>

					<!-- DESCRIPTION -->
					<p class="mt-8 mb-12 body-1" v-html="$t('mode.' + $route.params.mode + '.desc')"></p>
				</div>

				<svg class="mt-8" width="100%" height="96" preserveAspectRatio="none" viewBox="0 0 745 96" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M-10 0C-10 0 37.1718 0 53.3446 0C250.116 0 284.484 40.4894 500.124 40.4894C651.746 40.4894 769 16.4146 769 16.4146V136H-10V0Z" :fill="$vuetify.theme.themes.light.secondaryDark"/>
				</svg>
			</v-card>
		</v-container>
	</Page>
</template>

<script>
import Vue from 'vue';
import Page from '@/components/Page';

export default Vue.extend({
	name: 'Maintenance',

	components: { Page },
});
</script>
