<template>
	<v-form :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="guest_account_form" v-model="formIsValid" @submit="validate" lazy-validation>

		<p class="text-center" v-text="$t('guestAccountForm.desc')"></p>

		<v-text-field
			v-model="data.first_name"
			:label="$t('input.first_name')"
			:error-messages="formErrors.first_name"
			:rules="[rules.required]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-text-field
			v-model="data.last_name"
			:label="$t('input.last_name')"
			:error-messages="formErrors.last_name"
			:rules="[rules.required]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-text-field
			v-model="data.email"
			:label="$t('input.email')"
			:error-messages="formErrors.email"
			:rules="[rules.required, rules.email]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-text-field
			v-model="data.phone_number"
			:label="$t('input.phone_number')"
			:error-messages="formErrors.phone_number"
			:rules="[rules.required]"
			outlined
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<CustomField
			v-for="field in customFields.results"
			:value="data.fields[field.key]"
			:key="field.data.id"
			:field="field"
			:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
			:error-messages="formErrors[field.data.name]"
			:required="field.data.required"
			class="pt-0 mt-0"
			outlined
			inset
			@input="formErrors = {}"
		/>

		<v-btn type="submit" color="button" data-test-selector="guest_account_form_sign_int" :disabled="loading" :loading="loading" block>
			<span v-text="$t('btn.continue')"></span>
		</v-btn>
	</v-form>
</template>

<script>
import CustomField from '@/components/CustomField';
import { Rules, EComService, PaginationModel } from '@connectngo/sdk';

export default {
	name: "CompleteProfileForm",

	components: { CustomField },

	methods: {

		validate (event) {

			event.preventDefault();
			this.formErrors = {};

			if (this.$refs.form.validate()) {
				this.loading = true;
				new EComService().createGuestAccount()
					.then(account => {
						// console.log(account)
					})
					.catch(error => this.$handleError(this, error))
					.finally(() => this.loading = false);
			}
		},
	},

	data() {
		return {
			loading: false,
			formIsValid: false,
			formErrors: {},
			customFields: new PaginationModel(),
			data: {
				first_name: '',
				last_name: '',
				email: '',
			},
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
			},
		};
	},
}
</script>
