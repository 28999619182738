<template>
	<v-container
		v-bind="$attrs"
		v-on="$listeners"
		v-window-focus="handleWindowFocus"
	>
		<Modal
			v-model="showPdf"
			:title="$t('productWaiverCard.pdfModal.title')"
			max-width="800"
		>
			<template #body>
				<iframe
					:src="waiverPdf.data.base_64"
					class="waiver_pdf"
				></iframe>
			</template>
		</Modal>

		<v-form
			id="reload"
			ref="form"
			:disabled="loading"
			data-test-selector="cart_reload_form"
			v-model="formReloadValid"
			@submit.prevent="handleContinueClick"
		>
			<h1
				data-testid="reloadTitle"
				:class="{ 'text-h4 text-center': true, 'mb-8': true }"
				v-text="
					$t('cart.reload.mediaTitle', {
						media: $options.filters.translatable(
							$root.websiteConfig.data.fields.media_name,
							$root.websiteConfig.data.fields.media_name_i18n,
							$i18n.locale
						),
					})
				"
			></h1>
			<v-alert
				v-if="haveMedia && hasWaiver"
				class="text-center my-md-12"
				color="info"
				text
				outlined
			>
				<h3 v-text="$t('cart.reload.avoidDelays.title')"></h3>
				<p class="mt-2 mb-0">
					<span v-text="$t('cart.reload.avoidDelays.desc')"></span>
					<br /><strong
						v-text="$t('cart.reload.avoidDelays.emphasis')"
					></strong>
				</p>
			</v-alert>
			<v-row class="justify-center">
				<v-col cols="12" md="6">
					<v-card class="text-center">
						<v-card-title
							v-text="$t('cart.reload.almostDone.title')"
						></v-card-title>
						<v-card-text>
							<p
								v-text="
									$t('cart.reload.almostDone.desc', {
										media: $options.filters.translatable(
											$root.websiteConfig.data.fields
												.media_name,
											$root.websiteConfig.data.fields
												.media_name_i18n,
											$i18n.locale
										),
									})
								"
							></p>
							<v-img
								:src="
									$root.websiteConfig.data.images[
										'almost-done-page'
									]
								"
								max-height="300"
								contain
							></v-img>
						</v-card-text>
					</v-card>
					<v-card v-if="!haveMedia" class="text-center mt-8">
						<v-card-title
							v-text="$t('cart.reload.choseOption.title')"
						></v-card-title>
						<v-card-text>
							<v-img
								:src="$root.websiteConfig.data.images.media"
								max-height="300"
								contain
							></v-img>
							<v-btn
								data-testid="haveWristband"
								class="mt-8"
								color="button"
								block
								@click="handleHaveMediaClick"
							>
								<v-icon left>mdi-arrow-right</v-icon>
								<span
									v-text="
										$t(
											'cart.reload.choseOption.haveMediaButton',
											{
												media: $options.filters.translatable(
													$root.websiteConfig.data
														.fields.media_name,
													$root.websiteConfig.data
														.fields.media_name_i18n,
													$i18n.locale
												),
											}
										)
									"
								></span>
							</v-btn>
							<p
								class="mt-4"
								v-text="
									$t('cart.reload.choseOption.haveDesc', {
										media: $options.filters.translatable(
											$root.websiteConfig.data.fields
												.media_name,
											$root.websiteConfig.data.fields
												.media_name_i18n_pluralize,
											$i18n.locale
										),
									})
								"
							></p>

							<div class="my-8 text-center">
								<v-divider />
								<div class="mt-n3 mb-3">
									<v-sheet
										class="d-inline-block px-3"
										v-text="
											$t('cart.reload.choseOption.or')
										"
									></v-sheet>
								</div>
							</div>

							<v-btn
								data-testid="noWristband"
								color="button"
								block
								:to="{
									name: 'invoice_step',
									params: { step: 'confirmation' },
								}"
							>
								<v-icon left>mdi-arrow-right</v-icon>
								<span
									v-text="
										$t(
											'cart.reload.choseOption.dontHaveMediaButton',
											{
												media: $options.filters.translatable(
													$root.websiteConfig.data
														.fields.media_name,
													$root.websiteConfig.data
														.fields.media_name_i18n,
													$i18n.locale
												),
											}
										)
									"
								></span>
							</v-btn>
							<p
								class="mt-4"
								v-text="
									$t('cart.reload.choseOption.dontHaveDesc', {
										media: $options.filters.translatable(
											$root.websiteConfig.data.fields
												.media_name,
											$root.websiteConfig.data.fields
												.media_name_i18n,
											$i18n.locale
										),
									})
								"
							></p>
						</v-card-text>
					</v-card>
					<template v-else>
						<v-card class="text-center mt-8">
							<v-card-title
								v-text="
									$t('cart.reload.location.title', {
										media: $options.filters.translatable(
											$root.websiteConfig.data.fields
												.media_name,
											$root.websiteConfig.data.fields
												.media_name_i18n,
											$i18n.locale
										),
									})
								"
							></v-card-title>
							<v-card-text>
								<v-img
									:src="
										$root.websiteConfig.data.images[
											'wallet-uid-image'
										]
									"
									max-height="300"
									contain
								></v-img>
							</v-card-text>
						</v-card>
						<SummaryOrderCard
							class="mt-8"
							:cart.sync="invoice"
							:payments.sync="payments"
							:skeleton="skeleton"
						/>
					</template>
				</v-col>
				<v-col v-if="haveMedia" cols="12" md="6">
					<v-card>
						<v-card-title
							v-text="$t('cart.reload.assign.title')"
						></v-card-title>
						<v-card-text>
							<v-alert
								v-if="reloadItems.results.length === 0"
								class="ma-0"
								type="warning"
								text
							>
								<span
									v-text="$t('cart.reload.noProduct')"
								></span>
							</v-alert>

							<div
								:key="index"
								v-for="(
									reloadItem, index
								) in reloadItems.results"
							>
								<v-divider v-if="index > 0" class="my-4" />

								<Product
									:product="reloadItem.data.product"
									:skeleton="skeleton"
									minimal
									flat
								/>
								<ReloadAssigner
									v-model="wallets[reloadItem.data.id]"
									class="mt-4"
									:wallets.sync="wallets"
									:reloadItem="reloadItem"
									:subAccounts="subAccounts"
									:rules="[rules.required]"
									:loading="loading"
									:skeleton="skeleton"
									outlined
								/>

								<div
									:key="waiver.hash"
									v-for="waiver in reloadItem.data.waivers"
									class="mt-2"
								>
									<v-btn
										color="button"
										block
										:href="
											waiver.signed ? null : waiver.url
										"
										target="_blank"
										:loading="loadingWaiver === waiver.hash"
										:disabled="
											loadingWaiver === waiver.hash ||
											loading
										"
										@click="
											waiver.signed
												? handleShowWaiverClick(waiver)
												: null
										"
									>
										<span
											v-text="
												$t(
													'waiver.' +
														(waiver.signed
															? 'see'
															: 'sign')
												)
											"
										></span>
									</v-btn>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<div v-if="haveMedia">
				<v-btn
					class="mt-8"
					color="button"
					block
					large
					:disabled="!canContinue"
					:loading="loading"
					@click="handleContinueClick"
				>
					<span v-text="$t('btn.continue')"></span>
					<v-icon right>mdi-arrow-right</v-icon>
				</v-btn>

				<v-btn
					class="mt-4"
					color="button"
					outlined
					block
					large
					:disabled="loading"
					@click="handleBuyAtTicketBoothClick"
				>
					<span v-text="$t('cart.reload.buyAtTickerBooth')"></span>
				</v-btn>
			</div>
		</v-form>
	</v-container>
</template>

<script>
import SummaryOrderCard from "@/components/SummaryOrderCard";
import Product from "@/components/Product";
import ReloadAssigner from "@/components/ReloadAssigner";
import Modal from "@/components/Modal";
import {
	Rules,
	EComService,
	TransactionModel,
	PaginationModel,
	PaymentModel,
	BaseModel,
} from "@connectngo/sdk";
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
	name: "Reload",

	components: { SummaryOrderCard, Product, ReloadAssigner, Modal },

  mixins : [ReloadV2Mixin],

	props: {
		invoice: {
			type: TransactionModel,
			default: () => new TransactionModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
	},

	data: () => ({
		loading: false,
		formReloadValid: false,
		haveMedia: false,
		skeleton: true,
		reloadItems: new PaginationModel(),
		subAccounts: new PaginationModel(),
		wallets: new PaginationModel(),
		assignments: {},
		rules: {},
		loadingWaiver: false,
		showPdf: false,
		waiverPdf: new BaseModel(),
	}),

	computed: {
		_cart: {
			get() {
				return this.cart;
			},
			set(value) {
				this.$emit("update:cart", value);
			},
		},
		canContinue() {
			return !this.loading && this.formReloadValid;
		},
		hasWaiver() {
			return !this.reloadItems.results.every(
				(item) => item.data.waivers === null
			);
		},
	},

	methods: {
		handleShowWaiverClick(waiver) {
			this.loadingWaiver = waiver.hash;
			return Promise.all([new EComService().getWaiverPdf(waiver.hash)])
				.then(([waiverPdf]) => {
					Object.assign(this, { waiverPdf, showPdf: true });
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loadingWaiver = false));
		},

		handleWindowFocus() {
			this.loading = true;
			return Promise.all([
				new EComService().getReloadItems(this.invoice.data.uuid),
			])
				.then(([reloadItems]) => {
					Object.assign(this, { reloadItems });
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
		handleHaveMediaClick() {
			this.haveMedia = true;
			this.$vuetify.goTo("#reload", {
				offset: 30,
			});
		},
		handleContinueClick() {
			this.formErrors = {};
			if (this.$refs.form.validate()) {
				this.$router.push({
					name: "invoice_step",
					params: { step: "confirmation" },
				});
			}
		},
		handleBuyAtTicketBoothClick() {
			this.$router.push({
				name: "invoice_step",
				params: { step: "confirmation" },
			});
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getConnectedAccounts(),
				new EComService().getReloadItems(this.invoice.data.uuid),
			])
				.then(([subAccounts, reloadItems]) => {
					subAccounts.results.unshift(this.$root.user);
					Object.assign(this, { subAccounts, reloadItems });

					const promises = [];
					subAccounts.results.forEach((account) => {
						promises.push(
							new EComService().getVirtualWallets(account.data.id)
						);
					});
					return Promise.all(promises).then((responses) => {
						responses.forEach((wallets) => {
							wallets.results.forEach((wallet) => {
								this.wallets.results.push(wallet);
							});
						});
						this.skeleton = false;
					});
				})
				.catch((reason) => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

  beforeCreate () {
    if(this.reloadV2Enabled) {
      this.$router.push({
        name: "invoice_step",
        params: {step: "confirmation" },
      });
    }
  },

  created() {
		this.rules = {
			required: (value) =>
				Rules.required(value) || this.$t("rules.required"),
		};

		this.load();
	},
};
</script>
