<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">

		<AddSubAccountModal
			:visible.sync="addSubAccountModal"
			:parent-account="$root.user"
			scrollable
			max-width="500"
			@created="handleSubAccountCreated"
		/>

		<v-card-title class="d-flex align-center" style="justify-content: space-between !important;">
			<span v-text="$t('connectedAccountListingCard.title')"></span>
			<v-btn color="button" @click.stop="handleAddConnectedAccountClick">
				<v-icon left>mdi-account-plus</v-icon>
				<span v-text="$t('btn.add')"></span>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<p v-text="$t('connectedAccountListingCard.desc')"></p>
		</v-card-text>
		<div v-if="value.results.length === 0" class="px-4 pb-1">
			<v-alert text prominent type="info">
				<span v-text="$t('connectedAccountListingCard.empty')"></span>
			</v-alert>
		</div>
		<v-list v-else>
			<template v-for="(item, itemIdx) in value.results">
				<v-divider :key="itemIdx + '_sep'" />
				<v-list-item :key="itemIdx" :to="{ name: 'account_section_sub', params: { section: 'connected', sub: item.data.id } }">
					<v-list-item-icon>
						<v-avatar size="64" color="secondaryLight">
							<v-img :src="item.data.image" size="64" contain />
						</v-avatar>
					</v-list-item-icon>
					<v-list-item-content>
						<h3 class="title mb-1" style="line-height: 1.5rem" v-text="item.data.firstname"></h3>
						<div class="overline font-weight-light" style="line-height: 1rem" v-text="$options.filters.completeUserName(item)"></div>
					</v-list-item-content>
				</v-list-item>
			</template>

			<v-list-item class="justify-end">
				<v-list-item-action-text>
					<router-link class="text-decoration-none d-flex align-center" :to="routeAll">
						<span v-text="$t('connectedAccountListingCard.sellAll')"></span>
						<v-icon right x-small>mdi-arrow-right</v-icon>
					</router-link>
				</v-list-item-action-text>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import { PaginationModel } from '@connectngo/sdk';
import AddSubAccountModal from '@/components/AddSubAccountModal'

export default {
	name: 'ConnectedAccountListingCard',

	components: { AddSubAccountModal },

	props: {
		value: {
			type: PaginationModel,
			default: () => new PaginationModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		routeAll: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'connected' } }),
		},
	},

	data: () => ({
		addSubAccountModal: false,
	}),

	methods: {
		handleAddConnectedAccountClick() {
			this.addSubAccountModal = true;
		},
		handleSubAccountCreated(account) {
			this.$emit('refresh');
		},
	},
}
</script>
