<template>
	<v-form :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="addSubAccountModal" v-model="formIsValid" @submit="validate" lazy-validation>
		<Modal v-model="_visible" :title="$t('addSubAccountModal.title')" v-bind="$attrs" v-on="$listeners">
			<template #body>
				<v-card-text class="pt-4">
					<v-text-field
						v-model="data.first_name"
						:label="$t('input.first_name')"
						:error-messages="formErrors.first_name"
						:rules="[rules.required]"
						outlined
						background-color="white"
						@input="formErrors = {}"
					></v-text-field>

					<v-text-field
						v-model="data.last_name"
						:label="$t('input.last_name')"
						:error-messages="formErrors.last_name"
						:rules="[rules.required]"
						outlined
						background-color="white"
						@input="formErrors = {}"
					></v-text-field>

					<v-card v-if="customFields.results.length > 0" outlined>
						<v-card-title v-text="$t('addSubAccountModal.additionalFields')"></v-card-title>
						<v-card-text>
							<CustomField
								v-for="field in customFields.results"
								:value="data.fields[field.data.name]"
								:key="field.data.id"
								:field="field"
								:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
								:error-messages="formErrors[field.data.name]"
								:required="field.data.required"
								class="pt-0 mt-0"
								outlined
								inset
								@input="handleInput"
							/>
						</v-card-text>
					</v-card>

					<v-checkbox
						v-model="includeLogin"
						:label="$t('addSubAccountModal.includeLogin')"
					/>

					<v-expand-transition>
						<v-card v-if="includeLogin" outlined>
							<v-card-title v-text="$t('addSubAccountModal.account')"></v-card-title>
							<v-card-text>
								<v-text-field
									v-model="data.email"
									:label="$t('input.email')"
									:error-messages="formErrors.email"
									:rules="[rules.required, rules.email]"
									outlined
									background-color="white"
									@input="formErrors = {}"
								></v-text-field>

								<v-text-field
									v-model="data.password"
									:label="$t('input.password')"
									:error-messages="formErrors.password"
									:rules="[rules.required, rules.min]"
									:type="showPassword ? 'text' : 'password'"
									:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									autocomplete="password"
									outlined
									background-color="white"
									@click:append="showPassword = !showPassword"
									@input="formErrors = {}"
								></v-text-field>

								<v-text-field
									v-model="data.confirmation"
									:label="$t('input.confirmation')"
									:error-messages="formErrors.password"
									:rules="[rules.required, rules.min, rules.password]"
									:type="showPassword ? 'text' : 'password'"
									:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									autocomplete="password"
									outlined
									background-color="white"
									@click:append="showPassword = !showPassword"
									@input="formErrors = {}"
								></v-text-field>

								<StrengthIndicator class="my-4" :title="$t('createAccountForm.passwordMustContain')" :conditions="passwordConditions"></StrengthIndicator>
							</v-card-text>
						</v-card>
					</v-expand-transition>

				</v-card-text>
			</template>
			<template #actions>
				<v-btn type="submit" color="button" data-test-selector="addSubAccountModal_add" :disabled="loading" :loading="loading" @click="validate">
					<span v-text="$t('btn.create')"></span>
				</v-btn>
				<v-btn color="button" data-test-selector="addSubAccountModal_cancel" :disabled="loading" :loading="loading" outlined @click="_visible = false">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</template>
		</Modal>
	</v-form>
</template>

<script>
import Modal from './Modal';
import StrengthIndicator from './StrengthIndicator'
import CustomField from './CustomField'
import PasswordConditions from '../mixins/PasswordConditions'
import { Rules, EComService, AccountModel, PaginationModel } from '@connectngo/sdk'

export default {
	name: 'AddSubAccountModal',

	components: { Modal, StrengthIndicator, CustomField },

	mixins: [ PasswordConditions() ],

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		parentAccount: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
	},

	data() {
		return {
			loading: false,
			formIsValid: false,
			formErrors: {},
			data: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				confirmation: '',
				fields: {},
			},
			customFields: new PaginationModel(),
			includeLogin: false,
			showPassword: false,
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
				password: () => Rules.identical(this.data.password, this.data.confirmation) || this.$t('rules.identical'),
			},
		};
	},

	computed: {
		_visible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit('update:visible', value);
			},
		},
	},

	methods: {
		handleInput(value, name) {
			this.formErrors = {};
			this.data.fields[name] = value
		},
		load() {
			this.loading = true;
			new EComService().getCustomFields()
				.then(customFields => {
					Object.assign(this, { customFields });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
		validate (event) {
			event.preventDefault();
			this.formErrors = {};

			if (this.$refs.form.validate()) {
				this.loading = true;
				new EComService().createSubAccount(
					this.data.email,
					this.data.password,
					this.$i18n.locale,
					this.data.first_name,
					this.data.last_name,
					this.parentAccount.data.id,
					this.data.fields
				)
					.then(account => {
						this._visible = false;
						this.$snack(this.$i18n.t('addSubAccountModal.created'));
						this.$emit('created', account);
					})
					.catch(error => this.$handleError(this, error))
					.finally(() => this.loading = false);
			}
		},
	},

	created() {
		this.load();
	},
}
</script>
