export default class RestError {

	private response: any;
	private data: any;
	private stack: string|undefined;

	constructor(response: any, data?: any) {
		this.response = response;
		this.data = data;
		this.stack = new Error().stack;
	}
}
