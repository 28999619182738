export default class ServerError {

	private title: string;
	private desc: string;
	private trace: string|null;
	private options: any;

	constructor(vm: any, error: any, options?: any) {

		this.title = error.title || error.exception_name || error.exception || vm.$i18n.t('error.defaultTitle');

		if (error.message instanceof Array) {
			this.desc = '<pre>' + JSON.stringify(error.message, null, 2).trim() + '</pre>';
		} else if (error.messages) {
			this.desc = (error.messages && error.messages.error || '').toLowerCase();
		} else {
			this.desc = (error.message || '').toLowerCase();
		}

		if (error.trace) {
			this.trace = '<pre>' + JSON.stringify(error.trace, null, 2).trim() + '</pre>';
		} else {
			this.trace = null;
		}

		this.options = options;
	}
}
