<template>
	<Modal v-model="showModal" class="mx-6" hide-header max-width="400" scrollable :persistent="!date || date === ''" no-close>
		<template #body>
			<h2 class="text-center py-6">{{ $t('map.dateSelection.title') }}</h2>
			<v-date-picker
				no-title
				class="mx-6"
				v-model="date"
				:min="minDate"
				:locale="$i18n.locale"
				scrollable
				color="primary"
				header-color="secondaryDark"
			/>
		</template>
	</Modal>

</template>

<script>
import Modal from "@/components/Modal";
import momentTZ from "moment-timezone";
import moment from "moment";
import Calendar from "@/components/Calendar";
export default {
	name: "MapCalendarModal",
	components: {Calendar, Modal},
	props : {
		visible : {
			type : Boolean,
			required : true
		}
	},
	data() {
		return {
			showModal : this.visible,
			pickerDate: null,
			date : null,
			minDate : moment().format('YYYY-MM-DD'),
		}
	},
	watch : {
		visible(val) {
			this.showModal= val;
		},
		showModal(val){
			if(!val) {
				this.$emit("mapCalendarModalClosed");
			}
		},
		date(val) {
			this.$emit("dateChange", val);
		}
	},
}
</script>

<style scoped>

</style>
