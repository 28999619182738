import { render, staticRenderFns } from "./PdfModal.vue?vue&type=template&id=c8c98128&scoped=true&"
import script from "./PdfModal.vue?vue&type=script&lang=js&"
export * from "./PdfModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c98128",
  null
  
)

export default component.exports