<template>
	<v-menu transition="slide-y-transition" bottom>
		<template v-slot:activator="{ on: menu }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn data-testid="languageSwitcher" v-bind="$attrs" :class="btnClass" v-on="{ ...tooltip, ...menu, ...$listeners }" :disabled="languages.length === 0">
						<span>
							<span v-if="!isLoading" class="text-uppercase">{{ getSelectedLang() }}</span>
							<span v-else>
								<v-icon class="mdi-spin">mdi-loading</v-icon>
							</span>
						</span>
						<v-icon right>mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<span v-text="$t('languageSwitcher.tooltip')"></span>
			</v-tooltip>
		</template>
		<v-list>
			<v-list-item :data-test-selector="'language_switcher_' + lang.locale" v-for="lang in languages"
				:key="lang.locale"
				@click="switchLang(lang.locale)">
				<v-list-item-title :class="{ 'font-weight-bold': lang.locale === $i18n.locale }">
					{{ lang.originTitle }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'LanguageSwitcher',

	props: {
		value: {
			type: String,
			default: 'en',
		},
		btnClass: {
			type: String,
			default: null,
		},
	},

	computed: {
		languages() {
			return this.$root.websiteConfig.data.tenant.locales.map(locale => ({
				originTitle: this.$options.filters.translatable(locale.name, locale.name_i18n, this.$i18n.locale),
				locale: locale.locale,
			}));
		},
	},

	methods: {
		getSelectedLang() {
			return this.languages.find(lang => lang.locale === this.$i18n.locale)?.locale.substring(0, 2);
		},

		switchLang(lang) {
			this.$router.push({...this.$route, params: { ...this.$route.params, lang }});
		},
	},

	data() {
		return {
			isLoading: false
		};
	}
});
</script>
