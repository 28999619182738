<template>
	<v-container style="max-width: 35rem">
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
		<v-form v-else ref="form" v-model="formIsValid" :disabled="loading" data-test-selector="create_account_form"
				lazy-validation @submit="submit">
			<div class="mb-8 text-center">
				<h1 class="text-h4 mb-8" v-text="$t('account.fields.salesgroup.required')"></h1>
			</div>
			<v-card>
				<v-card-text>
					<CustomField
						v-for="(field, index) in requiredFields"
						:key="field.data.id"
						v-model="fieldValues[field.data.name]"
						:error-messages="formErrors[field.data.name]"
						:field="field"
						:label="$options.filters.translatable(field.data.name, field.data.name_i18n, $i18n.locale)"
						:required="true"
						:rules="rules"
						class="pt-0 mt-0"
						inset
						outlined
						@input="handleInput()"
					 />
					<v-btn :disabled="loading" :loading="loading" block color="button"
						data-test-selector="create_account_form_sign_int" type="submit">
						<span v-text="$t('btn.validate')"></span>
					</v-btn>
				</v-card-text>
			</v-card>

		</v-form>

	</v-container>

</template>

<script>
import CustomField from "./CustomField";
import {BaseModel, EComService, EventBus, Rules} from "@connectngo/sdk";
import globalVariables from "@/global";

export default {
	name: "CompleteAccountForm",
	components: {CustomField},

	data() {
		return {
			loading: true,
			skeleton: true,
			formIsValid: false,
			accountId: this.$root.user.data.id,
			formErrors: {},
			requiredFields: [],
			fieldValues: {},
		};
	},

	computed: {
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
			};
		},
	},
	beforeMount() {
		this.validateCart();
		this.skeleton = false;
		this.loading = false;
	},
	methods: {
		handleInput() {
			this.formErrors = {};
		},
		submit(event) {
			event.preventDefault();
			if (this.$refs.form.validate()) {
				this.skeleton = true;

				new EComService().patchAccount(this.accountId, {fields: this.fieldValues}).then(data => {
					this.patchCartId();
				}).catch(reason => {
					this.$handleError(this, reason)
					this.skeleton = false;
				})
			}
		},

		patchCartId() {
			this.loading = true;
			new EComService().patchCart({'account_id': this.accountId}).then(data => {
					EventBus.publish('CART_UPDATED', data);
					globalVariables.cart.data = data;
					this.$root.cart = data;
					this.$emit('next');
				}).catch(e => {
					this.$handleError(this, e);
				})
				.finally(() => {
					this.skeleton = false;
					this.loading = false;
				});
		},
		validateCart() {
			new EComService()
				.validateCart()
				.catch((e) => {
					if (new EComService().isMissingRequiredFields(e)) {
						this.requiredFields = e.fields.map(field => {
							field.required = true;
							return new BaseModel(field);
						});
					} else {
						this.$handleError(this, e);
					}
				});
		},
	},
}
</script>

<style scoped>

</style>
