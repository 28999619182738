<template>
	<div style="position:relative;">
		<v-container class="text-center" v-if="date" id="mapDateSelector">
				<v-row  justify="center" class="d-flex">
					<v-card class="pa-2 mt-1">
						<v-row  justify="center" class="d-flex">
							<v-col cols="4">
								<v-btn width="100%" :disabled="parentMaps.length === 0" color="button" text style="flex: 1" @click="backToPreviousMap">
									<v-icon x-large left>mdi-chevron-left</v-icon>
									<span v-text="$t('btn.back')"></span>
								</v-btn>
							</v-col>
							<v-col style="align-self: center" cols="4"><h3 id="dateMapDisplay" class="text-center" v-html="dateDisplay"></h3></v-col>
							<v-col cols="4">
								<v-btn width="100%" color="button" outlined style="flex: 1" @click="$emit('displayDateCalendar')">
									<span v-text="$t('btn.modify')"></span>
								</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-row>
		</v-container>

		<div v-if="windowWidth > 959" id="mapScrollable">
			<div id="mapContainer">
				<v-layout v-if="mapItemsLoading" align-center class="mapItemLoader" justify-center>
					<v-progress-circular
						indeterminate
						:size="100"
						color="secondaryDark">
					</v-progress-circular>
				</v-layout>

				<img id="mapImg" :src="currentMap.mappable.image_url" alt=""/>

				<MapItem v-if="!mapItemsLoading" v-for="item in currentMap.children" :item="item"
						 :key="item.id"
						 :date="date"
						 :productEvents="productsEvents"
						 @productItemClicked="productItemClicked"
						 @mapItemClicked="mapItemClicked"/>

			</div>
		</div>
		<div v-else>
			<div v-if="!imgLoaded">
				<img id="mapImgFormat"
					 :src="currentMap.mappable.image_url"
					 alt=""
					 class="mapImgMobile"
					 :style="mobileMapSizeStyle"/>
			</div>
			<PinchScrollZoom
				v-if="imgLoaded"
				ref="zoomer"
				:content-height="mapHeight"
				:content-width="mapWidth"
				:height="mobileMapHeight"
				:min-scale="1"
				:scale="scale"
				:width="mobileMapWidth"
				:wheelVelocity="0"
				@scaling="scaleUpdate"
			>
				<div>
					<div class="mapContainerMobile">
						<v-layout v-if="mapItemsLoading" align-center class="mapItemLoader" justify-center>
							<v-progress-circular
								:size="100"
								color="secondaryDark"
								indeterminate>
							</v-progress-circular>
						</v-layout>

						<img :src="currentMap.mappable.image_url" alt=""
							 class="mapImgMobile"
							 :style="mobileMapSizeStyle"/>

						<MapItem v-for="item in currentMap.children" v-if="!mapItemsLoading" :key="item.id"
								 :date="date"
								 :item="item"
								 :label-scale="labelScale"
								 :productEvents="productsEvents"
								 @mapItemClicked="mapItemClicked"
								 @productItemClicked="productItemClicked"/>

					</div>
				</div>
			</PinchScrollZoom>


		</div>

		<MapProductModal
			v-if="showProductModal"
			:product="selectedProductModel"
			:date="date"
			:day-selected="date"
			:visible="showProductModal"
			@mapProductModalClosed="mapProductModalClosed"/>


	</div>
</template>

<script>
import Modal from "@/components/Modal";
import ProductCard from "@/components/ProductCard";
import {EComService, EventBus, ProductModel} from "@connectngo/sdk";
import Product from "@/components/Product";
import MapProductModal from "@/components/InteractiveMap/MapProductModal";
import MapItem from "@/components/InteractiveMap/MapItem";
import moment from "moment";
import {WindowWidthMixin} from "@/mixins/ProductMixin";
import InteractiveMapCrossSellModal from "@/components/InteractiveMap/InteractiveMapCrossSellModal";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";

export default {
	name: "ProductMap",
	components: {InteractiveMapCrossSellModal, MapItem, MapProductModal, Product, ProductCard, Modal, PinchScrollZoom},
	mixins: [WindowWidthMixin],
	props: {
		mapData: {
			type: Object,
			require: true
		},
		date: {
			type: [String, null],
			default: null
		}
	},

	data() {
		return {
			showProductModal : false,
			selectedProduct: null,
			currentMap: null,
			parentMaps: [],
			mapDimensions: null,
			imgLoad: false,
			mapType: "EverestShared\\Models\\InteractiveMap",
			productType: "EverestShared\\Models\\Product",
			productsEvents: {},
			mapItemsLoading: false,
			eventSubscriber: null,
			scale: 1,
			mapWidth: 0,
			mapHeight: 0,
			imgLoaded: false,
			labelScale: 1,
		}
	},
	beforeMount() {
		this.currentMap = this.mapData;
	},
	mounted() {
		this.eventSubscriber = EventBus.subscribe("CART_UPDATED", (cart) =>
			this.refreshMapItemAvailabilities()
		);

		const mapImg = document.getElementById("mapImgFormat");
		const _ref = this;
		mapImg.onload = function () {
			_ref.mapWidth = this.width;
			_ref.mapHeight = this.height;
			_ref.imgLoaded = true;
			console.log("IMAGE SIZE", this.width, this.height)
		}
	},
	beforeDestroy() {
		this.eventSubscriber.unsubscribe();
	},
	computed : {
		selectedProductModel() {
			return this.selectedProduct ? new ProductModel(this.selectedProduct.mappable) : new ProductModel()
		},
		dateDisplay() {
			let month = moment(this.date).format("MMMM");
			month =  month.charAt(0).toUpperCase() + month.slice(1);

			if(this.$i18n.locale === "en") {
				return `${month} <span class="secondaryDark--text">${moment(this.date).format("Do")}</span>`;
			} else {
				return `<span class="secondaryDark--text">${moment(this.date).format("Do")}</span> ${month}`;
			}
		},
		mobileMapWidth() {
		  return this.windowWidth - 24;
		},
		mobileMapHeight() {
		  return this.windowHeight - 135;
		},
		mobileMapSizeStyle() {
			return this.windowHeight > this.windowWidth
				? 'height: calc(100vh - 135px); width: auto;'
				: 'height:auto; width:100%;';
		}
	},

	watch : {
		currentMap(val) {
			this.refreshMapItemAvailabilities();
		},
		date() {
			this.refreshMapItemAvailabilities();
		}
	},
	methods : {
		refreshMapItemAvailabilities() {
			this.mapItemsLoading =true;
			this.productsEvents = {};
			let itemDone = 0;

			const eventGroupIds = Array.from(new Set(
				this.currentMap.children
					.filter(item => item.mappable_type === this.productType && item.mappable.event_group )
					.map(item => item.mappable.event_group.id)
			));
			if(eventGroupIds.length > 0) {
				eventGroupIds.forEach((eventGroupId, index) => {
					const dateFrom = moment(this.date).startOf('day').unix();
					const dateTo = moment(this.date).endOf('day').unix();
					new EComService().getEventAvailabilities(eventGroupId, dateFrom, dateTo)
						.then(res => {
							const dayEvent = res.filter(slot => slot.data.day === this.date);
							this.productsEvents[eventGroupId] = dayEvent[0];
						}).catch(err => this.productsEvents[eventGroupId] = null)
						.finally(() => {
							// Check items availability complete
							itemDone++;
							if(eventGroupIds.length === itemDone) {
								this.mapItemsLoading = false;
							}
						});
				});
			} else {
				this.mapItemsLoading = false;
			}

		},
		mapProductModalClosed() {
			this.showProductModal = false;
			this.selectedProduct = null;
		},
		itemClicked(item) {
			return item.mappable_type === this.productType ? this.productItemClicked(item) : this.mapItemClicked(item);
		},
		mapItemClicked(item) {
			this.mapItemsLoading = true;
			this.parentMaps.push(this.currentMap);
			this.currentMap = item;
		},
		productItemClicked(item) {
			this.showProductModal = true;
			this.selectedProduct = item;
		},
		backToPreviousMap() {
			this.currentMap = this.parentMaps.pop();
		},
		scaleUpdate(e) {
			this.labelScale = 1 / e.scale;
		}
	},
}
</script>

<style scoped>
	#mapDateSelector {
		position: absolute;
		z-index: 1
	}

	.mapItemLoader {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
	}

	#mapScrollable {
		position: relative;
	}

	#mapContainer {
		position: relative;
	}

	@media all and (min-width: 960px) {
		#mapImg {
			height : auto;
			width : 100%;
		}
	}

	@media all and (max-width: 959px) {
		.mapImgMobile {
			max-width: fit-content;
		}

		.mapContainerMobile {
			position: relative;
			width: fit-content;
		}

	}


</style>
