<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="6">
				<EditContactInfoCard :value="user" :skeleton="skeleton" />
			</v-col>
			<v-col cols="12" lg="6">
				<EditProfileCard :value="user" :skeleton="skeleton" />
				<EditBillingInfoCard :value="user" :skeleton="skeleton" class="mt-8" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import EditContactInfoCard from '@/components/EditContactInfoCard';
import EditBillingInfoCard from '@/components/EditBillingInfoCard';
import { PaginationModel, EComService, AccountModel } from '@connectngo/sdk';
import EditProfileCard from '@/components/EditProfileCard';

export default {
	name: 'Edit',

	components: { EditContactInfoCard, EditProfileCard, EditBillingInfoCard },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_connected_edit') + ' - ' + this.user.data.firstname + ' ' + this.user.data.lastname,
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_connected_edit') + ' - ' + this.user.data.firstname + ' ' + this.user.data.lastname },
				{ property: 'og:url', content: window.location.href },
				{ property: 'og:image', content: this.user.data.image },
				{ property: 'twitter:image:src', content: this.user.data.image },
				{ property: 'og:site_name', content: this.$options.filters.translatable(this.$root.websiteConfig.data.name, this.$root.websiteConfig.data.name_i18n, this.$i18n.locale) }
			],
		};
	},

	data: () => ({
		skeleton: true,
		loading: false,
		user: new AccountModel({ firstname: '', lastname: '' }),
		networks: new PaginationModel(),
	}),

	methods: {
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getAccount(this.$route.params.sub),
			])
				.then(([user]) => {
					this.$route.meta.sub = this.$options.filters.shortUserName(user);
					this.$breadcrumbs.refresh();
					Object.assign(this, { user });
					this.networks = new PaginationModel([
						{ text: 'Facebook', icon: 'mdi-facebook', color: 'facebook', active: true },
						{ text: 'Twitter', icon: 'mdi-twitter', color: 'twitter', active: false },
						{ text: 'Linkedin', icon: 'mdi-linkedin', color: 'linkedin', active: false },
					]);
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.$route.meta.sub = this.$i18n.t('state.loading');
		this.load();
	},
}
</script>
