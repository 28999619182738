<template>
	<v-app>
		<div class="background white--text text-center w-100 fill-height d-flex align-center justify-center flex-nowrap">
			<div style="width: 10rem">
				<v-progress-linear indeterminate color="white"></v-progress-linear>
			</div>
		</div>
	</v-app>
</template>

<script>
export default {
	name: "Loading",
}
</script>
