export default {
	inject: (options) => {

		const mergedOptions = Object.assign({}, {
			notice_banner_type: "bottom-dialog",
			consent_type: "gdpr",
			palette: "dark",
			preferencesId : "#" ,
		}, options);

		const module = document.createElement('script');
		module.setAttribute('type', 'module');
		module.setAttribute('src', 'https://cookieconsent.popupsmart.com/js/CookieConsent.js');

		const app = document.createElement('script');
		app.setAttribute('type', 'text/javascript');
		app.setAttribute('src', 'https://cookieconsent.popupsmart.com/js/App.js');

		document.getElementsByTagName('head')[0].appendChild(module);
		document.getElementsByTagName('head')[0].appendChild(app);

		app.onload = () => {

			// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
			// @ts-ignore
			window.popupsmartCookieConsentPopup(mergedOptions);
			setTimeout(() => {

				const cookieConsent = window.localStorage.getItem("gdpr_cookie_consent");
				const parsed = JSON.parse(cookieConsent || '{}');
				const now = Date.now();

				if (parsed && parsed["expiry"] > now && parsed["strictly-necessary"]) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					window.popupsmart_current_consents = {
						"strictly-necessary": parsed["strictly-necessary"],
						"functionality": parsed["functionality"],
						"tracking": parsed["tracking"],
						"targeting-advertising": parsed["targeting-advertising"],
					};
					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					window.popupsmart_change_script_types();
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					window.popupsmart_change_script_types("firstRender");
					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					window.popupsmart_current_consents = {
						"strictly-necessary": true,
						"functionality": true,
						"tracking": true,
						"targeting-advertising": false,
					};

					// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
					// @ts-ignore
					if (typeof window.popupsmart_cookie_consent_start === 'function') {
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						window.popupsmart_cookie_consent_start(mergedOptions);
					} else {
						console.error('popupsmart_cookie_consent_start is not a function');
					}

				}
			}, 1000);
		}
	}
}
