<template>
	<v-skeleton-loader v-if="skeleton" type="list-item,list-item,list-item" />
	<v-form v-else-if="step === 'start'" :disabled="loading" data-test-selector="gift_card_form_start" v-model="formStartValid" @submit.prevent="handleContinueClick">
		<v-card>
			<v-card-title v-text="$t('cart.cashless.enterDetails')"></v-card-title>
			<v-card-text>
				<v-text-field
					name="number"
					v-model="data.cashless.number"
					:rules="[rules.required]"
					:label="$t('cashless.inputNumber')"
					:error-messages="formErrors.number"
					@input="formErrors = {}"
					:disabled="loading"
					outlined
				>
				</v-text-field>

				<v-text-field
					name="serial"
					v-model="data.cashless.serial"
					:rules="[rules.required]"
					:label="$t('cashless.inputSerial')"
					:error-messages="formErrors.serial"
					@input="formErrors = {}"
					:disabled="loading"
					outlined
				>
				</v-text-field>

				<v-checkbox v-model="data.readTerms" :disabled="loading" name="read_terms" class="mt-0" :rules="[rules.required]">
					<template v-slot:label>
				<span>
					<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
					&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
				</span>
					</template>
				</v-checkbox>

				<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canContinue" :loading="loading" block x-large>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</v-form>

	<v-form v-else-if="step === 'summary'" :disabled="loading" data-test-selector="gift_card_form_summary" v-model="formSummaryValid" @submit.prevent="handleContinueClick" lazy-validation>
		<v-card>
			<v-card-title v-text="$t('cart.cashless.summary')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="mb-4" v-text="$options.filters.currency(payments.data.balance)"></h3>
			</v-card-text>
		</v-card>
		<v-card class="mt-8">
			<v-card-title v-text="$t('cart.cashless.balance')"></v-card-title>
			<v-card-text>
				<v-text-field
					name="number"
					type="number"
					:prefix="$options.filters.currencySign(123)"
					v-model="details.data.balance"
					:rules="[rules.required]"
					:label="$t('giftCard.balance')"
					:error-messages="formErrors.number"
					@input="formErrors = {}"
					disabled
					outlined
				></v-text-field>

				<v-text-field
					name="serial"
					type="number"
					:prefix="$options.filters.currencySign(123)"
					v-model="data.cashless.balance"
					:rules="[rules.required, rules.balance, rules.summary, rules.notNegative, rules.notZero]"
					:label="$t('giftCard.useBalance')"
					:error-messages="formErrors.serial"
					@input="formErrors = {}"
					:disabled="loading"
					outlined
				></v-text-field>

				<v-checkbox v-model="data.readTerms" :disabled="loading" name="read_terms" class="mt-0" :rules="[rules.required]">
					<template v-slot:label>
						<span>
							<span v-text="$t('paymentForm.creditBalanceDialog.readTerms')"></span>
							&nbsp;<a @click.prevent.stop="handleTermsClick" v-text="$t('paymentForm.creditBalanceDialog.terms')"></a>
						</span>
					</template>
				</v-checkbox>

				<div class="g-recaptcha"
					 v-if="enableCaptcha"
					 data-callback="onReCaptchaSuccess"
					 :data-sitekey="$root.websiteConfig.data.gcp_recaptcha"
					 data-action="PAYMENT"></div>

				<v-btn class="mt-4 white--text" type="submit" color="button" :disabled="!canContinue" :loading="loading" block x-large>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>

				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</v-form>

	<div v-else-if="step === 'pay'">
		<PaymentProcessOverlay :loading="savingInvoice"/>
		<v-card class="mt-8">
			<v-card-title v-text="$t('cart.cashless.partialPayment')"></v-card-title>
			<v-card-text class="text-center">
				<h4 class="text--disabled" v-text="$t('cart.cashless.cardNumber', {
					number: data.cashless.number.toUpperCase()
				})"></h4>
			</v-card-text>
			<v-card-title v-text="$t('cart.cashless.amountToUse')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="font-weight-light" v-text="$options.filters.currency(data.cashless.balance)"></h3>
			</v-card-text>
			<v-card-title v-text="$t('cart.cashless.balanceRemainingAfterPayment')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="font-weight-light" v-text="$options.filters.currency(balanceRemainingAfterPayment)"></h3>
			</v-card-text>
			<v-card-title v-text="$t('cart.cashless.balanceRemainingToPay')"></v-card-title>
			<v-card-text class="text-center">
				<h3 class="mb-4" v-text="$options.filters.currency(balanceRemainingToPay)"></h3>
			</v-card-text>
		</v-card>

		<v-card v-if="balanceRemainingToPay > 0" class="mt-8">
			<v-card-text>
				<v-btn class="mt-4"  block x-large color="button" @click="$emit('cancel')">
					<span v-text="$t('btn.payRemainingBalance')"></span>
				</v-btn>
				<v-btn class="mt-4" x-large text block @click="handleCancelClick">
					<span v-text="$t('btn.cancelPayment')"></span>
				</v-btn>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import Vue from 'vue';
import PaymentProcessOverlay from "@/components/PaymentProcessOverlay";
import { BaseModel, Rules, EComService, CartModel, EventBus, PaymentService, PaymentModel } from '@connectngo/sdk';
import CaptchaMixin from "@/mixins/CaptchaMixin";
import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
	name: 'CashlessForm',

	mixins: [CaptchaMixin, ReloadV2Mixin],

	components: {
		PaymentProcessOverlay,
	},

	props: {
		skeleton: {
			type: Boolean,
			default: false,
		},
		component: {
			type: Vue.component,
			default: null,
		},
		cart: {
			type: CartModel,
			default: () => new CartModel(),
		},
		payments: {
			type: PaymentModel,
			default: () => new PaymentModel(),
		},
		engine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		cashlessEngine: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
	},

	data: () => ({
		loading: false,
		paying: false,
		success: false,
		error: false,
		step: 'start',
		formStartValid: false,
		formSummaryValid: false,
		formPaymentValid: false,
		details: new BaseModel(),
		data: {
			readTerms: false,
			cashless: {
				number: '',
				serial: '',
				balance: 0,
			},
		},
		steps: ['start', 'summary', 'pay'],
		formErrors: {},
		rules: {},
		savingInvoice: false,
		cashlessPaymentId: 0,
		balanceRemainingToPay : 0
	}),

	watch: {
		step(newValue, oldValue) {
			this.loadCaptcha()
			if (newValue == 'pay') {
				this.continue();
			}
		},
	},

	computed: {
		canPay() {
			return this.formPaymentValid && !this.paying;
		},
		canContinue() {
			switch (this.step) {
				case 'start':
					return this.formStartValid && !this.loading;
				case 'summary':
					return this.formSummaryValid && !this.loading;
			}
			return true;
		},
		balanceRemainingAfterPayment() {
			return this.details.data.balance - this.data.cashless.balance;
		},
		checkBalanceRemainingToPay() {
			if(Object.keys(this.cart?.getAppliedDiscounts()).length > 0 && this.cart.data?.total <= 0) {
				return this.cart.data.total;
			} else {
				return this.balanceRemainingToPay;
			}
		}
	},

	created() {
		this.balanceRemainingToPay = this.cart.data.amountToPay
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			balance: value => (value <= this.details.data.balance) || this.$t('rules.balance'),
			summary: value => (value <= this.payments.data.balance || value === 0) || this.$t('rules.summary'),
			notNegative: (value) => value >= 0 || this.$t("rules.notNegative"),
			notZero: (value) => value > 0 || this.$t("rules.notZero"),
		};
	},

	methods: {
		handleContinueClick() {
			this.continue();
		},
		handleCancelClick() {
			if (this.step === 'start') {
				this.$emit('cancel');
			} else if ((this.step === "summary" || this.step === "pay" ) && this.cashlessPaymentId !== 0) {
				this.loading = true;
				this.paying = true;

				Promise.all([
					new PaymentService().voidCashlessPayment(
						this.cashlessPaymentId,
						this.cart.data.uuid
					),
				])
					.then(() => {
						this.cart.data.amountToPay = (this.cart.data.balance + parseFloat(this.data.cashless.balance));
						this.cart.data.balance = this.cart.data.amountToPay;
						EventBus.publish('CART_UPDATED', this.cart);
						this.$snack(this.$i18n.t('cart.gift.refunded'));

						this.step =
							this.steps[
							this.steps.findIndex((item) => item === this.step) - 1
								];
					})
					.catch((error) => {
						this.error = true;
						this.$handleError(this, error);
					})
					.finally(() => {
						this.cashlessPaymentId = 0;
						this.loading = false;
						this.paying = false;
						this.$emit('partialPaymentCompleted')
					});
			} else {
				this.step = this.steps[this.steps.findIndex(item => item === this.step) - 1];
			}
		},
		triggerGAEvent(invoice) {
			const items = [];
			this.cart.data.items.forEach((item, index) => {
				items.push({
					item_id: item.data.product.data.id,
					item_name: this.$options.filters.translatable(
						item.data.product.data.name,
						item.data.product.data.name_i18n,
						this.$i18n.locale
					),
					coupon: item.data.hasDiscount
						? this.$options.filters.translatable(
								item.data.discount.name,
								item.data.discount.name_i18n,
								this.$i18n.locale
							)
						: null,
					currency:
						this.$root.websiteConfig.data.tenant.currency.code,
					discount: item.data.discountTotal,
					index: index++,
					item_category: item.data.mainTag
						? this.$options.filters.translatable(
								item.data.mainTag.name,
								item.data.mainTag.name_i18n,
								this.$i18n.locale
							)
						: null,
					price: item.data.total,
					quantity: item.data.quantity,
				});
			});
			if (this.$gtm) {
				dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
				dataLayer.push({
					event: "purchase",
					ecommerce: {
						transaction_id: invoice.data.invoice.transaction_id,
						value: invoice.data.invoice.amount,
						currency:
							this.$root.websiteConfig.data.tenant.currency.code,
						items: items,
					},
				});
			}
		},
		handlePaymentCompleted() {
			this.saveInvoice();
		},
		saveInvoice() {
			this.savingInvoice = true;
			return new EComService()
				.saveInvoice(this.cart.data.id)
				.then((invoice) => {
					this.triggerGAEvent(invoice);
					const createCartCb = () => {
						return new EComService().createCart().then((cart) => {
							this.$root.cart = cart;
							EventBus.publish("CART_UPDATED", cart);
							const nextStep =
                  !this.reloadV2FfEnabled && this.$root.websiteConfig.data.fields
									.active_reload === "1"
									? "reload"
									: "confirmation";
							this.$router.push({
								name: "invoice_step",
								params: {
									uuid: invoice.data.invoice.uuid,
									step: nextStep,
								},
							});
						});
					};
					return invoice.data.need_fulfillment
						? new EComService()
								.autoFulfillInvoice(invoice.data.invoice.uuid)
								.then(createCartCb)
						: createCartCb();
				})
				.catch((error) => this.$handleError(this, error))
				.finally(() => (this.savingInvoice = false));
		},
		handleTermsClick() {
			EventBus.publish('OPEN_PAGE', 'terms');
		},
		continue() {
			switch (this.step) {
				case 'start':
					this.loading = true;
					new PaymentService().getCashlessBalance(
						this.data.cashless.number,
						this.data.cashless.serial,
						this.cashlessEngine.data.id,
						this.cart.data.uuid,
					)
						.then(balance => {
							this.details = balance;
							this.step = 'summary';
						})
						.catch(error => this.$handleError(this, error))
						.finally(() => this.loading = false);
					break;
				case 'summary':
					this.pay();
					break;
			}
		},
		pay() {
			this.loading = true;
			this.paying = true;
			Promise.all([
				new PaymentService().processCashless(
					this.data.cashless.number,
					this.data.cashless.serial,
					this.cashlessEngine.data.id,
					this.cart.data.uuid,
					this.data.cashless.balance,
				),
			])
			.then((response) => {
				this.cashlessPaymentId = response[0].data.paymentId;
				const amountRemaining = (this.payments.data.balance - parseFloat(this.data.cashless.balance));
				this.balanceRemainingToPay = amountRemaining;
				this.cart.data.amountToPay = amountRemaining;
				this.cart.data.balance = this.cart.data.amountToPay;
				EventBus.publish('CART_UPDATED', this.cart);
				if (amountRemaining === 0) {
					this.$emit('completed')
				}  else {
					// Patial payment made
					this.$emit('partialPaymentCompleted')
				}
				this.$snack(this.$i18n.t('cashless.applied'));
				this.step = "pay";
			})
			.catch((error) => {
                    this.error = true;
                    this.$handleError(this, error);
			})
			.finally(() => {
				this.loading = false;
				this.paying = false;
			});
		},
	},
}
</script>
