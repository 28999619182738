<template>
  <v-tooltip
      style="z-index:1000"
      v-model="showTooltip"
      :top="position === 'top'"
      :bottom="position === 'bottom'"
      :left="position === 'left'"
      :right="position === 'right'"
      :content-class="`tooltip-${position}`"
      :open-on-click="false"
      :open-on-hover="false"
      :open-on-focus="false"
      max-width="400"
      color="#4C4C4C">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          @click="showTooltip = !isMobile ? showTooltip : !showTooltip"
          @mouseenter="showTooltip = !isMobile ? true : showTooltip"
          @mouseleave="showTooltip = !isMobile ? false : showTooltip"
          icon
          class="ml-1"
          x-small
          color="#4C4C4C"
      >

        <v-scale-transition>
          <span :class="`arrow arrow-${position}`" v-show="showTooltip"></span>
        </v-scale-transition>

        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
</template>

<script>

import { WindowWidthMixin } from '@/mixins/ProductMixin'

export default {
  name: "CustomTooltip",
	mixins : [WindowWidthMixin],
	props : {
    icon : {
      type : String,
      required : true
    },
    text : {
      type : String,
      required: true,
    },
    position : {
      type : String,
      default: 'bottom',
    }
  },
  data() {
    return {
      isMobile : false,
      showTooltip : false,
      hovering : false,
    }
  },
	mounted () {
		document.addEventListener("mouseup", this.mobileClickOutside)
	},
	destroyed () {
		document.removeEventListener("mouseup", this.mobileClickOutside)
	},
	methods : {
		mobileClickOutside() {
			if(this.isMobile && this.showTooltip) {
				this.showTooltip = false;
			}
		}
	}
}
</script>

<style scoped lang="scss">
  .v-tooltip__content {
    display: inline-block !important; /*To prevent tooltip hidden on mobile*/
    background-color: #4C4C4C !important;
    &.menuable__content__active {
      opacity: 1;
    }
  }

  .arrow {
     width: 0;
     height: 0;
     z-index: 100;
     position: absolute;

     &.arrow-bottom {
       border-left: 10px solid transparent;
       border-right: 10px solid transparent;
       border-bottom: 10px solid #4C4C4C;
       bottom:-12px;
     }

     &.arrow-top {
       border-top: 10px solid #4C4C4C;
       border-left: 10px solid transparent;
       border-right: 10px solid transparent;
       top:-12px;
     }

     &.arrow-right {
       border-right: 10px solid #4C4C4C;
       border-top: 10px solid transparent;
       border-bottom: 10px solid transparent;
       right: -3px;
     }

     &.arrow-left {
       border-left: 10px solid #4C4C4C;
       border-top: 10px solid transparent;
       border-bottom: 10px solid transparent;
       left: -3px;
     }
   }
</style>
