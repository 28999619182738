<template>
	<v-container>

		<AddSubAccountModal
			:visible.sync="addSubAccountModal"
			:parent-account="$root.user"
			scrollable
			max-width="500"
			@created="handleSubAccountCreated"
		/>

		<v-row>
			<v-col cols="12" md="6" class="d-flex flex-column justify-center">
				<h2 v-if="$route.params.is_b2b" class="display-1 font-weight-light" v-text="this.$root.user.data.company_name ? $t('account.connected.title', {
					name: $root.user.data.company_name,
				}) : $t('account.company_missing')""></h2>
				<h2 v-else class="display-1 font-weight-light" v-text="$t('account.connected.title', {
					name: $root.user.data.firstname,
				})"></h2>
			</v-col>
			<v-col cols="12" md="6" class="d-flex flex-column justify-center align-end">
				<v-btn color="button" :class="{
					'py-3 v-size--stretch': true,
				}" outlined :block="$vuetify.breakpoint.smAndDown" @click="handleCreateSubAccountClick">
					<v-icon left>mdi-account-plus</v-icon>
					<span :class="{
						'text-left ml-3 text-none': true,
						'text-wrap': $vuetify.breakpoint.smAndDown,
					}" style="width: min-content; margin: auto; flex: 1">
						<span v-text="$t('account.connected.addMember')"></span>
					</span>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="items.results.length === 0" cols="12">
				<v-alert text prominent type="info" class="ma-0">
					<span v-text="$t('connectedAccountListingCard.empty')"></span>
				</v-alert>
			</v-col>
			<v-col :key="item.guid" v-for="item in items.results" cols="12" lg="6">
				<ConnectedAccountCard :value="item" :skeleton="skeleton" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ConnectedAccountCard from '@/components/ConnectedAccountCard';
import AddSubAccountModal from '@/components/AddSubAccountModal';
import { PaginationModel, EComService } from '@connectngo/sdk';

export default {
	name: 'Index',

	components: { ConnectedAccountCard, AddSubAccountModal },

	data: () => ({
		skeleton: true,
		loading: false,
		addSubAccountModal: false,
		items: new PaginationModel([{},{},{}]),
	}),

	methods: {
		handleSubAccountCreated(account) {
			this.load();
		},
		handleCreateSubAccountClick() {
			this.addSubAccountModal = true;
		},
		load() {
			this.loading = true;
			return Promise.all([
				new EComService().getConnectedAccounts(),
			])
				.then(([connectedAccounts]) => {
					Object.assign(this, { items: connectedAccounts });
					this.skeleton = false;
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loading = false));
		},
	},

	created() {
		this.load();
	},
}
</script>
