<template>
	<Modal
		v-model="changeDateModal.visible"
		footerClass="pa-8"
		headerClass="px-6 pt-6 pb-0 modal-header systemDark1--text"
		:title="$t('account.reschedule.changeDate')"
		max-width="1090"
		width="1090"
		scrollable
		class="dialog"
		id="dialog"
		@close="onCloseModal"
	>
		<template #body>
			<v-card-text class="modal-content" :class="{ 'px-6': isDesktopBreakpoint }">
				<h2 class="subtitle mb-6 mediumGrey--text">{{ $t('account.reschedule.updateDateOfVisit') }}</h2>
				<v-container>
					<v-row>
							<v-col :cols="isDesktopBreakpoint ? 7 : 12" class="left-column" :class="{ 'mb-6': !isDesktopBreakpoint }">
								<div class="pt-2">
									<v-row class="d-flex justify-space-between ma-0 howto-container" :class="{ 'pl-4': !isDesktopBreakpoint }">
										<div class="green--text">
    										<v-icon :color="$vuetify.theme.themes.light.success">mdi-circle-medium</v-icon>
    										<span class="black--text text--small" v-text="$t('account.reschedule.howTo.green')"></span>
  										</div>
  										<div class="orange--text">
    										<v-icon :color="$vuetify.theme.themes.light.warning">mdi-circle-medium</v-icon>
    										<span class="black--text text--small" v-text="$t('account.reschedule.howTo.orange')"></span>
										</div>
										<div>
											<v-icon :color="$vuetify.theme.themes.light.systemDark1">mdi-circle-medium</v-icon>
											<span class="black--text text--small">
												{{$t('account.reschedule.howTo.black', { hours: reschedule_restrict_hour })}}
											</span>
										</div>
									</v-row>
									<v-row class="mt-0" :class="{ 'ma-0': !isDesktopBreakpoint }">
										<v-col cols="12" class="pa-0">
											<v-date-picker
												:allowed-dates="allowedDates"
												color="primary"
												header-color="secondaryDark"
												v-model="selectedDate"
												@input="onDayChange"
												@update:picker-date="onMonthChange"
												:min="new Date().toISOString().substr(0, 10)"
												:events="functionEvents"
												:disabled="loading || loadingColors.length !== 0"
												full-width
												no-title
											>
												<div class="w-100">
													<v-expand-transition>
														<v-progress-linear v-if="loadingColors.length !== 0" indeterminate></v-progress-linear>
													</v-expand-transition>
												</div>
											</v-date-picker>
										</v-col>
									</v-row>
								</div>
							</v-col>
						<v-col :cols="isDesktopBreakpoint ? 5 : 12" class="right-column pt-0">
							<template v-for="(item, index) in selectedGroupTimebasedProducts">
								<product-timeslot-picker
									:product="{ data: item.product }"
									:time-slots="timeSlots.filter(slot => slot?.event_group_id === item?.product?.event_group?.id) || []"
									product-type="tag"
									:otherSelectedTimeslots="otherSelectedTimeslots[item.product.id]"
									:hide-header="true"
									:show-title="false"
									:skeleton="skeleton"
									:selected-options.sync="selectedTimeslots"
									@option-selected="(ts) => onSelectTimeSlotClick(ts, item.product, index)"
									:selected-index="index"
									:progress="`${index + 1}/${selectedGroupTimebasedProducts.length}`"
								/>
							</template>
						</v-col>
						<v-col cols="12" md="7" v-if="selectedSlot.id && (selectedSlot.price_delta > 0 || hasModifiers)">
							<v-card outlined class="full-height" :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 24px' : null">
								<v-card-title class="pb-4">
									<i18n path="account.reschedule.priceDiffAvail" tag="span">
										<span class="error--text">*</span>
									</i18n>
								</v-card-title>
								<v-card-text>
									<v-card :key="productIdx" v-for="(item, productIdx) in selectedGroup.items" outlined :class="{
										'mt-3': productIdx > 0,
									}">
										<div :class="{
											'd-flex align-center': true,
										}">
											<v-img :src="item.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
											<v-card-text class="py-2">
												<h4 class="mb-0" v-text="$options.filters.translatable(item.product.name, item.product.name_i18n)"></h4>
												<p class="caption opacity-50 mb-2">{{ selectedSlot.period }}</p>

												<i18n path="account.reschedule.priceDiff" tag="h5">
													<strong :class="{
														'primary--text': getProductPrice(item) > 0,
														'error--text': getProductPrice(item) < 0,
													}">
														<span>
															{{ getProductPrice(item) > 0 ? '+' : '' }}
															<span>{{ $options.filters.currency(priceDelta[item.product.id]) }}</span>
														</span>
													</strong>
												</i18n>

												<i18n path="account.reschedule.newDate" tag="h5">
													<span class="font-weight-regular">{{ selectedSlot.day }} {{ selectedSlot.period }}</span>
												</i18n>

												<h5>
													<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
													&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
														price: $options.filters.currency(calcUpdatedPrice(item))
													})"></span>
												</h5>
											</v-card-text>
										</div>

								<v-card :key="modifierIdx" v-for="(modifier, modifierIdx) in item.timeBasedModifiers" outlined :class="{
										'mt-3': modifierIdx > 0,
									}">
										<div :class="{
											'd-flex align-center': true,
										}">
											<v-img :src="modifier.product.image" width="150" style="border-radius: 3px 0 0 0" :aspect-ratio="16/15"></v-img>
											<v-card-text class="py-2">
												<h4 class="mb-0" v-text="$options.filters.translatable(modifier.product.name, modifier.product.name_i18n)"></h4>
												<p class="caption opacity-50 mb-2"></p>

												<i18n path="account.reschedule.priceDiff" tag="h5">
													<strong :class="{
														'primary--text': getProductPrice(modifier) > 0,
														'error--text': getProductPrice(modifier) < 0,
													}">
														<span>
															{{ getProductPrice(modifier) > 0 ? '+' : '' }}
															<span>{{ $options.filters.currency(modifierDelta[productIdx][modifierIdx]) }}</span>
														</span>
													</strong>
												</i18n>
												<h5>
													<strong v-text="$t('account.reschedule.updatedPriceTitle')"></strong>
													&nbsp;<span class="font-weight-regular" v-text="$t('account.reschedule.updatedPriceDetails', {
														price: $options.filters.currency(getProductPrice(modifier) + (modifierDelta[productIdx][modifierIdx] || 0))
													})"></span>
												</h5>
										<v-divider />
										<v-card-text class="pt-2" style="background-color: #f6f6f6">
											<i18n path="account.reschedule.chooseNewTime" tag="h4" class="mb-2">
												<span class="error--text">*</span>
											</i18n>
											<v-select
												v-model="modifier.period"
												:items="getModifierSlots(modifier)"
												@change="handleModifierChange($event, modifier, productIdx, modifierIdx)"
												:label="$t('account.reschedule.makeSelection')"
												background-color="white"
												dense
												outlined
												hide-details
											/>
											</v-card-text>
										</v-card-text>
										</div>
									</v-card>
									</v-card>
								</v-card-text>
								<v-card-actions>
									<div class="w-100">
										<i18n path="account.reschedule.totalPriceDiff" tag="span" class="title">
											<strong v-text="$options.filters.currency(totalDiff)"></strong>
										</i18n>
										<br>
										<i18n path="account.reschedule.lowerPriceNotRefund" tag="span" class="opacity-50">
											<span class="error--text">*</span>
										</i18n>
									</div>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</template>

		<template #buttons>
			<v-row>
				<v-col :cols="isDesktopBreakpoint ? 6 : 12">
					<span  v-if="reschedule_restrict_hour && reschedule_restrict_hour != 0" class="calendar__footer pl-6">
						*{{$t('account.reschedule.howTo.point4', { hours: reschedule_restrict_hour })}}
					</span>
				</v-col>
				<v-col :cols="isDesktopBreakpoint ? 6 : 12">
					<div class="btn-container d-flex justify-end">
						<v-btn
							class="btn--cancel mediumGreen--text"
							outlined
							:disabled="applyingChanges"
							large
							@click="onCancelClick"
						>
							<span v-text="$t('btn.cancel')"></span>
						</v-btn>
						<v-btn class="btn--confirm secondaryDark" large :disabled="canConfirm" :loading="applyingChanges" @click="updateSchedule">
							<span v-text="$t('btn.confirmChanges')"></span>
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</template>
	</Modal>
</template>

<script>
import moment from 'moment';
import Calendar from '@/components/CalendarCombo.vue';
import TransactionCard from '@/components/TransactionCard';
import ProductWaiverCard from '@/components/ProductWaiverCard';
import TimestampCountdown from '@/components/TimestampCountdown';
import ProductTimeslotPicker from '@/components/ProductTimeslotPicker';
import Modal from '@/components/Modal';
import CalendarSlot from '@/components/CalendarSlot';
import {BaseModel, EComService, EventBus} from '@connectngo/sdk';
import globalVariables from "@/global";

export default {
name: 'RescheduleComboModal',

	components: { TransactionCard, ProductWaiverCard, TimestampCountdown, Modal, CalendarSlot, ProductTimeslotPicker, Calendar },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_reschedule'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_reschedule') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	props: {
		selectedGroup: {
			type: Object,
			default: () => {
				return {};
			}
		},
		changeDateModal: {
			type: Object,
			default: () => {
				return {};
			}
		},
		updateSchedule: {
			type: Function,
			default: () => {
				return {};
			}
		},
		initialSelectedDate: {
			type: String,
			default: () => "",
		},
		transactionDetailIds: {
			type: Array,
			default: () => {
				return [];
			}
		},
		productIds: {
			type: Array,
			default: () => {
				return [];
			}
		},
		eventGroupIds: {
			type: Array,
			default: () => [],
		},
		selectedModifiers: {
			type: Array,
			default: () => {
				return [];
			}
		},
		transactionProducts: {
			type: Array,
			default: () => {
				return [];
			}
		},
		eventSlots: {
			type: Array,
			default: () => [],
		},
		productSlots: {
			type: Array,
			default: () => [],
		},
		onMonthChange: {
			type: Function,
			default: () => {
				return {};
			}
		},
		onDayChange: {
			type: Function,
			default: () => {
				return {};
			}
		},
		selectedDate: {
			type: String,
			default: null,
		},
		handleModifierChange: {
			type: Function,
			default: () => {
				return {};
			}
		},
		loadingColors: {
			type: Array,
			default: () => []
		},
		applyingChanges: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['update-selected-timeslots'],

	data: () => ({
		now: new Date().getTime(),
		loading: false,
		otherSelectedTimeslots: {},
		mappedData: [],
		priceDelta: {},
		dayClosed: false,
		calendarDataForCurrentMonth: {},
		selectedTimeslots: {},
		isSlotSelected: false,
		selectedSlot: {},
		allModifiersSelected: false,
		modifierDelta: [],
		skeleton: true,
		cartConfirm: false,
		totalDiff: 0,
		pending: false,
		pendingTime: 0,
		hasModifiers: false,
		reschedule_restrict_hour: 0,
		reschedule_allow_hour: 0,
	}),

	computed: {
		canApplyChanges() {
			return !(this.loading || this.applyingChanges || !this.selectedDate || !this.isSlotSelected) ? true : false;
		},
		hasPriceChangeDifference() {
			return Object.values(this.selectedTimeslots).some(slot => slot?.price_delta > 0);
		},
		canConfirm() {
			return !this.canApplyChanges || !this.allModifiersSelected || !this.allTimeslotsSelected;
		},
		allTimeslotsSelected() {
    		return this.selectedTimeslots && Object.values(this.selectedTimeslots).filter(item => item && item.id).length === this.selectedGroupTimebasedProducts.length;
  		},
		selectedGroupTimebasedProducts() {
			return this.selectedGroup?.items?.filter(item =>
    			this.eventGroupIds.includes(item?.product?.event_group?.id)
  			) ?? [];
		},
		// This function should only be used to display mock timeslots for testing layout.
		calanderCardWidth() {
			return !(Object.values(this.selectedTimeslots).some(slot => slot?.id && (slot?.price_delta > 0 || this?.hasModifiers))) && '500' || 'auto';
		},
		showAvailability() {
			return !!parseInt(globalVariables.websiteConfig.data.fields.show_tickets_availabilities) ?? false;
		},
		slotsForSelectDisplay() {
			if (!this.currentSlots || !this.currentSlots.items) return [];
			return this.currentSlots.items.map(item => {
				let label = item.period;

				if(this.showAvailability) {
					label += ` (${this.$tc('calendar.places', item.amountLeft, {
						amount: item.amountLeft
					})})`;
				}

				return {
					value : item,
					disabled : item.amountLeft <= 0,
					text : label,
				}
			})
		},
		currentSlots() {
			return this.mappedData?.find(d => d.day === this.selectedDate);
		},
		timeSlots() {
			if (!this.currentSlots || !this.currentSlots.items) return [];
			return this.currentSlots.items.filter(item => item.amountLeft > 0).map(item => ({
				period: item.period,
				amountLeft: item.amountLeft,
				id: item.id,
				event_group_id: item.event_group_id
			}));
		},
		isDesktopBreakpoint() {
			return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.md
		},
	},
	methods: {
		calculateTotal() {
			if (!this.isSlotSelected) return 0;

			let diff = 0;

			this.selectedGroup.items.forEach((item, itemIdx) => {
				diff += this.priceDelta[item.product.id];

				item.timeBasedModifiers.forEach((modifier, modifierIdx) => {
					if (this.priceDelta[modifier.product.id] && this.selectedModifiers[itemIdx][modifierIdx]) {
						diff += this.priceDelta[modifier.product.id];
					}
				})

			});
			this.totalDiff = diff;
		},
		getProductPrice(item) {
			if (this.selectedGroup !== null) {
				for (let i in this.selectedGroup.items) {
					let currentItem = this.selectedGroup.items[i];

					if (currentItem.id === item.id && currentItem.amount) {
						return currentItem.amount;
					}
				}
			}

			let product = item.product

			if (product.price) {
				return product.price.min_price;
			}

			return 0;
		},
		functionEvents(date) {
			const todayDate = moment().format('YYYY-MM-DD');
			const currentTimeStamp = moment().unix();
			// Display the event color based on the comboSlots.
			if (moment(date).isBefore(todayDate) || !this.productSlots?.length) {
				return;
			}
			const currSlots = this.productSlots.find(d => d.data.day === date);
			if (!currSlots) {
				return;
			}

			if (todayDate === date) {
				const lastEventStartTime = currSlots.data?.events?.[currSlots.data.events?.length - 1]?.start_ts;
				if (!moment(currentTimeStamp).isBefore(lastEventStartTime)) {
					return;
				}
			}

			if (currSlots.data?.events?.find(e => e.price_delta > 0)) return 'orange';

			return currSlots.data.color;
		},
		showTimeSlotPicker(product) {
			return this.selectedGroup?.items && product?.id && product?.event_group?.id;
		},
		handleSelectDate(date) {
			let selectDate = moment.unix(date).format('YYYY-MM-DD');
			if (this.reschedule_allow_hour && this.reschedule_allow_hour != 0) {
				let currentAvailableDate = moment().add(this.reschedule_allow_hour + 24, 'hours').format('YYYY-MM-DD');
				if (moment(selectDate).isBefore(currentAvailableDate)) {
					return currentAvailableDate;
				}
			}
			return selectDate;
		},
		getOtherSelectedTimeslots(productId) {
			const timeslots = Object.keys(this.selectedTimeslots)
      			.filter(id => Number(id) !== Number(productId) && this.selectedTimeslots[id])
      			.map(id => this.selectedTimeslots[id]);
			return timeslots;
		},
		onCancelClick() {
			this.changeDateModal.visible = false;
			this.resetData();
		},
		onCloseModal() {
			this.resetData();
		},
		resetData() {
			this.selectedTimeslots = {};
			this.otherSelectedTimeslots = {};
		},
		onSelectTimeSlotClick(slot, product, index) {
			this.isSlotSelected = true;
			if (!slot) {
				this.$delete(this.selectedTimeslots, product.id);
			} else {
				this.$set(this.selectedTimeslots, product.id, { ...slot, index });
			}
			let modsOn = true;
			let hasMods = false;

			this.selectedGroup.items.forEach(item => {
				if (item.timeBasedModifiers.length > 0) {
					modsOn = false;
					hasMods = true;
				}
			});

			this.allModifiersSelected = modsOn;
			this.hasModifiers = hasMods;

			if (!slot?.id) return;

			return new EComService().getProductPrices(slot?.id, this.productIds)
				.then(response => {
					const deltas = {};
					this.selectedGroup.items.forEach(item => {
						if (response.data[item.product.id]) {
							deltas[item.product.id] = response.data[item.product.id].min_price - this.getProductPrice(item);
						}

						item.timeBasedModifiers.forEach(modifier => {
							deltas[modifier.product.id] = response.data[modifier.product.id].min_price - this.getProductPrice(modifier);
						})
					});

					this.priceDelta = deltas;
					this.calculateTotal();
				})
				.finally(() => this.loading = false);
		},
		allowedDates(val) {
			return val >= moment().format('YYYY-MM-DD') && this.functionEvents(val) && this.functionEvents(val) !== 'red';
		},
		getModifierSlots(modifier) {
			if (!this.calendarDataForCurrentMonth[modifier.product.event_group.id] || !this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate)) {
				return [];
			}

			return this.getMappedData(this.calendarDataForCurrentMonth[modifier.product.event_group.id]).find(d => d.date === this.selectedDate).items.map(i => i.period);
		},
		getMappedData(slots = [], eventSlots = []) {
  			return slots.map(slot => {
    			const productSlotsPerDay = eventSlots.filter(eventSlot => eventSlot?.data?.day === slot.data.day);

    			return {
      				day: slot.data.day,
      				state: slot.data.closed ? 'closed' : 'open',
      				color: slot.data.color,
      				items: productSlotsPerDay.flatMap(productSlot =>
						productSlot.data.events.map(event => ({
        					id: event.data.id,
        					day: slot.data.day,
        					period: event.data.period,
        					amountLeft: event.data.available || 0,
        					totalAmount: event.data.available || 0,
        					price_delta: event.data.price_delta || 0,
							event_group_id: productSlot.data.event_group_id,
						})
					))
    			};
  			});
		},
		load() {
			this.loading = true;

			return new EComService().getReschedulableInvoices()
				.then((transactions) => {
					Object.assign(this, { transactions });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => {
					this.loading = false;
					this.skeleton = false;
				});
		},
		calcUpdatedPrice(item) {
			return parseFloat(this.getProductPrice(item)) + (this.priceDelta[item.product.id] || 0);
		}
	},
	created() {
		this.reschedule_restrict_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_restrict_hour);
		this.reschedule_allow_hour = parseInt(globalVariables.websiteConfig.data.fields?.reschedule_allow_hour);
		this.$route.meta.section = this.$i18n.t('route.account_reschedule');
		this.load();
	},
	mounted() {
		this.timeSlotEventBus = EventBus.subscribe(
			"timeSlotPickedForGroup" + this.eventGroupId,
			this.eventSelected
		);
	},
	watch: {
		selectedDate: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					for (let product of this.transactionProducts) {
						if (product?.id) {
							this.$set(this.selectedTimeslots, product.id, null);
							this.$set(this.otherSelectedTimeslots, product.id, []);
						}
					}
				}
			},
		},
		selectedTimeslots: {
			deep: true,
			immediate: true,
			handler() {
				for (let product of this.transactionProducts) {
					if (product?.id) {
						this.$set(this.otherSelectedTimeslots, product.id, this.getOtherSelectedTimeslots(product.id));
					}
				}
				this.$emit('update-selected-timeslots', this.selectedTimeslots);
			}
		},
		eventSlots: {
			deep: true,
			handler(newValue) {
				if (newValue) {
					this.mappedData = this.getMappedData(this.productSlots, newValue);
				}
			}
		},
		productSlots: {
			deep: true,
			handler(newValue) {
				if (newValue) {
					this.mappedData = this.getMappedData(newValue, this.eventSlots);
				}
			}
		}
	},
}
</script>

<style lang="scss">
#dialog .modal-header {
	.headline {
		font-size: 27px !important;
		font-weight: 600;
		line-height: 36px;
		text-align: left;
	}
	@media screen and (max-width: 600px) {
		.headline {
			font-size: 1.5rem;
			font-weight: 400;
			line-height: 2rem;
			letter-spacing: normal;
		}
	}
}

</style>

<style scoped lang="scss">
.subtitle {
	font-size: 18px !important;
	font-weight: 600;
	line-height: 23px;
	text-align: left;
}

.card {
	border-radius: 16px 16px 0px 0px;
	box-shadow: 0px 4px 8px 0px #6864641A;
}

::v-deep .v-picker .v-picker__actions {
	padding: 8px 0px 0px 0px !important;
}

.text--small {
	font-size: 11px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.1599999964237213px;
	text-align: left;
}

.left-column {
	display: flex;
	flex-direction: column;
	gap: 8px;
	border-radius: 16px;
	box-shadow: 0px 4px 8px 0px #6864641A;
	border: 1px solid #F2F2F2;
}

.left-column i {
	width: 30px;
	height: 30px;
	font-size: 50px;
}

.calendar__footer {
	font-size: 11px;
	font-weight: 600;
	line-height: 17px;
	text-align: left;
	color: #EF8686;
}

.right-column {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

::v-deep .v-date-picker-table {
	min-height: 250px;
}

ul {
	display: flex;
	flex-direction: column;
	gap: 8px;
	li {
		list-style: none;
		.v-icon {
			font-size: 50px;
			width: 15px;
			height: 15px;
			margin-right: 12px;
		}
	}
}

::v-deep .calendar .calendar__btn {
	min-height: 50px !important;
	&--inner {
		gap: 4px;
	}
	.title {
		line-height: initial;
		font-size: 16px !important;
	}
}

.calander-center {
    margin: auto;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ul-list-padding {
	padding-left: 8px !important;
}

.v-card__text > .title {
	font-size: 16px !important;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0px;
}

.btn-container {
	width: 100%;
	gap: 16px;
	.v-btn {
		max-width: 235px;
		width: 100%;
		min-height: 40px;
		padding: 12px 24px 12px 24px;
		border-radius: 8px;
		line-height: 23px;
		text-align: center;
		text-transform: none;
		font-size: 18px;
		font-weight: 600;
		text-align: left;
	}
	.btn--confirm {
		color: #fff !important;
		padding: 8px 16px 8px 16px;
		border-radius: 4px;
		&:disabled {
			background-color: #009AA6 !important;
			opacity: 50%;
		}
	}

	.btn--cancel {
		padding: 8px 24px 8px 24px;
		border-radius: 4;
		border: 1px solid #48C4C5;
		text-align: center;
		&:disabled {
			border: 1px solid #48C4C5 !important;
			color: #48C4C5 !important;
			opacity: 50%;
		}
	}
}

@media all and (max-width: 767px) {
	.subtitle {
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 2rem;
		letter-spacing: 0.0125rem !important;
	}
	.column-mobile {
		flex-wrap: wrap;
		justify-content: center;
	}
	.column-mobile button{
		margin-top: 20px
	}
	.howto-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	.btn-container {
		display: flex;
		flex-direction: column-reverse;
		.v-btn {
			width: 100%;
			max-width: inherit;
		}
	}
	.card {
		gap: 24px;
	}

	.col {
		padding-left: 0px;
		padding-right: 0px;
	}

	.subtitle {
		font-size: 14px;
	}

}
</style>
