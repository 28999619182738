<template>
	<v-dialog v-model="_visible" v-bind="$attrs" v-on="$listeners" :persistent="persistent">
		<v-card :id="id" :class="modalClass">
			<slot v-if="!hideHeader" name="header">
				<v-card-title :class="headerClass" class="d-flex flex-nowrap text-left transparent" style="justify-content: space-between !important">
					<div class="d-flex flex-column">
						<span class="headline text-truncate mb-1" v-text="title"></span>
						<span class="subtitle text-truncate" v-text="subtitle" v-if="subtitle"></span>
					</div>
					<v-btn v-if="!noClose" @click="_visible = false" class="mr-n2" data-test-selector="modal_title_close" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
			</slot>
			<slot v-if="!hideBody" name="body">
				<v-card-text>
					<span v-html="body"></span>
				</v-card-text>
			</slot>
			<slot v-if="!hideFooter" name="footer">
				<v-card-actions :class="footerClass">
					<v-divider />
					<slot name="actions">
						<div class="d-flex flex-column flex-md-row w-100 justify-end" style="gap: 0.5rem">
							<slot name="buttons">
								<v-btn :key="btnIdx" v-for="(btn, btnIdx) in buttons" v-bind="btn.attrs" v-on="btn.events" :block="$vuetify.breakpoint.smAndDown" :outlined="btn.type === 'cancel' || (btn.attrs && btn.attrs.outlined)" large @click="btn.type === 'cancel' ? _visible = false : null">
									<v-icon v-if="btn.icon" v-text="btn.icon" left></v-icon>
									<span v-if="btn.type === 'cancel'" v-text="$t('btn.cancel')"></span>
									<span v-else v-text="btn.text"></span>
								</v-btn>
							</slot>
						</div>
					</slot>
				</v-card-actions>
			</slot>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "Modal",

	props: {
		id: {
			type: String,
			default: null,
		},
		value: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
			required: false,
		},
		body: {
			type: String,
			default: null,
		},
		buttons: {
			type: Array,
			default: null,
		},
		hideHeader: {
			type: Boolean,
			default: false,
		},
		hideBody: {
			type: Boolean,
			default: false,
		},
		hideFooter: {
			type: Boolean,
			default: false,
		},
		noClose: {
			type: Boolean,
			default: false,
		},
		modalClass: {
			type: String,
			default: null,
		},
		footerClass: {
			type: String,
			default: null
		},
		headerClass: {
			type: String,
			default: null
		},
		persistent: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		defaultButtons: [],
	}),

	computed: {
		_visible: {
			get() {
				return this.value;
			},
			set(value) {
				if (!value) {
					this.$emit('close');
				}
				this.$emit('input', value);
			},
		},
		_buttons() {
			return this.buttons === null ? this.defaultButtons : this.buttons;
		},
	},

	created() {
		if (this.buttons === null) {
			this.defaultButtons = [
				{ i18n: 'btn.close', events: {
					click: () => {
						this._visible = false;
					}
				}}
			];
		}
	}
}
</script>
