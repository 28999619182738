<template>
	<div>
		<div class="w-100" id="dropin-container"></div>
	</div>
</template>

<script>

import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { EComService } from '@connectngo/sdk'

export default {
	name: 'AdyenTokenizationForm',
	props: {
		walletId: {
			type: [Number, String],
			required: true,
		},
		tokenizationEngine: {
			type: Object,
			required: true
		},
		onSubmit: {
			type: Function,
			default: null,
			required: true,
		}
	},
	data () {
		return {
			loading: false,
			dropin: null,
			paymentMethods: {},
			error: '',
		}
	},
	created () {
		this.paymentMethods = JSON.parse(this.tokenizationEngine.external_data.payment_methods)
		this.loading = true
	},
	mounted () {
		this.initDropIn()
	},
	methods: {
		initDropIn () {
			const locale = this.$i18n.locale + '-' + this.tokenizationEngine.engine_fields.COUNTRY_CODE
			const translations = {
				[locale]: {
					'payButton': this.$t('save'),
				},
			}
			const configuration = {
				paymentMethodsResponse: this.paymentMethods,
				paymentMethodsConfiguration: {
					card: {
						hasHolderName: true,
						holderNameRequired: true,
						billingAddressRequired: false
					}
				},
				clientKey: this.tokenizationEngine.engine_fields.CLIENT_KEY,
				locale: locale,
				environment: this.tokenizationEngine.engine_fields.ENVIRONMENT,
				translations: translations,
				onSubmit: (state, dropin) => {
					delete state.data.paymentMethod.checkoutAttemptId;
					new EComService().createToken(this.walletId, state.data, 'POS_create_token_' + this.walletId + '_' + Date.now(), window.location.href)
						.then(() => {
								dropin.setStatus('ready')
								this.onSubmit()
							}
						)
				}
			}

			new AdyenCheckout(configuration).then(checkout => {
				this.dropin = checkout.create('dropin', {
					openFirstPaymentMethod: true,
					onReady: () => {
						this.loading = false
					}
				}).mount('#dropin-container')
			})

		},
	}
}
</script>

<style scoped>

</style>
