<template>
	<div v-bind="$attrs" v-on="$listeners">
		<div v-if="title" class="font-weight-bold" v-text="title"></div>
		<div :key="conditionIdx" v-for="(condition, conditionIdx) in conditions" :class="{
			'mt-1': true,
			'success--text': condition.valid,
			'danger--text': !condition.valid,
		}">
			<v-icon v-if="condition.valid" color="success" left>mdi-check</v-icon>
			<v-icon v-else color="error" left>mdi-close</v-icon>
			<span v-text="condition.text"></span>
		</div>
	</div>
</template>

<script>
export default {
	name: "StrengthIndicator",

	props: {

		title: {
			type: String,
		},

		conditions: {
			type: Array,
			default: () => ([]),
		},
	},
}
</script>
