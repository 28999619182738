<template>
	<Page color="neutralLight" breadcrumbs force-showing-sidebar>
		<template #sidebar>
			<v-list :dense="$vuetify.breakpoint.smAndDown" rounded>
				<v-list-item class="py-4">
					<v-list-item-title v-if="$route.params.is_b2b " class="text-uppercase">
						<span v-if="$root.user.data.company_name == null">
							{{ $t('account.company_missing') }}
						</span>
						<span v-else v-text="$t('toolbar.welcomeUser', {
							name:  $root.user.data.company_name
						})"></span>
					</v-list-item-title>
					<v-list-item-title v-else class="text-uppercase">
						<span v-text="$t('toolbar.welcomeUser', {
							name: $root.user.data.firstname
						})"></span>
					</v-list-item-title>
				</v-list-item>

				<v-list-item color="secondaryDark" v-for="(link, linkIdx) in menuLinks" :key="linkIdx" v-bind="link" v-on="link.events" :exact="link.exact" :class="link.classes">
					<v-list-item-icon>
						<v-icon v-text="link.icon"></v-icon>
					</v-list-item-icon>
					<v-list-item-title v-text="$t('accountMenu.' + link.key)"></v-list-item-title>
				</v-list-item>
			</v-list>
		</template>

		<v-container class="mb-4">
			<v-row>
				<v-col cols="12" lg="6" class="d-flex flex-column justify-center">
					<h1 v-if="$route.params.is_b2b && $route.name == 'account'" class="display-1">
						<span v-if="$root.user.data.company_name == null">
							{{ $t('account.company_missing') }}
						</span>
						<span v-else v-text="$t(pages[$route.params.section || 'index'].title, {
							name: this.$root.user.data.company_name,
						}) || $t('route.account_' + $route.params.section)">
						</span>
					</h1>
					<h1 v-else class="display-1" v-text="$t(pages[$route.params.section || 'index'].title, {
							name: this.$root.user.data.firstname,
						}) || $t('route.account_' + $route.params.section)">
					</h1>
				</v-col>
				<v-col cols="12" lg="6" class="d-flex flex-column justify-center align-end d-print-none">
					<v-btn color="button" :class="{
						'py-3 v-size--stretch': true,
					}" :to="{ name: 'home' }" :block="$vuetify.breakpoint.mdAndDown">
						<v-icon x-large left>mdi-cart-outline</v-icon>
						<span :class="{
							'text-left ml-3 text-none': true,
							'text-wrap': $vuetify.breakpoint.mdAndDown,
						}" style="width: min-content; margin: auto; flex: 1">
							<strong v-text="$t('account.goToShopBtn.title')"></strong>
							<br><span v-text="$t('account.goToShopBtn.desc')"></span>
						</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<component v-bind="pages[$route.params.section || 'index']"></component>
	</Page>
</template>

<script>
import Page from '@/components/Page';
import Index from '@/routes/App/Account/Index';
import Edit from '@/routes/App/Account/Edit';
import ConnectedAccounts from '@/routes/App/Account/ConnectedAccounts';
import Wallet from '@/routes/App/Account/Wallet';
import Memories from '@/routes/App/Account/Memories';
import Transactions from '@/routes/App/Account/Transactions';
import Reschedule from '@/routes/App/Account/Reschedule';
import Reservations from '@/routes/App/Account/Reservations.vue';

export default {
	name: "Account",

	components: { Page },

	metaInfo() {
		return {
			title: this.$i18n.t('route.account'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		pages: {},
	}),

	computed:{
		menuLinks(){
			let links = this.$root.accountLinks;

			links = links.filter(link => {
				if(link.key === 'reschedule' && this.$root.websiteConfig.data.fields.reschedule === '0'){
					return;
				}

				return link;
			})

			return links;
		}
	},

	created() {
		if (this.$route.params.section) {
			this.$route.meta.section = this.$i18n.t('route.account_' + this.$route.params.section);
		}

		this.pages = {
			index: { is: Index, title: 'account.index.title' },
			edit: { is: Edit, title: 'account.edit.title' },
			connected: { is: ConnectedAccounts },
			wallet: { is: Wallet },
			transactions: { is: Transactions },
			reschedule: { is: Reschedule },
			reservations: { is: Reservations },
		};

		if (this.$root.websiteConfig.data.experiential_module) {
			this.pages.memories = { is: Memories };
		}
		const feature_flags = this.$root.websiteConfig.data.feature_flags || [];
	},
}
</script>

