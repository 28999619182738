<template>
	<Modal v-model="visible" v-bind="$attrs" v-on="$listeners" max-width="800">
		<template #body>
			<v-card-text class="px-0 px-md-4 text-center">
					<h1 class="display-1" v-text="$t('interactiveMap.modalTitle')"></h1>
					<v-container class="cross-sell-container">
						<InteractiveMapButton :interactive-map="interactiveMap" :on-click="closeModal" />
					</v-container>
				</v-card-text>
		</template>
		<template #actions>
			<div class="d-flex w-100 justify-end" style="gap: 0.5rem">
				<v-btn
					color="button"
					data-test-selector="crossSellModal_cancel"
					outlined
					@click="closeModal"
				>
					<span v-text="$t('btn.cancel')"></span>
				</v-btn>
				<v-btn
					type="submit"
					color="button"
					data-test-selector="crossSellModal_add"
					@click="handleContinueClick"
				>
					<span v-text="$t('btn.continue')"></span>
				</v-btn>
			</div>
		</template>
	</Modal>
</template>
<script>
import Modal from "../Modal";
import InteractiveMapButton from "./InteractiveMapButton";

export default {
	name: "InteractiveMapCrossSellModal",
	components: {InteractiveMapButton, Modal},
	props : {
		visible : {
			default : false,
			type : Boolean
		},
		nextAction : {
			required:true,
			type : Function
		}
	},
	data() {
		return {
			showModalEvent : null,
		}
	},
	computed: {
		interactiveMap() {
			return this.$root.websiteConfig.data.interactive_map;
		}
	},
	methods : {
		handleContinueClick() {
			this.closeModal();
			this.nextAction();
		},
		closeModal() {
			this.$emit("modalClosed");
		}
	},
}
</script>

<style scoped>

</style>
