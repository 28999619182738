import baseEn from '@connectngo/vue-components/locales/en.json';
import baseFr from '@connectngo/vue-components/locales/fr.json';
import baseEs from '@connectngo/vue-components/locales/es.json';
import baseAr from '@connectngo/vue-components/locales/ar.json';

import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import ar from './locales/ar.json';
import fr_CA from './locales/fr-ca.json';
import fr_FR from './locales/fr-fr.json';
import en_CA from './locales/en-ca.json';
import en_US from './locales/en-us.json';
import en_GB from './locales/en-gb.json';

const messages = {
	en: { ...baseEn, ...en },
	fr: { ...baseFr, ...fr },
	es: { ...baseEs, ...es },
	ar: { ...baseAr, ...ar },
	'fr-ca': {...baseFr, ...fr_CA},
	'fr-fr': {...baseFr, ...fr_FR},
	'en-ca': {...baseFr, ...en_CA},
	'en-us': {...baseFr, ...en_US},
	'en-gb': {...baseFr, ...en_GB},
};
const availableLanguages = Object.keys(messages);

export default messages;

export {
	availableLanguages,
}
