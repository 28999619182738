<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,actions"></v-skeleton-loader>
	<v-card v-else v-bind="$attrs" v-on="$listeners">
		<v-card-text v-if="!value.data.return">
			<div class="d-flex align-center">
				<QrCode
					v-if="_hiddeQrCode"
					v-model="value.data.barcode"
					class="mr-3"
				/>

				<Modal v-model="showPdf" :title="$t('productWaiverCard.pdfModal.title')" max-width="800">
					<template #body>
						<iframe :src="waiverPdf.data.base_64" class="waiver_pdf"></iframe>
					</template>
				</Modal>

				<div>
					<h3 class="title mb-1" style="line-height: 1.5rem" v-text="$options.filters.translatable(value.data.product.name, value.data.product.name_i18n, $i18n.locale)"></h3>
					<div v-if="value.data.product.id" class="font-weight-bold" style="font-size: 0.75rem !important; line-height: 1rem" v-text="$t('qrCodeCard.productNumber', {
						number: value.data.barcode,
					})"></div>

					<div v-if="value.data.event"
						style="font-size: 0.75rem !important; line-height: 1rem"
						class="font-weight-bold">
						<span v-if="$options.filters.tsToFormat(value.data.event.start_datetime * 1000, 'LL', true) !== $options.filters.tsToFormat(value.data.event.end_datetime * 1000, 'LL', true)">
							{{ $t('productWaiverCard.visitingDateComplete', {
								start_date: $options.filters.tsToFormat(value.data.event.start_datetime * 1000, 'LL', true),
								start_time: $options.filters.tsToFormat(value.data.event.start_datetime * 1000, 'LT', true),
								end_date: $options.filters.tsToFormat(value.data.event.end_datetime * 1000, 'LL', true),
								end_time: $options.filters.tsToFormat(value.data.event.end_datetime * 1000, 'LT', true)
							}) }}
						</span>
						<span v-else>
							{{
							$t('productWaiverCard.visitingDateCompleteSameDay', {
								start_date: $options.filters.tsToFormat(value.data.event.start_datetime * 1000, 'LL', true),
								start_time: $options.filters.tsToFormat(value.data.event.start_datetime * 1000, 'LT', true),
								end_time: $options.filters.tsToFormat(value.data.event.end_datetime * 1000, 'LT', true)
							})
							}}
						</span>
					</div>
				</div>
			</div>
			<div v-if="value.data.event_group" class="d-flex align-center mt-2">
				<v-icon>mdi-calendar</v-icon>
				<div class="ml-3">
					<div class="overline font-weight-light" style="font-size: 0.55rem !important; line-height: 0.55rem" v-text="$t('productWaiverCard.visitingDate')"></div>
					<div v-if="value.data.event_group" v-text="value.data.event_group ? value.data.event_group.date : null"></div>
					<div v-else v-text="$t('productWaiverCard.na')"></div>
				</div>
			</div>
			<div v-if="value.data.account" class="d-flex align-center mt-2 mb-4">
				<v-icon>mdi-account</v-icon>
				<div class="ml-3">
					<div class="overline font-weight-light" style="font-size: 0.55rem !important; line-height: 0.55rem" v-text="$t('productWaiverCard.assignedTo')"></div>
					<div v-if="value.data.account" v-text="value.data.account ? $options.filters.completeUserName({ data: value.data.account }) : null"></div>
					<div v-else v-text="$t('productWaiverCard.na')"></div>
				</div>
			</div>
			<DigitalWallet
				v-if="value.data.type === 'wallet'"
				:source="'transaction-detail'"
				:itemId="transactionDetailId"
				:itemIdx="value.data.transaction_detail_index"
				class="mt-4"
			/>
			<div :key="waiver.hash" v-for="waiver in value.data.waivers" class="mt-2">
				<v-btn color="button" block :href="waiver.signed ? null : waiver.url" target="_blank" :loading="loadingWaiver === waiver.hash" :disabled="loadingWaiver === waiver.hash" @click="waiver.signed ? handleShowWaiverClick(waiver) : null">
					<span v-text="$t('waiver.' + (waiver.signed ? 'see' : 'sign'))"></span>
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import Modal from '@/components/Modal'
import QrCode from '@/components/QrCode'
import DigitalWallet from '@/components/DigitalWallet/DigitalWallet'
import { BaseModel, EComService } from '@connectngo/sdk';

export default {
	name: 'ProductWaiverCard',

	components: { Modal, QrCode, DigitalWallet },
	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loadingWaiver: false,
		showPdf: false,
		waiverPdf: new BaseModel(),
	}),

	computed: {
		_value: {
			get () {
				return this.value
			},
			set (value) {
				this.$emit('input', value)
			},
		},
		_hiddeQrCode() {
			return !(this.value.data?.returned == 1)
		},

		transactionDetailId() {
			if (this.value.data.type === 'wallet') {
				return this.value.data.transaction_detail_id
			}

			return this.value.data.id
		}
	},

	methods: {
		handleShowWaiverClick(waiver) {
			this.loadingWaiver = waiver.hash;
			return Promise.all([
				new EComService().getWaiverPdf(waiver.hash),
			])
				.then(([waiverPdf]) => {
					Object.assign(this, { waiverPdf, showPdf: true });
				})
				.catch(reason => this.$handleError(this, reason))
				.finally(() => (this.loadingWaiver = false));
		},
	},
}
</script>
