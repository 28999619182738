<template>
	<v-skeleton-loader v-if="skeleton" type="list-item"></v-skeleton-loader>
	<v-card data-testid="transactionCard" v-else v-bind="$attrs" v-on="$listeners" :to="to">
		<div v-if="!value.data.exchange" class="d-flex" style="gap: 0.5rem">
			<QrCode data-testid="transactionQRCode" v-if="_hiddeQrCode" v-model="value.data.barcode" />
			<v-container v-if="value.data.type !== 'RESERVATION'">
				<v-row>
					<v-col sm="4" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.orderNumber')"></div>
						<h3 data-testid="orderNumber" class="secondaryDark--text my-1" v-text="(value.data.transaction_id || '').toUpperCase()"></h3>
						<h5 class="red--text" v-if="this.value.data.isReturned" v-text="$t('account.transactions.rescheduled')"></h5>
						<h6 data-testid="transactionNumber" v-else v-text="$t('transactionCard.id', {
							number: value.data.barcode,
						})"></h6>
					</v-col>
					<v-col sm="4" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.date')"></div>
						<h3 data-testid="transactionDate" class="my-1" v-text="$options.filters.tsToFormat(value.data.created_at, $t('date.format.ymd'))"></h3>
					</v-col>
					<v-col sm="4" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.amount')"></div>
						<h3 data-testid="transactionTotal" class="my-1" v-text="$options.filters.currency(value.data.total || value.data.amount)"></h3>

						<div v-if="!hideDetails && to" class="d-flex justify-end mt-8 mt-md-1">
							<router-link class="text-decoration-none d-flex align-center" :to="to">
								<span v-text="$t('transactionCard.viewDetails')"></span>
								<v-icon right x-small>mdi-arrow-right</v-icon>
							</router-link>
						</div>
					</v-col>
				</v-row>
			</v-container>

			<v-container v-if="value.data.type === 'RESERVATION'">
				<v-row>
					<v-col sm="2" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationNumber')"></div>
						<h3 class="secondaryDark--text my-1" v-text="(value.data.reservation_id || '').toUpperCase()"></h3>
						<h6 v-text="$t('transactionCard.id', {
							number: value.data.uuid,
						})"></h6>
					</v-col>
					<v-col sm="2" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservedOn')"></div>
						<h3 class="my-1" v-text="$options.filters.tsToFormat(value.data.created_at, $t('date.format.ymd'))"></h3>
					</v-col>
					<v-col sm="2" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationDate')"></div>
						<h3 class="my-1" v-text="$options.filters.tsToFormat(value.data.reservation_date, $t('date.format.ymd'))"></h3>
					</v-col>
					<v-col sm="2" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.amount')"></div>
						<h3 class="my-1" v-text="$options.filters.currency(value.data.total)"></h3>
					</v-col>
					<v-col sm="4" class="py-md-6">
						<div class="caption text--disabled text-uppercase" v-text="$t('transactionCard.reservationBalance')"></div>
						<h3 class="my-1" v-text="$options.filters.currency(value.data.balance)"></h3>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</v-card>
</template>

<script>
import QrCode from '@/components/QrCode';
import { BaseModel } from '@connectngo/sdk';

export default {
	name: 'TransactionCard',

	components: { QrCode },

	props: {
		value: {
			type: BaseModel,
			default: () => new BaseModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		hideQrCode: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		to: {
			type: Object,
			default: () => ({ name: 'account_section', params: { section: 'transactions' } }),
		},
	},
	computed: {
		_hiddeQrCode() {
			return !((this.value.data.isReturned) || this.hideQrCode) && this.value.data.type !== 'RESERVATION'
		},
	}
}
</script>
