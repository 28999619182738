<template>
  <v-alert
      :type="allReloadableAssigned ? 'info' : 'error'"
      :icon="allReloadableAssigned ? 'mdi-check' : 'mdi-alert-circle-outline'"
      outlined
      rounded
      text>
    <span class="mr-4">{{$t(allReloadableAssigned ? 'reloadTag.all_products_assigned' : 'reloadTag.assign_all_products')}}</span>
    <span class="mr-4">{{reloadItemTotalAssigned}}/{{reloadItemTotal}}</span>
    <span>{{new Date().toLocaleDateString($i18n.locale)}}</span>
  </v-alert>
</template>

<script>

import ReloadV2Mixin from '@/mixins/ReloadV2Mixin'

export default {
  name: "CartReloadBanner",
  props : {
    reloadItems : {
      type : Array ,
      required : true
    }
  },

  mixins: [ReloadV2Mixin],
}
</script>


<style scoped lang="scss">

</style>
