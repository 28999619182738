<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions"></v-skeleton-loader>
	<v-form v-else id="editBillingInfoCard" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation @submit="handleSubmit">
		<v-card v-bind="$attrs" v-on="$listeners">
			<v-card-title class="text-left">
				<span v-text="$t('account.edit.billing.title')"></span>
			</v-card-title>
			<v-card-text>

				<v-expand-transition>
					<v-alert v-if="saved" type="success" prominent dismissible>
						<span v-text="$t('alert.infoUpdated')"></span>
					</v-alert>
				</v-expand-transition>

				<AddressAutocompleteField
					data-testid="billingAddress"
					v-model="_value.data.address.address1"
					:label="$t('input.address')"
					:error-messages="formErrors.address1"
					:rules="[rules.required]"
					:loading="skeleton"
					:messages="$t('editBillingInfoCard.addressTip')"
					class="mb-4"
					outlined
					@update="handleAddressesFound"
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				 />

				<v-text-field
					data-testid="billingUnitNumber"
					v-model="_value.data.address.address2"
					:label="$t('input.address2')"
					:error-messages="formErrors.address2"
					:loading="skeleton"
					outlined
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				 ></v-text-field>

				<v-text-field
					data-testid="billingCity"
					v-model="_value.data.address.city"
					:label="$t('input.city')"
					:error-messages="formErrors.city"
					:rules="[rules.required]"
					:loading="skeleton"
					outlined
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				 ></v-text-field>

				<v-text-field
					data-testid="billingProvince"
					v-if="!parseInt($root.websiteConfig.data.fields['disable-province-field'])"
					v-model="_value.data.address.state_province_region"
					:label="$t('input.province')"
					:error-messages="formErrors.province"
					:rules="[rules.required]"
					:loading="skeleton"
					outlined
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				></v-text-field>

				<v-text-field
					data-testid="billingCountry"
					v-model="_value.data.address.country"
					:label="$t('input.country')"
					:error-messages="formErrors.country"
					:rules="[rules.required]"
					:loading="skeleton"
					outlined
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				></v-text-field>

				<v-text-field
					data-testid="billingPostal"
					v-model="_value.data.address.zipcode_postalcode"
					:label="$t('input.postal')"
					:error-messages="formErrors.postal"
					:rules="this.postalRules"
					:loading="skeleton"
					outlined
					@input="handleInput"
					:disabled="$route.params.is_b2b ? true : false"
				 ></v-text-field>
			</v-card-text>
			<v-card-actions v-if="!$route.params.is_b2b || $route.name == 'cart_step'">
				<v-btn data-testid="continue" color="button" type="submit" :loading="loading" :disabled="!canSave" block @click.prevent="handleSubmit">
					<v-icon v-if="btnIcon" left v-text="btnIcon"></v-icon>
					<span v-text="btnText ? btnText : $t('btn.save')"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import AddressAutocompleteField from '@/components/AddressAutocompleteField';
import {Rules, AccountModel, EComService} from '@connectngo/sdk';
import globalVariables from "@/global";

export default {
	name: 'EditBillingInfoCard',

	components: { AddressAutocompleteField },

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
		btnIcon: {
			type: String,
			default: 'mdi-content-save-outline',
		},
		btnText: {
			type: String,
			default: null,
		},
		noSnack: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		saved: false,
		formIsValid: true,
		formErrors: {},
	}),

	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		canSave() {
			return !this.loading && this.formIsValid && !this.skeleton;
		},
		canSubmit() {
			return !this.loading && !this.skeleton;
		},
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
			};
		},
		postalRules() {
			return this.$root.websiteConfig.data.fields.postal_code_required === '1' ? [this.rules.required] : [];
		}
	},

	methods: {
		handleInput() {
			this.formErrors = {};
		},
		handleAddressesFound(address) {
			this._value.data.address.address1 = address.name;
			this._value.data.address.country = address.country;
			this._value.data.address.city = address.locality;
			this._value.data.address.state_province_region = address.administrative_area_level_1;
			this._value.data.address.zipcode_postalcode = address.postal_code;
		},
		handleSubmit() {
			if (this.$refs.form.validate() || this.$route.params.is_b2b) {
				this.loading = true;
				new EComService().patchAccount(this.value.data.id, {
					address: {
						address1: this.value.data.address.address1,
						address2: this.value.data.address.address2,
						city: this.value.data.address.city,
						country: this.value.data.address.country,
						state_province_region: this.value.data.address.state_province_region,
						zipcode_postalcode: this.value.data.address.zipcode_postalcode,
					}
				}).then(() => {
					if (!this.noSnack) {
						this.$snack(this.$i18n.t('alert.infoUpdated'));
					}

					this.$emit('completed');
				})
				.catch(reason => {
					this.$handleError(this, reason)
				})
				.finally(() => (this.loading = false));
			}
		},
	},
}
</script>
