import { render, staticRenderFns } from "./ReloadProductCard.vue?vue&type=template&id=0977cf13&scoped=true&"
import script from "./ReloadProductCard.vue?vue&type=script&lang=js&"
export * from "./ReloadProductCard.vue?vue&type=script&lang=js&"
import style0 from "./ReloadProductCard.vue?vue&type=style&index=0&id=0977cf13&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0977cf13",
  null
  
)

export default component.exports