<template>
	<div>
		<h3 class="mb-4">{{$t('combo.includes')}}</h3>
		<ul class="mb-4">
			<li v-for="product in productsSummary" :key="product.name">
				{{ product.quantity }}x {{ product.name }}
			</li>
		</ul>
		<div v-if="firstPart">
			<span v-html="firstPart"></span>
			<span v-if="!showFullDescription && secondPart">...</span>
			<a v-if="secondPart && !showFullDescription" @click.prevent="toggleDescription" class="text-black">
				{{ $t('product.readMore') }}
			</a>
			<span v-if="showFullDescription" v-html="secondPart"></span>
			<a v-if="showFullDescription && secondPart" @click.prevent="toggleDescription" class="text-black">
				{{ $t('product.readLess') }}
			</a>
		</div>
		<div v-if="comboProductsDesc.length && showFullDescription">
			<h4>{{$t('combo.includes')}}</h4>
			<ul>
				<li v-for="product in comboProductsDesc" :key="product.name">
				{{ capitalize(product.name) }}: {{ convertHtmlToPlainText(product.description) }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
	name: 'ComboDetailIntro',
	props: {
		initialState: {
			type: Boolean,
			default: false
		},
		productsSummary: {
			type: Array,
			default: () => []
		},
		comboDescription: {
			type: String,
			default: ''
		},
		comboProductsDesc: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			showFullDescription: this.initialState,
			firstPart: '',
			secondPart: ''
		};
	},
	mounted() {
		this.splitDescription();
	},
	methods: {
		toggleDescription() {
			this.showFullDescription = !this.showFullDescription;
		},
		convertHtmlToPlainText(htmlString) {
			const parser = new DOMParser();
			const dom = parser.parseFromString(htmlString, 'text/html');
			return dom.body.textContent || '';
		},
		capitalize(str) {
			return _.capitalize(str);
		},
		splitDescription() {
			const plainTextDescription = this.replaceH1WithH3(this.comboDescription);
			const words = plainTextDescription.split(' ');

			this.firstPart = words.slice(0, 30).join(' ');
			this.secondPart = words.slice(30).join(' ');

			if (!this.secondPart) {
				this.showFullDescription = true;
			}
		},
		replaceH1WithH3(htmlString) {
			return htmlString.replace(/<h1>/g, '<h3>');
		}
	},
	watch: {
		comboDescription: 'splitDescription'
	}
	};
</script>

<style scoped lang="scss">
	h3, h4 {
		color: #413F3F;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		text-align: left;
	}
	li, p, div {
		font-weight: 400;
		font-size: 14px;
		color: #686A71;
	}
	a {
		font-weight: 600;
		text-decoration: underline;
	}
</style>
