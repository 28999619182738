<template>
	<div @click="mapClicked" class="interactive-map-button"
		 :style="`background-image: url(${interactiveMap.mappable.image_url});`">
		<div class="btn-overlay"></div>
		<h3 class="mb-0">
			{{$options.filters.translatable(interactiveMap.name, interactiveMap.name_i18n, $i18n.locale)}}
		</h3>
	</div>
</template>

<script>
export default {
	name: "InteractiveMapButton",
	props : {
		interactiveMap : {
			require:true,
			type:Object,
		},
		onClick : {
			default : () => {},
			type : Function
		}
	},
	methods : {
		mapClicked() {
			this.onClick();
			this.$router.push({name : 'map'});
		}
	}
}
</script>

<style scoped>
	div.interactive-map-button {
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 70px;
		border-radius: 8px;
		background-size: cover;
		cursor: pointer;
		display:flex;
		justify-content:center;
		align-items:center;
		position:relative;
		color: white;
	}

	div.interactive-map-button > .btn-overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
		border-radius: 8px;
	}

	div.interactive-map-button > h3 {
		z-index: 1;
	}
</style>
