<template>
	<v-app>
		<v-main class="fill-height">
			<v-sheet color="background" class="fill-height d-flex flex-column align-center justify-center text-center" dark>

				<!-- TITLE -->
				<h1 v-text="$t('error.api.title')"></h1>

				<!-- DESCRIPTION -->
				<p class="mt-4" v-text="$t('error.api.desc')"></p>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
	name: 'Error',
});
</script>
