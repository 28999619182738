<template>
	<Modal v-model="_visible" v-bind="$attrs" v-on="$listeners">

		<template #body>

			<v-card-text class="px-0">
				<v-expansion-panels class="px-4 elevation-0" flat>

					<v-expansion-panel v-if="_manageToken" class="px-0" v-model="panel">

						<v-expansion-panel-header
							style="border:1px solid #009aa6;border-radius: 5px; padding-top: 0; padding-bottom: 0; padding-left: 5px;">
							<div class="d-flex align-center justify-space-around w-100 px-0">
								<div v-if="creditCardImage">
									<img :src="creditCardImage" width="35" :alt="$t('creditCardPreview.creditCard')"/>
								</div>
								<h5 class="ml-2" v-text="creditCardTitle"></h5>
								<h5 class="ml-2" v-text="$t('creditCardPreview.expiresOn', {date:expiryDate})"></h5>
							</div>
						</v-expansion-panel-header>

						<v-expansion-panel-content>

							<v-form class="mt-4" v-model="creditCardUpdateFormValid" @submit.prevent="handleUpdate"
									:loading="loading" :disabled="loading">
								<v-text-field
									v-model="creditCardUpdateFormData.name"
									name="ccname"
									prepend-inner-icon="mdi-account"
									:rules="[rules.required]"
									:label="$t('input.ccName')"
									:error-messages="formErrors.name"
									:autofocus="$vuetify.breakpoint.mdAndUp"
									autocomplete="cc-name"
									outlined
									@input="formErrors = {}"
								></v-text-field>

								<v-text-field
									v-model="creditCardUpdateFormData.exp"
									v-mask="'##/##'"
									name="exp"
									prepend-inner-icon="mdi-calendar-blank-outline"
									:rules="[rules.required, rules.creditCardExp]"
									:label="$t('input.ccExp')"
									:error-messages="formErrors.expiration"
									autocomplete="cc-exp"
									outlined
									@input="formErrors = {}"
								></v-text-field>

								<div v-if="loading" class="d-flex justify-center">
									<v-progress-circular indeterminate color="secondaryDark" size="64"/>
								</div>

								<div v-if="!loading">
									<v-btn class="mt-4 white--text" type="submit" color="button"
										   :disabled="!creditCardUpdateFormValid" block large>
										<span v-text="$t('btn.save')"></span>
									</v-btn>

									<v-btn color="error" class="mt-4" outlined block large
										   @click="handleDelete()">
										<v-icon left>mdi-trash-can</v-icon>
										<span v-text="$t('creditCardDialog.btnRemoveCreditCard')"></span>
									</v-btn>
								</div>
							</v-form>

						</v-expansion-panel-content>

					</v-expansion-panel>

				</v-expansion-panels>

				<v-card v-if="!_manageToken" class="px-0 py-0" outlined>
					<v-card-text v-if="!_manageToken">
						<p class="text-center" v-text="$t('creditCardDialog.body')"></p>
						<AdyenTokenizationForm :tokenization-engine="tokenizationEngine" :wallet-id="walletId"
											   :on-submit="handleSubmit" :token="_token"/>
					</v-card-text>
				</v-card>

			</v-card-text>
		</template>
		<template #buttons v-if="! _manageToken">
			<v-btn color="button" data-test-selector="creditCardDialog_cancel" :disabled="loading" :loading="loading"
				   outlined @click="_visible = false">
				<span v-text="$t('btn.cancel')"></span>
			</v-btn>
		</template>
	</Modal>
</template>

<script>
import Modal from './Modal'
import TokenForm from './TokenForm'
import { EComService, Rules } from '@connectngo/sdk'
import AdyenTokenizationForm from '@/components/AdyenTokenizationForm'

export default {
	name: 'CreditCardDialog',

	components: { Modal, TokenForm, AdyenTokenizationForm },

	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		manageToken: {
			type: Boolean,
			default: false
		},
		walletId: {
			type: [Number, String],
			required: true,
		},
		token: {
			type: Object,
			default: null,
		},
		creditCardTitle: {
			type: String,
			default: '',
		},
		creditCardImage: {
			type: String,
			default: null,
		},
		expiryDate: {
			type: String,
			default: null,
		}
	},

	data () {
		return {
			loading: false,
			skeleton: false,
			rules: {},
			formErrors: {},
			toggleDetails: false,
			panel: [0],
			tokenizationEngine: null,
			creditCardUpdateFormValid: true,
			creditCardUpdateFormData: {
				name: '',
				exp: '',
			},
		}
	},

	computed: {

		_token: {
			get () {
				return this.token
			},
			set (value) {
				this.$emit('update:token', value)
			},
		},

		_visible: {
			get () {
				return this.visible
			},
			set (card) {
				this.$emit('update:visible', card)
			},
		},

		_manageToken: {
			get () {
				return this.manageToken
			},
			set (value) {
				this.$emit('update:manageToken', value)
			},
		},
	},

	methods: {
		init () {
			new EComService().getTokenizationEngine()
				.then(response => {
					this.tokenizationEngine = response.data.data
				})
		},
		handleInput () {
			this.formErrors = {}
		},
		handleSubmit () {
			this._visible = false
			this.$emit('on-token-change')
		},
		handleDelete () {
			this.loading = true
			new EComService().deleteToken(this.token.data.id, this.walletId).then(data => {
				this._manageToken = false
				this.$emit('on-token-change')
				this.loading = false
				this._visible = false
			})
		},
		handleUpdate () {
			this.loading = true
			new EComService().updateToken(this.token.data.id, {
				token: this.token.data.data.token,
				tokenizationId: this.token.data.id,
				walletId: this.walletId,
				cardHolderName: this.creditCardUpdateFormData.name,
				expiryMonth: this.creditCardUpdateFormData.exp.split('/', 2)[0],
				expiryYear: this.creditCardUpdateFormData.exp.split('/', 2)[1],
				reference: 'POS_update_token_' + this.walletId + '_' + Date.now(),
			})
				.then((response) => {
					this.$emit('on-token-change', response)
					this.loading = false
				})
		},
		load () {
			this.skeleton = false
			this.loading = false
		},

	},

	created () {
		this.rules = {
			required: value => Rules.required(value) || this.$t('rules.required'),
			creditCardExp: value => Rules.creditCardExp(value) || this.$t('rules.creditCardExp'),
		}
		this.init()
		this.load()

	},

	watch: {
		token: {
			deep: true,
			handler () {
				this.creditCardUpdateFormData.name = this.token.data.data.cardHolderName
				this.creditCardUpdateFormData.exp = this.token.data.data.expiryDate
			},
		},
	}
}
</script>
