<template>
	<v-btn v-bind="$attrs" v-on="$listeners" :color="color" class="d-flex align-center" style="min-height: 4.33rem" block :disabled="_disableSlot || remaining <= 0">
		<div style="flex: 4" class="text-center text-capitalize" :class="{'caption': isCrossSale}">
			<span v-text="period"></span>
			<span v-if="remaining > 0" >
				<span v-if="showAvailability && !_disableSlot">
					<br><span v-if="!_disableSlot" v-text="$tc(isCrossSale && 'calendar.cross_sale_places' || 'calendar.places', remaining, {
						amount: remaining
					})"></span>
				</span>
				<br><span v-if="_disableSlot" v-text="$t('calendar.unavailable')">
					</span>
			</span>

			<span v-else><br>{{ $t('calendar.soldOut') }}</span>
		</div>
		<div style="flex: 1" class="ml-3">
			<v-progress-linear :value="remaining * 100 / total === 0 ? 100 : 0" height="5" :color="_color" class="mt-1" rounded></v-progress-linear>
			<v-progress-linear :value="remaining * 100 / total <= 25 ? 100 : 0" height="5" :color="_color" class="mt-1" rounded></v-progress-linear>
			<v-progress-linear :value="remaining * 100 / total <= 75 ? 100 : 0" height="5" :color="_color" class="mt-1" rounded></v-progress-linear>
			<v-progress-linear :value="remaining * 100 / total < 100 ? 100 : 0" height="5" :color="_color" class="mt-1" rounded></v-progress-linear>
		</div>
	</v-btn>
</template>

<script>

export default {
	name: 'CalendarSlot',

	props: {
		color: {
			type: String,
			default: null,
		},
		remaining: {
			type: Number,
			default: 0,
		},
		total: {
			type: Number,
			default: 0,
		},
		period: {
			type: String,
			default: null,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		showAvailability: {
			type: Boolean,
			default: true,
		},
		isCrossSale: {
			type: Boolean,
			default: false
		},
		disableSlot: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		_color() {
			let color;
			if (this.remaining === 0 || this._disableSlot) {
				color = '#ccc';
			} else {
				if (this.selected) {
					color = 'white';
				} else {
					color = this.color;
				}
			}
			return color;
		},
		_disableSlot() {
			return this.disableSlot;
		}
	},
	mounted() {
		this.$emit('slotMounded');
	}
}
</script>
