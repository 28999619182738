<template>
	<v-skeleton-loader v-if="skeleton" type="card-heading,list-item-two-line,list-item-two-line,list-item-two-line,actions"></v-skeleton-loader>
	<v-form v-else id="editPasswordCard" ref="form" v-model="formIsValid" :disabled="!canSubmit" lazy-validation @submit="handleSubmit">
		<v-card v-bind="$attrs" v-on="$listeners">
			<v-card-title class="text-left">
				<span v-text="$t('account.edit.password.title')"></span>
			</v-card-title>
			<v-card-text>

				<p v-text="$t('account.edit.password.desc')"></p>

				<v-expand-transition>
					<v-alert v-if="saved" type="success" prominent dismissible>
						<span v-text="$t('alert.passwordUpdated')"></span>
					</v-alert>
				</v-expand-transition>

				<v-text-field
					v-model="data.password"
					:label="$t('input.newPassword')"
					:error-messages="formErrors.password"
					:rules="[rules.required, rules.min]"
					:loading="skeleton"
					:type="showPassword ? 'text' : 'password'"
					:append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					outlined
					@click:append="showPassword = !showPassword"
					@input="handleInput"
				></v-text-field>

				<v-text-field
					v-model="data.confirmation"
					:label="$t('input.confirmation')"
					:error-messages="formErrors.confirmation"
					:rules="[rules.required, rules.identical, rules.min, rules.isValidPassword]"
					:loading="skeleton"
					:type="showConfirmation ? 'text' : 'password'"
					:append-icon="!showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
					outlined
					@click:append="showConfirmation = !showConfirmation"
					@input="handleInput"
				></v-text-field>

				<v-expand-transition>
					<StrengthIndicator
						v-if="formIsDirty"
						class="my-4"
						:title="$t('createAccountForm.passwordMustContain')"
						:conditions="passwordConditions"
					></StrengthIndicator>
				</v-expand-transition>

			</v-card-text>
			<v-card-actions>
				<v-btn color="button" type="submit" :loading="loading" :disabled="!canSave" block @click.prevent="handleSubmit">
					<v-icon left>mdi-content-save-outline</v-icon>
					<span v-text="$t('btn.confirm')"></span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import {Rules, AccountModel, EComService} from '@connectngo/sdk';
import StrengthIndicator from "@/components/StrengthIndicator";
import PasswordConditions from "@/mixins/PasswordConditions";

export default {
	name: 'EditPasswordCard',

	mixins: [ PasswordConditions() ],

	components: { StrengthIndicator },

	props: {
		value: {
			type: AccountModel,
			default: () => new AccountModel(),
		},
		skeleton: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		loading: false,
		saved: false,
		formIsValid: true,
		showCurrent: false,
		showPassword: false,
		showConfirmation: false,
		formIsDirty: false,
		formErrors: {},
		data: {
			current: '',
			password: '',
			confirmation: '',
		},
	}),

	computed: {
		canSave() {
			return !this.loading && this.formIsValid && !this.skeleton;
		},
		canSubmit() {
			return !this.loading && !this.skeleton;
		},
		rules() {
			return {
				required: value => Rules.required(value) || this.$t('rules.required'),
				min: value => Rules.min(8, value) || this.$t('rules.min', { amount: 8 }),
				identical: () => Rules.identical(this.data.password, this.data.confirmation) || this.$t('rules.passwordConfirm'),
				isValidPassword: () => this.passwordIsValid || this.$t('rules.passwordInvalid'),
			};
		},
	},

	methods: {
		handleInput() {
			this.formIsDirty = true;
			this.formErrors = {};
		},
		handleSubmit() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				return Promise.all([
					new EComService().updatePassword(this.data.password),
					// new EComService().getTags()
				])
					.then(([response]) => {
						this.$snack(this.$i18n.t('alert.passwordUpdated'));
					})
					.catch(reason => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			}
		},
	},

}
</script>
