<template>
	<v-form :disabled="loading" v-bind="$attrs" ref="form" data-test-selector="forgot_password_form" v-model="formIsValid" @submit.prevent="handleSubmit" lazy-validation>

		<v-text-field
			v-model="data.email"
			ref="email"
			:rules="[rules.required, rules.email]"
			:label="$t('input.email')"
			prepend-inner-icon="mdi-account"
			autocomplete="email"
			outlined
			:disabled="loading"
			:error-messages="formErrors.email || formErrors.password"
			background-color="white"
			@input="formErrors = {}"
		></v-text-field>

		<v-btn type="submit" color="button" class="mt-4" data-test-selector="forgot_password_form_sign_int" :disabled="loading" :loading="loading" block>
			<span v-text="$t('btn.send')"></span>
		</v-btn>
	</v-form>
</template>

<script>
import { Rules, EComService } from '@connectngo/sdk';

export default {
	name: "ForgotPasswordForm",

	methods: {
		handleSubmit() {
			this.formErrors = {};
			if (this.$refs.form.validate()) {
				this.loading = true;

				const resetUrl = this.$router.resolve({
					name: 'password.reset',
					params: { lang: this.$i18n.locale }
				})

				new EComService().forgotPassword(this.data.email, window.location.origin + resetUrl.href)
					.then(response => {
						this.$snack(this.$i18n.t('alert.forgotPasswordEmailSent'));
						this.$emit('reset', response);
					})
					.catch(error => {
						if (error.response.status === 404) {
							this.$snack(this.$i18n.t('alert.forgotPasswordEmailSent'));
							this.$emit('reset', error);
						} else {
							this.formErrors.email = error.message;
						}
					})
					.finally(() => this.loading = false);
			} else {
				this.$refs.email.focus();
			}
		},
	},

	data() {
		return {
			formSubmit: false,
			loading: false,
			formIsValid: false,
			formErrors: {},
			data: {
				email: '',
			},
			rules: {
				required: value => Rules.required(value) || this.$t('rules.required'),
				email: value => Rules.email(value) || this.$t('rules.email'),
			},
		}
	},

	created() {
		setTimeout(() => {
			this.$refs.email.$el.querySelector('input').focus();
		}, 300);
	},
}
</script>
