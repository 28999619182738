<template>
	<component v-bind="pages[$route.name || 'index']"></component>
</template>

<script>
import Index from '@/routes/App/Account/ConnectedAccounts/Index'
import Account from '@/routes/App/Account/ConnectedAccounts/Account'
import Edit from '@/routes/App/Account/ConnectedAccounts/Edit'
import Transactions from '@/routes/App/Account/ConnectedAccounts/Transactions'
import TransactionDetails from '@/routes/App/Account/ConnectedAccounts/TransactionDetails'
import Wallet from '@/routes/App/Account/ConnectedAccounts/Wallet'
import WalletDetails from '@/routes/App/Account/ConnectedAccounts/WalletDetails'

export default {
	name: 'ConnectedAccounts',

	metaInfo() {
		return {
			title: this.$i18n.t('route.account_connected'),
			meta: [
				{ property: 'og:title', content: this.$i18n.t('route.account_connected') },
				{ property: 'og:url', content: window.location.href },
			],
		};
	},

	data: () => ({
		pages: {},
	}),

	created() {
		this.$route.meta.section = this.$i18n.t('route.account_connected');
		if (this.$route.meta.sub) {
			this.$route.meta.sub = this.$root.user.data.firstname;
		}
		if (this.$route.params.action) {
			this.$route.meta.action = this.$i18n.t('route.account_connected_' + this.$route.params.action);
		}

		this.pages = {
			account_section: { is: Index },
			account_section_sub: { is: Account },
		};

		switch (this.$route.params.action) {
			case 'edit':
				this.pages.account_section_sub_action = { is: Edit };
				break;
			case 'transactions':
				this.pages.account_section_sub_action = { is: Transactions };
				this.pages.account_section_sub_action_param1 = { is: TransactionDetails };
				break;
			case 'wallet':
				this.pages.account_section_sub_action = { is: Wallet };
				this.pages.account_section_sub_action_param1 = { is: WalletDetails };
				break;
		}
	},
}
</script>
